import Modal from "../modal";
import Box from "@mui/material/Box";
import ModalHeader from "../pageHeader/ModaHeader";
import Grid from "@mui/material/Grid";
import InputController from "../formController/InputController";
import * as React from "react";
import { useTranslation } from "react-i18next";
import QuickGuideImport from "./utilis";


const ImportComponent = (parameters) => {
    const { isOpen, handleClose, handleSubmit, onSubmit, control, errors, isValid, title, subtitle, importTemplateURL } = parameters;
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left'
                    }}
                >
                    <ModalHeader title={title} subtitle={subtitle}
                        isValid={isValid}
                        handleClose={handleClose} onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ mt: 1, width: '100%', height: '320px', display: 'flex', alignItems: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{height: '95px'}} >
                                    <InputController
                                        control={control}
                                        error={errors?.documentId}
                                        name='documentId'
                                        required
                                        fullWidth
                                        label={t("enter.docId")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <QuickGuideImport importTemplateURL={importTemplateURL}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </ModalHeader>
                </Box>
            </form>
        </Modal>
    );
}
export default ImportComponent;
