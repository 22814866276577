import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ManageClientsOfSubscriberGrid } from "./component";
import { PageHeader } from "../pageHeader";
import { REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY } from "../../graphql/client";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../App";

export default function ManageClientsOfSubscriberComponent({ inForm = false }) {
  const [clientItems, setClientItems] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useContext(AppContext);
  const { data, loading } = useQuery(REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, {
    variables: {
      parameters: {
        subscriber: {
          id: user?.subscriber?.id,
        },
        showAllItems: showAll,
        showOnlyDisabledItems: false,
      },
    },
  });

  const handleShowAll = (e) => {
    const value = e.target.checked;
    setShowAll(value);
  };
  useEffect(() => {
    if (!loading && data?.requestClientsOfSubscriber?.data) {
      setClientItems(data?.requestClientsOfSubscriber?.data);
      setIsLoading(false);
    } else if (!loading && !data?.requestClientsOfSubscriber?.data) {
      setIsLoading(false);
    }
  }, [data, loading]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        textAlign: "left",
        px: 1.5,
      }}>
      {inForm ? <></> : <PageHeader />}
      <ManageClientsOfSubscriberGrid
        list={clientItems}
        inForm={inForm}
        loading={isLoading}
        handleShowAll={handleShowAll}
        showAll={showAll}
      />
    </Box>
  );
}
