import {gql} from "@apollo/client";


export const CREATE_EVENT_MUTATION = gql`
    mutation CreateEvent($parameters: CreateEventInput) {
        createEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {

                id

                programContactName

                endDate

                lastYearSponsors

                 agencyNotes{
                    agencyId
                    notes
                }

                description

                startDate

                lastYearAgenda

                isOnline

                sessionTypes{
                    name
                }

                eventForm

                yearEstablished

                logo

                deadline

                industries {
                    id
                    name
                }
                venue

                attendance

                expertTips

                country

                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status

                sponsorshipInfo

                programContactEmailAddress

                programContactPhoneNumber

                guidelineInfo

                speakerEventGuidelines

                sessionTopics

                website

                organizerCompany

                createdAt
                
                name


            }
        }
    }
`;

export const DELETE_EVENT_MUTATION = gql`
    mutation DeleteEvent($parameters: DeleteEventInput) {
        deleteEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const IMPORT_EVENTS_MUTATION = gql`
    mutation ImportEvents($parameters: ImportEventsInput) {
        importEvents(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                lastYearSponsors
                programContactName
                yearEstablished
                programContactEmailAddress
                venue
                lastYearAgenda
                endDate
                sessionTypes{
                    name
                }
                sessionTopics
                deadline
                expertTips
                industries {
                    id
                    name
                }
                website
                organizerCompany
                startDate
                programContactPhoneNumber
                country
                name
                sponsorshipInfo
                speakerEventGuidelines
                guidelineInfo
                status
                id
                description

            }
        }
    }
`;

export const REGISTER_EVENT_MUTATION = gql`
    mutation RegisterEvent($parameters: RegisterEventInput) {
        registerEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                documentId

                id

                programContactName

                endDate

                lastYearSponsors

                description

                startDate

                lastYearAgenda

                isOnline

                sessionTypes{
                    name
                }

                eventForm

                yearEstablished

                logo

                deadline

                industries {
                    id
                    name
                }
                venue

                attendance

                expertTips

                country

                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status

                sponsorshipInfo

                programContactEmailAddress

                programContactPhoneNumber

                guidelineInfo

                speakerEventGuidelines

                sessionTopics

                website

                organizerCompany

                createdAt

                name


            }
        }
    }
`;

export const REQUEST_APPROVED_EVENTS_QUERY = gql`
    query RequestApprovedEvents($parameters: RequestEventsInput) {
        requestApprovedEvents(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                startDate
                deadline
                industries {
                    name
                    id
                }
                status
                venue
                endDate
                name
                id
                country
                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
            }
        }
    }
`;
export const REQUEST_ANALYTICS_OF_EVENTS_QUERY = gql`
    query RequestEventAnalytics($parameters: RequestEventAnalyticsInput) {
        requestEventAnalytics(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                deadline
                name
                analytics {
                    speaking
                    attending
                    sponsor
                    other
                    pending
                    completed
                    clients
                }
                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
            }
        }
    }
`;
export const REQUEST_EVENT_QUERY = gql`
    query RequestEvent($parameters: RequestEventInput) {
        requestEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {

                id

                programContactName

                endDate

                lastYearSponsors

                agencyNotes{
                    agencyId
                    notes
                }

                description

                startDate

                lastYearAgenda

                isOnline

                sessionTypes{
                    name
                }

                eventForm

                yearEstablished

                logo

                deadline

                industries {
                    id
                    name
                }
                venue

                attendance

                expertTips

                country

                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status

                sponsorshipInfo

                programContactEmailAddress

                programContactPhoneNumber

                guidelineInfo

                speakerEventGuidelines

                sessionTopics

                website

                organizerCompany

                createdAt
                
                name


            }
        }
    }
`;
export const REQUEST_EVENT_DETAILS_QUERY = gql`
    query RequestEvent($parameters: RequestEventInput) {
        requestEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                deadline
                startDate
                endDate
                website
                name
            }
        }
    }
`;

export const REQUEST_EVENTS_OF_CLIENT_QUERY = gql`
    query RequestEventsOfClient($parameters: RequestEventsOfClientInput) {
        requestEventsOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                documentId

                id

                programContactName

                endDate

                lastYearSponsors

                 agencyNotes{
                    agencyId
                    notes
                }

                description

                startDate

                lastYearAgenda

                isOnline

                sessionTypes{
                    name
                }

                eventForm

                yearEstablished

                logo

                deadline

                industries {
                    deleted
                    updatedAt
                    id
                    name
                    documentId
                    createdAt
                }
                venue

                attendance

                expertTips

                country

                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status

                sponsorshipInfo

                programContactEmailAddress

                programContactPhoneNumber

                guidelineInfo

                speakerEventGuidelines

                sessionTopics

                website

                organizerCompany

                createdAt

                interestedClients {
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                name


            }
        }
    }
`;

export const REQUEST_EVENTS_QUERY = gql`
    query RequestEvents($parameters: RequestEventsInput) {
       requestEvents(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                isDisabled
                lastYearSponsors
                startDate
                endDate
                sessionTypes{
                    name
                }
                deadline
                industries {
                    id
                    name
                }
                venue
                country
                website
                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status
                name
            }
        }
    }
`;

export const UPDATE_EVENT_MUTATION = gql`
    mutation UpdateEvent($parameters: UpdateEventInput) {
        updateEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                documentId

                id

                programContactName

                endDate

                lastYearSponsors

                 agencyNotes{
                    agencyId
                    notes
                }

                description

                startDate

                lastYearAgenda

                isOnline

                sessionTypes{
                    name
                }

                eventForm

                yearEstablished

                logo

                deadline

                industries {
                    deleted
                    updatedAt
                    id
                    name
                    documentId
                    createdAt
                }
                venue

                attendance

                expertTips

                country

                deadlineSetting {
                    rollingDeadline
                    coldPitch
                }
                status

                sponsorshipInfo

                programContactEmailAddress

                programContactPhoneNumber

                guidelineInfo

                speakerEventGuidelines

                sessionTopics

                website

                organizerCompany

                createdAt

                interestedClients {
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                name


            }
        }
    }
`;
