import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import {InputController, DatePickerController, TextEditorController, AvatarUploadController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import IndustriesListComponent from "../sharedComponents/industries";
import CheckboxController from "../formController/CheckboxController";
import ViewClientsOfAwardComponent from "../viewClientsOfAward";
import {AppContext} from "../../App";


const AwardForm = ({isViewOnly, isUpdate}) => {
    const {t} = useTranslation();
    const {control,getValues, formState: {errors}} = useFormContext();
    const currentSearchParams = new URLSearchParams(window.location.search);
    const isRegistration = currentSearchParams.get('register') === 'true';
    const {user} = useContext(AppContext);
    const awardData = getValues();
    return (
        <>
            {isRegistration ? (<FormContainer title={t('section.details')} to={'details'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.name}
                            name='name'
                            required
                            fullWidth
                            label={t("enter.name")}
                            autoComplete="off"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.website}
                            name='website'
                            required
                            fullWidth
                            label={t("enter.website")}
                            autoComplete="off"
                            type="link"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px', position: 'relative'}}>
                        <label style={{position: 'absolute', top: 0, left: 15}}>{t('enter.deadline')}</label>
                        <CheckboxController control={control} name="isOnline"
                                            label="Deadline is within two weeks and keeping me from completing a submission"/>
                    </Grid>

                </Grid>
            </FormContainer>) : (<>
                <FormContainer title={t('section.details')} to={'details'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <AvatarUploadController label={t("enter.award.logo")} control={control} name="logo"
                                                    readOnly={isViewOnly}/>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.name}
                                        name='name'
                                        required
                                        fullWidth
                                        disabled={isViewOnly}
                                        label={t("enter.name")}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.website}
                                        name='website'
                                        required
                                        fullWidth
                                        disabled={isViewOnly}
                                        label={t("enter.website")}
                                        autoComplete="off"
                                        type="link"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.country}
                                        name='country'
                                        disabled={isViewOnly}
                                        fullWidth
                                        label={t("enter.country")}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <DatePickerController
                                        control={control}
                                        error={errors?.applicationDeadline}
                                        name='applicationDeadline'
                                        disabled={isViewOnly}
                                        required
                                        fullWidth
                                        label={t("enter.applicationDeadline")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.organizerCompany}
                                        name='organizerCompany'
                                        disabled={isViewOnly}
                                        fullWidth
                                        label={t("enter.organizerCompany")}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.programContactName}
                                        name='programContactName'
                                        disabled={isViewOnly}
                                        fullWidth
                                        label={t("enter.programContactName")}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.programContactPhoneNumber}
                                        name='programContactPhoneNumber'
                                        disabled={isViewOnly}
                                        fullWidth
                                        label={t("enter.programContactPhoneNumber")}
                                        autoComplete="off"
                                        type="phone"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.programContactEmailAddress}
                                        name='programContactEmailAddress'
                                        disabled={isViewOnly}
                                        fullWidth
                                        label={t("enter.programContactEmailAddress")}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </FormContainer>
                <FormContainer title={t('section.description')} to={'description'}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextEditorController
                                disabled={isViewOnly}
                                control={control}
                                name='description'
                                label={t('section.description')}
                            />
                        </Grid></Grid>
                </FormContainer>
                <FormContainer title={t('section.awardGuidelines')} to={'awardGuidelines'}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextEditorController
                                disabled={isViewOnly}
                                control={control}
                                name='awardGuidelines'
                                label={t('section.awardGuidelines')}
                            />
                        </Grid></Grid>
                </FormContainer>
                {IndustriesListComponent({disabled: isViewOnly})}
                <FormContainer title={t('section.awardCategories')} to={'awardCategories'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextEditorController
                                disabled={isViewOnly}
                                control={control}
                                name='awardCategories'
                                label={t('section.awardCategories')}
                            />
                        </Grid>
                    </Grid>
                </FormContainer>
                {isViewOnly &&
                <FormContainer title={t('section.agencyNotes')} to={'agencyNotes'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextEditorController
                                control={control}
                                name='agencyNotes'
                                label={t('section.agencyNotes')}
                            />
                        </Grid></Grid>
                </FormContainer>
                }
                {user?.userRole?.name !== 'Administrator'  && isUpdate ? (
                    <FormContainer title={t('section.clients')} to={'clients'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ViewClientsOfAwardComponent inForm={true} award={awardData}/>
                            </Grid>
                        </Grid>
                    </FormContainer>
                ) : null}
            </>)}
        </>
    );
};
export default AwardForm;