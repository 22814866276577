import React from 'react';
import Box from "@mui/material/Box";
import {ManageAssignedTeamMembersComponent} from "./component";

export default function ManageAssignedTeamMembers(props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
            }}
        >
            <ManageAssignedTeamMembersComponent {...props}/>
        </Box>
    );
}
