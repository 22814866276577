import * as React from 'react';
import {useTranslation} from 'react-i18next';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputController from "../formController/InputController";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import {changePasswordFormValidations} from "./helpers";
import {useMutation} from "@apollo/client";
import {FORGET_PASSWORD_MUTATION} from "../../graphql/user";
import Toast from "../alerts";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {IconButton} from "@mui/material";

const ForgetPasswordOfUser = ({isOpen, handleClose}) => {
    const {t} = useTranslation();
    const {control, reset, clearErrors, handleSubmit, formState: {errors, isValid}} = useForm({
        mode: 'onTouched',
        resolver: yupResolver(changePasswordFormValidations(t)),
    });

    useEffect(() => {
        reset({'emailAddress': ''});
        clearErrors()
        // eslint-disable-next-line
    }, [isOpen])
    const [forgetPassword] = useMutation(FORGET_PASSWORD_MUTATION);
    const onSubmit = (data) => {
        forgetPassword({
            variables: {
                parameters: {
                    user: data
                },
            },
        })
            .then(() => {
                toast.dismiss(t('successMessage.forgotPassword'));
                Toast({
                    type: 'success',
                    message: t('successMessage.forgotPassword'),
                });
                handleClose()
                reset()
            })
            .catch((error) => {
                toast.dismiss(t('errorMessage.forgotPassword'));
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                let message = errorMessage[0]
                Toast({
                    type: 'error',
                    message: message
                });
            });
    };
    return (
        <Box sx={{ width: '80%'}}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mb:6
            }}>
                {/* Back Button with Arrow Icon */}
                <IconButton onClick={handleClose}>
                    <ArrowBackSharpIcon/>
                </IconButton>

                {/* Centered Title - the flex: 1 ensures it occupies the remaining space */}
                <Box sx={{display: 'flex', justifyContent: 'center', flex: 1}}>
                    <Typography
                        variant="formHeader"
                        id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-header-label-client"
                        color="#EE814D"
                        sx={{fontSize:"32px"}}
                    >
                        {t('headers.forgotPassword')}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{mt: 1, width: '100%', display: 'flex', alignItems: 'center'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.emailAddress}
                            name='emailAddress'
                            required
                            fullWidth
                            label={t("enter.emailAddress")}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{display: 'flex', justifyContent: 'center', width: '100%', mt:4}}
            >
                <Button
                    type="submit"
                    variant="outlined"
                    sx={{color: 'white',marginColor:'white', borderRadius: '2px'}}
                    disabled={!isValid}
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-importClientss"
                >
                    {t('button.resetCap')}
                </Button>
            </Box>
        </Box>
    );

}
export default ForgetPasswordOfUser