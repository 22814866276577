import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import {getSubmissionStatusChip} from "../utilis/columnHelpers";

const getTaskColumns = ({role = 'event', isUpdate}) => {
    let nameHref = ""
    let interestClientHref = ""
    let columnName
    nameHref = role === 'event' ? `/agency/events/tasks` : '/agency/awards/tasks';
    interestClientHref = role === 'event' ? '/agency/events/browse' : '/agency/awards/browse';
    columnName = role === 'event' ? 'columns.manageTasks.event' : 'columns.manageTasks.award';

    let columns = [{

        field: 'submissionStatus',
        headerName: t('columns.manageTasks.submissionStatus'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => getSubmissionStatusChip(params.row?.submissionStatus),
    },
        {
            field: 'title',
            headerName: t('columns.manageTasks.title'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link
                    href={`${nameHref}/${params?.id}?update_task`}>{params.value}</Link>),
        },
        {
            field: 'isDone',
            headerName: t('columns.manageTasks.isDone'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                return params.row?.isDone ? <DoneIcon sx={{cursor: 'default'}}/> : <CloseIcon sx={{cursor: 'default'}}/>
            },
            valueGetter: (params) => params.row?.isDone ? "Completed" : "Not Completed"
        },
        {
            field: 'dueDate',
            headerName: t('columns.manageTasks.dueDate'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                const deadline = params.value ? params.value : '';
                return <span>{deadline}</span>;
            }
        },
        {
            field: 'createdAt',
            headerName: t('columns.manageTasks.createdAt'),
            flex: 100,
            minWidth: 100,
            maxWidth: 180,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                return params?.value ? dayjs(params?.value).format('MM/DD/YYYY HH:mm A') : null;
            },
            valueGetter: (params) => params?.value ? dayjs(params?.value).format('MM/DD/YYYY HH:mm') : null
        },
        {
            field: 'completedAt',
            headerName: t('columns.manageTasks.completedAt'),
            flex: 100,
            minWidth: 100,
            maxWidth: 180,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                return params?.value ? dayjs(params?.value).format('MM/DD/YYYY HH:mm A') : null;
            },
            valueGetter: (params) => params?.value ? dayjs(params?.value).format('MM/DD/YYYY HH:mm') : null
        },
    ];
    if (!isUpdate) {
        const titleColumnIndex = columns.findIndex(column => column.field === 'title');
        let newColumns = [{
            field: 'interestedClient.client.name',
            headerName: t('columns.manageTasks.client'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (<Link
                href={`/agency/clients/${params?.row?.interestedClient?.client?.id}?update_client`}>{params?.row?.interestedClient?.client?.name}</Link>),
            valueGetter: (params) => params?.row?.interestedClient?.client?.name,
        },
            {
                field: 'interestedClient.recognition.event.name',
                headerName: t(columnName),
                flex: 100,
                minWidth: 250,
                align: "left",
                headerAlign: 'left',
                renderCell: (params) => (<Link
                    href={`${interestClientHref}/${role === 'event' ? params?.row?.interestedClient?.recognition?.event?.id : params?.row?.interestedClient?.recognition?.award?.id}?update_event`}
                >{role === 'event' ? params?.row?.interestedClient?.recognition?.event?.name : params?.row?.interestedClient?.recognition?.award?.name}
                </Link>),
                valueGetter: (params) => role === 'event' ? params?.row?.interestedClient?.recognition?.event?.name : params?.row?.interestedClient?.recognition?.award?.name,
            }];
        columns.splice(titleColumnIndex + 1, 0, ...newColumns);
    }
    return columns;
}
export default getTaskColumns;