import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import GlobalDataGridPro from "../manage";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DELETE_CLIENT_MUTATION, REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, DISABLE_CLIENT_MUTATION } from "../../graphql/client";
import { useMutation } from "@apollo/client";
import DeleteClient from "./Delete";
import { AppContext } from "../../App";
import searchByString from "../../helpers/searchByString";
import getClientColumns from "../manage/globalColumns/clients";
import { CircularProgress } from "@mui/material";
import Toast from '../alerts';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ShowDisabledItems from '../manage/utilis/quickFilters';

const AssignTeamMembers = lazy(() => import('../forms/assignTeamMembers'));
const ImportClient = lazy(() => import('./Import'));


export const ManageClientsOfSubscriberGrid = (
    {
        list,
        inForm = false,
        loading,
        handleShowAll,
        showAll
    }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAssign, setIsOpenAssing] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const { user } = useContext(AppContext);
    const [deleteClient] = useMutation(DELETE_CLIENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            },
            {
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            }],
            awaitRefetchQueries: true
        });

    const [updateClient] = useMutation(DISABLE_CLIENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            }, {
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            }],
            awaitRefetchQueries: true
        });
    const handleDelete = DeleteClient(deleteClient);
    const handleDeleteClient = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const handleAdd = () => {
        navigate(`/agency/clients/new_client`)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const handleOpenAssing = (params) => {
        setSelectedClient(params?.row)
        setIsOpenAssing(!isOpenAssign)
    }
    const handleDisable = (params) => {
        const toastId = 'handleDisableClient'
        Toast({
            autoClose: 7000,
            type: 'info',
            message: 'updating...',
            toastId: toastId
        });
        updateClient({
            variables: {
                parameters: {
                    client: { id: params?.id, isDisabled: !params.isDisabled },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.eventUpdated'),
                    toastId: toastId
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0],
                    toastId: toastId
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...getClientColumns({ role: user?.userRole?.name, handleButtonClick: handleOpenAssing }),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-Client"
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(`/agency/clients/${params?.id}?update_client`)
                        }}
                        showInMenu={true} />,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-Client"
                        icon={<DeleteForeverIcon />}
                        onClick={() => {
                            handleDeleteClient(params.id)
                        }}
                        showInMenu={true} />,
                    <GridActionsCellItem
                        key={params.id}
                        label={params?.row?.isDisabled ? t('button.activateItem') : t('button.disableItem')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Disabled-client"
                        icon={params?.row?.isDisabled ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        onClick={() => {
                            handleDisable(params.row)
                        }}
                        showInMenu={true}
                    />,

                ]
            }
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Clients",
        title: inForm ? "" : "Clients",
        searchLabel: "Search Client",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        customSubToolbar: <ShowDisabledItems toggleShowAll={handleShowAll} showAll={showAll} label={'button.showDisabledClients'} />,
        autoHeight: false,
        handleAdd: handleAdd,
        handleImport: toogleImport,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading
    }
    return (

        <>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm} />
            {isOpen && (
                <Suspense fallback={<CircularProgress />}>
                    <ImportClient isOpen={isOpen} handleClose={toogleImport} />
                </Suspense>
            )}
            {isOpenAssign && (
                <Suspense fallback={<CircularProgress />}>
                    <AssignTeamMembers isOpen={isOpenAssign} handleClose={handleOpenAssing} client={selectedClient} />
                </Suspense>
            )}
        </>

    );
};