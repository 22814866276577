import {REQUEST_TASKS_OF_SUBMISSION_QUERY, UPDATE_TASK_MUTATION} from "../../../graphql/task";
import {REQUEST_SUBMISSION_QUERY} from "../../../graphql/submission";
import {useMutation} from "@apollo/client";
import Toast from "../../alerts";
import {REQUEST_HISTORIES_QUERY} from "../../../graphql/history";

export const useUpdateTask = (submissionId, user, t, refetchSubmissionQuery) => {
    // Mutation for updating task
    const [updateTask] = useMutation(UPDATE_TASK_MUTATION, {
        refetchQueries: [
            {
                query: REQUEST_TASKS_OF_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id: submissionId
                        }
                    }
                }
            },
            {
                query: REQUEST_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id: submissionId
                        }
                    }
                }
            },
            {
                query: REQUEST_HISTORIES_QUERY,
                variables: {
                    parameters: {
                        history: {
                            recordId: submissionId
                        }
                    }
                }
            }
        ],
        awaitRefetchQueries: true
    });

    // Function to handle form submission
    return (task, isDone) => {

        updateTask({
            variables: {
                parameters: {
                    task: {
                        id: task?.id,
                        title: task?.title,
                        dueDate: task?.dueDate,
                        isDone: !isDone
                    },
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        fullName: user?.fullName
                    }
                }
            }
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.taskUpdated')
                });
                refetchSubmissionQuery();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0] || 'An error occurred'
                });
            });
    };
};