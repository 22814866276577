import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';

const ClientColumns = [
    {
        field: 'name',
        headerName: t('columns.viewClients.name'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/clients/${params?.row?.client.id}?update_client`}>{params?.row?.client.name}</Link>),
        valueGetter: (params) => params?.row?.client?.name
    },
    {
        field: 'tasksDue',
        headerName: t('columns.viewClients.tasksDue'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => params?.row?.client?.tasksDue ? params?.row?.client?.tasksDue : 0,
        valueGetter: (params) => params?.row?.client?.tasksDue ? params?.row?.client?.tasksDue : 0


    },
    {
        field: 'submissionsDueThisWeek',
        headerName: t('columns.viewClients.submissionsDueThisWeek'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => params?.row?.client?.submissionsDueThisWeek ? params?.row?.client?.submissionsDueThisWeek : 0,
        valueGetter: (params) => params?.row?.client?.submissionsDueThisWeek ? params?.row?.client?.submissionsDueThisWeek : 0
    },
    {
        field: 'submissionsInProcess',
        headerName: t('columns.viewClients.submissionsInProcess'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => params?.row?.client?.submissionsInProcess ? params?.row?.client?.submissionsInProcess : 0,
        valueGetter: (params) => params?.row?.client?.submissionsInProcess ? params?.row?.client?.submissionsInProcess : 0
    },
    {
        field: 'submissionsTracking',
        headerName: t('columns.viewClients.submissionsTracking'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => params?.row?.client?.submissionsTracking ? params?.row?.client?.submissionsTracking : 0,
        valueGetter: (params) => params?.row?.client?.submissionsTracking ? params?.row?.client?.submissionsTracking : 0
    },

]
export default ClientColumns;