import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Divider, Tooltip} from '@mui/material';
import Modal from "../modal";
const AssignModal = (
    {
        isOpen,
        handleClose,
        title,
        subtitle,
        children
    }) => {
    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            sxBox={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: '12px',
                width: '90%', // Set a percentage width instead of a fixed value
                minHeight: '500px', // Use vh unit instead of a fixed value
                maxHeight: '80vh', // Use vh unit instead of a fixed value
                overflow: 'auto', // Enable scrollbars if the content overflows
                '@media screen and (max-width: 1440px) and (max-height: 900px)': {
                    minHeight: '85vh', // Use vh unit instead of a fixed value
                    maxHeight: '90vh',
                }
            }}

        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    width: '100%', // Use a relative width (100%) to ensure responsiveness
                    overflowX: 'auto',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: "15vh" }}>
                    <Tooltip title={title}>
                        <Box sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            color: 'white' // Apply the color to the Box containing the text
                        }}>
                            <Typography variant="modalHeader">
                                {title}
                            </Typography>
                        </Box>
                    </Tooltip>
                    <Typography variant="body2" sx={{ color: 'white' }}>
                        {subtitle}
                    </Typography>
                </Box>

                <Divider sx={{my: 1, width: '100%'}}/>
                <Grid
                    container
                    spacing={3}
                >
                    {children}
                </Grid>
            </Box>
        </Modal>
    );
};

export default AssignModal;
