import React, {useContext, lazy, Suspense} from "react";
import Grid from "@mui/material/Grid";
import {
    InputController,
    DatePickerController,
    TextEditorController,
    CustomDropdownController,
    AvatarUploadController
} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
const IndustriesListComponent = lazy(() => import('../sharedComponents/industries'));

import {CircularRadioController} from "../formController";
import CheckboxController from "../formController/CheckboxController";
import {FormLabel} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {AppContext} from "../../App";
const ViewClientsOfEventComponent = lazy(() => import('../viewClientsOfEvent'));

const EventForm = ({sessionTypes, isViewOnly,isUpdate}) => {
    const {t} = useTranslation();
    const {control, getValues, formState: {errors}} = useFormContext();
    const currentSearchParams = new URLSearchParams(window.location.search);
    const isRegistration = currentSearchParams.get('register') === 'true';
    const eventData = getValues();
    const {user} = useContext(AppContext);
    return (
        <>
            {isRegistration ? (<FormContainer title={t('section.details')} to={'details'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.name}
                            name='name'
                            required
                            fullWidth
                            label={t("enter.name")}
                            autoComplete="off"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.website}
                            name='website'
                            required
                            fullWidth
                            label={t("enter.website")}
                            autoComplete="off"
                            type="link"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px', position: 'relative'}}>
                        <label style={{position: 'absolute', top: 0, left: 15}}>{t('enter.deadline')}</label>
                        <CheckboxController control={control} name="deadlineSetting.rollingDeadline"
                                            label="Deadline is within two weeks and keeping me from completing a submission"/>
                    </Grid>

                </Grid>
            </FormContainer>) : (<>
                    <FormContainer title={t('section.details')} to={'details'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={2}>
                                <AvatarUploadController label={t("enter.event.logo")} control={control} name="logo"
                                                        readOnly={isViewOnly}/>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                        <InputController
                                            control={control}
                                            error={errors?.name}
                                            name='name'
                                            required
                                            fullWidth
                                            disabled={isViewOnly}
                                            label={t("enter.name")}
                                            autoComplete="off"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                        <InputController
                                            control={control}
                                            error={errors?.website}
                                            name='website'
                                            required
                                            fullWidth
                                            disabled={isViewOnly}
                                            label={t("enter.website")}
                                            autoComplete="off"
                                            type="link"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                        <InputController
                                            control={control}
                                            error={errors?.venue}
                                            name='venue'
                                            disabled={isViewOnly}
                                            fullWidth
                                            label={t("enter.city")}
                                            autoComplete="off"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                        <InputController
                                            control={control}
                                            error={errors?.country}
                                            name='country'
                                            disabled={isViewOnly}
                                            fullWidth
                                            label={t("enter.country")}
                                            autoComplete="off"
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <DatePickerController
                                            control={control}
                                            error={errors?.startDate}
                                            name='startDate'
                                            disabled={isViewOnly}
                                            required
                                            fullWidth
                                            label={t("enter.startDate")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <DatePickerController
                                            control={control}
                                            error={errors?.endDate}
                                            name='endDate'
                                            required
                                            disabled={isViewOnly}
                                            fullWidth
                                            label={t("enter.endDate")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <DatePickerController
                                            control={control}
                                            error={errors?.deadline}
                                            name='deadline'
                                            disabled={isViewOnly}
                                            fullWidth
                                            label={t("enter.deadline")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <InputController
                                            control={control}
                                            error={errors?.attendance}
                                            name='attendance'
                                            disabled={isViewOnly}
                                            fullWidth
                                            label={t("enter.attendance")}
                                            autoComplete="off"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                        <CustomDropdownController
                                            control={control}
                                            disabled={isViewOnly}
                                            controllerName='sessionTypes' // Specify a unique name for the Controller
                                            multiSelect={true} // Set to true for multi-select dropdown
                                            options={sessionTypes} // Pass the array of options here
                                            label={t('section.sessionTypes')}// Optional label for the dropdown
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <CircularRadioController disabled={isViewOnly} control={control} row={true}
                                                                 name='isOnline'
                                                                 label={t('enter.isOnline')}/>
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{height: '95px'}}>
                                        <FormControl>
                                            <FormLabel
                                                id="row-radio-buttons-group-label">{t('enter.deadlineSettings')}</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <CheckboxController disabled={isViewOnly} control={control}
                                                                    name="deadlineSetting.rollingDeadline"
                                                                    label={t('enter.rollingDeadline')}/>
                                                <CheckboxController disabled={isViewOnly} control={control}
                                                                    name="deadlineSetting.coldPitch"
                                                                    label={t('enter.coldPitch')}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('section.organizer')} to={'organizer'}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.organizerCompany}
                                    name='organizerCompany'
                                    disabled={isViewOnly}
                                    fullWidth
                                    label={t("enter.organizerCompany")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid> <Grid item xs={12} md={6} sx={{height: '95px'}}>
                            <InputController
                                control={control}
                                error={errors?.programContactName}
                                name='programContactName'
                                disabled={isViewOnly}
                                fullWidth
                                label={t("enter.programContactName")}
                                autoComplete="off"
                                type="text"
                            />
                        </Grid> <Grid item xs={12} md={6} sx={{height: '95px'}}>
                            <InputController
                                control={control}
                                error={errors?.programContactPhoneNumber}
                                name='programContactPhoneNumber'
                                disabled={isViewOnly}
                                fullWidth
                                label={t("enter.programContactPhoneNumber")}
                                autoComplete="off"
                                type="phone"
                            />
                        </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.programContactEmailAddress}
                                    name='programContactEmailAddress'
                                    disabled={isViewOnly}
                                    fullWidth
                                    label={t("enter.programContactEmailAddress")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('section.description')} to={'description'}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextEditorController
                                    disabled={isViewOnly}
                                    control={control}
                                    name='description'
                                    label={t('section.description')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('section.speakerEventGuidelines')} to={'speakerEventGuidelines'}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextEditorController
                                    disabled={isViewOnly}
                                    control={control}
                                    name='speakerEventGuidelines'
                                    label={t('section.speakerEventGuidelines')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    {isRegistration ? null :  <Suspense fallback={<div>Loading...</div>}><IndustriesListComponent disabled={isViewOnly}/></Suspense>}
                    <FormContainer title={t('section.sessionTopics')} to={'sessionTopics'}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextEditorController
                                    disabled={isViewOnly}
                                    control={control}
                                    name='sessionTopics'
                                    label={t('section.sessionTopics')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('section.expertTips')} to={'expertTips'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextEditorController
                                    disabled={isViewOnly}
                                    control={control}
                                    name='expertTips'
                                    label={t('section.expertTips')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    {isViewOnly &&
                    <FormContainer title={t('section.agencyNotes')} to={'agencyNotes'}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextEditorController
                                    control={control}
                                    name='agencyNotes'
                                    label={t('section.agencyNotes')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    }
                    <FormContainer title={t('section.additionalDetails')} to={'additionalDetails'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.yearEstablished}
                                    name='yearEstablished'
                                    disabled={isViewOnly}
                                    fullWidth
                                    label={t("enter.yearEstablished")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.lastYearSponsors}
                                    name='lastYearAgenda'
                                    disabled={isViewOnly}
                                    fullWidth
                                    label={t("enter.lastYearAgenda")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.lastYearSponsors}
                                    name='lastYearSponsors'
                                    disabled={isViewOnly}
                                    fullWidth
                                    label={t("enter.lastYearSponsors")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    {user?.userRole?.name !== 'Administrator'  && isUpdate ? (
                        <Suspense fallback={<div>Loading...</div>}>
                            <FormContainer title={t('section.clients')} to={'clients'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <ViewClientsOfEventComponent inForm={true} event={eventData}/>
                                    </Grid>
                                </Grid>
                            </FormContainer>
                        </Suspense>
                    ) : null}
                </>
            )}
        </>
    );
};
export default EventForm;