import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewAwardSubmissionsOfClientGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_AWARD_SUBMISSIONS_OF_CLIENT_QUERY} from "../../graphql/submission";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {useParams} from "react-router-dom";
import {t} from "i18next";
export default function ViewAwardSubmissionsOfClientComponent({inForm = false}) {
    const [submissionItems, setSubmissionItems] = useState([]);
    //This will Replace the data with the data from the API
    const {clientId} = useParams();
    const {user} = useContext(AppContext);
    const {data, loading} = useQuery(REQUEST_AWARD_SUBMISSIONS_OF_CLIENT_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: user?.client?.id ? user?.client?.id : clientId
                    }
                }
            }
        });

    useEffect(() => {
        if (data?.requestAwardSubmissionsOfClient?.data) {
            setSubmissionItems(data?.requestAwardSubmissionsOfClient?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: inForm ? 0 : 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewAwardSubmissionsOfClient.subTitle")}/>}
            <ViewAwardSubmissionsOfClientGrid
                list={submissionItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
