import React, {useState, useEffect, useContext} from 'react';
import GlobalDataGridPro from "../manage";
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import searchByString from "../../helpers/searchByString";
import ContactColumns from "./helpers/columns";
import {
    DELETE_CONTACT_MUTATION,
    REQUEST_CONTACTS_OF_CLIENT_QUERY,
    SET_PRIMARY_CONTACT_MUTATION
} from "../../graphql/contact";
import DeleteContact from "./Delete";
import Toast from "../alerts";
import {AppContext} from "../../App";

export const ManageContactsGrid = ({list, inForm = false, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {user} = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const {clientId} = useParams();
    const [deleteContact] = useMutation(DELETE_CONTACT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_CONTACTS_OF_CLIENT_QUERY,
                variables: {
                    parameters: {
                        client: {
                            id: clientId
                        }
                    }
                }
            }],
            awaitRefetchQueries: true
        });
    const [setPrimaryContact] = useMutation(SET_PRIMARY_CONTACT_MUTATION,{
        refetchQueries: [{
            query: REQUEST_CONTACTS_OF_CLIENT_QUERY,
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    }
                }
            }
        }],
            awaitRefetchQueries: true
    });

    const handleDelete = DeleteContact(deleteContact);
    const handleDeleteUser = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    const handleAdd = () => {
        navigate(`/agency/clients/contacts/new_contact?clientId=${clientId}`)
    }
    const handleSetPrimaryContact = (id) => {
        setPrimaryContact({
            variables: {
                parameters: {
                    contact: {
                        id: id,
                        isPrimaryContact: true
                    },
                    client: {
                        id: clientId
                    },
                    subscriber:{
                        id: user?.subscriber?.id
                    }
                }
            }
        }).then(() => {
            Toast({
                type: 'success',
                message: t('successMessage.primaryContactUpdated')
            });

        } ).catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...ContactColumns,
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-edit-contact"
                        icon={<EditIcon/>}
                        onClick={() => {
                            navigate(`/agency/clients/contacts/${params?.id}?update_contact`)
                        }}
                        showInMenu={true}/>,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-delete-contact"
                        icon={<DeleteForeverIcon/>}
                        onClick={() => {
                            handleDeleteUser(params.id)
                        }}
                        showInMenu={true}/>,
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.primaryContact')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-set-primary-contact"
                        icon={<VerifiedUserIcon/>}
                        disabled={params?.row?.isPrimaryContact}
                        onClick={() => {
                            handleSetPrimaryContact(params.id)
                        }}
                        showInMenu={true}/>,

                ]
            }
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-contacts",
        title: inForm ? "" : "Users",
        searchLabel: "Search Contact",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleAdd: handleAdd,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
    );
};