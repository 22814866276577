import {gql} from "@apollo/client";

export const REQUEST_SUBMISSION_STATUS_QUERY = gql`
    query RequestSubmissionStatus {
        requestSubmissionStatus {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                reasons{
                    name
                    typeName
                }
                sequence
            }
        }
    }
`;