import {gql} from "@apollo/client";


export const CREATE_TASK_MUTATION = gql`
    mutation CreateTask($parameters: CreateTaskInput) {
        createTask(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone

                description

                updatedAt

                document

                title

                interestedClient {
                    recognition{
                        award{
                            id
                        }
                        event{
                            id
                        }
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                subscriber {
                    deleted
                    id
                    documentId
                    city
                    updatedAt
                    name
                    phoneNumber
                    address
                    createdAt
                    eventSummaries
                    zipCode
                    logo
                    state
                }
                deleted

                createdAt

                id

                submission {
                    subscriberId
                    updatedAt
                    clientApprovalRequired
                    deleted
                    id
                    title
                    createdAt
                    document
                    status{
                        name
                        reasons{
                            name
                        }
                    }
                }
                status

                dueDate

                user {
                    isAdmin
                    zipCode
                    lastName
                    documentId
                    phoneNumber
                    createdAt
                    password
                    username
                    updatedAt
                    deleted
                    isLocked
                    state
                    fullName
                    headshot
                    city
                    emailAddress
                    id
                    avatar
                    isActive
                    address
                    firstName
                }

            }
        }
    }
`;

export const DELETE_TASK_MUTATION = gql`
    mutation DeleteTask($parameters: DeleteTaskInput) {
        deleteTask(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const REQUEST_TASK_QUERY = gql`
    query RequestTask($parameters: RequestTaskInput) {
        requestTask(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone
                description
                title
                completedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                updatedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                submissionStatus
                sequence
                interestedClient {
                    client{
                        id
                        name
                    }
                    recognition{
                        award{
                            id
                            name
                        }
                        event{
                            name
                            id
                        }
                    }
                    id
                }
                subscriber {
                    id
                    name
                }
                deleted

                createdAt

                id

                submission {
                    subscriberId
                    id
                    title
                    status{
                        name
                    }
                }
                status

                dueDate
                updating
                updatingBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                user {
                    lastName
                    fullName
                    id
                    firstName
                }

            }
        }
    }
`;

export const REQUEST_TASKS_QUERY = gql`
    query RequestTasks($parameters: RequestTasksInput) {
        requestTasks(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone

                description

                updatedAt
                createdAt
                document

                title
                createdBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                updatedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                completedAt
                completedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                completed
                submissionStatus
                sequence
                createdBySystem
                completedBySystem
                interestedClient {
                    recognition{
                        award{
                            id
                        }
                        event{
                            id
                        }
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                subscriber {
                    deleted
                    id
                    documentId
                    city
                    updatedAt
                    name
                    phoneNumber
                    address
                    createdAt
                    eventSummaries
                    zipCode
                    logo
                    state
                }
                deleted

                createdAt

                id

                submission {
                    subscriberId
                    updatedAt
                    clientApprovalRequired
                    deleted
                    id
                    title
                    createdAt
                    document
                    status{
                        name
                        reasons{
                            name
                        }
                    }
                }
                status

                dueDate

                user {
                    isAdmin
                    zipCode
                    lastName
                    documentId
                    phoneNumber
                    createdAt
                    password
                    username
                    updatedAt
                    deleted
                    isLocked
                    state
                    fullName
                    headshot
                    city
                    emailAddress
                    id
                    avatar
                    isActive
                    address
                    firstName
                }

            }
        }
    }
`;

export const REQUEST_TASKS_OF_SUBMISSION_QUERY = gql`
    query RequestTasksOfSubmission($parameters: RequestTasksOfSubmissionInput) {
        requestTasksOfSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone
                updatedAt
                title
                completedAt
                submissionStatus
                sequence
                interestedClient {
                    client{
                        id
                    }
                    recognition{
                        award{
                            id
                        }
                        event{
                            id
                        }
                    }
                    id
                }
                subscriber {
                    id
                    name
                }
                deleted
                createdAt
                id
                submission {
                    subscriberId
                    id
                    title
                    status{
                        name
                    }
                }
                status

                dueDate
                user {
                    lastName
                    fullName
                    id
                    firstName
                }

            }
        }
    }
`;

export const REQUEST_TASKS_OF_SUBSCRIBER_QUERY = gql`
    query RequestTasksOfSubscriber($parameters: RequestTasksOfSubscriberInput) {
        requestTasksOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone
                description
                updatedAt
                document
                title
                createdBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                updatedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                completedAt
                completedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                completed
                submissionStatus
                sequence
                interestedClient {
                    client{
                        id
                        name
                    }
                    recognition{
                        award{
                            id
                            name
                        }
                        event{
                            id
                            name
                        }
                    }
                    firstName
                    id
                    lastName
                }
                subscriber {
                    id
                    name
                }
                deleted
                createdAt
                id
                submission {
                    subscriberId
                    id
                    title
                    status{
                        name
                    }
                }
                status
                dueDate
                user {
                    lastName
                    fullName
                    id
                    firstName
                }
            }
        }
    }
`;
export const REQUEST_TASKS_OF_CLIENT_QUERY = gql`
    query RequestTasksOfClient($parameters: RequestTasksOfClientInput) {
        requestTasksOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone
                description
                updatedAt
                document
                title
                interestedClient {
                    client{
                        id
                        name
                    }
                    recognition{
                        award{
                            id
                            name
                        }
                        event{
                            id
                            name
                        }
                    }
                    firstName
                    id
                    emailAddress
                    lastName
                }
                subscriber {
                    id
                    documentId
                    name
                }
                deleted
                createdAt
                id
                submission {
                    subscriberId
                    updatedAt
                    clientApprovalRequired
                    deleted
                    id
                    title
                    createdAt
                    document
                    status{
                        name
                        reasons{
                            name
                        }
                    }
                }
                status
                dueDate
                user {
                    lastName
                    fullName
                    id
                    firstName
                }
            }
        }
    }
`;

export const UPDATE_TASK_MUTATION = gql`
    mutation UpdateTask($parameters: UpdateTaskInput) {
        updateTask(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDone

                description

                updatedAt

                document

                title

                interestedClient {
                    client{
                        id
                        name
                    }
                    recognition{
                        award{
                            id
                            name
                        }
                        event{
                            id
                            name
                        }
                    }
                    firstName
                    id
                    emailAddress
                    lastName
                }
                subscriber {
                    id
                    documentId
                    name
                }
                deleted

                createdAt

                id

                submission {
                    subscriberId
                    updatedAt
                    clientApprovalRequired
                    deleted
                    id
                    title
                    createdAt
                    document
                    status{
                        name
                        reasons{
                            name
                        }
                    }
                }
                status

                dueDate

                user {
                    lastName
                    fullName
                    id
                    firstName
                }

            }
        }
    }
`;
export const RECORD_LOCKING_TASK_MUTATION = gql`
    mutation updateRecordLockingStatusOfTask($parameters: UpdateTaskInput) {
        updateRecordLockingStatusOfTask(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                updating
                updatingBy{
                    id
                }
            }
        }
    }
`;
