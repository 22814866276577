import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {CheckIcon, EmailCell, formatPhoneNumber} from "../../manage/utilis/columnHelpers";


const UserColumns = [
    {
        field: 'fullName',
        headerName: t('columns.viewTeamMemberUsers.fullName'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/team_members/${params?.id}?update_user`}>{params.value}</Link>),
    },
    {
        field: 'emailAddress',
        headerName: t('columns.viewTeamMemberUsers.emailAddress'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (<EmailCell value={params.value}/>)
    },
    {
        field: 'phoneNumber',
        headerName: t('columns.viewTeamMemberUsers.phoneNumber'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => formatPhoneNumber(params.value)
    },
    {
        field: 'isAdmin',
        headerName: t('columns.viewTeamMemberUsers.isAdmin'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (<CheckIcon value={params.value}/>)
    },
    {
        field: 'assignedClients',
        headerName: t('columns.viewTeamMemberUsers.assignedClients'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left'
    },
    {
        field: 'submissionInProcess',
        headerName: t('columns.viewTeamMemberUsers.submissionInProcess'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left'
    },
    {
        field: 'accepted',
        headerName: t('columns.viewTeamMemberUsers.accepted'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left'
    },
]
export default UserColumns;