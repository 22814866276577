import React from 'react';
import Box from "@mui/material/Box";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {IndustryForm} from "../../forms";
import {CREATE_INDUSTRY_MUTATION, REQUEST_INDUSTRIES_QUERY} from "../../../graphql/industry";
import {useMutation} from "@apollo/client";
import {industryFormValidations} from "../Edit/helpers";
import Toast from "../../alerts";
import Modal from "../../modal";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export const AddIndustry = ({isOpen, handleClose}) => {
    const {t} = useTranslation();
    const methods = useForm({
        mode: 'onTouched',
        resolver: yupResolver(industryFormValidations(t)),
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;
    const [createIndustry] = useMutation(CREATE_INDUSTRY_MUTATION, {
        update: (cache, {data: {createIndustry}}) => {
            // Read the existing cache for the industries
            const existingIndustries = cache.readQuery({query: REQUEST_INDUSTRIES_QUERY});

            // Add the newly created industry to the list
            const updatedIndustries = [createIndustry.data, ...existingIndustries.requestIndustries.data];

            // Write the modified data back to the cache
            cache.writeQuery({
                query: REQUEST_INDUSTRIES_QUERY,
                data: {requestIndustries: {data: updatedIndustries}},
            });
        },
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = getValues()

        createIndustry({
            variables: {
                parameters: {
                    industry: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.industryCreated')
                });
                reset();
                handleClose()
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form onBlur={() => {
                        trigger()
                    }} onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'left'
                            }}
                        >
                            <Typography variant="formHeader"
                                        id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-header-label-industry" color="white">
                                {t('headers.manageIndustries.add.title')}
                            </Typography>
                            <Divider sx={{width: '100%'}}/>
                            <Box sx={{mt: 1, width: '100%', height: '200px', display: 'flex', alignItems: 'center'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <IndustryForm/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{width: '100%', mb: 1}}/>
                            <Box
                                sx={{display: 'flex', justifyContent: 'end', width: '100%'}}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{borderRadius: '12px'}}
                                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-cancel"
                                    onClick={handleClose}
                                >
                                    {t('button.cancelCAP')}
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{color: '#FFFFFF', borderRadius: '12px', ml: 1}}
                                    disabled={!isValid}
                                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-industry"
                                >
                                    {t('button.addCAP')}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Modal>
    );
};