import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewTasksGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {
    REQUEST_TASKS_OF_CLIENT_QUERY,
    REQUEST_TASKS_OF_SUBSCRIBER_QUERY
} from "../../graphql/task";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {t} from "i18next";
export default function ViewAwardTasksComponent({inForm = false}) {
    const [taskItems, setTaskItems] = useState([]);
    //This will Replace the data with the data from the API

    const {user} = useContext(AppContext);
    const subscriberId = user?.subscriber ? user.subscriber.id : null;
    const {data: taskSubscriberData, loading: taskSubscriberLoading} = useQuery(REQUEST_TASKS_OF_SUBSCRIBER_QUERY, {
        variables: {
            parameters: {
                task: {},
                subscriber: {
                    id: subscriberId
                }
            }
        }
    });
    const clientId = user?.client ? user.client.id : null;
    const {data, loading} = useQuery(REQUEST_TASKS_OF_CLIENT_QUERY, {
        variables: {
            parameters: {
                task: {},
                client: {
                    id: clientId
                }
            }
        }
    });

    useEffect(() => {
        if (user?.userRole?.name === 'Client' || user?.userRole?.name === 'Client Administrator') {
            if (!loading && data?.requestTasksOfClient?.data) {
                setTaskItems(data?.requestTasksOfClient?.data.filter((task) => {
                    if (task?.interestedClient?.recognition?.award?.id) {
                        return task
                    }
                }));
            }
        }
    }, [user, data, loading]);
    useEffect(() => {
        if (user?.userRole?.name !== 'Client' && user?.userRole?.name !== 'Client Administrator') {
            if (!taskSubscriberLoading && taskSubscriberData?.requestTasksOfSubscriber?.data) {
                setTaskItems(taskSubscriberData?.requestTasksOfSubscriber?.data.filter((task) => {
                    if (task?.interestedClient?.recognition?.award?.id) {
                        return task
                    }
                }));
            }
        }
    }, [user,taskSubscriberLoading, taskSubscriberData]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewAwardTasks.subTitle")}/>}
            <ViewTasksGrid
                list={taskItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
