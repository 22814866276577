
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, Paper, Typography } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const QuickGuideImport = ({importTemplateURL}) => {
    const { t } = useTranslation();
    const documentationUrl = process.env.REACT_APP_IMPORT_DOCUMENTATION || ""
    return (
        <Paper sx={{ p: 2, mb: 2, borderRadius: '12px' }} elevation={3}>
            <Grid container spacing={1}>
                <InstructionItem title={t('import.quickGuide')} variant="subtitle2"/>
                <InstructionItem title={t('import.instruction1')} subtitle={t('import.instruction1Subtitle')}>
                    <Link variant="body2" sx={{ display: 'flex' }} href={importTemplateURL} target="_blank">
                        See Template &nbsp; <OpenInNewIcon fontSize="small" />
                    </Link>
                </InstructionItem>
                <InstructionItem title={t('import.instruction2')} subtitle={t('import.instruction2Subtitle')} />
                <InstructionItem title={t('import.instruction3')} subtitle={t('import.instruction3Subtitle')} />
                <InstructionItem title={t('import.needHelp')} subtitle={t('import.needHelpSubtitle')}>
                <Link variant="body2" sx={{ display: 'flex' }} href={documentationUrl} target="_blank">
                         See Guide &nbsp; <OpenInNewIcon fontSize="small" />
                    </Link>
                </InstructionItem>
            </Grid>
        </Paper>
    );
}
export default QuickGuideImport;


const InstructionItem = (parameters) => {
    const { title, subtitle, children, variant, ...rest } = parameters;
    return (
        <Grid item xs={12} display={'flex'} flexWrap="wrap" color="secoundary">
            {title && <Typography variant={variant || "body2"} color="white" {...rest}><strong>{title}</strong></Typography>}
            {subtitle && <Typography variant="body2" color="secoundary" >&nbsp;{subtitle}&nbsp;</Typography>}
            {children}
        </Grid>
    )
}
