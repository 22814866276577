import React,{useContext} from 'react';
import { useForm } from 'react-hook-form';
import {AppContext} from "../../App";


export const useManagedForm = (options) => {
    const formMethods = useForm(options);
    const {setFormDirty} = useContext(AppContext);
    const {formState: { isDirty, touchedFields}} = formMethods;
    const hasChanges =  isDirty && Object.keys(touchedFields).length > 0;

    // Automatically update the global context when form dirty state changes
    React.useEffect(() => {
        setFormDirty(hasChanges);
    }, [hasChanges, setFormDirty]);

    return formMethods;
};
