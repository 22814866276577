import {gql} from "@apollo/client";




export const REQUEST_SUBMISSION_QUERY = gql`
    query RequestSubmission($parameters: RequestSubmissionInput) {
        requestSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                    reasons{
                        name
                    }
                }
                id
                notes
                readyToMove
                updating
                updatingBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                lastStatus{
                    name
                }
                updatingTime
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognitionType {
                        name
                        documentId
                        id
                    }
                    recognition{
                        event{
                            industries{
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                        award{
                            id industries{ name id }
                            applicationDeadline
                            website
                            country
                        }
                        willSpeak
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                clientApprovalRequired
                title
                document
                customSections{
                    name
                    value
                    id
                }
                tasks{
                    id
                    submissionStatus
                    title
isDone
                }
            }
        }
    }
`;
export const REQUEST_CURRENT_SUBMISSION_STATUS_QUERY = gql`
    query RequestSubmission($parameters: RequestSubmissionInput) {
        requestSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                }
                id
                readyToMove
                title
            }
        }
    }
`;
export const REQUEST_AWARD_SUBMISSIONS_OF_CLIENT_QUERY = gql`
    query RequestAwardSubmissionsOfClient($parameters: RequestAwardSubmissionsOfClientInput) {
        requestAwardSubmissionsOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                    reasons{
                        name
                    }
                }
                id
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognitionType {
                        name
                        documentId
                        id
                    }
                    recognition{
                        event{
                            industries{
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                        award{
                            id industries{ name id }
                            applicationDeadline
                            website
                            country
                        }
                        willSpeak
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                clientApprovalRequired
                title
                document

            }
        }
    }
`;
export const REQUEST_EVENT_SUBMISSIONS_OF_CLIENT_QUERY = gql`
    query RequestEventSubmissionsOfClient($parameters: RequestEventSubmissionsOfClientInput) {
        requestEventSubmissionsOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                    reasons{
                        name
                    }
                }
                id
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognitionType {
                        name
                        documentId
                        id
                    }
                    recognition{
                        event{
                            industries{
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                        award{
                            id industries{ name id }
                            applicationDeadline
                            website
                            country
                        }
                        willSpeak
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                clientApprovalRequired
                title
                document

            }
        }
    }
`;
export const REQUEST_EVENT_SUBMISSIONS_OF_SUBSCRIBER_QUERY = gql`
    query RequestEventSubmissionsOfSubscriber($parameters: RequestEventSubmissionsOfSubscriberInput) {
        requestEventSubmissionsOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                }
                id
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognition{
                        event{
                            industries{
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                    }
                    id
                }
                title
            }
        }
    }
`;
export const REQUEST_EVENTS_SUBMISSIONS_OF_SUBSCRIBER_QUERY = gql`
    query RequestEventSubmissionsOfSubscriber($parameters: RequestEventSubmissionsOfSubscriberInput) {
        requestEventSubmissionsOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                }
                id
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognition{
                        event{
                            industries{
                                id
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                    }
                    id
                }
                title
                document

            }
        }
    }
`;
export const REQUEST_AWARD_SUBMISSIONS_OF_SUBSCRIBER_QUERY = gql`
    query RequestAwardSubmissionsOfSubscriber($parameters: RequestAwardSubmissionsOfSubscriberInput) {
        requestAwardSubmissionsOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                }
                id
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognition{
                        award{
                            id
                            name
                            industries{
                                name
                                id
                            }
                            applicationDeadline
                            website
                            country
                        }
                        willSpeak
                    }
                    id
                }
                title

            }
        }
    }
`;



export const UPDATE_SUBMISSION_MUTATION = gql`
    mutation UpdateSubmission($parameters: UpdateSubmissionInput) {
        updateSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                title
                notes
                document
                readyToMove
                updating
                updatedBy{
                    id
                    fullName
                    firstName
                    lastName
                }
                updatingTime
                interestedClient {
                    client{
                        name
                        id
                    }
                    recognitionType {
                        name
                        documentId
                        id
                    }
                    recognition{
                        event{
                            industries{
                                name
                            }
                            name
                            venue
                            country
                            deadline
                            id
                        }
                        award{
                            id industries{ name id }
                            applicationDeadline
                            website
                            country
                        }
                        willSpeak
                    }
                    phoneNumber
                    firstName
                    id
                    emailAddress
                    haveBooth
                    lastName
                    planAttend
                }
                clientApprovalRequired
                status{
                    name
                    reasons{
                        name
                    }
                }
                id
            }
        }
    }
`;

export const RECORD_LOCKING_SUBMISSION_MUTATION = gql`
    mutation updateRecordLockingStatusOfSubmission($parameters: UpdateSubmissionInput) {
        updateRecordLockingStatusOfSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                updating
                updatingBy{
                    id
                }
            }
        }
    }
`;
export const REQUEST_TASKS_ORDER_SUBMISSION_QUERY = gql`
    query RequestSubmission($parameters: RequestSubmissionInput) {
        requestSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                status{
                    name
                }
                id
                readyToMove
                title
                tasks{
                    id
                    submissionStatus
                    title
                    isDone
                }
            }
        }
    }
`;
export const UPDATE_TASKS_SUBMISSION_ORDER_MUTATION = gql`
    mutation updateTasksOfSubmission($parameters: UpdateSubmissionInput) {
        updateTasksOfSubmission(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                title
                tasks{
                    id
                    submissionStatus
                    title
                    isDone
                }
                id
            }
        }
    }
`;
