import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewAnalyticsOfEventsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_ANALYTICS_OF_EVENTS_QUERY} from "../../graphql/event";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {t} from "i18next";
export default function ViewAnalyticsOfEventsComponent({inForm = false}) {
    const [eventItems, setEventItems] = useState([]);
    //This will Replace the data with the data from the API

    const {user} = useContext(AppContext);
     const {data, loading: loadingEvents, refetch} = useQuery(REQUEST_ANALYTICS_OF_EVENTS_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    },
                    showAllItems: false,
                    showOnlyDisabledItems: false
                }
            }
        });

    useEffect(() => {
        if (data?.requestEventAnalytics?.data) {
            setEventItems(data?.requestEventAnalytics?.data);
        }
    }, [data]);
    useEffect(() => {
        refetch();
    }, [refetch]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewEventAnalytics.subTitle")}/>}
            <ViewAnalyticsOfEventsGrid
                list={eventItems} inForm={inForm}  loading={loadingEvents}
            />
        </Box>
    );
}
