import React from 'react';
import {Grid, Button} from '@mui/material';
import { useTableFilter} from "../filters/containers/hooks/filterProvider";

const FilterContainer = ({sections, id}) => {
    const {
        applyFilters,
        cancelFilters,
        hasTempSelectedFilters,
        isTempFilterDifferent
    } = useTableFilter();
    return (
        <Grid container sx={{py: 2, px: 1}}>
            {sections.map((section, index) => (
                <Grid key={index} xs={12} sx={{px: 1, mb: section.marginBottom || 2}}>
                    {section.header && section.header()}
                    {section.component && section.component()}
                </Grid>
            ))}
            <Grid xs={12} sx={{px: 1, mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                <Button sx={{mr: 2}} disabled={!hasTempSelectedFilters(id)} onClick={() => cancelFilters(id)}>Reset all filters</Button>
                <Button variant="contained" disabled={!isTempFilterDifferent(id)} color="primary" onClick={() => applyFilters(id)}>Apply</Button>
            </Grid>
        </Grid>
    )
}

export default FilterContainer;
