import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {AwardContext} from "../component";
import DatePickerComponent from "../../filters/dateFilter";
import MultiSelectComponent from "../../filters/industriesFilter";
import {IconButton} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {t} from "i18next";
import FilterContainer from "../../formContainer/FilterContainer";


    
    
    function getHeaderFilterSection(title, value, onClick) {
    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '42px'
        }}>
        <Typography variant="navItem">
            {title}
        </Typography>
        {value &&
            <IconButton aria-label="close"
                        size="small" onClick={onClick}>
                <DeleteForeverIcon color="error"/>
            </IconButton>
        }
    </Box>;
}
const AwardFiltersContainer = () => {
    const {
        filterByApplicationDeadline: contextDeadline,
        setFilterByApplicationDeadline: setContextDeadline,
        filterByIndustries: contextIndustries,
        setFilterByIndustries: setContextIndustries
    } = useContext(AwardContext);

    // Local draft states
    const [filterByApplicationDeadline, setFilterByApplicationDeadline] = useState(contextDeadline);
    const [filterByIndustries, setFilterByIndustries] = useState(contextIndustries);

    // Update local state when context changes
    useEffect(() => {
        setFilterByApplicationDeadline(contextDeadline);
        setFilterByIndustries(contextIndustries);
    }, [contextDeadline, contextIndustries]);

    const handleCancel = () => {
        // Revert the draft states to the values from the context.
        setFilterByApplicationDeadline(contextDeadline);
        setFilterByIndustries(contextIndustries);
    };

    const handleApply = () => {
        // Update the context with the draft values.
        setContextDeadline(filterByApplicationDeadline);
        setContextIndustries(filterByIndustries);
    };

    const sections = [
        {
            header: () => getHeaderFilterSection(t("manage.viewAwardsOfClient.filters.deadline"), filterByApplicationDeadline, () => setFilterByApplicationDeadline(null)),
            component: () => <DatePickerComponent value={filterByApplicationDeadline} setValue={setFilterByApplicationDeadline} />,
            marginBottom: 3
        },
        {
            header: () => getHeaderFilterSection(t("manage.viewAwardsOfClient.filters.industries"), !!filterByIndustries.length, () => setFilterByIndustries([])),
            component: () => <MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} />
        }
    ];

    return (
        <FilterContainer
            sections={sections}
            onCancel={handleCancel}
            onApply={handleApply}
        />
    );
}
export default AwardFiltersContainer;
export const AwardFilters = () => {
    const {
        
filterByApplicationDeadline,
setFilterByApplicationDeadline,
filterByIndustries,
setFilterByIndustries
    } = useContext(AwardContext);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                mx: 2
            }}
        >
           <DatePickerComponent value={filterByApplicationDeadline} setValue={setFilterByApplicationDeadline} /><MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} componentSx={{ mr: 2 }} />
        </Box>
    )

}
