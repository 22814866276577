import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {AppContext} from "../../App";
import {useQuery} from "@apollo/client";
import AssignModal from "../assignModal";
import {REQUEST_ASSIGNED_TEAM_MEMBER_QUERY, REQUEST_UNASSIGNED_TEAM_MEMBER_QUERY} from "../../graphql/teamMember";
import ManageUnassignedTeamMembers from "../manageClientsOfSubscriber/Unassigned";
import ManageAssignedTeamMembers from "../manageClientsOfSubscriber/Assigned";

const AssignTeamMembers = ({isOpen, handleClose, client}) => {
    const {t} = useTranslation();
    const [unAssignTeamMembers, setUnAssingTeamMembers] = useState([]);
    const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);
    const clientId = client?.id;
    const {user} = useContext(AppContext);
    const {
        data: unAssigned,
        loading: loadingUnassigned
    } = useQuery(REQUEST_UNASSIGNED_TEAM_MEMBER_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });
    const {data: assigned, loading: loadingAssigned} = useQuery(REQUEST_ASSIGNED_TEAM_MEMBER_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });
    useEffect(() => {
        if (!loadingUnassigned && unAssigned?.requestTeamMembersUnassignedOfClient?.data) {
            setUnAssingTeamMembers(unAssigned?.requestTeamMembersUnassignedOfClient?.data);
        }
    }, [loadingUnassigned, unAssigned]);
    useEffect(() => {
        if (!loadingUnassigned && assigned?.requestTeamMembersAssignedOfClient?.data) {
            setAssignedTeamMembers(assigned?.requestTeamMembersAssignedOfClient?.data);
        }
    }, [loadingAssigned, assigned]);
    return (
        <AssignModal
            isOpen={isOpen}
            handleClose={handleClose}
            title={`Assign team members to ${client?.name}`}
            subtitle={t('modal.assignTeamMember.subTitle')}
        >
            <Grid item xs={6}>
                <ManageUnassignedTeamMembers
                    list={unAssignTeamMembers}
                    loading={loadingUnassigned}
                    clientId={clientId}
                />
            </Grid>
            <Grid item xs={6}>
                <ManageAssignedTeamMembers
                    list={assignedTeamMembers}
                    loading={loadingAssigned}
                    clientId={clientId}
                />
            </Grid>
        </AssignModal>
    );
};

export default AssignTeamMembers;
