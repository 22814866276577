import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewEventsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_APPROVED_EVENTS_QUERY} from "../../graphql/event";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {
    REQUEST_INTERESTED_CLIENTS_BROWSE_QUERY,
} from "../../graphql/interestedClient";
import {t} from "i18next";
import useUpdatedItems from "../../hooks/useUpdateIndustries";
export default function ViewEventsComponent({inForm = false}) {
    const [eventItems, setEventItems] = useState([]);
    //This will Replace the data with the data from the API
    const [interestedClients, setInterestedClients] = useState([]);
    const {user} = useContext(AppContext);
    const {data, loading: loadingEvents} = useQuery(REQUEST_APPROVED_EVENTS_QUERY, {
        variables: {
            parameters: {
                showAllItems: false,
                showOnlyDisabledItems: false,
            }
        }
    });
    const {data: interestedClientData, loading,refetch} = useQuery(REQUEST_INTERESTED_CLIENTS_BROWSE_QUERY, {
        variables: {
            parameters: {
                interestedClient: {
                    recognition: {
                        event:{}
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        }
    });
    useEffect(() => {
        if (interestedClientData?.requestInterestedClients?.data.length > 0) {
            setInterestedClients(interestedClientData?.requestInterestedClients?.data)
        }
    }, [interestedClientData, loading])
    useEffect(() => {
        if (data?.requestApprovedEvents?.data) {
            setEventItems(data?.requestApprovedEvents?.data);
        }
    }, [data]);
    const updatedItems = useUpdatedItems(eventItems);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewEventsOfSubscriber.subTitle")}/>}
            <ViewEventsGrid
                list={updatedItems} inForm={inForm} interestedClients={interestedClients}
                refetch={refetch} loading={loadingEvents}
            />
        </Box>
    );
}
