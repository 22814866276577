import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {contactFormValidations} from "./helpers";
import Toast from "../../alerts";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {
    REQUEST_CONTACT_QUERY,
    REQUEST_CONTACTS_OF_CLIENT_QUERY,
    UPDATE_CONTACT_MUTATION
} from "../../../graphql/contact";
import {createEditorState, textEditorFormat} from "../../../helpers/formatters";
import ContactForm from "../../forms/contacts";
import {useParams} from "react-router-dom";

export const EditContact = () => {
    const {t} = useTranslation();
    const [contactData, setContactData] = useState({})
    const {contactId} = useParams();
    const {data, refetch} = useQuery(REQUEST_CONTACT_QUERY, {
        variables: {
            parameters: {
                contact: {
                    id: contactId,
                },
            },
        }
    });

    useEffect(() => {
        if (data?.requestContact?.data) {
            let obj = {...data?.requestContact?.data}
            const newObj = {
                ...obj,
                notes: createEditorState(obj.notes),
            }
            setContactData(newObj);
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(contactFormValidations(t)),
        values: contactData
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    const [updateUser] = useMutation(UPDATE_CONTACT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_CONTACTS_OF_CLIENT_QUERY,
                variables: {
                    parameters: {
                        client: {
                            id: contactData?.client?.id
                        }
                    }
                }
            }],
            awaitRefetchQueries: true
        });
    const cancelChanges = () => {
        reset(contactData)
    }
    const handleSubmit = () => {
        let data = getValues()
        data.notes = textEditorFormat(data?.notes)
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(contactData);
        if (!isDataChanged) return;

        updateUser({
            variables: {
                parameters: {
                    contact: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.contactUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >
                        <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                                              title={contactData?.name || 'Contact Profile'}
                                              cancelFn={cancelChanges}
                        >
                            <ContactForm isUpdate/>
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};