import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import InputController from "../formController/InputController";
import SplitView from "../../helpers/splitViewComponent";
import CheckboxController from "../formController/CheckboxController";
import {useFormContext} from "react-hook-form";
import {useState} from "react";
import ForgetPasswordOfUser from "../forgetPasswordOfUser";

export const LoginForm = ({onSubmit}) => {
    const {t} = useTranslation();
    const {control, formState: {errors, isValid}} = useFormContext();
    const [isOpen, setIsOpen] = useState(false)
    const toogleChangePassword = () => {
        setIsOpen(!isOpen)
    }
    return (
        <SplitView sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: 4,
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                {isOpen ? <ForgetPasswordOfUser isOpen={isOpen} handleClose={toogleChangePassword}/> :
                    <Box sx={{mt: 1, width: '80%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.emailAddress}
                                    name='emailAddress'
                                    required
                                    fullWidth
                                    label={t("enter.emailAddress")}
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.password}
                                    name='password'
                                    required
                                    fullWidth
                                    label={t("enter.password")}
                                    type="password"
                                    autoComplete="current-password"
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs>
                                <CheckboxController
                                    control={control}
                                    name="rememberMe"
                                    label={t("login.rememberMe")}
                                />
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2" onClick={toogleChangePassword}>
                                    {t('login.forgotPassword')}
                                </Link>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            onClick={onSubmit}
                            fullWidth
                            size="large"
                            variant="outlined"
                            disabled={!isValid}
                            sx={{mt: 3, mb: 2}}
                        >
                            {t('button.logInCAP')}
                        </Button>
                    </Box>
                }
            </Box>
            <Box sx={{textAlign: 'center', mb: 1}}>
                <Typography variant="paragraph">
                    {t('login.footer')} <br/>
                    <Typography variant="paragraph" component="span" sx={{fontWeight: 'bold'}}>
                        {t('login.footer1')}
                    </Typography> <br/> {t('login.subFooter')}
                </Typography>
            </Box>
        </SplitView>
    );
};