import { useEffect } from 'react';

const useScroll = (callback) => {
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (documentHeight - scrollPosition === windowHeight) {
                callback();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [callback]);
};

export default useScroll;