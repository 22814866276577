import {gql} from "@apollo/client";


export const LOGIN_MUTATION = gql`
    mutation Login($parameters: AuthenticateCredentialOfUserInput) {
        login(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                subscriber {
                    id
                    city
                    updatedAt
                    name
                    emailAddress
                    website
                    phoneNumber
                    address
                    createdAt
                    eventSummaries
                    zipCode
                    logo
                    state
                }
                isAdmin
                lastName
                client {
                    phoneNumber
                    emailAddress
                    deleted
                    state
                    createdAt
                    avatar
                    keywords
                    name
                    subscriber{
                        id
                    }
                    id
                    headshot
                    zipCode
                    documentId
                    updatedAt
                    address
                    city
                }
                userRole {
                    name
                    documentId
                    id
                    label
                }
                firstName
                id
                fullName
                emailAddress

            }
        }
    }
`;
