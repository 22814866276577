import React from 'react';
import {Controller} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from "@mui/material/ListItemText";
import {getSubmissionStatusChip} from "../manage/utilis/columnHelpers";

const SingleSelectDropdownController = (
    {
        control,
        controllerName,
        options = [],
        sxDrop,
        disabled,
        isSubmissionStatus = false,
        ...rest
    }
) => {
    const onControlOnChange = ({event, field}) => {
        const value = event.target.value; // Assuming 'value' is a single object
        const objValue = options.find((option) => option.id === value);
        field.onChange(objValue);
    };
    return (
        <Controller
            name={controllerName}
            control={control}
            render={({field}) => {
                return (
                    <FormControl sx={sxDrop}>
                        <InputLabel htmlFor={controllerName}>
                            {rest.label || 'Select'}
                        </InputLabel>

                        <Select
                            labelId={`${controllerName}-label`}
                            id={controllerName}
                            value={field?.value?.id || ''}
                            label={rest.label || 'Select'}
                            onChange={(event) => {
                                onControlOnChange({event, field: field});
                            }}
                            disabled={disabled}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.name} value={option.id} disabled={option?.disabled}>
                                    <ListItemText
                                        primary={isSubmissionStatus ? getSubmissionStatusChip(option.name) : option.name}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
};

export default SingleSelectDropdownController;
