import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "./index";

export const InterestedClientModal = (
    {
        isOpen,
        handleClose,
        title,
        subTitle,
        children,
        onSave,
        modalSX,
        titleSX,
        saveDivider = true,
        hasFooter = true
    }
) => {
    return (
        <Modal isOpen={isOpen} handleClose={handleClose} sxBox={{
            ...modalSX
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                {/* Header */}
                <Box sx={{flexGrow: 0, ...titleSX}}>
                    <Typography variant="formHeader" sx={{color: 'white', textAlign: 'left'}}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" sx={{color: 'white'}}>
                        {subTitle}
                    </Typography>
                </Box>
                <Divider sx={{mt: 1, width: '100%'}}/>
                {/* Scrollable Content */}
                <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
                    {children}
                </Box>
                {saveDivider && <Divider sx={{mt: 1, width: '100%'}}/>}
                {/* Footer */}
                {hasFooter &&
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingY: '5px',
                            mr: 4,
                            mt: 1,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{borderRadius: '12px'}}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            sx={{color: '#FFFFFF', borderRadius: '12px', ml: 1}}
                            onClick={onSave}
                        >
                            Save
                        </Button>
                    </Box>
                }
            </Box>
        </Modal>
    );
};
