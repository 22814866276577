import {gql} from "@apollo/client";

export const REQUEST_HISTORIES_QUERY = gql`
    query requestHistoryChanges($parameters: RequestHistoryInput) {
        requestHistoryChanges(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                recordId
                changes{
                    id
                    updatedBy{
                        id
                        firstName
                        lastName
                        fullName
                    }
                    timestamp
                    changesMade{
                        id
                        kind
                        lhs
                        rhs
                        path
                        index
                        item{
                            kind
                            rhs
                            lhs
                        }
                    }
                }
                
            }
        }
    }
`;

export const REQUEST_HISTORY_CHANGE_QUERY = gql`
    query requestHistoryChangeByChangeId($parameters: RequestHistoryInput) {
        requestHistoryChangeByChangeId(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                recordId
                changes{
                    id
                    updatedBy{
                        id
                        fullName
                    }
                    timestamp
                    changesMade{
                        id
                        kind
                        lhs
                        rhs
                        path
                        index
                        item{
                            lhs
                            kind
                            rhs
                        }
                    }
                }

            }
        }
    }
`;