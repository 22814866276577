import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';

const AwardColumns = (handleButtonClick) => {
    return [
        {
            field: 'name',
            headerName: t('columns.viewAwardAnalyticsOfClients.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/agency/clients/${params?.id}?update_client`}>{params.value}</Link>),
        },
        {
            field: 'awards',
            headerName: t('columns.viewAwardAnalyticsOfClients.awards'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            resizable: false,
            sortable: false,
            renderCell: (params) => {
                return <Avatar
                    onClick={() => {
                        if(params?.row?.analytics?.awards === 0) return;
                        handleButtonClick(params);
                    }}
                    sx={{
                        backgroundColor: "#717485",
                        color: "#fff",
                        fontSize: "1rem",
                        width: 30,
                        height: 30,
                    }}
                >
                    {params?.row?.analytics?.awards}
                </Avatar>
            },
            valueGetter: (params) => {
               return params?.row?.analytics?.awards
            }
        },
        {
            field: 'analytics?.deadlineNet30',
            headerName: t('columns.viewAwardAnalyticsOfClients.deadline'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
           valueGetter: (params) => {
                return params?.row?.analytics?.deadlineNet30
           }
        },
        {
            field: 'submissionTracking',
            headerName: t('columns.viewAwardAnalyticsOfClients.submissionsTracking'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.submissionsTracking
            }
        },

    ]
}
export default AwardColumns;