import React, {useMemo} from "react";
import Grid from "@mui/material/Grid";
import {InputController, DatePickerController, TextEditorController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import SingleSelectDropdownController from "../formController/SingleSelectDropdownController";
import CheckboxController from "../formController/CheckboxController";
import EventDetailFormContainer from "./detailViewOfEvent";
import ClientDetailComponent from "./detailViewOfClients";
import {Snackbar} from "@mui/material";
import {statuses} from "../manageTasks/Helpers";

const TaskForm = ({
                      usersOptions,
                      event = false,
                      customData,
                      readOnly = false,
                      isEditing = false,
                      isUpdate = false,
                      currentStatus
                  }) => {
    const {t} = useTranslation();
    const {control, formState: {errors}} = useFormContext();
    // Memoize the calculation of currentIndex
    const currentIndex = useMemo(() => statuses.findIndex(status => status.name === currentStatus), [currentStatus]);
    // Map through the statuses and add a disabled flag up to the current index
    const updatedStatuses = statuses.map((status, index) => ({
        ...status,
        // If the index is less than the current index, add the disabled flag
        disabled: index < currentIndex,
    }));

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={isEditing}
                message={`Task profile is being modified by ${customData?.updatingBy?.fullName}. Please wait to make your edits.`}
            />
            <FormContainer title={t('section.details')} to={'details'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <SingleSelectDropdownController
                            control={control}
                            controllerName='submissionStatus'
                            label="Submission Status"
                            disabled={readOnly}
                            options={updatedStatuses}
                            isSubmissionStatus={true}
                            sxDrop={{width: "100%"}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <SingleSelectDropdownController
                            control={control}
                            controllerName='user'
                            label="Assign User"
                            options={usersOptions}
                            disabled={readOnly}
                            sxDrop={{width: "100%"}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.title}
                            name='title'
                            required
                            fullWidth
                            label={t("enter.title")}
                            autoComplete="off"
                            type="text"
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={isUpdate ? 5 : 6} sx={{height: '95px'}}>
                        <DatePickerController
                            control={control}
                            error={errors?.dueDate}
                            name='dueDate'
                            required
                            fullWidth
                            label={t("enter.dueDate")}
                            disabled={readOnly}
                        />
                    </Grid>
                    {isUpdate &&
                        <Grid item xs={12} md={1} sx={{height: '95px', textAlign: 'center'}}>
                            <CheckboxController
                                control={control}
                                name="isDone"
                                label="Completed"
                                disabled={readOnly}
                            />
                        </Grid>
                    }

                </Grid>
            </FormContainer>
            <FormContainer title={t('section.description')} to={'description'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextEditorController
                            control={control}
                            name='description'
                            label={t('section.description')}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            {isUpdate && <>
                <FormContainer
                    title={t(event ? 'section.eventDetails' : 'section.awardDetails')}
                    to={event ? 'eventDetails' : 'awardDetails'}
                    boxSX={{height: '160px'}}
                >
                    <EventDetailFormContainer
                        id={event ? customData?.interestedClient?.recognition?.event?.id : customData?.interestedClient?.recognition?.award?.id}
                        event={event}
                    />
                </FormContainer>
                <FormContainer
                    title={t('section.clientDetails')}
                    to={'clientDetails'}
                    boxSX={{height: '160px'}}
                >
                    <ClientDetailComponent
                        id={customData?.interestedClient?.client?.id}
                    />
                </FormContainer>
            </>}
        </>
    );
};
export default TaskForm;