import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DateRangePickerComponent, {DateRangePickerSingleComponent} from "../../filters/dateRangeFilter";
import {Button, Grid, IconButton} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {t} from "i18next";
import {useTableFilter} from "./hooks/filterProvider";
import MultiSelectComponent from "../industriesFilter";
import CheckboxComponent from "../checkboxFilter";


function getHeaderFilterSection(title, value, onClick) {
    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '42px'
        }}>
        <Typography variant="navItem">
            {title}
        </Typography>
        {value &&
            <IconButton aria-label="close"
                        size="small" onClick={onClick}>
                <DeleteForeverIcon color="error"/>
            </IconButton>
        }
    </Box>;
}

function GetFilters(tableFilter, setTableFilter, isSmall = false) {
    return useMemo(() => filter => {
        switch (filter.type) {
            case 'dateRange':
                return isSmall ?
                    <DateRangePickerSingleComponent value={tableFilter[filter?.fieldName]} setValue={setTableFilter}
                                              isGlobal={filter?.isGlobal} field={filter?.fieldName} label={filter?.title}/>
                    : <DateRangePickerComponent value={tableFilter[filter?.fieldName]} setValue={setTableFilter}
                                                isGlobal={filter?.isGlobal} field={filter?.fieldName}/>;
            case 'multiSelect':
                return <MultiSelectComponent value={tableFilter[filter?.fieldName]} setValue={setTableFilter}
                                             isGlobal={filter?.isGlobal} field={filter?.fieldName} isSmall/>;
            case 'checkbox':
                return <CheckboxComponent value={tableFilter[filter.fieldName]} field={filter?.fieldName} setValue={setTableFilter} label={filter?.title} />;
            // Add other cases for different types
            default:
                return null;
        }
    }, [tableFilter]);
}

const GlobalFiltersContainer = (tableID, filters, handleClose) => {
    const {
        setTableFilter,
        isFilterActive,
        tableFilter,
        hasTempSelectedFilters,
        isTempFilterDifferent,
        cancelFilters,
        applyFilters
    } = useTableFilter(tableID);
    const renderFilterComponent = GetFilters(tableFilter, setTableFilter);

    return (
        <Grid container sx={{py: 1, px: 1}}>
            {filters.map((filter, index) => (
                <Grid item key={index} xs={12} sx={{px: 1, mb: 1}}>
                    {getHeaderFilterSection(t(filter?.title), isFilterActive(tableID, filter?.fieldName), () => setTableFilter(filter?.fieldName, filter?.defaultValue))}
                    {renderFilterComponent(filter)}
                </Grid>
            ))}
            <Grid item xs={12} sx={{px: 1, mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
                <Button sx={{mr: 2}} disabled={!hasTempSelectedFilters(tableID)} onClick={() => cancelFilters(tableID)}>Reset
                    all filters</Button>
                <Button
                    variant="contained"
                    disabled={!isTempFilterDifferent(tableID)}
                    color="primary"
                        onClick={
                    () => {
                        applyFilters(tableID)
                        handleClose()
                    }
                }
                >Apply</Button>
            </Grid>
        </Grid>
    );
};

export default GlobalFiltersContainer;

export const GlobalFilters = (tableID, filters) => {
    const {
        tableFilter,
        setTableFilter
    } = useTableFilter(tableID);
    const renderFilterComponent = GetFilters(tableFilter, setTableFilter, true);
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                alignItems: 'center',
            }}
        >
            {filters.map((filter, index) => (
                <Box key={index} sx={{
                    ml: 1,
                    my: 1,
                }}>
                    {renderFilterComponent(filter)}
                </Box>
            ))}
        </Box>
    )

}
