import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {renderDeadlineCell} from "../../manage/utilis/columnHelpers";

const EventColumns = () => {
    return [
        {
            field: 'name',
            headerName: t('columns.viewEventAnalytics.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/agency/events/analytics/${params?.id}?update_event`}>{params.value}</Link>
            ),
        },
        {
            field: 'clients',
            headerName: t('columns.viewEventAnalytics.clients'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.clients
            }
        },
        {
            field: 'analytics.speaking',
            headerName: t('columns.viewEventAnalytics.speaking'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.speaking
            }
        },
        {
            field: 'attending',
            headerName: t('columns.viewEventAnalytics.attending'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.attending
            }
        },
        {
            field: 'sponsor',
            headerName: t('columns.viewEventAnalytics.sponsor'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.sponsor
            }
        },
        {
            field: 'other',
            headerName: t('columns.viewEventAnalytics.other'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.other
            }
        },
        {
            field: 'pending',
            headerName: t('columns.viewEventAnalytics.pending'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.pending
            }
        },
        {
            field: 'completed',
            headerName: t('columns.viewEventAnalytics.completed'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.completed
            }
        },
        {
            field: 'deadline',
            headerName: t('columns.manageEvents.deadline'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.deadline
            },
            renderCell: (params) => {
                return renderDeadlineCell(params?.row?.deadline)
            }
        },
    ];
}

export default EventColumns;