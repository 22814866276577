import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewAwardAnalyticsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_AWARD_ANALYTICS_QUERY} from "../../graphql/award";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {t} from "i18next";
export default function ViewAwardAnalyticsComponent({inForm = false}) {
    const [analyticsItems, setAnalyticsItems] = useState([]);
    const {user} = useContext(AppContext);

    const {data, loading: loadingAwards, refetch} = useQuery(REQUEST_AWARD_ANALYTICS_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    },
                    showAllItems: false,
                    showOnlyDisabledItems: false
                }
            }
        });


    useEffect(() => {
        if (data?.requestAwardAnalytics?.data) {
            setAnalyticsItems(data?.requestAwardAnalytics?.data);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [refetch]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewAwardAnalytics.subTitle")}/>}
            <ViewAwardAnalyticsGrid
                list={analyticsItems} inForm={inForm} loading={loadingAwards}
            />
        </Box>
    );
}
