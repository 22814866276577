import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import get  from "lodash/get";

export default function GlobalDataListComponent({
  id,
  items = [],
  loading,
  rowHeight,
  hasActions = false,
  Actions,
  keyName = "name",
  containerHeight, // Pass this prop from the parent component
}) {
  const defaultRowHeight = 55;
  const calculatedRowHeight = rowHeight || defaultRowHeight;
  const calculateItemKey = (item) => {
    // Check if 'item' is an object and not null
    if (typeof item === 'object' && item !== null) {
        // Check if 'keyName' is an array
        if (Array.isArray(keyName)) {
            // Ensure the keys exist in the item
            if (keyName.length >= 2 && keyName[0] in item && keyName[1] in item) {
                return get(item, keyName[0], "") + ' - ' +  get(item, keyName[1],'');
            }
        } 
        // Check if 'keyName' is a string
        else if (typeof keyName === 'string') {
            // Ensure the key exists in the item
            return get(item, keyName, "")
        }
      }

    // Optional: handle unexpected 'keyName' types or 'item' types
    // Return null or throw an error based on your use case
    return null;
};

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: containerHeight, // Use the passed prop for minHeight
        maxHeight: containerHeight, // Use the passed prop for maxHeight
        overflow: "auto", // Scroll only if necessary
      }}>
      <List
        sx={{
          padding: 0,
          maxHeight: containerHeight, // Match the parent container's height
        }}
        id={id ? id + "-list" : ""}>
        {loading ? (
          <ListItem>
            <ListItemText primary="Loading..." />
          </ListItem>
        ) : (
          items.map((item) => (
            <ListItem key={item.id} style={{ height: calculatedRowHeight }}>
              <ListItemText 
              primary={
                <Typography noWrap overflow="hidden" textOverflow="ellipsis" maxWidth="95%">
                    {calculateItemKey(item)}
                </Typography>
            }
               />
              {hasActions && Actions({ params: item })}
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
}
