import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { useDropzone } from 'react-dropzone';

const FileUploadControllerComponent = ({ label, onChange, value, paperSx, readOnly }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [uploadedAvatar, setUploadedAvatar] = useState(value);
    const fileInputRef = useRef(null);

    const { getRootProps, getInputProps } = useDropzone({
        disabled: readOnly,
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                setUploadedAvatar(base64data);
                onChange(base64data);
            };
            reader.readAsDataURL(file);
        },
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
        },
    });

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFileUpload = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = reader.result;
                setUploadedAvatar(newImage);
                onChange(newImage);
            };
            reader.readAsDataURL(file);
        }
    };

    const onDeleteClick = () => {
        setUploadedAvatar(null);
        onChange(null);
        handleClose();
    };

    const onMenuUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
        handleClose();
    };

    useEffect(() => {
        if (value) {
            const image = new Image();
            image.onload = () => {
                setUploadedAvatar(value.startsWith("data:image") ? value : `${value}?timestamp=${new Date().getTime()}`);
            };
            image.onerror = () => {
                setUploadedAvatar(null);
            };
            image.src = value;
        }
    }, [value]);

    return (
        <Paper elevation={2} sx={{ mb: 2, borderRadius: '12px', overflow: 'hidden', ...paperSx }}>
            <Box p={2}>
                {!readOnly && (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography align="left" id="header-label-avatar">
                            {label}
                        </Typography>
                        <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={onMenuUploadClick}>
                                {uploadedAvatar ? 'Edit' : 'Upload'}
                            </MenuItem>
                            {uploadedAvatar && (
                                <MenuItem onClick={onDeleteClick}>
                                    Delete
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>
                )}
                <Box
                    {...getRootProps()}
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border={1}
                    borderColor="grey.300"
                    style={{ borderStyle: !uploadedAvatar ? 'dotted' : 'none' }}
                    p={2}
                >
                    <input {...getInputProps()} ref={fileInputRef} onChange={handleFileUpload} />
                    {!uploadedAvatar && !readOnly ? (
                        <>
                            <CloudUploadIcon fontSize="large" />
                            <Button component="label" variant="outlined" sx={{ mt: 1 }} color='blue' size={'small'}>
                                Choose File
                            </Button>
                            <Box mt={1}>
                                <Typography variant="subtitle2" align="center">
                                    or drag and drop file here to upload
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Avatar key={uploadedAvatar} src={uploadedAvatar} alt="Uploaded Avatar" sx={{ width: 150, height: 150 }} />
                    )}
                </Box>
            </Box>
        </Paper>
    );
};

const FileUploadController = ({ label, control, name, paperSx, readOnly }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
                <FileUploadControllerComponent
                    paperSx={paperSx}
                    readOnly={readOnly}
                    {...field}
                    label={label}
                />
            )}
        />
    );
};

export default FileUploadController;