import * as yup from "yup";
import regex from "../../../../helpers/regex";

export const clientFormValidations = (t) => {
    return yup.object().shape({
        name: yup.string().required(t('errorMessage.nameRequired')),
        emailAddress: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
            .required(t('errorMessage.emailAddressRequired')),
        phoneNumber: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat'))
            .required(t('errorMessage.phoneNumberRequired')),
        address: yup.string().required(t('errorMessage.addressRequired')),
        city: yup.string().required(t('errorMessage.cityRequired')),
        state: yup.string().required(t('errorMessage.stateRequired')),
        zipCode: yup.string().required(t('errorMessage.zipCodeRequired')),
    })
};
