import * as yup from "yup";
import regex from "../../../helpers/regex";
export const changePasswordFormValidations = (t) => {
    return yup.object().shape({
        emailAddress: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
            .required(t('errorMessage.emailAddressRequired')),
})};
