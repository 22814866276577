import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageSubscribersGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_SUBSCRIBERS_QUERY} from "../../graphql/subscriber";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

export default function ManageSubscribersComponent({inForm = false}) {
    const [subscriberItems, setSubscriberItems] = useState([]);
    //This will Replace the data with the data from the API
    const {t} = useTranslation();
    const {data, loading} = useQuery(REQUEST_SUBSCRIBERS_QUERY);

    useEffect(() => {
        if (data?.requestSubscribers?.data) {
            setSubscriberItems(data?.requestSubscribers?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.manageSubscribers.subTitle")}/>}
            <ManageSubscribersGrid
                list={subscriberItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
