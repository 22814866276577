import React, { useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {UserForm} from "../../forms";
import {
    REQUEST_USER_QUERY,
    REQUEST_USERS_QUERY,
    UPDATE_USER_MUTATION,
    FORGET_PASSWORD_MUTATION, REQUEST_LOGGED_USER_QUERY
} from "../../../graphql/user";
import {useMutation, useQuery} from "@apollo/client";
import {userFormValidations} from "./helpers";
import Toast from "../../alerts";
import {useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
export const EditUser = () => {
    const {t} = useTranslation();
    const [userData, setUserData] = useState({})
    const {userId} = useParams();
    const {data, refetch} = useQuery(REQUEST_USER_QUERY, {
        variables: {
            parameters: {
                user: {
                    id: userId,
                },
            },
        }
    });

    useEffect(() => {
        if (data?.requestUser?.data) {
            let obj = {...data?.requestUser?.data}
            const newObj = {
                ...obj,

            }
            setUserData(newObj);
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(userFormValidations(t)),
        values: userData
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    const [updateUser] = useMutation(UPDATE_USER_MUTATION,
        {
            refetchQueries: [{query: REQUEST_USERS_QUERY},{query: REQUEST_LOGGED_USER_QUERY}],
            awaitRefetchQueries: true
        });
    const cancelChanges = () => {
        reset(userData)
    }
    const [forgetPassword] = useMutation(FORGET_PASSWORD_MUTATION);
    const handleResetPassword = () => {
        forgetPassword({
            variables: {
                parameters: {
                    user: {
                        id: userId,
                        emailAddress: userData.emailAddress,
                    },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.forgotPassword')
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    const handleSubmit = () => {
        let data = getValues()
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(userData);
        if (!isDataChanged) return;

        updateUser({
            variables: {
                parameters: {
                    user: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.userUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >
                        <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                                              title={userData?.fullName || 'User'}
                                              cancelFn={cancelChanges}
                        >
                            <UserForm handleResetPassword={handleResetPassword} isUpdate/>
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};