import * as React from "react";
import {t} from "i18next";
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { EmailCell, formatPhoneNumber } from "../../manage/utilis/columnHelpers";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const UserColumns = (primaryContact) => {
    return [
        {
            field: 'fullName',
            headerName: t('columns.viewTeamMemberUsersOfClient.fullName'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link
                    component={RouterLink}
                    to={{
                        pathname: `/agency/user/${params?.id}`,
                        search: "?update_user",
                        state: { isView: true },
                        hash: "isView=true",
                    }}
                >
                    {params.value}
                </Link>),
        },
        {
            field: 'emailAddress',
            headerName: t('columns.viewTeamMemberUsersOfClient.emailAddress'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (<EmailCell value={params.value}/>)
        },
        {
            field: 'phoneNumber',
            headerName: t('columns.viewTeamMemberUsersOfClient.phoneNumber'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => formatPhoneNumber(params.value)
        },
        {
            field: 'isPrimaryContact',
            headerName: t('columns.viewTeamMemberUsersOfClient.isPrimaryTeamMember'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "center",
            headerAlign: 'center',
            renderCell: (params) => {
                return params.id === primaryContact?.id ? <DoneIcon sx={{cursor: 'default'}}/> : <CloseIcon sx={{cursor: 'default'}}/>
            },
            valueGetter: (params) => params.id === primaryContact?.id ? "Primary Contact" : ""
        },
    ]
}
export default UserColumns;