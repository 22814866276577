import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {TaskForm} from "../../forms";
import {CREATE_TASK_MUTATION, REQUEST_TASKS_OF_SUBMISSION_QUERY} from "../../../graphql/task";
import {useMutation, useQuery} from "@apollo/client";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {REQUEST_SUBMISSION_QUERY} from "../../../graphql/submission";
import {REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../../graphql/user";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {textEditorFormat} from "../../../helpers/formatters";
import {taskFormValidations} from "../Helpers/validation";

const AddTask = ({type}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useContext(AppContext);

    const [submissionObj, setSubmissionData] = useState({});
    const [userList, setUserList] = useState([]);

    const currentSearchParams = new URLSearchParams(window.location.search);
    const submissionId = currentSearchParams.get('submissionId');
    const [createTask] = useMutation(CREATE_TASK_MUTATION,{
        refetchQueries: [{
            query: REQUEST_TASKS_OF_SUBMISSION_QUERY, variables: {
                parameters: {
                    submission: {
                        id: submissionId
                    }
                }
            }
        },
            {
                query: REQUEST_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id:submissionId
                        }
                    },
                }
            }],
            awaitRefetchQueries: true
    });

    // Fetch submission data
    const {data: submissionData, loading: submissionLoading} = useQuery(REQUEST_SUBMISSION_QUERY, {
        variables: {parameters: {submission: {id: submissionId}}}
    });

    // Fetch team member users data
    const {data: teamMemberData} = useQuery(REQUEST_TEAM_MEMBER_USERS_QUERY, {
        variables: {parameters: {subscriber: {id: user?.subscriber?.id}}}
    });

    // Effect for setting submission data
    useEffect(() => {
        if (submissionData?.requestSubmission?.data && !submissionLoading) {
            setSubmissionData({...submissionData.requestSubmission.data});
        }
    }, [submissionData, submissionLoading]);

    // Effect for setting user list
    useEffect(() => {
        if (teamMemberData?.requestTeamMemberUsers?.data) {
            const users = teamMemberData.requestTeamMemberUsers.data.map(user => ({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                lastName: user.lastName,
            }));
            setUserList(users);
        }
    }, [teamMemberData]);

    // Form methods
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(taskFormValidations(t)),
        values: {
            user: {
                id: user?.id,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user?.firstName,
                lastName: user?.lastName,
            },
            submissionStatus: {
                id: submissionData?.requestSubmission?.data?.status?.name,
                name: submissionData?.requestSubmission?.data?.status?.name,
            }

        }
    });

    const {trigger, formState: {isValid}, getValues} = methods;

    // Handle form submission
    const handleSubmit = () => {
        let data = getValues();
        data.description = textEditorFormat(data?.description);

        // Create task mutation
        createTask({
            variables: {
                parameters: {
                    task: {
                        ...data,
                        user: {id: data.user.id},
                        submissionStatus: data.submissionStatus.name,
                        subscriber: {id: user?.subscriber?.id},
                        interestedClient: submissionObj.interestedClient,
                        submission: {id: submissionId}
                    },
                },
            },
        }).then(res => {
            // Success handling
            let taskId = res?.data?.createTask?.data?.id;
            Toast({type: 'success', message: t('successMessage.taskCreated')});
            const navigateTo = type === 'event' ? `/agency/events/tasks/${taskId}?update_task` : `/agency/awards/tasks/${taskId}?update_task`;
            navigate(navigateTo, { replace: true });
        }).catch(error => {
            // Error handling
            const errorMessage = error.graphQLErrors.map(x => x.message);
            Toast({type: 'error', message: errorMessage[0]});
        });
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: 'left',
            px: 2,
        }}
        >
            <FormProvider {...methods}>
                <form onBlur={() => trigger()}>
                    <FormWrapperComponent
                        submitFn={isValid ? handleSubmit : null}
                        title={'New Task'}
                        variant="add"
                        backTo={`/agency/events/submissions/${submissionId}?update_submission`}
                    >
                        <TaskForm
                            event={type === 'event'}
                            usersOptions={userList}
                            currentStatus={submissionObj?.status?.name}
                        />
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>
    );
};
export default AddTask;
