import {
    Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu,
    MenuItem
} from "@mui/material";
import React, {useContext, useState} from "react";
import {AppContext} from "../../App";
import {getNameInitials} from "../../helpers/formatters";
import {useNavigate} from "react-router-dom";
import {Link} from '@mui/material';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {LOGOUT_MUTATION} from '../../graphql/user';
import {useMutation} from "@apollo/client";
import {AUTH_KEY} from "../../index";
import PasswordIcon from '@mui/icons-material/Password';
import ChangePasswordOfUser from "../changePasswordOfUser";

const NavUserProfile = () => {
    const {isMinimized, user, setUser} = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false)
    const userRole = user?.userRole?.name || '';
    const userAvatar = user?.headshot || null;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [logout] = useMutation(LOGOUT_MUTATION);
    const navigate = useNavigate();
    const toogleChangePassword = () => {
        setIsOpen(!isOpen)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout().then(() => {
            setUser(null);
            localStorage.setItem(AUTH_KEY, null);
            navigate(`/logout`);
        });
    };
    const handleProfile = () => {
        if (userRole === 'Administrator') {
            navigate(`/administrator/administrators/${user?.id}?update_user`);
        } else if (userRole.includes('Subscriber')) {
            navigate(`/agency/team_members/${user?.id}?update_user`);
        } else if (userRole.includes('Client')) {
            navigate(`/client/contact/contacts/${user?.id}?update_user`);
        }
    };
    return (
        <div>
            <List key={'a27a0512-9a28-11ee-b9d1-0242ac120002'}>
                <ListItem key={user?.fullName} sx={{height: '48px'}} disablePadding>
                    <ListItemButton
                        title={user?.fullName}
                        sx={{
                            p: 0,
                            height: '48px',
                        }}
                        onClick={handleClick}
                    >
                        <ListItemAvatar sx={{display: 'flex', justifyContent: 'center'}}>
                            {userAvatar ?
                                <Avatar alt={user?.fullName} src={userAvatar}/>
                                :
                                <Avatar alt={user?.fullName}
                                        sx={{backgroundColor: "#fff"}}>{getNameInitials(user?.fullName)}</Avatar>
                            }
                        </ListItemAvatar>
                        {!isMinimized && (

                            <ListItemText
                                sx={{
                                    maxWidth: '95%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                primary={user?.fullName}/>
                        )}
                    </ListItemButton>
                </ListItem>
            </List>

            <Menu
                sx={{left: '200px'}}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <MenuItem sx={{p: 2, minWidth: '200px'}}>
                    <ListItemIcon>
                        <ManageAccountsIcon/>
                    </ListItemIcon>
                    <Link onClick={handleProfile}>{user?.fullName}</Link>
                </MenuItem>
                <MenuItem onClick={toogleChangePassword}>
                    <ListItemIcon>
                        <PasswordIcon/>
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout} sx={{p: 2, minWidth: '200px'}}>
                    <ListItemIcon>
                        <LogoutOutlinedIcon/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <ChangePasswordOfUser isOpen={isOpen} handleClose={toogleChangePassword}/>
        </div>
    );
};

export default NavUserProfile;