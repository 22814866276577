import React, {useState, useEffect} from 'react';
import GlobalDataGridPro from "../manage";
import UserColumns from './helpers/columns';
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DELETE_USER_MUTATION, REQUEST_USERS_QUERY} from "../../graphql/user";
import {useMutation} from "@apollo/client";
import DeleteUser from "./Delete";
import ImportUser from "./Import";
import searchByString from "../../helpers/searchByString";

export const ManageUsersGrid = ({list, inForm = false, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);

    const [isOpen, setIsOpen] = useState(false);
    const [deleteUser] = useMutation(DELETE_USER_MUTATION,
        {
            refetchQueries: [{query: REQUEST_USERS_QUERY}],
            awaitRefetchQueries: true
        });
    const handleDelete = DeleteUser(deleteUser);
    const handleDeleteUser = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    const handleAdd = () => {
        navigate(`/administrator/administrators/new_user_profile`)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const CustomColumns = React.useMemo(
        () => [
            ...UserColumns,
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-User"
                        icon={<EditIcon/>}
                        onClick={() => {
                            navigate(`/administrator/administrators/${params?.id}?update_user`)
                        }}
                        showInMenu={true}/>,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-User"
                        icon={<DeleteForeverIcon/>}
                        onClick={() => {
                            handleDeleteUser(params.id)
                        }}
                        showInMenu={true}/>,

                ]
            }
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Users",
        title: inForm ? "" : "Users",
        searchLabel: "Search Admin User",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleAdd: handleAdd,
        handleImport: toogleImport,
        handleSearch: handleSearch,

        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps}/>
            <ImportUser isOpen={isOpen} handleClose={toogleImport}/>
        </>

    );
};