import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {SubmissionContext} from "../component";
import DateRangePickerComponent from "../../filters/dateRangeFilter";
import DatePickerComponent from "../../filters/dateFilter";
import MultiSelectComponent from "../../filters/industriesFilter";
import {IconButton} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {t} from "i18next";
import FilterContainer from "../../formContainer/FilterContainer";


    
    
    function getHeaderFilterSection(title, value, onClick) {
    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '42px'
        }}>
        <Typography variant="navItem">
            {title}
        </Typography>
        {value &&
            <IconButton aria-label="close"
                        size="small" onClick={onClick}>
                <DeleteForeverIcon color="error"/>
            </IconButton>
        }
    </Box>;
}
const SubmissionFiltersContainer = () => {
    const {
        filterByDeadline: contextDeadline,
        setFilterByDeadline: setContextDeadline,
        filterBySubmissionDate: contextSubmissionDate,
        setFilterBySubmissionDate: setContextSubmissionDate,
        filterByIndustries: contextIndustries,
        setFilterByIndustries: setContextIndustries
    } = useContext(SubmissionContext);

    // Local draft states
    const [filterByDeadline, setFilterByDeadline] = useState(contextDeadline);
    const [filterBySubmissionDate, setFilterBySubmissionDate] = useState(contextSubmissionDate);
    const [filterByIndustries, setFilterByIndustries] = useState(contextIndustries);

    // Update local state when context changes
    useEffect(() => {
        setFilterByDeadline(contextDeadline);
        setFilterBySubmissionDate(contextSubmissionDate);
        setFilterByIndustries(contextIndustries);
    }, [contextDeadline, contextSubmissionDate, contextIndustries]);

    const handleCancel = () => {
        // Revert the draft states to the values from the context.
        setFilterByDeadline(contextDeadline);
        setFilterBySubmissionDate(contextSubmissionDate);
        setFilterByIndustries(contextIndustries);
    };

    const handleApply = () => {
        // Update the context with the draft values.
        setContextDeadline(filterByDeadline);
        setContextSubmissionDate(filterBySubmissionDate);
        setContextIndustries(filterByIndustries);
    };

    const sections = [
        {
            header: () => getHeaderFilterSection(t("manage.viewEventSubmissionOfClient.filters.submissionDateRange"), filterBySubmissionDate[0], () => setFilterBySubmissionDate([null, null])),
            component: () => <DateRangePickerComponent value={filterBySubmissionDate} setValue={setFilterBySubmissionDate} />,
            marginBottom: 2
        },
        {
            header: () => getHeaderFilterSection(t("manage.viewEventSubmissionOfClient.filters.deadline"), filterByDeadline, () => setFilterByDeadline(null)),
            component: () => <DatePickerComponent value={filterByDeadline} setValue={setFilterByDeadline} />,
            marginBottom: 3
        },
        {
            header: () => getHeaderFilterSection(t("manage.viewEventSubmissionOfClient.filters.industries"), !!filterByIndustries.length, () => setFilterByIndustries([])),
            component: () => <MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} />
        }
    ];

    return (
        <FilterContainer
            sections={sections}
            onCancel={handleCancel}
            onApply={handleApply}
        />
    );
}
export default SubmissionFiltersContainer;
export const SubmissionFilters = () => {
    const {
        
filterByDeadline,
setFilterByDeadline,
filterBySubmissionDate,
setFilterBySubmissionDate,
filterByIndustries,
setFilterByIndustries
    } = useContext(SubmissionContext);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                mx: 2
            }}
        >
           <DatePickerComponent value={filterByDeadline} setValue={setFilterByDeadline} /><DateRangePickerComponent value={filterBySubmissionDate} setValue={setFilterBySubmissionDate} componentSx={{ mr: 2 }} /><MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} componentSx={{ mr: 2 }} />
        </Box>
    )

}
