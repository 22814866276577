import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewEventsOfClientGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_EVENTS_OF_CLIENT_QUERY} from "../../graphql/event";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {useParams} from "react-router-dom";
import {t} from "i18next";

export default function ViewEventsOfClientComponent({inForm = false}) {
    const [eventItems, setEventItems] = useState([]);
    //This will Replace the data with the data from the API
    const {clientId} = useParams();

    const {user} = useContext(AppContext);
    const {data, loading} = useQuery(REQUEST_EVENTS_OF_CLIENT_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: user?.client?.id ? user?.client?.id : clientId
                    }
                }
            }
        });

    useEffect(() => {
        if (data?.requestEventsOfClient?.data) {
            setEventItems(data?.requestEventsOfClient?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: inForm ? 0 : 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewEventsOfClient.subTitle")}/>}
            <ViewEventsOfClientGrid
                list={eventItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
