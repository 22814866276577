import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {useQuery} from "@apollo/client";

import {REQUEST_TASKS_OF_SUBMISSION_QUERY} from "../../graphql/task";

import {PageHeader} from "../pageHeader";
import ManageTasksGrid from "./component";

const ManageTasksComponent = ({inForm = false, submissionId, type, readOnly, refetchSubmission}) => {

    const [taskItems, setTaskItems] = useState([]);

    const {data, loading, refetch} = useQuery(REQUEST_TASKS_OF_SUBMISSION_QUERY, {
        variables: {
            parameters: {
                submission: {
                    id: submissionId
                }
            }
        }
    });
    useEffect(() => {
        if (!loading && data?.requestTasksOfSubmission?.data) {
            setTaskItems(data?.requestTasksOfSubmission?.data);
        }
    }, [data, loading]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
            }}
        >
            {inForm ? <></> : <PageHeader/>}
            <ManageTasksGrid
                list={taskItems}
                inForm={inForm}
                submissionId={submissionId}
                type={type}
                loading={loading}
                readOnly={readOnly}
                refetch={refetch}
                refetchSubmissionQuery={refetchSubmission}
            />
        </Box>
    );
}
export default ManageTasksComponent;
