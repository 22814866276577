import React, {useState, useEffect, createContext} from 'react';
import GlobalDataGridPro from "../manage";
import EventFiltersContainer, {EventFilters} from "./Filter";

import searchByString from "../../helpers/searchByString";
import dayjs from "dayjs";
import {filterBySelection, filterDataByDateRange} from "../../helpers/filterBySelection";
import getEventColumns from "../manage/globalColumns/events";

export const EventContext = createContext();
export const ViewEventsOfClientGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const [filterByDeadline, setFilterByDeadline] = useState(null)
    const [filterByEventDate, setFilterByEventDate] = useState([null, null])
    const [filterByIndustries, setFilterByIndustries] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const formatDate = (date) => {
        return dayjs(date).format('MM/DD/YYYY');
    };
    const removeAllFilters = () => {

        setFilterByDeadline(null)
        setFilterByEventDate([null, null])
        setFilterByIndustries([])

    }
    const hasFilters = filterByDeadline !== null || (filterByEventDate[0] !== null || filterByEventDate[1] !== null) || filterByIndustries.length !== 0
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            deadline: filterByDeadline ? formatDate(filterByDeadline) : '',
            industries: filterByIndustries.map((industry) => {
                return industry.name
            }),
        }
        results = filterBySelection(results, filteredParams)
        results = filterDataByDateRange(results, filterByEventDate)
        setSearchResults(results)
    }, [filterByDeadline, filterByEventDate, filterByIndustries, searchTerm])
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...getEventColumns(
                {
                    baseURL: '/agency/events/browse'
                })
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Events",
        title: inForm ? "" : "Events",
        searchLabel: "Search Event",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: EventFiltersContainer,
            filterComponentToolbar: EventFilters,
            anchorEl: anchorEl,
            title: 'Filters',
            removeAllFilters: removeAllFilters,
            hasFilters: hasFilters
        },
        searchTerm: searchTerm,
        loading: loading
    }
    const context = {
        filterByDeadline, filterByEventDate, filterByIndustries,
        setFilterByDeadline, setFilterByEventDate, setFilterByIndustries,
        removeAllFilters
    }
    return (

        <EventContext.Provider value={context}>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>

        </EventContext.Provider>

    );
};