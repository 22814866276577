import Swal from 'sweetalert2';

const DataProtectionPopUp = {
    show: (isFormDirty, confirmHandler, t) => {
        if (isFormDirty) {
            Swal.fire({
                title: t('dataProtections.title'),
                html: `
                    <p>${t('dataProtections.body1')}</p>
                    <p>${t('dataProtections.body2')}</p>
                `,
                showCancelButton: true,
                confirmButtonText: t('dataProtections.confirm'),
                cancelButtonText: t('dataProtections.cancel'),
                focusCancel: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmHandler();
                }
            });
        }
    }
}

export default DataProtectionPopUp;