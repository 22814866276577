import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';

const ClientColumns = [
    {
        field: 'name',
        headerName: t('columns.viewClients.name'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/clients/${params?.id}?update_client`}>{params.value}</Link>),
    },
    {
        field: 'tasksDue',
        headerName: t('columns.viewClients.tasksDue'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.tasksDue ? params?.row?.tasksDue : 0
        }
    },
    {
        field: 'submissionsDueThisWeek',
        headerName: t('columns.viewClients.submissionsDueThisWeek'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.submissionsDueThisWeek ? params?.row?.submissionsDueThisWeek : 0
        }
    },
    {
        field: 'submissionsInProcess',
        headerName: t('columns.viewClients.submissionsInProcess'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.submissionsInProcess ? params?.row?.submissionsInProcess : 0
        }
    },
    {
        field: 'submissionsTracking',
        headerName: t('columns.viewClients.submissionsTracking'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.submissionsTracking ? params?.row?.submissionsTracking : 0
        }
    },

]
export default ClientColumns;