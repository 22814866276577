import React, { useState, useEffect, Suspense} from 'react';
import GlobalDataGridPro from "../manage";
import ClientColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";
import {CircularProgress} from "@mui/material";
import AssignInterestedClients from '../viewAwards/assign';
export const ViewClientsGrid = ({ list, inForm = false, loading, award }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...ClientColumns
        ], []);

    const toggleClose = () => {
        setIsOpen(!isOpen)
    }
    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Clients",
        title: inForm ? "" : "Clients",
        searchLabel: "Search Client",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        handleAdd: toggleClose,
        addLabel: "button.assignClient",
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: { pageSize: pageSize },
        autoHeight: false,


        handleSearch: handleSearch,

        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm} />
            {isOpen && (
                <Suspense fallback={ <CircularProgress />}>
                    <AssignInterestedClients isOpen={isOpen} handleClose={toggleClose} award={award}/>
                </Suspense>
            )}
        </>
    );
};