import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';
import {calculateLocation, dateRangeComparator, getChipStatusCell, renderDeadlineCell} from "../utilis/columnHelpers";

const getEventColumns = (
    {
        baseURL = '/administrator/events',
        role = '',
        handleButtonClick,
        interestedClients
    }
) => {
    const columns = [
        {
            field: 'name',
            headerName: t('columns.manageEvents.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`${baseURL}/${params?.id}?update_event`}>{params.value}</Link>),
        },
        {
            field: 'location',
            headerName: t('columns.manageEvents.location'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => calculateLocation(params.row)
        },
        {
            field: 'industries',
            headerName: t('columns.manageEvents.industries'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => params.row.industries?.map(industry => industry.name).join(', ')
        },
        {
            field: 'date',
            headerName: t('columns.manageEvents.date'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return `${params?.row?.startDate} - ${params?.row?.endDate}`
            },
            // Specify the custom comparator for this column
            sortComparator: dateRangeComparator,
            renderCell: (params) => {
                const startDate = params.row && params.row.startDate ? params.row.startDate : '';
                const endDate = params.row && params.row.endDate ? params.row.endDate : '';
                const formattedDate = `${startDate} - ${endDate}`;
                return <span>{formattedDate}</span>;
            }
        },
        {
            field: 'deadline',
            headerName: t('columns.manageEvents.deadline'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.deadline
            },
            renderCell: (params) => {
                return renderDeadlineCell(params?.row?.deadline)
            }
        },
    ]
    if (role === 'Administrator') {
        columns.push({
            field: 'status',
            headerName: t('columns.manageEvents.status'),
            width: 200,
            align: "center",
            resizable: false,
            headerAlign: 'center',
            renderCell: getChipStatusCell(),
            valueGetter: (params) => {
                return params?.row?.isDisabled ? 'Disabled' : params?.row?.status === 'Approved' ? 'Published' : 'Pending'
            },
        });
    }
    if (role.includes("Subscriber")) {
        columns.push( {
            field: 'clients',
            headerName: t('columns.viewEvents.clients'),
            width: 200,
            align: "center",
            resizable: false,
            headerAlign: 'center',
            valueGetter: (params) => {
                return interestedClients?.reduce((count, client) => {
                    return client?.recognition?.event?.id === params?.id ? count + 1 : count;
                }, 0)
            },
            renderCell: (params) => {
                let numberOfInterestedClients = interestedClients?.reduce((count, client) => {
                    return client?.recognition?.event?.id === params?.id ? count + 1 : count;
                }, 0);
                return <Avatar
                    onClick={() => {
                        handleButtonClick(params);
                    }}
                    sx={{
                        backgroundColor: "#717485",
                        color: "#fff",
                        fontSize: "1rem",
                        width: 30,
                        height: 30,
                    }}
                >
                    {numberOfInterestedClients}
                </Avatar>
            }
        });
    }

    return columns
}
export default getEventColumns;