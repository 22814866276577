import {Icon, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {AppContext} from "../../App";
import './helpers/scroll.css'
import {Link, scroller} from 'react-scroll';
import useScroll from "../../helpers/hooks/useScroll";
import {getInitials} from "./helpers";

const SubScrollToItem = ({item, index, lastItem, isCustom = false}) => {
    const {
        isMinimized,
        currentNav,
        setCurrentNav,
        viewHistory,
        setViewHistory,
        setScrollTo,
        scrollTo
    } = useContext(AppContext);
    const isActive = viewHistory ? false : currentNav === item?.href
    const handleScroll = (position) => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (documentHeight - scrollPosition === windowHeight) {
            setCurrentNav(lastItem?.href);
        } else {
            setCurrentNav(position)
        }
    }
    useScroll(handleScroll);

    useEffect(() => {
        if (index === 0 && !isCustom) {
            setCurrentNav(item?.href)
        }
        // eslint-disable-next-line
    }, [])

    // This effect hook reacts to changes in viewHistory.
    useEffect(() => {
        if (!viewHistory && item?.href === scrollTo) {
            // Only set current nav when viewHistory is false
            scroller.scrollTo(scrollTo, {
                duration: 800,
                smooth: 'easeInOutQuint',
                offset: -75
            });
            setScrollTo('');
        }
    }, [viewHistory]); // Only re-run the effect if viewHistory changes

    const handleOnClick = () => {
        if (viewHistory) {
            setViewHistory(false);
            setScrollTo(item?.href)
        } else {
            setCurrentNav(item?.href)
        }
    }
    return (
        <ListItem
            key={item.id || item?.description}
            className="fadeInSlideIn"
            disablePadding
            sx={{height: '58px'}}
        >
            <Link
                to={item?.href}
                spy={true}
                smooth='easeInOutQuint'
                onSetActive={handleScroll}
                offset={-75}
                duration={600}
                className="scrollLink"
            >
                <ListItemButton
                    sx={{
                        p: 0,
                        height: '48px',
                        ml: !isMinimized && 2,
                        backgroundColor: isActive ? '#EE814D' : false,
                        borderRadius: '12px',
                        '&:hover': {
                            backgroundColor: isActive && '#c65a25',
                        },
                    }}
                    onClick={() => handleOnClick()}
                >
                    {item?.icon ?
                        <ListItemIcon sx={{justifyContent: 'center'}}>
                            <Icon
                                as={item?.icon}
                                sx={{
                                    ml: !isMinimized ? '6px' : 0,
                                    mr: !isMinimized ? '14px' : 0,
                                    fontSize: '24px',
                                    color: isActive && item?.hasSubNav ? '#EE814D' : isActive ? '#082032' : '#fff',
                                }}
                            />
                        </ListItemIcon>
                        : isMinimized ?
                            <ListItemText
                                primaryTypographyProps={
                                    {
                                        sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: isActive && item?.hasSubNav ? '#EE814D' : isActive ? '#082032' : '#fff',
                                        },
                                        variant: "navItem"
                                    }
                                }
                                primary={getInitials(item?.title)}
                            />
                            :
                            <></>
                    }
                    {!isMinimized &&
                        <ListItemText
                            primaryTypographyProps={
                                {
                                    sx: {
                                        ml: !item?.icon ? 2 : false,
                                        color: isActive && item?.hasSubNav ? '#EE814D' : isActive ? '#082032' : '#fff',
                                    },
                                    variant: "navItem"
                                }
                            }
                            primary={item?.title}
                        />
                    }
                </ListItemButton>
            </Link>
        </ListItem>
    );
}
export default SubScrollToItem