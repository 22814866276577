import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

const ShowDisabledItems = (
    {
        toggleShowAll,
        showAll,
        label
    }) => {
    // Hook for internationalization.
    const { t } = useTranslation();

    return (

        <FormControlLabel
            control={
                <Checkbox
                    checked={showAll}
                    onChange={(e) => toggleShowAll(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
            label={t(label || "buttons.showAll")}
        />

    );
};

export default ShowDisabledItems;
