import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {EventContext} from "../component";
import DateRangePickerComponent from "../../filters/dateRangeFilter";
import DatePickerComponent from "../../filters/dateFilter";
import MultiSelectComponent from "../../filters/industriesFilter";
import {IconButton} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {t} from "i18next";
import FilterContainer from "../../formContainer/FilterContainer";


    
    
    function getHeaderFilterSection(title, value, onClick) {
    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '42px'
        }}>
        <Typography variant="navItem">
            {title}
        </Typography>
        {value &&
            <IconButton aria-label="close"
                        size="small" onClick={onClick}>
                <DeleteForeverIcon color="error"/>
            </IconButton>
        }
    </Box>;
}
const EventFiltersContainer = () => {
    const {
        filterByDeadline: contextDeadline,
        setFilterByDeadline: setContextDeadline,
        filterByEventDate: contextEventDate,
        setFilterByEventDate: setContextEventDate,
        filterByIndustries: contextIndustries,
        setFilterByIndustries: setContextIndustries
    } = useContext(EventContext);
    // Local draft states
    const [filterByDeadline, setFilterByDeadline] = useState(contextDeadline);
    const [filterByEventDate, setFilterByEventDate] = useState(contextEventDate);
    const [filterByIndustries, setFilterByIndustries] = useState(contextIndustries);
    // Listen for changes in the context and update the local state
    useEffect(() => {
        setFilterByDeadline(contextDeadline);
        setFilterByEventDate(contextEventDate);
        setFilterByIndustries(contextIndustries);
    }, [contextDeadline, contextEventDate, contextIndustries]);

    const handleCancel = () => {
        // Revert the draft states to the values from the context.
        setFilterByDeadline(contextDeadline);
        setFilterByEventDate(contextEventDate);
        setFilterByIndustries(contextIndustries);
    };

    const handleApply = () => {
        // Update the context with the draft values.
        setContextDeadline(filterByDeadline);
        setContextEventDate(filterByEventDate);
        setContextIndustries(filterByIndustries);
    };
    const sections = [
        {
            header: () => getHeaderFilterSection(t("manage.viewEventsOfClient.filters.eventRange"), filterByEventDate[0], () => setFilterByEventDate([null, null])),
            component: () => <DateRangePickerComponent value={filterByEventDate} setValue={setFilterByEventDate} />
        },
        {
            header: () => getHeaderFilterSection(t("manage.viewEventsOfClient.filters.deadline"), filterByDeadline, () => setFilterByDeadline(null)),
            component: () => <DatePickerComponent value={filterByDeadline} setValue={setFilterByDeadline} />,
            marginBottom: 3
        },
        {
            header: () => getHeaderFilterSection(t("manage.viewEventsOfClient.filters.industries"), !!filterByIndustries.length, () => setFilterByIndustries([])),
            component: () => <MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} />
        }
    ];

    return (
        <FilterContainer
            sections={sections}
            onCancel={handleCancel}
            onApply={handleApply}
        />
    );
};
export default EventFiltersContainer;
export const EventFilters = () => {
    const {
        
filterByDeadline,
setFilterByDeadline,
filterByEventDate,
setFilterByEventDate,
filterByIndustries,
setFilterByIndustries
    } = useContext(EventContext);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                mx: 2
            }}
        >
            <DateRangePickerComponent value={filterByEventDate} setValue={setFilterByEventDate} componentSx={{ mr: 2 }} />
            <DatePickerComponent value={filterByDeadline} setValue={setFilterByDeadline} />
            <MultiSelectComponent value={filterByIndustries} setValue={setFilterByIndustries} componentSx={{ mr: 2 }} />
        </Box>
    )

}
