import React, {useState, useEffect} from 'react';
import GlobalDataGridPro from "../manage";
import AnalyticsColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";
import {filterBySelection} from "../../helpers/filterBySelection";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";
import {awardFiltersOptions} from "../viewAwards/Filter";

const tableID = '1473ed38-8d61-11ee-b9d1-0242ac120002-analytics-awards';
export const ViewAwardAnalyticsGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const {permanentFilter} = useTableFilter(tableID);
    const {deadline = [null, null], industries = []} = permanentFilter;

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            applicationDeadline: {
                type: 'dateRange',
                value: deadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
        }
        results = filterBySelection(results, filteredParams)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...AnalyticsColumns()
        ], []);

    const dataGridProps = {
        id: tableID,
        title: inForm ? "" : "Analytics",
        searchLabel: "Search Analytics",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,


        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: awardFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters',
        },
        searchTerm: searchTerm,
        loading: loading
    }

    return (
        <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
    );
};