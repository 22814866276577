import * as React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from "../modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputController from "../formController/InputController";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import {changePasswordFormValidations} from "./helpers";
import {useMutation} from "@apollo/client";
import {CHANGE_PASSWORD_OF_USER_MUTATION} from "../../graphql/user";
import Toast from "../alerts";
import {useContext, useEffect} from "react";
import {toast} from "react-toastify";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {Divider} from "@mui/material";

const ChangePasswordOfUser = ({isOpen, handleClose}) => {
    const {t} = useTranslation();
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    const {control, reset, clearErrors, handleSubmit, formState: {errors, isValid}} = useForm({
        mode: 'onTouched',
        resolver: yupResolver(changePasswordFormValidations(t)),
    });
    const navigate = useNavigate();
    const {user} = useContext(AppContext);
    const handleNavigationAndRefresh = () => {
        navigate('/login');
        window.location.reload();
    }
    useEffect(() => {
        reset({'password': ''});
        clearErrors()
        // eslint-disable-next-line
    }, [isOpen])
    const [changePasswordOfUser] = useMutation(CHANGE_PASSWORD_OF_USER_MUTATION);
    const onSubmit = (data) => {
        let parameters;
        if (token) {
            parameters = {
                user: {
                    password: data.password,
                },
                token: token
            }

        } else {
            parameters = {
                user: {
                    id: user?.id,
                    password: data.password,
                },
            }
        }
        changePasswordOfUser({
            variables: {
                parameters: {
                    ...parameters
                },
            },
        })
            .then(() => {
                toast.dismiss(t('successMessage.changePassword'));
                Toast({
                    type: 'success',
                    message: t('successMessage.changePassword'),
                });
                handleClose()
                if (token) {
                    handleNavigationAndRefresh();
                }
                reset()
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                let message = errorMessage[0]
                Toast({
                    type: 'error',
                    message: message
                });
            });
    };
    return (
        <Modal isOpen={isOpen} handleClose={handleClose} sx={{p:1.5}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'left',
                        color: "white"
                    }}
                >
                    <Box sx={{width: '100%', display: 'flex',flexDirection:"column", justifyContent: 'left'}}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: "column", justifyContent: 'left' }}>
                            <Typography
                                variant="formHeader"
                                align="left"
                                id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-header-label-client"
                            >
                                {t('headers.changePassword')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-header-label-client"
                            >
                                {t('headers.changePasswordSubtitle')}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{width: "100%", mt: 1,mb:1}}/>
                    <Box sx={{
                        mt: 1,
                        width: '100%',
                        height: '180px',
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.password}
                                    name='password'
                                    required
                                    fullWidth
                                    label={t("enter.passwordLabel")}
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.confirmPassword}
                                    name='confirmPassword'
                                    type="password"
                                    required
                                    fullWidth
                                    label={t("enter.confirmPassword")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider sx={{width: "100%",mb:2}}/>
                    <Box
                        sx={{display: 'flex', justifyContent: 'end', width: '100%'}}
                    >
                        <Button
                            variant="outlined"
                            sx={{borderRadius: '12px'}}
                            id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-cancel"
                            onClick={handleClose}
                        >
                            {t('button.cancelCAP')}
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{color: '#FFFFFF', borderRadius: '12px', ml: 1}}
                            disabled={!isValid}
                            id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-importClientss"
                        >
                            {t('button.changePasswordCAP')}
                        </Button>
                    </Box>
                </Box>
            </form>
        </Modal>
    );
}
export default ChangePasswordOfUser