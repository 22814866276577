
export const AwardsFiltersOptions = [

    {
        title: "manage.manageAwards.filters.deadline",
        fieldName: "applicationDeadline",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEvents.filters.industries",
        fieldName: "industries",
        isGlobal: true,
        type: 'multiSelect',
        defaultValue: []
    }

]