import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {AwardForm} from "../../forms";
import {REQUEST_AWARD_QUERY, REQUEST_AWARDS_QUERY, UPDATE_AWARD_MUTATION} from "../../../graphql/award";
import {useMutation, useQuery} from "@apollo/client";
import {awardFormValidations} from "./helpers";
import Toast from "../../alerts";
import {useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {createEditorState, textEditorFormat} from "../../../helpers/formatters";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {REQUEST_INDUSTRIES_QUERY} from "../../../graphql/industry";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {getAgencyNote, updateAgencyNotes} from "../../../helpers/findAgencyNote";

export const EditAward = () => {
    const {t} = useTranslation();
    const [awardData, setAwardData] = useState({})
    const [queryData, setQueryData] = useState({})
    const {awardId} = useParams();
    const {data,refetch} = useQuery(REQUEST_AWARD_QUERY, {
        variables: {
            parameters: {
                award: {
                    id: awardId,
                },
            },
        }
    });
    const {user} = useContext(AppContext);
    const isAdmin = user?.userRole?.name === "Administrator";
    useEffect(() => {
        if (data?.requestAward?.data) {
            let obj = {...data?.requestAward?.data}
            const newObj = {
                ...obj,
                description: createEditorState(obj.description),
                awardGuidelines: createEditorState(obj.awardGuidelines),
                awardCategories: createEditorState(obj.awardCategories),

            }
            if(!isAdmin){
                newObj.agencyNotes = getAgencyNote(user?.subscriber?.id, newObj.agencyNotes)
            }
            setAwardData(newObj);
            setQueryData(obj)
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(awardFormValidations(t)),
        values: awardData
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    const [updateAward] = useMutation(UPDATE_AWARD_MUTATION,
        {
            refetchQueries: [
                {query: REQUEST_AWARDS_QUERY, variables: {
                parameters: {
                    showAllItems: true,
                    showOnlyDisabledItems: false
                }
            }},
            {query: REQUEST_AWARDS_QUERY, variables: {
                parameters: {
                    showAllItems: false,
                    showOnlyDisabledItems: false
                }
            }},
            {query: REQUEST_INDUSTRIES_QUERY}],
            awaitRefetchQueries: true
});
    const cancelChanges = () => {
        reset(awardData)
    }
    const handleSubmit = (e,stepper) => {
        let formValues = getValues()
        formValues.description = textEditorFormat(formValues?.description)
        formValues.awardGuidelines = textEditorFormat(formValues?.awardGuidelines)
        formValues.awardCategories = textEditorFormat(formValues?.awardCategories)
        if(!isAdmin){
            formValues.agencyNotes = updateAgencyNotes(user?.subscriber?.id, queryData?.agencyNotes, formValues?.agencyNotes )
        }
        if (stepper) {
            formValues.status = "Approved"
        }
        updateAward({
            variables: {
                parameters: {
                    award: formValues,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.awardUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >
                        <FormWrapperComponent
                            title={t('headers.manageAwards.edit.subTitle')}
                            submitFn={isValid ? handleSubmit : null}
                            cancelFn={cancelChanges}
                            stepper={awardData?.status === "Pending" && isAdmin}
                            status={awardData?.status}
                            stepperSubtitle={t('enter.award.stepper.editSubtitle')}
                            stepperSubmit={handleSubmit}
                        >
                            <AwardForm isViewOnly={!isAdmin} isUpdate/>
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};