import * as React from "react";
import {t} from "i18next";
import {Typography} from '@mui/material';

const IndustryColumns = (handleEdit) => {
    return [
        {
            field: 'name',
            headerName: t('columns.manageIndustries.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Typography
                    component="span"
                    sx={{ cursor: 'pointer', textDecoration: 'underline'}}
                    onClick={(e) => handleEdit(e,{id:params.id})}
                >
                    {params.value}
                </Typography>),
        },{
            field: 'eventCount',
            headerName: t('columns.manageIndustries.eventCount'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
        },
        {
            field: 'awardCount',
            headerName: t('columns.manageIndustries.awardCount'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
        }

    ];
}
export default IndustryColumns;