import {createTheme} from '@mui/material';

// Define a function to create your theme
function createMyTheme() {
    const baseTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#082032',
                paper: '#2C394B'
            },
            primary: {
                main: '#EE814D',
                light: '#f0956d',
                dark: '#c65a25'
            },
            blue: {
                light: '#4dbbee',
                main: '#009fe8',
                dark: '#006eb1'
            }
        },
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    columnHeader: {
                        backgroundColor: '#1A2D3F',
                    },
                    columnHeaders: {
                        overflow: 'visible',
                    },
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: 'inherit', // Change this color to your desired link color
                    },
                },
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#082032',
                        color: 'white',
                        borderRadius: '12px',
                    },
                },
            },
        }
    });
    return {
        ...baseTheme,
        typography: {
            ...baseTheme.typography,
            formHeader: {
                fontWeight: 600,
                lineHeight: '32.02px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                cursor: 'default',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '24px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '18px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '20px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '22px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '24px'
                },
            },
            formSubHeader: {
                fontWeight: 500,
                lineHeight: '32px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                cursor: 'default',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '24px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '18px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '20px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '22px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '24px'
                },
            },
            cardHeader: {
                fontWeight: 600,
                lineHeight: '51px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                color: '#F5822DDE',
                cursor: 'default',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '38px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '32px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '34px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '36px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '38px'
                },
            },
            pageHeader: {
                fontWeight: 500,
                lineHeight: '51px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                cursor: 'default',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '32px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '26px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '28px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '30px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '32px'
                },
            },
            navHeader: {
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '24px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '18px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '20px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '22px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '24px'
                },
            },
            navItem: {
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.01562em',
                textAlign: 'left',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '18px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '14px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '16px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '16px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '18px'
                },
            },
            modalHeader: {
                fontWeight: 600,
                lineHeight: '32.02px',
                letterSpacing: '-0.01562em',
                textAlign: 'center',
                cursor: 'default',
                fontFamily: 'Roboto',
                // Responsive font sizes
                fontSize: '20px',
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '14px'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '16px'
                },
                [baseTheme.breakpoints.up('lg')]: {
                    fontSize: '18px'
                },
                [baseTheme.breakpoints.up('xl')]: {
                    fontSize: '20px'
                },
            },
        },
    }
}

export const theme = createMyTheme();
