import React, {useState, useEffect, useContext, Suspense, lazy} from 'react';
import GlobalDataGridPro from "../manage";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import searchByString from "../../helpers/searchByString";
import {AppContext} from "../../App";
import {filterBySelection, filterDataByDateRange} from "../../helpers/filterBySelection";
import getEventColumns from "../manage/globalColumns/events";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";
import {EventFiltersOptions} from "../manageEvents/Filter";
import {CircularProgress} from "@mui/material";
const AssignInterestedClients = lazy(() => import('./assign'));

const tableID = 'de15950a-8fd3-11ee-b9d1-0242ac120002-browse-events';
export const ViewEventsGrid = ({list, interestedClients, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const {user} = useContext(AppContext);
    const {permanentFilter} = useTableFilter(tableID);
    const {
        eventDate = [null, null],
        deadline = [null, null],
        industries = [],
        rollingDeadline = false,
        directContact = false
    } = permanentFilter;

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = useState(10);
    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState({});

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            deadline: {
                type: 'dateRange',
                value: deadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
            coldPitch: {
                type: 'objSearch',
                value: directContact
            },
            rollingDeadline: {
                type: 'objSearch',
                value: rollingDeadline
            },
        }
        results = filterBySelection(results, filteredParams)
        results = filterDataByDateRange(results, eventDate)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])
    const handleAdd = () => {
        navigate(`${pathname}/new_event?register=true`)
    }
    const handleButtonClick = (params) => {
        setSelectedEvent(params?.row)
        setIsOpen(!isOpen)
    };
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...getEventColumns(
                {
                    baseURL: pathname,
                    role: user?.userRole?.name,
                    handleButtonClick,
                    interestedClients
                })
        ], [interestedClients, isOpen]);

    const dataGridProps = {
        id: tableID,
        title: t("view.viewEvents.title"),
        searchLabel: t("view.viewEvents.search"),
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleAdd: handleAdd,

        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: isOpen ? 'simple-popper' : undefined,
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: EventFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters'
        },
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps}/>
            {isOpen && (
                <Suspense fallback={ <CircularProgress />}>
                    <AssignInterestedClients isOpen={isOpen} handleClose={handleButtonClick} event={selectedEvent}/>
                </Suspense>
            )}
        </>

    );
};