import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { InputController, TextEditorController, AvatarUploadController } from "../formController";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import FormContainer from "../formContainer";
import IndustriesListComponent from "../sharedComponents/industries";
import ViewTeamMemberUsersOfClientComponent from "../viewTeamMemberUsersOfClient";
import ViewEventsOfClientComponent from "../viewEventsOfClient";
import ViewEventSubmissionsOfClientComponent from "../viewEventSubmissionsOfClient";
import ViewAwardsOfClientComponent from "../viewAwardsOfClient";
import ViewAwardSubmissionsOfClientComponent from "../viewAwardSubmissionsOfClient";
import ManageContactsComponent from "../manageContacts";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { AppContext } from "src/App";

const ClientForm = ({ isUpdate = false, handleSubmit }) => {
    const { t } = useTranslation();
    const {user} = useContext(AppContext);
    const isAdmin = user?.userRole?.name === 'Subscriber Administrator';
    const { control, watch, setValue, formState: { errors } } = useFormContext();
    const isDisabled = watch('isDisabled');
    const handleToggleDisableClient = () => {
        setValue('isDisabled', !isDisabled);
        handleSubmit()
    }

    const ToggleDisableClient = () => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" pr={2}>{`Status: ${isDisabled ? "Disabled" : "Active"}`}</Typography>
                <Button
                    variant={"outlined"}
                    color={isDisabled ? 'blue' : 'warning'}
                    startIcon={isDisabled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                    sx={{ borderRadius: '12px' }}
                    onClick={handleToggleDisableClient}
                    type='button'
                >
                    {isDisabled ? t('button.activateItem') : t('button.disableItem')}
                </Button>
            </Box>
        )
    }
    return (
        <>
            <FormContainer title={t('section.details')} to={'details'} custonRightButton={isUpdate && isAdmin ? ToggleDisableClient : null}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <AvatarUploadController label={t("enter.user.avatar")} control={control} name="avatar" />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.name}
                                    name='name'
                                    required
                                    fullWidth
                                    label={t("enter.name")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid> <Grid item xs={12} md={6} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.emailAddress}
                                    name='emailAddress'
                                    required
                                    fullWidth
                                    label={t("enter.emailAddress")}
                                    autoComplete="email"
                                    type="email"
                                />
                            </Grid> <Grid item xs={12} md={6} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.phoneNumber}
                                    name='phoneNumber'
                                    required
                                    fullWidth
                                    label={t("enter.phoneNumber")}
                                    autoComplete="tel"
                                    type="phone"
                                />
                            </Grid> <Grid item xs={12} md={6} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.address}
                                    name='address'
                                    required
                                    fullWidth
                                    label={t("enter.address")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid> <Grid item xs={12} md={4} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.city}
                                    name='city'
                                    required
                                    fullWidth
                                    label={t("enter.city")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid> <Grid item xs={12} md={4} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.state}
                                    name='state'
                                    required
                                    fullWidth
                                    label={t("enter.state")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ height: '95px' }}>
                                <InputController
                                    control={control}
                                    error={errors?.zipCode}
                                    name='zipCode'
                                    required
                                    fullWidth
                                    label={t("enter.zipCode")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormContainer>
            {isUpdate && <>
                <FormContainer title={t('section.events')} to={'events'}>
                    <ViewEventsOfClientComponent inForm={true} />
                </FormContainer>
                <FormContainer title={t('section.eventSubmissions')} to={'eventSubmissions'}>
                    <ViewEventSubmissionsOfClientComponent inForm={true} />
                </FormContainer>
                <FormContainer title={t('section.awards')} to={'awards'}>
                    <ViewAwardsOfClientComponent inForm={true} />
                </FormContainer>
                <FormContainer title={t('section.awardSubmissions')} to={'awardSubmissions'}>
                    <ViewAwardSubmissionsOfClientComponent inForm={true} />
                </FormContainer>
                <FormContainer title={t('section.keyboards')} to={'keywords'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextEditorController
                                control={control}
                                name='keywords'
                                label={t('keywords')}
                            />
                        </Grid>
                    </Grid>
                </FormContainer>
                {IndustriesListComponent(t, control)}

                <FormContainer title={t('section.contacts')} to={'contacts'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ManageContactsComponent inForm={true} />
                        </Grid>
                    </Grid>
                </FormContainer>
                <FormContainer title={t('section.teamMembers')} to={'teamMembers'}>
                    <ViewTeamMemberUsersOfClientComponent inForm={true} client={watch()}/>
                </FormContainer>
            </>}
        </>
    );
};
export default ClientForm;
