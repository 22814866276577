import React, { createContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { useQuery } from "@apollo/client";

import './i18n';
import './theme/global.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { REQUEST_LOGGED_USER_QUERY } from "./graphql/user";
import { AUTH_KEY } from "./index";
import UpdateTitleComponent from "./UpdateTitleComponent";
import AppLogout from "./helpers/auto-logout";
import { FilterProvider } from "./components/filters/containers/hooks/filterProvider";
import { generateRoutes } from "./helpers/appHelpers/routes";
import Layout from "./helpers/appHelpers/Layout";

export const AppContext = createContext();

const App = () => {
    const [user, setUser] = useState(localStorage.getItem(AUTH_KEY) || null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isMinimized, toggleNav] = useState(false);
    const [isFormDirty, setFormDirty] = useState(false);
    const [isAddingSection, setIsAddingSection] = useState(false);
    const [triggerFunction, setTriggerFunction] = useState(() => () => {});
    const [currentSideNavWidth, setCurrentSideNavWidth] = useState(null);
    const [navItems, setNavItems] = useState([]);
    const [isNavLoading, setIsNavLoading] = useState(true);
    const [viewHistory, setViewHistory] = useState(false);
    const [currentNav, setCurrentNav] = useState();
    const [scrollTo, setScrollTo] = useState('');

    const {data, loading} = useQuery(REQUEST_LOGGED_USER_QUERY);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isFormDirty) {
                event.preventDefault();
                event.returnValue = 'Are you sure you want to leave the page? You have unsaved changes!';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFormDirty]);
    useEffect(() => {
        if (!loading && data?.requestLoggedUser?.data) {
            setUser(data.requestLoggedUser.data);
        } else if (!loading) {
            setUser(null);
            localStorage.setItem(AUTH_KEY, null);
        }
    }, [data, loading]);
    useEffect(() => {
        if (user && user?.userRole) {
            localStorage.setItem(AUTH_KEY, user?.id);
        }
        // eslint-disable-next-line
    }, [user]);

    const context = {
        user,
        setUser,
        isMinimized,
        toggleNav,
        isLoading,
        setIsLoading,
        currentNav,
        setCurrentNav,
        isFormDirty,
        setFormDirty,
        isAddingSection,
        setIsAddingSection,
        triggerFunction,
        setTriggerFunction,
        navItems,
        setNavItems,
        currentSideNavWidth,
        setCurrentSideNavWidth,
        isNavLoading,
        setIsNavLoading,
        setIsEditing,
        isEditing,
        viewHistory,
        setViewHistory,
        scrollTo,
        setScrollTo
    }

    const routes = useMemo(() => {
        if (!loading) return generateRoutes({user, setUser});
    }, [user]);

    LicenseInfo.setLicenseKey(
        '2db17a29dbc98dbf61271daceb72ba84T1JERVI6NDEzNDIsRVhQSVJZPTE2ODA5MjUwNDAwMDAsS0VZVkVSU0lPTj0x',
    );
    return (
        <AppContext.Provider value={context}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                    <UpdateTitleComponent/>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        minHeight: '100vh',
                        background: "#082032"
                    }}>
                        <FilterProvider>
                            <Layout isMinimized>
                                {user && user?.userRole && <AppLogout/>}
                                {routes}
                            </Layout>
                        </FilterProvider>
                    </Box>
                </BrowserRouter>
            </LocalizationProvider>
        </AppContext.Provider>
    );
}

export default App;