import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {EmailCell, formatPhoneNumber} from "../../manage/utilis/columnHelpers";

const UserColumns = [
    {
        field: 'fullName',
        headerName: t('columns.manageUsers.fullName'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/administrator/administrators/${params?.id}?update_user`}>{params.value}</Link>),
    },
    {
        field: 'emailAddress',
        headerName: t('columns.manageUsers.emailAddress'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (<EmailCell value={params.value}/>)
    },
    {
        field: 'phoneNumber',
        headerName: t('columns.manageUsers.phoneNumber'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => formatPhoneNumber(params.value)
    },

]
export default UserColumns;