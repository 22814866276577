import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";
import FilterListIcon from '@mui/icons-material/FilterList';
import {useTableFilter} from "../../filters/containers/hooks/filterProvider";

export const DataGridFilterToolbar = (parameters) => {
    const {
        hasTempSelectedFilters,
        isTempFilterDifferent,
        cancelFilters,
        applyFilters
    } = useTableFilter();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 1,
            height: '68px',
            borderRadius: '12px',
            backgroundColor: '#1A2D3F'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                }}>
                <FilterListIcon/>
                {parameters?.filterProps?.filterComponentToolbar &&
                    (parameters?.filterProps?.filterComponentToolbar(parameters?.id, parameters?.filterProps?.filterOptions))
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    whiteSpace: 'nowrap',
                }}
            >
                <Button
                    size="small"
                    sx={{mr: 1}}
                    disabled={!hasTempSelectedFilters(parameters?.id)}
                    onClick={() => cancelFilters(parameters?.id)}
                >
                    Reset all filters
                </Button>
                <Button
                    size="small"
                    sx={{borderRadius: '12px'}}
                    variant="contained"
                    disabled={!isTempFilterDifferent(parameters?.id)}
                    color="primary"
                    onClick={() => applyFilters(parameters?.id)}
                >
                    Apply
                </Button>
            </Box>
        </Box>
    );
};