import * as yup from "yup";

export const submissionFormValidations = (t) => {
    return yup.object().shape({
        title: yup.string().required(t('errorMessage.titleRequired')),
        customSections: yup.array().of(
            yup.object().shape({
                name: yup.string().required(t('errorMessage.nameRequired')),
            })
        )
    })
};
