import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Controller} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import debounce from 'lodash/debounce'; // Make sure lodash is installed

// Utility function to check if a string is a valid URL
const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

const InputController = React.memo((
    {
        control,
        error,
        name,
        inputMode = 'both',
        inputProps = {},
        helperText = '',
        inputSx = {},
        ...rest
    }
) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const textFieldRef = useRef(null);

    // Toggle the password visibility
    const togglePasswordVisibility = useCallback(() => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    }, []);

    // Function to format phone numbers
    const formatPhoneNumber = useCallback((phoneNumberString) => {
        if (!phoneNumberString) {
            return '';
        }

        // Remove all non-digit characters except for the plus sign
        let cleaned = phoneNumberString.replace(/[^\d+]/g, '');
        let format = "";

        // Check for and format international numbers
        if (cleaned.startsWith('+')) { // International number
            let digits = cleaned.replace(/[^\d]/g, '');
            let countryCodeLength = 1; // Assume 1 by default; adjust based on specific cases
            const countryCodePatterns = {
                '1': 1, // USA, Canada, and other NANP countries
                '7': 1, // Russia, Kazakhstan
                '20': 2, // Egypt
                '27': 2, // South Africa
                '30': 2, // Greece
                '31': 2, // Netherlands
                '32': 2, // Belgium
                '33': 2, // France
                '34': 2, // Spain
                '36': 2, // Hungary
                '39': 2, // Italy
                '40': 2, // Romania
                '44': 2, // United Kingdom
                '49': 2, // Germany
                '61': 2, // Australia
                '62': 2, // Indonesia
                '63': 2, // Philippines
                '64': 2, // New Zealand
                '65': 2, // Singapore
                '66': 2, // Thailand
                '81': 2, // Japan
                '82': 2, // South Korea
                '84': 2, // Vietnam
                '86': 2, // China
                '90': 2, // Turkey
                '91': 2, // India
                '92': 2, // Pakistan
                '93': 2, // Afghanistan
                '94': 2, // Sri Lanka
                '95': 2, // Myanmar
                '98': 2, // Iran
                '212': 3, // Morocco
                '213': 3, // Algeria
                '216': 3, // Tunisia
                '8':1, // Russia, Kazakhstan
                // Add more country codes as needed
            };
            Object.keys(countryCodePatterns).forEach(code => {
                if (digits.startsWith(code)) {
                    countryCodeLength = countryCodePatterns[code];
                }
            });

            let countryCode = digits.slice(0, countryCodeLength);
            let mainNumber = digits.slice(countryCodeLength);
            if (mainNumber.length > 6) {
                format = `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(3, 6)}-${mainNumber.slice(6)}`;
            } else if (mainNumber.length > 3) {
                format = `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(3)}`;
            } else {
                format = `+${countryCode} ${mainNumber}`;
            }
        } else { // Local number without country code
            let digits = cleaned.replace(/[^\d]/g, '');
            if (digits.length > 6) {
                format = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
            } else if (digits.length > 3) {
                format = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
            } else {
                format = digits;
            }
        }

        return format;
    }, []);



    // Handle phone number formatting
    const handlePhoneChange = useCallback((e, field, value) => {
        value = value.replace(/\D/g, ''); // remove all non-digits
        if (value.length > 10) value = value.substring(0, 10); // limit to 10 digits
        field.onChange(value); // update the form value with the formatted value
    }, [formatPhoneNumber]);

    // Handle input change with trimming space at the start
    const handleInputChange = useCallback((e, field) => {
        if (rest.disabled) {
            return;
        }
        let value = e.target.value;

        // If value starts with space, trim it
        if (value.startsWith(' ')) {
            value = value.trimStart();
        }

        switch (inputMode) {
            case 'number':
                value = value.replace(/[^0-9\s]/g, ''); // remove all non-digits, but keep spaces
                break;
            case 'letter':
                value = value.replace(/[^a-zA-Z\s]/g, ''); // remove all non-letters, but keep spaces
                break;
            case 'specialChar':
                value = value.replace(/[a-zA-Z0-9\s]/g, ''); // remove all letters and numbers, leaving only special characters and spaces
                break;
            default:
                // no filter for 'both'
                break;
        }

        if (rest.type === "phone") {
            handlePhoneChange(e, field, value); // pass the value to handlePhoneChange
        } else {
            field.onChange(value);
        }
    }, [rest.disabled, inputMode, handlePhoneChange]);

    // Function to update the tooltip display based on text overflow
    const updateTooltip = useCallback(() => {
        if (textFieldRef.current) {
            setShowTooltip(textFieldRef.current.scrollWidth > textFieldRef.current.clientWidth);
        }
    }, []);

    // Debounced version of updateTooltip
    const debouncedUpdateTooltip = useCallback(debounce(updateTooltip, 100), [updateTooltip]);
    useEffect(() => {
        window.addEventListener('resize', debouncedUpdateTooltip);

        // Cleanup listener on component unmount
        return () => {
            debouncedUpdateTooltip.cancel();
            window.removeEventListener('resize', debouncedUpdateTooltip);
        };
    }, [debouncedUpdateTooltip]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({field}) => {
                const displayAsLink = isValidUrl(field.value) && rest.type === 'link';
                const formattedValue = rest.type === "phone" ? formatPhoneNumber(field.value) : field.value || '';
                // Define end adornment for link icon
                const linkAdornment = displayAsLink ? (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="open link"
                            onClick={() => window.open(field.value, '_blank')}
                        >
                            <OpenInNewIcon/>
                        </IconButton>
                    </InputAdornment>
                ) : null;

                return (
                    <Tooltip title={showTooltip ? field.value : ''} placement="top" disableHoverListener={!showTooltip}>
            <span> {/* Wrapping span for Tooltip to work with disabled input */}
                <TextField
                    {...rest}
                    error={!!error}
                    helperText={error?.message || helperText}
                    type={showPassword ? 'text' : rest.type}
                    {...field}
                    value={formattedValue || '' }
                    onChange={(e) => handleInputChange(e, field)}
                    InputProps={{
                        readOnly: rest.disabled,
                        endAdornment: (
                            rest.type === 'password' && !rest.disabled ? (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            ) : linkAdornment
                        ),
                        ...rest.InputProps,
                    }}
                    sx={{
                        cursor: rest.disabled ? 'default !important' : 'text',
                        '& .MuiInputBase-input': {
                            color: displayAsLink && !rest.disabled ? 'blue' : 'inherit',
                            textDecoration: displayAsLink && !rest.disabled ? 'underline' : 'none',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        },
                        ...inputSx
                    }}
                    inputProps={{
                        ref: textFieldRef, // Add ref to inputProps
                        style: { // Ensure the text can trigger overflow
                            maxWidth: '100%',
                        },
                        maxLength: rest.type === 'number' ? 3 : 255,
                        ...inputProps
                    }}
                    onMouseOver={debouncedUpdateTooltip} // Update tooltip on mouse over
                    onMouseOut={() => setShowTooltip(false)} // Hide tooltip when mouse leaves
                />
            </span>
                    </Tooltip>
                );
            }}
        />
    );
});

export default InputController;
