import {gql} from "@apollo/client";

export const REQUEST_INTERESTED_CLIENTS_OF_EVENT_QUERY = gql`
    query RequestInterestedClientsOfEvent($parameters: RequestInterestedClientsOfEventInput) {
        requestInterestedClientsOfEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    name
                    id
                }
                subscriber {
                    id
                }
                recognition {
                    id
                    event {
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                id
                recognitionType {
                    name
                    id
                }
            }
        }
    }
`;
export const REQUEST_CLIENTS_OF_EVENT_QUERY = gql`
    query RequestInterestedClientsOfEvent($parameters: RequestInterestedClientsOfEventInput) {
        requestInterestedClientsOfEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    submissionsInProcess
                    submissionsTracking
                    submissionsDueThisWeek
                    tasksDue
                    name
                    id
                }
                recognition {
                    id
                    event {
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                haveBooth
                

                planAttend


                other

                notes
                subscriber {
                    id
                }
                id
            }
        }
    }
`;
export const REQUEST_INTERESTED_CLIENTS_QUERY = gql`
    query RequestInterestedClients($parameters: RequestInterestedClientInput){
        requestInterestedClients(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    name
                    id
                }
                subscriber {
                    id
                }
                recognition {
                    id
                    event {
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                haveBooth


                planAttend


                other

                notes
                id
                recognitionType {
                    name
                    id
                }
            }
        }
    }
`;
export const REQUEST_INTERESTED_CLIENTS_BROWSE_QUERY = gql`
    query RequestInterestedClients($parameters: RequestInterestedClientInput){
        requestInterestedClients(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                recognition {
                    id
                    event {
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                haveBooth


                planAttend


                other

                notes
            }
        }
    }
`;
export const REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY = gql`
    query RequestInterestedClientsOfAward($parameters: RequestInterestedClientsOfAwardInput) {
        requestInterestedClientsOfAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    name
                    id
                }
                subscriber {
                    id
                }
                recognition {
                    id
                    event {
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                id
                recognitionType {
                    name
                    id
                }
            }
        }
    }
`;
export const REQUEST_CLIENTS_OF_AWARD_QUERY = gql`
    query RequestInterestedClientsOfAward($parameters: RequestInterestedClientsOfAwardInput) {
        requestInterestedClientsOfAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    submissionsInProcess
                    submissionsTracking
                    submissionsDueThisWeek
                    tasksDue
                    name
                    id
                }
                subscriber {
                    id
                }
                id
            }
        }
    }
`;
export const DELETE_INTERESTED_CLIENT_MUTATION = gql`
    mutation DeleteInterestedClient($parameters: DeleteInterestedClientInput) {
        deleteInterestedClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;


export const CREATE_INTERESTED_CLIENT_QUERY = gql`
    mutation CreateInterestedClient($parameters: CreateInterestedClientInput) {
        createInterestedClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    name
                    id
                }
                subscriber {
                    id
                }
                phoneNumber

                recognition {
                    id
                    willSpeak
                }
                firstName

                id

                recognitionType {
                    name
                    documentId
                    id
                }
                emailAddress

                haveBooth

                lastName

                planAttend

                other

                notes

            }
        }
    }
`;
export const UPDATE_INTERESTED_CLIENT_QUERY = gql`
    mutation UpdateInterestedClient($parameters: UpdateInterestedClientInput) {
        updateInterestedClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                client {
                    phoneNumber
                    emailAddress
                    deleted
                    state
                    createdAt
                    avatar
                    keywords
                    name
                    subscriberId
                    id
                    headshot
                    zipCode
                    documentId
                    updatedAt
                    address
                    city
                }
                subscriber {
                    deleted
                    id
                    documentId
                    city
                    updatedAt
                    name
                    phoneNumber
                    address
                    createdAt
                    eventSummaries
                    zipCode
                    logo
                    state
                }
                phoneNumber

                recognition {
                    id
                    event{
                        id
                        name
                    }
                    award {
                        id
                        name
                    }
                    willSpeak
                }
                firstName

                id

                recognitionType {
                    name
                    documentId
                    id
                }
                emailAddress

                haveBooth

                lastName

                planAttend


                other

                notes
            }
        }
    }
`;
