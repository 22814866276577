import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';
import { getChipStatusCell } from "../utilis/columnHelpers";

const getClientColumns = (
    {
        role = '',
        handleButtonClick
    }
) => {
    const columns = [
        {
            field: 'name',
            headerName: t('columns.manageClientsOfSubscriber.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/agency/clients/${params?.id}?update_client`}>{params.value}</Link>),
        },
        {
            field: 'tasksDue',
            headerName: t('columns.manageClientsOfSubscriber.tasksDue'),
            flex: 100,
            minWidth: 150,
            maxWidth: 220,
            align: "left",
            headerAlign: 'left'
        },
        {
            field: 'submissionsDueThisWeek',
            headerName: t('columns.manageClientsOfSubscriber.submissionsDueThisWeek'),
            flex: 100,
            minWidth: 150,
            maxWidth: 220,
            align: "left",
            headerAlign: 'left'
        },
        {
            field: 'submissionsInProcess',
            headerName: t('columns.manageClientsOfSubscriber.submissionsInProcess'),
            flex: 100,
            minWidth: 150,
            maxWidth: 220,
            align: "left",
            headerAlign: 'left'
        },
        {
            field: 'submissionsTracking',
            headerName: t('columns.manageClientsOfSubscriber.submissionsTracking'),
            flex: 100,
            minWidth: 150,
            maxWidth: 220,
            align: "left",
            headerAlign: 'left'
        }
    ]

    if (role.includes("Subscriber Administrator")) {
        columns.push(
            {
                field: "assignedUsers",
                headerName: t('columns.manageClientsOfSubscriber.assignedTeamMembers'),
                width: 200,
                align: "center",
                resizable: false,
                headerAlign: 'center',
                valueGetter: (params) => {
                    return params?.value?.length || 0
                },
                renderCell: (params) => {
                    let numberOfInterestedClients = params?.value || 0;
                    return <Avatar
                        onClick={() => {
                            handleButtonClick(params);
                        }}
                        sx={{
                            backgroundColor: "#717485",
                            color: "#fff",
                            fontSize: "1rem",
                            width: 30,
                            height: 30,
                        }}
                    >
                        {numberOfInterestedClients}
                    </Avatar>
                }
            },
            {
                field: 'status',
                headerName: t('columns.manageEvents.status'),
                width: 200,
                align: "center",
                resizable: false,
                headerAlign: 'center',
                renderCell: getChipStatusCell(true),
                valueGetter: (params) => {
                    return params?.row?.isDisabled ? 'Disabled' : 'Active'
                },
            }
        );
    }

    return columns
}
export default getClientColumns;