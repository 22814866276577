
export const taskFiltersOptions = [

    {
        title: "manage.manageEventTasksOfSubscriber.filters.taskDateRange",
        fieldName: "deadline",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    }

]