import React from "react";
import Grid from "@mui/material/Grid";
import {InputController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

const IndustryForm = () => {
    const {t} = useTranslation();
    const {control, formState: {errors}} = useFormContext();

    return (
        <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.name}
                            name='name'
                            required
                            fullWidth
                            label={t("enter.name")}
                            autoComplete="off"
                            type="text"
                        />
                    </Grid>
                </Grid>
        </>
    );
};
export default IndustryForm;