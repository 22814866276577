import {gql} from "@apollo/client";

export const REQUEST_UNASSIGNED_TEAM_MEMBER_QUERY = gql`
    query requestTeamMembersUnassignedOfClient($parameters: RequestTeamMembersUnassignedOfClientInput) {
        requestTeamMembersUnassignedOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                firstName
                fullName
                lastName
                emailAddress
            }
        }
    }
`;

export const REQUEST_ASSIGNED_TEAM_MEMBER_QUERY = gql`
    query requestTeamMembersAssignedOfClient($parameters: RequestTeamMembersUnassignedOfClientInput) {
        requestTeamMembersAssignedOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                firstName
                fullName
                lastName
                emailAddress
            }
        }
    }
`;
export const UNASSIGN_TEAM_MEMBER_MUTATION = gql`
    mutation unassignTeamMemberFromClient($parameters: UnassignTeamMemberFromClientInput) {
        unassignTeamMemberFromClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;
export const ASSIGN_TEAM_MEMBER_MUTATION = gql`
    mutation assignTeamMemberToClient($parameters: AssignTeamMemberToClientInput) {
        assignTeamMemberToClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;
