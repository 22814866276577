const searchByString = (data, searchTerm, keyName) => {
    // Split searchTerm into individual keywords, trim whitespace, and convert to lowercase for case-insensitive matching.
    let filters = searchTerm.split("+").map(x => x.trim().toLowerCase());

    // Helper function to check if a given value matches any of the filters.
    const matchesFilter = (value, filter) => {
        if (Array.isArray(value)) {
            // If value is an array, recursively check each element for a match.
            return value.some(element => matchesFilter(element, filter));
        } else if (value && typeof value === 'object') {
            // If value is an object (excluding null), recursively check each value.
            return Object.values(value).some(val => matchesFilter(val, filter));
        } else if (typeof value === 'string' || typeof value === 'number') {
            // For strings and numbers, check if the value includes the filter text.
            return value.toString().toLowerCase().includes(filter);
        }
        // Return false if value is of an unsupported type or doesn't match the filter.
        return false;
    };

    // Check if data is an array and not empty.
    if (Array.isArray(data) && data.length > 0) {
        return data.filter(item => {
            // Iterate through each filter to check if the item matches.
            for (let filter of filters) {
                let match = false;
                if (keyName) {
                    // If keyName is provided, check only the specified key in the item.
                    let value = item[keyName];
                    if (value && matchesFilter(value, filter)) {
                        match = true;
                        break; // Break if a match is found for this filter.
                    }
                } else {
                    // If no keyName is provided, check all keys except 'id'.
                    for (let key in item) {
                        if (key !== "id") {
                            let value = item[key];
                            if (value && matchesFilter(value, filter)) {
                                match = true;
                                break; // Break if a match is found for this filter.
                            }
                        }
                    }
                }
                if (!match) {
                    return false; // If the item does not match the current filter, exclude it from the results.
                }
            }
            return true; // Include the item if it matches all filters.
        });
    } else {
        return []; // Return an empty array if data is not an array or is empty.
    }
};

export default searchByString;