import { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from "@mui/material/Box";
import {Chip} from "@mui/material";

const CustomDropdownController = ({ control, controllerName, multiSelect, options = [],sxDrop, ...rest }) => {
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        setSelectedValues(control._defaultValues[controllerName] || []);
    }, [control._defaultValues[controllerName]]);
    const onControlOnChange = ({ event, field }) => {
        if (multiSelect) {
            const selectedValue = event.target.value[event.target.value.length - 1];
            const isAlreadySelected = selectedValues.some((val) => val.name === selectedValue.name);

            if (isAlreadySelected) {
                // If the value is already selected, remove it from the state
                setSelectedValues((prevSelected) => prevSelected.filter((val) => val.name !== selectedValue.name));
            } else {
                // If the value is not selected, add it to the state
                setSelectedValues(event.target.value);
            }

            field.onChange(event.target.value);
        } else {
            setSelectedValues(event.target.value);
            field.onChange(event.target.value);
        }
    };

    return (
        <Controller
            name={controllerName}
            control={control}
            defaultValue={multiSelect ? [] : ''}
            render={({ field }) => {
                const isArrayValue = Array.isArray(field.value);

                return (
                    <FormControl sx={sxDrop} fullWidth {...rest}>
                        <InputLabel htmlFor={controllerName}>
                            {rest.label || 'Select'}
                        </InputLabel>
                        <Select
                            labelId={`${controllerName}-label`}
                            fullWidth
                            id={controllerName}
                            multiple={multiSelect} // Set the multiple prop based on multiSelect prop
                            value={selectedValues} // Use the local state 'selectedValues'
                            onChange={(event)=>{
                                onControlOnChange({event,field:field})
                            }} // Handle the onChange event
                            renderValue={(selected) =>
                                isArrayValue
                                    ? (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    {...rest}
                                                    key={value.name}
                                                    label={options.find((option) => option.name === value.name)?.name || ''}
                                                    onDelete={(event) => {
                                                        onControlOnChange({event,field:field})
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )
                                    : options.find((option) => option.name === selected.name)?.name || ''
                            }

                        >
                            {options?.map((option) => (
                                <MenuItem key={option.name} value={option}>
                                    {multiSelect ? (
                                        <>
                                            <Checkbox checked={selectedValues.some((val) => val.name === option.name)} />
                                            <ListItemText primary={option.name} />
                                        </>
                                    ) : (
                                        <ListItemText primary={option.name} />
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
};

export default CustomDropdownController;
