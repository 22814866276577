import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewEventAnalyticsOfClientsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_EVENT_ANALYTICS_OF_CLIENTS_QUERY} from "../../graphql/client";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {t} from "i18next";
export default function ViewEventAnalyticsOfClientsComponent({inForm = false}) {
    const [clientItems, setClientItems] = useState([]);
    //This will Replace the data with the data from the API
    const {user} = useContext(AppContext);
    const [myClients, setMyClients] = useState(false);
    const [allClients, setAllClients] = useState(true);
    useEffect(() => {
        if (user?.userRole && user?.userRole?.name !== "Subscriber Administrator") {
            setMyClients(true);
            setAllClients(false);
        } else {
            setMyClients(false);
            setAllClients(true);
        }
    }, [user]);
    const showMyClients = () => {
        setAllClients(!allClients);
        setMyClients(!myClients);
    }
    const showAllClients = () => {
        setAllClients(!allClients);
        setMyClients(!myClients);
    }
    const {data, refetch, loading: loadingEventAnalytics} = useQuery(REQUEST_EVENT_ANALYTICS_OF_CLIENTS_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber ? user?.subscriber?.id : null
                    },
                    user: {
                        id: myClients ? user?.id : null
                    },
                }
            }
        });

    useEffect(() => {
        if (data?.requestEventAnalyticsOfClients?.data) {
            setClientItems(data?.requestEventAnalyticsOfClients?.data);
        }
    }, [data]);
    useEffect(() => {
        refetch();
    }, [myClients, allClients]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewEventAnalyticsOfClient.subTitle")}/>}
            <ViewEventAnalyticsOfClientsGrid
                list={clientItems} inForm={inForm}
                showMyClients={showMyClients}
                refetchData={refetch}
                showAllClients={showAllClients} myClients={myClients} allClients={allClients}
                loading={loadingEventAnalytics}
            />
        </Box>
    );
}
