import React from 'react';
import Typography from '@mui/material/Typography';
import {Link} from '@mui/material';
import {Controller} from 'react-hook-form';

const ReadOnlyFieldController = (
    {
        name,
        label,
        link,
        enableLink = false,
        customData,
        type,
        control,
        id,
        skip = false
    }
) => {
    const calculateFieldValue = () => {
        if (type === 'fullDate') {
            const startDate = customData?.startDate ? customData?.startDate + ' -' : '';
            const endDate = customData?.endDate ? customData?.endDate : '';
            return `${startDate} ${endDate}`;
        } else {
            return customData?.[name];
        }
    }
    const displayValue = calculateFieldValue();
    const renderContentWithLink = (content) => {
        if (!enableLink) {
            return content;
        }
        const href = link || content;
        return (
            <Link component="a" href={href}>
                {content}
            </Link>
        );
    };
    const labelSX = {
        whiteSpace: 'pre-wrap', // Prevent wrapping of long text,
        overflowWrap: 'break-word',
        overflow: 'auto', // Hide any overflowing content
        maxHeight: '112px', // Set the maximum height for the text block
    }
    if (skip) {
        return null;
    }
    return (
        <Controller
            key={id + 'controller'}
            name={name}
            control={control}
            render={() => (

                <Typography
                    key={id + 'typo'}
                    variant="subtitle1"
                    sx={
                        labelSX
                    }
                >
                    <strong> {label}</strong> {renderContentWithLink(displayValue)}
                </Typography>
            )}
        />
    );
};

export default ReadOnlyFieldController;
