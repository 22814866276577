import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {SubscriberForm} from "../../forms";
import {REQUEST_SUBSCRIBER_QUERY, REQUEST_SUBSCRIBERS_QUERY, update_agency_MUTATION} from "../../../graphql/subscriber";
import {useMutation, useQuery} from "@apollo/client";
import {UpdateSubscriberFormValidations} from "./helpers";
import Toast from "../../alerts";
import { useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";

export const EditSubscriber = () => {
    const {t} = useTranslation();
    const [subscriberData, setSubscriberData] = useState({})
    const {subscriberId} = useParams();
    const {data, refetch} = useQuery(REQUEST_SUBSCRIBER_QUERY, {
        variables: {
            parameters: {
                subscriber: {
                    id: subscriberId,
                },
            },
        }
    });

    useEffect(() => {
        if (data?.requestSubscriber?.data) {
            let obj = {...data?.requestSubscriber?.data}
            const newObj = {
                ...obj,
                ownerUser: {
                    ...obj?.ownerUser,
                    fullName: obj?.ownerUser?.firstName + ' ' + obj?.ownerUser?.lastName
                }

            }
            setSubscriberData(newObj);
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(UpdateSubscriberFormValidations(t)),
        values: subscriberData
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    const [updateSubscriber] = useMutation(update_agency_MUTATION,
        {
            refetchQueries: [{query: REQUEST_SUBSCRIBERS_QUERY}],
            awaitRefetchQueries: true
        });
    const cancelChanges = () => {
        reset(subscriberData)
    }
    const handleSubmit = () => {
        let data = getValues();
        updateSubscriber({
            variables: {
                parameters: {
                    subscriber: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.subscriberUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >
                        <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                                              title={t('headers.agency.edit.subtitle')}
                                              cancelFn={cancelChanges}
                        >
                            <SubscriberForm isUpdate/>
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};