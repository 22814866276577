import * as yup from "yup";
import regex from "../../../../helpers/regex";

export const subscriberFormValidations = (t) => {
    return yup.object().shape({
        name: yup.string().required(t('errorMessage.nameRequired')),
        phoneNumber: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat')),
        address: yup.string().required(t('errorMessage.addressRequired'))
            .required(t('errorMessage.phoneNumberRequired')),
        city: yup.string().required(t('errorMessage.cityRequired')),
        state: yup.string().required(t('errorMessage.stateRequired')),
        zipCode: yup.string().required(t('errorMessage.zipCodeRequired')),
        emailAddress: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
            .required(t('errorMessage.emailAddressRequired')),
        ownerUser: yup.object().shape({
            lastName: yup.string().required(t('errorMessage.lastNameRequired')),
            firstName: yup.string().required(t('errorMessage.firstNameRequired')),
            emailAddress: yup
                .string()
                .nullable()
                .transform((value, originalValue) => (originalValue === '' ? null : value))
                .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
                .required(t('errorMessage.emailAddressRequired')),
            phoneNumber: yup
                .string()
                .nullable()
                .transform((value, originalValue) => (originalValue === '' ? null : value))
                .matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat'))
                .required(t('errorMessage.phoneNumberRequired')),
        }),
    })
};
export const UpdateSubscriberFormValidations = (t) => {
    return yup.object().shape({
        name: yup.string().required(t('errorMessage.nameRequired')),
        phoneNumber: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat')),
        address: yup.string().required(t('errorMessage.addressRequired'))
            .required(t('errorMessage.phoneNumberRequired')),
        city: yup.string().required(t('errorMessage.cityRequired')),
        state: yup.string().required(t('errorMessage.stateRequired')),
        zipCode: yup.string().required(t('errorMessage.zipCodeRequired')),
        gracePeriod: yup
            .string()
            .test(
                'no-whitespace-if-edited',
                'The grace period cannot be empty!',
                value => value === undefined || value?.trim().length !== 0
            )
            .notRequired(),
        emailAddress: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
            .required(t('errorMessage.emailAddressRequired')),
    })
};
