const emailAddress = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{8,}$/;
const onlyLetters =/^[a-zA-Z\s]*$/;
const phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const internationalPhoneNumber = /^\+?(\d{1,2})?\s?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/;
const onlyNumbers = /^\d+$/;
const onlyNumbersIncludesDecimals = /^\d+(\.\d+)?$/;
const regex = {
    emailAddress,
    password,
    onlyLetters,
    phoneNumber,
    internationalPhoneNumber,
    onlyNumbers,
    onlyNumbersIncludesDecimals
}
export default regex