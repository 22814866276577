import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Chip, Popper} from "@mui/material";
import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {REQUEST_INDUSTRIES_QUERY} from "../../graphql/industry";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const popperModifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, 8], // Adjust the offset values as needed
        },
    },
];

const renderTags = (value, getTagProps) => {
    let limitedValue = value.slice(0, 1); // Limit the displayed tags to the first two options
    let customTag = null;

    if (value.length > 1) {
        const customText = `+${value.length - 1} more`;
        customTag = (
            <Chip
                size="small"
                label={customText}
            />
        );
    }

    return [...limitedValue.map((option, index) => (
        <Chip
            key={index}
            size="small"
            label={option?.name}
            {...getTagProps({index})}
        />
    )), customTag];
};
const renderLengthTags = (value) => {
    if (value.length === 0) {
        return null; // Return null if there are no tags
    }

    const tagCount = value.length; // Get the count of selected tags
    const labelText = tagCount > 1 ? `${tagCount} selected` : value[0]?.name;

    return (
        <Chip
            size="small"
            label={labelText}
        />
    );
};

const MultiSelectComponent = ({value, setValue, componentSx, isGlobal, field, isSmall}) => {
    const [industryItems, setIndustryItems] = useState([]);
    //This will Replace the data with the data from the API
    const {data} = useQuery(REQUEST_INDUSTRIES_QUERY);

    useEffect(() => {
        if (data?.requestIndustries?.data) {
            setIndustryItems(data?.requestIndustries?.data);
        }
    }, [data]);

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags"
            options={industryItems}
            disableCloseOnSelect
            renderTags={isSmall ? renderLengthTags : renderTags}
            value={value || []}
            onChange={(event, newValue) => {
                if (isGlobal) {
                    setValue(field, newValue);
                } else {
                    setValue(newValue);
                }
            }}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option?.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label="Industries" placeholder="Select an option" size="small" fullWidth/>
            )}
            limitTags={2}
            sx={{...componentSx, minWidth: isSmall && 220}}
            PopperComponent={(props) => (
                <Popper {...props} placement="bottom" modifiers={popperModifiers}/>
            )}
        />
    );
}
export default MultiSelectComponent