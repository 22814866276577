
export const submissionFiltersOptions = [
    {
        title: "manage.manageEventSubmissionsOfSubscriber.filters.submissionDateRange",
        fieldName: "submissionDate",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEventSubmissionsOfSubscriber.filters.deadline",
        fieldName: "deadline",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEventSubmissionsOfSubscriber.filters.industries",
        fieldName: "industries",
        isGlobal: true,
        type: 'multiSelect',
        defaultValue: []
    }

]