import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import Box from "@mui/material/Box";
import {AppContext} from "../../App";
import SideNav from "../../components/sidenav/SideNav";

const Layout = ({children, isMinimized}) => {
    const location = useLocation();
    const {setFormDirty} = useContext(AppContext);

    useEffect(() => {
        setFormDirty(false);
    }, [location, setFormDirty]);

    return (
        <Box sx={{display: 'flex', width: '100%', height: '100vh'}}>
            {location.pathname !== '/login' &&
                location.pathname !== '/logout' &&
                location.pathname !== '/changepassword' && (
                    <Box
                        component={SideNav}
                        sx={{
                            width: isMinimized ? 'auto' : 'auto',
                            flexShrink: 0,
                            transition: 'width 1s ease-in-out'
                        }}
                    />
                )}
            <Box
                sx={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 'auto',
                    transition: 'flex-basis 1s ease-in-out', // Transition on flex-basis
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
export default Layout;
