import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {IndustryForm} from "../../forms";
import {REQUEST_INDUSTRY_QUERY, REQUEST_INDUSTRIES_QUERY, UPDATE_INDUSTRY_MUTATION} from "../../../graphql/industry";
import {useMutation, useQuery} from "@apollo/client";
import {industryFormValidations} from "./helpers";
import Toast from "../../alerts";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import Modal from "../../modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Divider} from "@mui/material";

export const EditIndustry = ({isOpen, handleClose}) => {
    const {t} = useTranslation();
    const [industryData, setIndustryData] = useState({})
    const currentSearchParams = new URLSearchParams(window.location.search);
    const industryId = currentSearchParams.get('industryId');
    const {data} = useQuery(REQUEST_INDUSTRY_QUERY, {
        variables: {
            parameters: {
                industry: {
                    id: industryId,
                },
            },
        }
    });
    useEffect(() => {
        if (data?.requestIndustry?.data) {
            let obj = {...data?.requestIndustry?.data}
            const newObj = {
                ...obj,

            }
            setIndustryData(newObj);
        }
    }, [data]);
    const methods = useForm({
        mode: 'onTouched',
        resolver: yupResolver(industryFormValidations(t)),
        values: industryData
    });
    const {trigger, formState: {isValid, isDirty}, getValues} = methods;

    const [updateIndustry] = useMutation(UPDATE_INDUSTRY_MUTATION, {
        update: (cache, {data: {updateIndustry}}) => {
            // Read the existing cache for the industries
            const existingIndustries = cache.readQuery({query: REQUEST_INDUSTRIES_QUERY});
            // Modify the cache with the updated industry
            const updatedIndustries = existingIndustries.requestIndustries.data.map(industry =>
                industry.id === updateIndustry.data.id ? updateIndustry.data : industry
            );
            // Write the modified data back to the cache
            cache.writeQuery({
                query: REQUEST_INDUSTRIES_QUERY,
                data: {requestIndustries: updatedIndustries},
            });
        },
    });
    const handleSubmit = (event) => {
        event.preventDefault();
        let data = getValues()
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(industryData);
        if (!isDataChanged) return;

        updateIndustry({
            variables: {
                parameters: {
                    industry: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.industryUpdated')
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form onBlur={() => {
                        trigger()
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'left'
                            }}
                        >
                            <Typography variant="formHeader"
                                        id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-header-label-industry" color="white">
                                {t('headers.manageIndustries.edit.title')}
                            </Typography>
                            <Divider sx={{width: '100%'}}/>
                            <Box sx={{mt: 1, width: '100%', height: '200px', display: 'flex', alignItems: 'center'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <IndustryForm/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{width: '100%', mb: 1}}/>
                            <Box
                                sx={{display: 'flex', justifyContent: 'end', width: '100%'}}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{borderRadius: '12px'}}
                                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-cancel"
                                    onClick={handleClose}
                                >
                                    {t('button.cancelCAP')}
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={(event) => handleSubmit(event)}
                                    sx={{color: '#FFFFFF', borderRadius: '12px', ml: 1}}
                                    disabled={!isValid || !isDirty}
                                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-industry"
                                >
                                    {t('button.saveCAP')}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Modal>
    );
};