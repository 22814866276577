import Swal from 'sweetalert2';
import './sweetalert.css'

export default function popupConfirmation(parameters, thenFunction, id) {
    let subParameters = parameters?.subParameters || {};
    Swal.fire({
        title: parameters.title ? parameters.title : 'Are you sure?',
        text: parameters.text ? parameters.text : "You won't be able to revert this!",
        icon: parameters.icon ? parameters.icon : 'warning',
        showCancelButton: parameters.showCancelButton ? parameters.showCancelButton : true,
        confirmButtonColor: parameters.confirmButtonColor ? parameters.confirmButtonColor : '#393BA5',
        cancelButtonColor: parameters.cancelButtonColor ? parameters.cancelButtonColor : '#8D1D1C',
        confirmButtonText: parameters.confirmButtonText ? parameters.confirmButtonText : 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            thenFunction(id).then(() => {
                if (subParameters.successTitle && subParameters.successText) {
                    Swal.fire({
                        title: subParameters.successTitle,
                        text: subParameters.successText,
                        icon: 'success',
                        timer: 1500
                    });
                }
            }).catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Swal.fire({
                    title: subParameters.errorTitle || 'Error',
                    text: errorMessage[0] || subParameters.errorText || 'An error occurred.',
                    icon: 'error'
                });
            });
        }
    });
}

export function basicPopupConfirmation(parameters, thenFunction) {
    Swal.fire({
        title: parameters.title ? parameters.title : 'Are you sure?',
        text: parameters.text ? parameters.text : "You won't be able to revert this!",
        icon: parameters.icon ? parameters.icon : 'warning',
        showCancelButton: parameters.showCancelButton ? parameters.showCancelButton : true,
        confirmButtonColor: parameters.confirmButtonColor ? parameters.confirmButtonColor : '#393BA5',
        cancelButtonColor: parameters.cancelButtonColor ? parameters.cancelButtonColor : '#8D1D1C',
        confirmButtonText: parameters.confirmButtonText ? parameters.confirmButtonText : 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            thenFunction()
        }
    });
}
