import * as yup from "yup";
import regex from "../../../../helpers/regex";
export const userFormValidations = (t) => {
    return yup.object().shape({
lastName: yup.string().required(t('errorMessage.lastNameRequired')),
firstName: yup.string().required(t('errorMessage.firstNameRequired')),
emailAddress: yup
.string()
.nullable()
.transform((value, originalValue) => (originalValue === '' ? null : value))
.matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
.required(t('errorMessage.emailAddressRequired')),
phoneNumber: yup
.string()
.nullable()
.transform((value, originalValue) => (originalValue === '' ? null : value))
.matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat'))
    .required(t('errorMessage.phoneNumberRequired')),
})};
