import {gql} from "@apollo/client";


export const CREATE_AWARD_MUTATION = gql`
    mutation CreateAward($parameters: CreateAwardInput) {
        createAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                country
                website
                programContactPhoneNumber
                programContactEmailAddress
                awardCategories
                id
                logo
                awardGuidelines
                industries {
                    id
                    name
                }
                programContactName
                name
                applicationDeadline
                organizerCompany
                description

            }
        }
    }
`;

export const DELETE_AWARD_MUTATION = gql`
    mutation DeleteAward($parameters: DeleteAwardInput) {
        deleteAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const IMPORT_AWARDS_MUTATION = gql`
    mutation ImportAwards($parameters: ImportAwardsInput) {
        importAwards(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                description
                country
                organizerCompany
                id
                programContactEmailAddress
                name
                logo
                programContactPhoneNumber
                applicationDeadline
                website
                awardGuidelines
                awardCategories
                industries {
                    id
                    name
                }
                programContactName

            }
        }
    }
`;

export const REGISTER_AWARD_MUTATION = gql`
    mutation RegisterAward($parameters: RegisterAwardInput) {
        registerAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                country
                applicationDeadline
                description
                logo
                awardGuidelines
                awardCategories
                programContactPhoneNumber
                programContactEmailAddress
                industries {
                    id
                    name
                }
                website
                programContactName
                organizerCompany
                id

            }
        }
    }
`;

export const REQUEST_APPROVED_AWARDS_QUERY = gql`
    query RequestApprovedAwards($parameters: RequestAwardsInput) {
        requestApprovedAwards(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                organizerCompany
                country
                name
                logo
                industries {
                    name
                    id
                }
                applicationDeadline
                id
                status

            }
        }
    }
`;
export const REQUEST_AWARD_ANALYTICS_QUERY = gql`
    query RequestAwardAnalytics($parameters: RequestAwardAnalyticsInput) {
        requestAwardAnalytics(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                applicationDeadline
                id
                submissionsTracking
                 analytics {
                     deadlineNet30
                     pending
                     completed
                     submissionsTracking
                     clients
                 }

            }
        }
    }
`;
export const REQUEST_AWARD_QUERY = gql`
    query RequestAward($parameters: RequestAwardInput) {
        requestAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                country
                programContactName
                name
                logo
                description
                organizerCompany
                id
                industries {
                    id
                    name
                }
                status
                programContactPhoneNumber
                awardGuidelines
                agencyNotes{
                    agencyId
                    notes
                }
                programContactEmailAddress
                website
                applicationDeadline
                awardCategories

            }
        }
    }
`;
export const REQUEST_AWARD_DETAILS_QUERY = gql`
    query RequestAward($parameters: RequestAwardInput) {
        requestAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                id
                applicationDeadline
                website
            }
        }
    }
`;
export const REQUEST_AWARDS_OF_CLIENT_QUERY = gql`
    query RequestAwardsOfClient($parameters: RequestAwardsOfClientInput) {
        requestAwardsOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                country
                programContactName
                name
                description
                organizerCompany
                id
                logo
                industries {
                    id
                    name
                }
                programContactPhoneNumber
                awardGuidelines
                agencyNotes{
                    agencyId
                    notes
                }
                programContactEmailAddress
                website
                applicationDeadline
                awardCategories

            }
        }
    }
`;

export const REQUEST_AWARDS_QUERY = gql`
    query RequestAwards ($parameters: RequestAwardsInput) {
        requestAwards(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                applicationDeadline
                id
                name
                status
                isDisabled
                website
                organizerCompany
                industries {
                    id
                    name
                }

            }
        }
    }
`;

export const UPDATE_AWARD_MUTATION = gql`
    mutation UpdateAward($parameters: UpdateAwardInput) {
        updateAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                description
                awardGuidelines
                organizerCompany
                awardCategories
                programContactName
                industries {
                    id
                    name
                }
                programContactEmailAddress
                website
                applicationDeadline
                programContactPhoneNumber
                id
                logo
                country
                agencyNotes{
                    agencyId
                    notes
                }
                name

            }
        }
    }
`;
