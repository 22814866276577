import React,{useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {SubscriberForm} from "../../forms";
import {CREATE_SUBSCRIBER_MUTATION, REQUEST_SUBSCRIBERS_QUERY} from "../../../graphql/subscriber";
import {useMutation} from "@apollo/client";
import {subscriberFormValidations} from "../Edit/helpers";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
export const AddSubscriber = () => {
    const {t} = useTranslation();
        const navigate = useNavigate();
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(subscriberFormValidations(t)),
    });
    const {trigger, formState: {isValid}, getValues} = methods;
    const [roleName, setRoleName] = useState('');
    const {user} = useContext(AppContext);
    const [createSubscriber] = useMutation(CREATE_SUBSCRIBER_MUTATION,{
        refetchQueries: [{
            query: REQUEST_SUBSCRIBERS_QUERY,
        }],
        awaitRefetchQueries: true,
    });
     useEffect(() => {
        if(user?.userRole?.name === "Administrator") {
            setRoleName("administrator");
        }else if (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") {
            setRoleName("agency");
        }else if(user?.userRole?.name === "Client" || user?.userRole?.name === "Client Administrator") {
            setRoleName("client");
        }
    }, [user]);
const handleSubmit = (e) => {
        e.preventDefault();
        let data = getValues()
        
        createSubscriber({
            variables: {
                parameters: {
                    subscriber: {...data, gracePeriod:'0' },
                },
            },
        })
            .then((res) => {
                let subscriberId = res?.data?.createSubscriber?.data?.id
                Toast({
                    type: 'success',
                    message: t('successMessage.subscriberCreated')
                });
                navigate(`/${roleName}/agencies/${subscriberId}?update_agency`, { replace: true })
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (
        
       <Box
                   sx={{
                       display: 'flex',
                       flexDirection: 'column',
                       alignItems: 'left',
                       textAlign: 'left',
                       px: 2,
                   }}
               >
                   <FormProvider {...methods} >
                <form
                    onBlur={()=>{trigger()}}
                  >
                    <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                               title={t('headers.agency.add.subtitle')}
                               variant="add"
                               backTo={`/${roleName}/agencies`}
                           >
                            <SubscriberForm/>
                           </FormWrapperComponent>
                       </form>
                   </FormProvider>
               </Box>

    );
};