import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect, useRef } from 'react';


// Pre-create a tooltip-enabled span to avoid creating it inside renderCell
const TooltipSpan = React.memo(({ value, children }) => (
    <Tooltip title={String(value)}>
        <span>{children}</span>
    </Tooltip>
));

const CellWithTooltip = ({ value, currentWidth, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const cellRef = useRef(null);

    useEffect(() => {
        const cellElement = cellRef.current;
        if (!cellElement) return;

        const checkOverflow = () => {
            // Check if the content width is greater than the container width
            setShowTooltip(cellElement.offsetWidth < cellElement.scrollWidth);
        };

        // Initialize check
        checkOverflow();
    }, [currentWidth]);

    return (
        <div ref={cellRef} className="ellipsis-cell">
            {showTooltip ? <TooltipSpan value={value}>{children}</TooltipSpan> : children}
        </div>
    );
};

export default CellWithTooltip;
