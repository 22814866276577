import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export const DataGridSubToolbar = (parameters) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                px: 1
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <Typography variant="modalHeader" id={parameters?.id + '-label'} sx={{textAlign: "left", color: "white", fontWeight:'500'}}>
                    {parameters?.title}
                </Typography>
                {parameters?.subtitle && (
                    <Tooltip title={parameters?.subtitle} placement="bottom">
                        <Typography
                            variant="body2"
                            id={parameters?.id + '-label'}
                            sx={{
                                color: "white",
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '90%',
                            }}
                        >
                            {parameters?.subtitle}
                        </Typography>
                    </Tooltip>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end', // This ensures that the last item (search) is always pushed to the right
                    width: '100%',
                    mt: 2,
                }}
            >
                {parameters?.filterCircle && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto' }}>
                        {parameters?.filterCircle()}
                    </Box>
                )}
                <TextField
                    variant="outlined"
                    size="small"
                    sx={parameters?.searchSX}
                    label={parameters?.searchLabel}
                    value={parameters?.searchTerm || ""}
                    onChange={parameters?.handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {parameters?.handleFilter && (
                <Button
                    sx={{ borderRadius: '12px', mt: 2, ...parameters?.filterSX }}  // margin-top for spacing
                    variant="outlined"
                    color="blue"
                    startIcon={<FilterListIcon />}
                    onClick={parameters.handleFilter}
                    id={parameters?.id + '-filters'}
                >
                    {t('button.filtersCAP')}
                </Button>
            )}
        </Box>
    );
};
