import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import FilterListIcon from '@mui/icons-material/FilterList';

export const DataGridFilterToolbar = (parameters) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1,
            px: 2,
            height:'68px',
            backgroundColor: '#1A2D3F'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <FilterListIcon/>
                {parameters?.filterProps?.filterComponentToolbar &&
                    (parameters?.filterProps?.filterComponentToolbar())
                }
            </Box>
            <Box>
                {parameters?.filterProps?.hasFilters &&
                <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    startIcon={<DeleteSweepOutlinedIcon/>}
                    onClick={parameters?.filterProps?.removeAllFilters}>
                    Reset all filters
                </Button>
                }
            </Box>
        </Box>
    );
};