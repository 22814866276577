import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function UpdateTitleComponent() {
    const location = useLocation();

    useEffect(() => {
        const formatString = (str) => {
            return str
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }

        const getLastSegment = () => {
            const segments = location.pathname.split('/');
            return segments.pop() || segments.pop();  // handles trailing slashes
        }

        const getParameter = () => {
            const params = new URLSearchParams(location.search);
            for (let [key, value] of params.entries()) {
                if (!key.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) {
                    return value;
                }
            }
            return null;
        }

        let segment = getLastSegment();

        // Check if segment matches UUID pattern
        if (segment.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) {
            segment = getParameter();
        }

        if (segment) {
            const formattedSegment = formatString(segment);
            document.title = `Technica PRM  - ${formattedSegment}`;
        }
    }, [location]);  // Re-run the effect when the location changes

    return null;  // This component doesn't render anything
}

export default UpdateTitleComponent;
