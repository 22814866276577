import React from 'react';
import Grid from '@mui/material/Grid';
import {useFormContext} from 'react-hook-form';
import {TextEditorController} from '../formController';
import {useTranslation} from 'react-i18next';
import Box from "@mui/material/Box";
import CheckboxController from "../formController/CheckboxController";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";


const InterestedClientForm = () => {
    const {control} = useFormContext();
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
                overflowY: 'auto', // Only keep overflow on the outermost container
                color: "white",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography>{t("enter.interestedEvent")}</Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <CheckboxController control={control} name="recognition.willSpeak"
                                            label={t("enter.speak")}/>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                borderRightWidth: 'medium',
                            }}
                            variant={'middle'}
                        />
                        <CheckboxController control={control} name="planAttend"
                                            label={t("enter.attend")}/>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                borderRightWidth: 'medium',
                            }}
                            variant={'middle'}
                        />
                        <CheckboxController control={control} name="haveBooth"
                                            label={t("enter.booth")}/>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                borderRightWidth: 'medium',
                            }}
                            variant={'middle'}
                        />
                        <CheckboxController control={control} name="other"
                                            label={t("enter.other")}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{t("enter.notes")}</Typography>
                    <TextEditorController
                        control={control}
                        name='notes'
                        label='Notes'
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default InterestedClientForm;