import {gql} from "@apollo/client";


export const CREATE_CLIENT_MUTATION = gql`
    mutation CreateClient($parameters: CreateClientInput) {
        createClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                emailAddress
                phoneNumber
                keywords
                assignedUsers{
                    id
                    firstName
                    lastName
                }
                industries{
                    name
                }
                city
                address
                zipCode
                name
                state
                id
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
            }
        }
    }
`;

export const DELETE_CLIENT_MUTATION = gql`
    mutation DeleteClient($parameters: DeleteClientInput) {
        deleteClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
              id
            }
        }
    }
`;



export const IMPORT_CLIENTS_OF_SUBSCRIBER_MUTATION = gql`
    mutation ImportClientsOfSubscriber($parameters: ImportClientsOfSubscriberInput) {
        importClientsOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                city
                id
                phoneNumber
                name
                zipCode
                keywords
                industries{
                    name
                }
                assignedUsers{
                    id
                    firstName
                    lastName
                }
                address
                state
                emailAddress

            }
        }
    }
`;

export const REQUEST_CLIENT_QUERY = gql`
    query RequestClient($parameters: RequestClientInput) {
        requestClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                keywords
                emailAddress
                phoneNumber
                zipCode
                city
                industries{
                    name
                }
                assignedUsers{
                    id
                    firstName
                    lastName
                    isPrimaryContact
                }
                state
                name
                address
                id
                isDisabled
            }
        }
    }
`;
export const REQUEST_CLIENT_DETAILS_QUERY = gql`
    query RequestClient($parameters: RequestClientInput) {
        requestClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                keywords
                emailAddress
                phoneNumber
                zipCode
                city
                state
                name
                address
                id
            }
        }
    }
`;
export const REQUEST_UNASSIGNED_CLIENTS_OF_EVENT_QUERY = gql`
    query RequestUnassignedClientsOfEvent($parameters: RequestUnassignedClientsOfEventInput) {
        requestUnassignedClientsOfEvent(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                keywords
                emailAddress
                phoneNumber
                state
                zipCode
                industries{
                    name
                }
                assignedUsers{
                    id
                    firstName
                    lastName
                }
                city
                name
                id
                address

            }
        }
    }
`;
export const REQUEST_EVENT_ANALYTICS_OF_CLIENTS_QUERY = gql`
    query RequestEventAnalyticsOfClients($parameters: RequestEventAnalyticsOfClientsInput) {
        requestEventAnalyticsOfClients(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                submissionsTracking
                analytics {
                    speaking
                    attending
                    other
                    sponsor
                    deadlineNet30
                    submissionsTracking
                    events
                }
            }
        }
    }
`;
export const REQUEST_AWARD_ANALYTICS_OF_CLIENTS_QUERY = gql`
    query RequestAwardAnalyticsOfClients($parameters: RequestAwardAnalyticsOfClientsInput) {
        requestAwardAnalyticsOfClients(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                id
                analytics {
                    deadlineNet30
                    submissionsTracking
                    awards
                }
            }
        }
    }
`;
export const REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY = gql`
    query RequestUnassignedClientsOfAward($parameters: RequestUnassignedClientsOfAwardInput) {
        requestUnassignedClientsOfAward(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                keywords
                emailAddress
                phoneNumber
                state
                zipCode
                industries{
                    name
                }
                assignedUsers{
                    id
                    firstName
                    lastName
                }
                city
                name
                id
                address

            }
        }
    }
`;


export const REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY = gql`
    query RequestClientsOfSubscriber($parameters: RequestClientsOfSubscriberInput) {
        requestClientsOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDisabled
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                assignedUsers{
                    id
                }
                name
                id
            }
        }
    }
`;
export const REQUEST_CLIENTS_OF_BROWSE_QUERY = gql`
    query requestClientsBrowseOfSubscriber($parameters: RequestClientsOfSubscriberInput) {
        requestClientsBrowseOfSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDisabled
                openEventSubmissions
                openAwardSubmissions
                agencyContact{
                    id
                    fullName
                }
                clientContact{
                    id
                    name
                }
                name
                id
            }
        }
    }
`;
export const REQUEST_CLIENTS_OF_USER_QUERY = gql`
    query RequestClientsOfUser($parameters: RequestClientsOfUserInput) {
        requestClientsOfUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                name
                id
            }
        }
    }
`;
export const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient($parameters: UpdateClientInput) {
        updateClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                avatar
                submissionsInProcess
                submissionsTracking
                submissionsDueThisWeek
                tasksDue
                emailAddress
                phoneNumber
                address
                city
                keywords
                assignedUsers{
                    id
                    firstName
                    lastName
                }
                industries{
                    name
                }
                name
                zipCode
                id
                state

            }
        }
    }
`;

export const REQUEST_UNASSIGNED_CLIENT_QUERY = gql`
    query requestUnassignedClientsOfTeamMember($parameters: RequestUnassignedClientsOfTeamMemberInput) {
        requestUnassignedClientsOfTeamMember(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
            }
        }
    }
`;

export const REQUEST_ASSIGNED_CLIENT_QUERY = gql`
    query requestAssignedClientsOfTeamMember($parameters: RequestUnassignedClientsOfTeamMemberInput) {
        requestAssignedClientsOfTeamMember(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
            }
        }
    }
`;
export const UNASSIGN_CLIENT_MUTATION = gql`
    mutation unassignClientFromTeamMember($parameters: UnassignClientFromTeamMemberInput) {
        unassignClientFromTeamMember(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;
export const ASSIGN_CLIENT_MUTATION = gql`
    mutation assignClientToTeamMember($parameters: AssignClientToTeamMemberInput) {
        assignClientToTeamMember(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;
export const DISABLE_CLIENT_MUTATION = gql`
    mutation disableClient($parameters: UpdateClientInput) {
        disableClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDisabled
                name
                id
            }
        }
    }
`;


