import React, {useState, useEffect} from 'react';
import GlobalDataGridPro from "../manage";
import SubmissionColumns from './helpers/columns';
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {submissionFiltersOptions} from "./Filter";

import searchByString from "../../helpers/searchByString";
import {filterBySelection, filterDataByDateRange} from "../../helpers/filterBySelection";
import QuickFilterSubmission from "../filters/quickFilterSubmission";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";

const tableID = '0560b2b2-8fd8-11ee-b9d1-0242ac120002-submission-events';
export const ManageEventSubmissionsOfSubscriberGrid = ({list, inForm = false, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const {permanentFilter} = useTableFilter(tableID);
    const {submissionDate = [null, null], deadline = [null, null], industries = []} = permanentFilter;

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    };
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            deadline: {
                type: 'dateRange',
                value: deadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
        }
        results = filterBySelection(results, filteredParams)
        results = filterDataByDateRange(results, submissionDate)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])

    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...SubmissionColumns(pathname),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params?.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-Submission"
                        icon={<EditIcon/>}
                        onClick={() => {
                            navigate(`${pathname}/${params?.id}?update_submission`)
                        }}
                        showInMenu={true}/>,


                ]
            }
        ], []);
    const filterEventSubmissions = () => {
        return (
            <QuickFilterSubmission searchResults={searchResults} setSearchResults={setSearchResults} list={list}/>
        )
    }

    const dataGridProps = {
        id: tableID,
        title: inForm ? "" : "Submissions",
        searchLabel: "Search Submission",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        handleQuickFilter: {
            component: filterEventSubmissions,
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,


        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: submissionFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters'
        },
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <GlobalDataGridPro {...dataGridProps}/>
    );
};