export function updateSubmissionParams(submissionId, url, navigate) {
    const newUrl = url.includes('submissionId')
        ? url.replace(/submissionId=([^&]+)/, `submissionId=${submissionId}`)
        : `${url}${url.includes('?') ? '&' : '?'}submissionId=${submissionId}`;
    navigate(newUrl);
}

export const statuses = [
    {name: 'New', id: 'New'},
    {name: 'Ready for Draft', id: 'Ready for Draft'},
    {name: 'Internal Approval Required', id: 'Internal Approval Required'},
    {name: 'Client Approval Required', id: 'Client Approval Required'},
    {name: 'Approved', id: 'Approved'},
    {name: 'Submitted', id: 'Submitted'},
    {name: 'Results Reported', id: 'Results Reported'}
];