import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {EventForm} from "../../forms";
import {REQUEST_EVENT_QUERY, REQUEST_EVENTS_QUERY, UPDATE_EVENT_MUTATION} from "../../../graphql/event";
import {useMutation, useQuery} from "@apollo/client";
import {eventFormValidations} from "./helpers";
import Toast from "../../alerts";
import {useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {createEditorState, textEditorFormat} from "../../../helpers/formatters";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {REQUEST_INDUSTRIES_QUERY} from "../../../graphql/industry";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {getAgencyNote, updateAgencyNotes} from "../../../helpers/findAgencyNote";

export const EditEvent = () => {
    const {t} = useTranslation();
    const [eventData, setEventData] = useState({})
    const [queryData, setQueryData] = useState({})
    const sessionTypes = [{name: "Speaker"},
        {name: "Forum"},
        {name: "Panel"},
        {name: "Abstract"}]
    const {paramId} = useParams();
    const {data, refetch} = useQuery(REQUEST_EVENT_QUERY, {
        variables: {
            parameters: {
                event: {
                    id: paramId,
                },
            },
        }
    });
    const {user} = useContext(AppContext);
    const isAdmin = user?.userRole?.name === "Administrator";
    useEffect(() => {
        if (data?.requestEvent?.data) {
            let obj = {...data?.requestEvent?.data}
            const newObj = {
                ...obj,
                sessionTopics: createEditorState(obj.sessionTopics),
                speakerEventGuidelines: createEditorState(obj.speakerEventGuidelines),
                expertTips: createEditorState(obj.expertTips),
                description: createEditorState(obj.description),
            }
            if(!isAdmin){
                newObj.agencyNotes = getAgencyNote(user?.subscriber?.id, newObj.agencyNotes)
            }
            setEventData(newObj);
            setQueryData(obj)
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(eventFormValidations(t)),
        values: eventData
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            },{
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            }, {query: REQUEST_INDUSTRIES_QUERY}],
            awaitRefetchQueries: true
        });
    const cancelChanges = () => {
        reset(eventData)
    }
    const handleSubmit = (e,stepper) => {
        let formValues = getValues()
        formValues.expertTips = textEditorFormat(formValues?.expertTips)
        formValues.speakerEventGuidelines = textEditorFormat(formValues?.speakerEventGuidelines)
        formValues.description = textEditorFormat(formValues?.description)
        formValues.sessionTopics = textEditorFormat(formValues?.sessionTopics)
        if(!isAdmin){
            formValues.agencyNotes = updateAgencyNotes(user?.subscriber?.id, queryData?.agencyNotes, formValues?.agencyNotes )
            console.log(formValues)
        }
        if (stepper) {
            formValues.status = "Approved"
        }
        updateEvent({
            variables: {
                parameters: {
                    event: formValues,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.eventUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >
                        <FormWrapperComponent
                            title={t('headers.manageEvents.edit.subTitle')}
                            submitFn={isValid ? handleSubmit : null}
                            cancelFn={cancelChanges}
                            stepper={eventData?.status === "Pending" && isAdmin}
                            status={eventData?.status}
                            stepperSubtitle={t('enter.event.stepper.editSubtitle')}
                            stepperSubmit={handleSubmit}
                        >
                            <EventForm sessionTypes={sessionTypes} isViewOnly={!isAdmin} isUpdate/>
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};