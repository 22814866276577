import popupConfirmation from "../../popUpAlerts/confirmation";
import Alert from "../../alerts";
import {useTranslation} from "react-i18next";

const DeleteEvent = (deletedEvent) => {
    const {t} = useTranslation();
    
         const handleDelete = (id) => {
        let popUpParameters = {
            subParameters: {
                title: t('successMessage.eventDeleted.subTitle'),
                text: t('successMessage.eventDeleted.text'),
                icon: 'success'
            }
        }
        popupConfirmation(popUpParameters, onDelete, id);
    };

    const onDelete = (id) => {
        return deletedEvent({
            variables: {
                parameters: {
                    event: {id: id},
                },
            },
        })
            .then(() => {
                Alert({type: 'success', message: t('successMessage.eventDeleted.title')});
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Alert({type: 'error', message: errorMessage[0]});
            });
    };
     return handleDelete;
}
export default DeleteEvent