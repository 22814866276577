import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Element} from 'react-scroll';
import {IconButton, InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const FormContainer = (
    {
        title,
        to,
        children,
        hasSearch = false,
        searchParams,
        handleResetPassword,
        custonRightButton,
        ...rest
    }
) => {
    return (
        <Element name={to} className={to}>
            <Paper
                elevation={1}
                sx={{
                    mb: 2,
                    pb:2,
                    borderRadius: '12px',
                    width: '100%',
                    ...rest?.paperSX
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        ...rest?.boxSX
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="formHeader" sx={{...rest?.titleSX}}>
                            {title}
                        </Typography>
                        {hasSearch &&
                            <TextField
                                variant="outlined"
                                size="small"
                                sx={searchParams?.searchSX}
                                label={searchParams?.searchLabel}
                                onChange={searchParams?.handleSearch}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        {handleResetPassword &&
                            <Button
                                variant="contained"
                                sx={{color: '#fff',borderRadius: '12px'}}
                                onClick={handleResetPassword}
                                type='button'
                            >
                                Reset Password
                            </Button>
                        }{
                            custonRightButton && 
                            custonRightButton()
                        }
                    </Box>
                    <Box sx={{mt: 3, ...rest?.formContainerSX}}>
                        {children}
                    </Box>
                </Box>
            </Paper>
        </Element>
    )
}
export default FormContainer