import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
    Breadcrumbs,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Skeleton
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {REQUEST_SUBMISSION_STATUS_QUERY} from "../../graphql/submissionStatus";
import {useQuery} from "@apollo/client";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {PathArray} from "../breadcrumbs/helpers";
import {getSubmissionStatusChip} from "../manage/utilis/columnHelpers";


export const ActionModal = ({
                                isOpen,
                                handleClose,
                                statusName = '',
                                handleSubmit,
                                submissionData,
                                approved,
                                rejected,
                                unArchived = false
                            }) => {
        const [statuses, setStatuses] = useState([]);
        const [selectedStatus, setSelectedStatus] = useState({});
        const [reasonSelected, setReasonSelected] = useState({name: ''});
        const [approvalStates, setApprovalStates] = useState({
            "approval": false,
            "rejected": false,
            "requestClientApproval": false,
            "requestSubscriberApproval": false,
            "waitForApproval": false,
            "waitForClientApproval": false,
            "waitForEventApproval": false,
            "clientApproval": false,
            "clientRejected": false,
            "eventOrganizerApproval": false,
            "eventOrganizerRejected": false,
            "submissionDone": false,
            "archived": false
        });

        const {data: statusData, loading: statusLoading} = useQuery(
            REQUEST_SUBMISSION_STATUS_QUERY
        );

        useEffect(() => {
            if (!statusLoading && statusData?.requestSubmissionStatus?.data) {
                setStatuses(statusData?.requestSubmissionStatus?.data);
            }
        }, [statusData, statusLoading]);
        const updateApproval = (statusName, approved, rejected) => {
            switch (statusName) {
                case 'New':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": false,
                        "waitForApproval": false,
                        "waitForClientApproval": true,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": false
                    })

                    break;
                case 'Ready for Draft':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": true,
                        "waitForApproval": false,
                        "waitForClientApproval": false,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": false
                    })
                    break;
                case 'Internal Approval Required':
                    if (approved) {
                        setApprovalStates({
                            "approval": true,
                            "rejected": false,
                            "requestClientApproval": !!submissionData?.clientApprovalRequired,
                            "requestSubscriberApproval": false,
                            "waitForApproval": true,
                            "waitForClientApproval": false,
                            "waitForEventApproval": false,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    } else if (rejected) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": true,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": true,
                            "waitForClientApproval": false,
                            "waitForEventApproval": false,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    }
                    break;
                case 'Client Approval Required':
                    if (approved) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": false,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": false,
                            "waitForClientApproval": true,
                            "waitForEventApproval": false,
                            "clientApproval": true,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    } else if (rejected) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": false,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": false,
                            "waitForClientApproval": true,
                            "waitForEventApproval": false,
                            "clientApproval": false,
                            "clientRejected": true,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    }
                    break;
                case 'Approved':
                    if (approved) {
                        setApprovalStates({
                            "approval": true,
                            "rejected": false,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": true,
                            "waitForClientApproval": false,
                            "waitForEventApproval": false,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    } else if (rejected) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": true,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": true,
                            "waitForClientApproval": false,
                            "waitForEventApproval": false,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    }
                    break;
                case'Submitted':
                    if (approved) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": false,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": false,
                            "waitForClientApproval": false,
                            "waitForEventApproval": true,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": true,
                            "eventOrganizerRejected": false,
                            "submissionDone": false,
                            "archived": false
                        })
                    } else if (rejected) {
                        setApprovalStates({
                            "approval": false,
                            "rejected": false,
                            "requestClientApproval": false,
                            "requestSubscriberApproval": false,
                            "waitForApproval": false,
                            "waitForClientApproval": false,
                            "waitForEventApproval": true,
                            "clientApproval": false,
                            "clientRejected": false,
                            "eventOrganizerApproval": false,
                            "eventOrganizerRejected": true,
                            "submissionDone": false,
                            "archived": false
                        })
                    }
                    break;
                case'Accepted':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": true,
                        "waitForApproval": false,
                        "waitForClientApproval": false,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": false
                    })
                    break;
                case'Results Reported':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": false,
                        "waitForApproval": false,
                        "waitForClientApproval": false,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": false
                    })
                    break;
                case 'Archived':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": false,
                        "waitForApproval": false,
                        "waitForClientApproval": false,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": true
                    })
                    break;
                case 'UnArchived':
                    setApprovalStates({
                        "approval": false,
                        "rejected": false,
                        "requestClientApproval": false,
                        "requestSubscriberApproval": false,
                        "waitForApproval": false,
                        "waitForClientApproval": false,
                        "waitForEventApproval": false,
                        "clientApproval": false,
                        "clientRejected": false,
                        "eventOrganizerApproval": false,
                        "eventOrganizerRejected": false,
                        "submissionDone": false,
                        "archived": false
                    })
                    break;
                default:
                    break;
            }
        }
        useEffect(() => {
            if (statusName) {
                updateApproval(statusName, approved, rejected, unArchived);
            }
        }, [statusName, isOpen]);
        const handleConfirm = () => {
            handleSubmit({updateStatus: true, newReason: reasonSelected, newApproval: approvalStates});
            handleClose();
        }

        useEffect(() => {
            if (statuses.length > 0) {
                const status = statuses.find((status) => status.name === statusName || (statusName === 'UnArchived' && status.name === 'Archived'));
                setSelectedStatus(status);
                // Set the initial reasonSelected based on the first reason in the status (you can change this logic based on your requirement)
                if (status?.reasons?.length > 0) {
                    setReasonSelected({name: status?.reasons[0]?.name});
                }
            }
        }, [statuses, statusName]);

        const handleReasonChange = (event) => {
            // Find the selected reason in the selectedStatus and update reasonSelected
            const selectedReasonName = event.target.value;
            const selectedReason = selectedStatus?.reasons.find((reason) => reason.name === selectedReasonName);
            setReasonSelected({name: selectedReason.name});
        };
        const optionType = approved || unArchived ? 'Approve Reason' : 'Decline Reason'
        const options = statusName !== "Archived" ?
            selectedStatus?.reasons?.filter(s => {
                return s?.typeName === optionType
            }) : selectedStatus?.reasons

        return (
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // Add styles for positioning the modal
                PaperProps={{
                    style: {
                        position: 'absolute',
                        top: '140px',
                        right: '100px',
                        width: '1000px',
                        height: '350px',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {`${statusName} Action Items`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please select an option
                    </DialogContentText>
                    <RadioGroup value={reasonSelected?.name} onChange={handleReasonChange}>
                        {options?.map((reason) => (
                            <FormControlLabel key={reason.name} value={reason.name} control={<Radio/>} label={reason.name}/>
                        ))}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
;
export const SubmissionHeader = ({data, openActionModal, user, disabled}) => {
    const paths = PathArray()
    const [isLoading, setIsLoading] = useState(true);
    const [showThirdButton, setShowThirdButton] = useState(false);
    const [showSecondButton, setShowSecondButton] = useState(true);
    const [secondButtonName, setSecondButtonName] = useState('');
    const [thirdButtonName, setThirdButtonName] = useState('');
    useEffect(() => {
        setIsLoading(true);
        if (Object.keys(data).length > 0) {
            setIsLoading(false);
        }
    }, [data]);
    useEffect(() => {
        switch (data?.status?.name) {
            case 'New':
                setSecondButtonName('Ready for Draft');
                setShowThirdButton(false);
                break;
            case 'Ready for Draft':
                setSecondButtonName('Request Internal Approval');
                setShowThirdButton(false);
                break;
            case 'Internal Approval Required':
                setSecondButtonName('Approve');
                setShowThirdButton(true);
                setThirdButtonName('Decline');
                break;
            case 'Client Approval Required':
                setSecondButtonName('Approve');
                setShowThirdButton(true);
                setThirdButtonName('Decline');
                break;
            case 'Approved':
                setSecondButtonName('Approve');
                setShowThirdButton(true);
                setThirdButtonName('Decline');
                break;
            case 'Submitted':
                setSecondButtonName('Approved By Organizer');
                setShowThirdButton(true);
                setThirdButtonName('Decline By Organizer');
                break;
            case 'Accepted':
                setShowSecondButton(false);
                setShowThirdButton(false);
                break;
            case 'Results Reported':
                setShowSecondButton(false);
                setShowThirdButton(false);
                break;
            case 'Archived':
                setSecondButtonName('UnArchived');
                setShowThirdButton(false);
                break;
            default:
                break;
        }

    }, [data]);

    return (
        <Paper
            elevation={2}
            sx={{
                mb: 2,
                borderRadius: '12px',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    px: 3,
                    py: 1.5,
                    display: 'flex', // Use flex display to align items horizontally
                    justifyContent: 'space-between', // Spacing between elements
                    alignItems: 'center', // Center items vertically
                }}
            >
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: "24px"}}>
                        {paths.map((path, index) => {
                            const isLast = index === paths.length - 1;

                            if (isLast) {
                                return (
                                    <Typography
                                        key={path?.url}
                                        sx={{
                                            cursor: 'default',
                                            fontWeight: '500',
                                            fontSize: "24px",
                                            color: "rgb(238,129,77)"
                                        }}
                                    >
                                        {path?.value}
                                    </Typography>
                                );
                            }
                            return (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    sx={{
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        fontSize: "24px",
                                    }}
                                    key={path?.url}
                                    href={path?.url}
                                >
                                    {path?.value}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                    <Box>
                        {isLoading ?
                            <>
                                <Skeleton variant="text" width={410} sx={{fontSize: '1.25rem'}}/>
                                <Skeleton variant="text" width={410} sx={{fontSize: '1.25rem'}}/>
                                <Skeleton variant="text" width={410} sx={{fontSize: '1.25rem'}}/>
                            </>
                            :
                            <>
                                <Typography variant="h6">
                                    {data?.title}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Status: {getSubmissionStatusChip(data?.status?.name)}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Action Item: {data?.status?.reasons?.length && data?.status?.reasons[0]?.name}
                                </Typography>
                            </>
                        }
                    </Box>
                </Box>

                {/* Buttons */}
                {((user?.userRole?.name === "Client" || user?.userRole?.name === "Client Administrator") && (data?.status?.name === "Ready for Client Approval" || data?.status?.name === "Client Approval Required")) || (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") ?
                    <Box sx={{display: 'flex', gap: '10px', mt: '100px'}}>
                        {user?.userRole?.name.includes("Subscriber") && data?.status?.name !== "Archived" &&
                            <Button disabled={disabled} variant="outlined" color="primary" onClick={() => {
                                openActionModal({status: "Archived"});
                            }}>
                                Archive
                            </Button>
                        }
                        {data?.status?.name === "Archived" ?
                            <Button disabled={disabled} variant="outlined" color="error" onClick={() => { /* Handle Decline */
                                openActionModal({
                                    status: "UnArchived",
                                    approved: false,
                                    rejected: false,
                                    unArchived: true
                                });
                            }}>
                                {"Unarchive"}
                            </Button> :
                            <>
                                {showSecondButton &&
                                    <Button disabled={disabled} variant="outlined" color="primary" onClick={() => { /* Handle Approve */
                                        openActionModal({status: data?.status?.name, approved: true, rejected: false});
                                    }}>
                                        {secondButtonName}
                                    </Button>
                                }
                                {showThirdButton &&
                                    <Button disabled={disabled} variant="outlined" color="error" onClick={() => { /* Handle Decline */
                                        openActionModal({status: data?.status?.name, approved: false, rejected: true});
                                    }}>
                                        {thirdButtonName}
                                    </Button>
                                }
                            </>
                        }
                    </Box> : null
                }
            </Box>
        </Paper>
    );
};
