import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {calculateLocation, getSubmissionStatusChip, renderDeadlineCell} from "../../manage/utilis/columnHelpers";


const SubmissionColumns = (userRoleName) => {
   return [
        {
            field: 'status',
            headerName: t('columns.manageSubmissions.status'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => getSubmissionStatusChip(params.row.status.name)
        },
        {
            field: 'title',
            headerName: t('columns.viewAwardSubmissionsOfClient.title'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link
                    href={`/${userRoleName}/awards/submissions/${params?.id}?update_submission`}>{params.value}</Link>),
        },
        {
            field: 'location',
            headerName: t('columns.viewAwardSubmissionsOfClient.location'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => calculateLocation(params.row.interestedClient.recognition.award)
        },
        {
            field: 'industries',
            headerName: t('columns.viewAwardSubmissionsOfClient.industries'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => params.row.interestedClient.recognition.award.industries?.map(industry => industry.name).join(', ')
        },
        {
            field: 'applicationDeadline',
            headerName: t('columns.viewAwardSubmissionsOfClient.deadline'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => renderDeadlineCell(params.row.interestedClient?.recognition?.award?.applicationDeadline)
        }
    ]
}
export default SubmissionColumns;