export default function getSpreadsheetId(input) {
  // Trim the input to remove any leading/trailing whitespace
  input = input.trim();

  // Check if input is null or undefined
  if (!input) {
    return null;
  }

  // Extract ID and the path following it from URL
  const urlRegex = /\/d\/([a-zA-Z0-9-_]+)(\/[^ ]*)?/;
  const matches = input.match(urlRegex);
  if (matches && matches[1]) {
    // Return the ID and any additional path or query parameters
    return matches[1] + (matches[2] ? matches[2] : "");
  } else {
    // Input is not a valid Google Spreadsheet URL
    return input;
  }
}
