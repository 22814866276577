import {gql} from "@apollo/client";


export const CREATE_INDUSTRY_MUTATION = gql`
    mutation CreateIndustry($parameters: CreateIndustryInput) {
        createIndustry(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                deleted

                updatedAt

                id

                name

                createdAt

            }
        }
    }
`;

export const DELETE_INDUSTRY_MUTATION = gql`
    mutation DeleteIndustry($parameters: DeleteIndustryInput) {
        deleteIndustry(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const IMPORT_INDUSTRIES_MUTATION = gql`
    mutation ImportIndustries($parameters: ImportIndustriesInput) {
        importIndustries(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                id

            }
        }
    }
`;

export const REQUEST_INDUSTRIES_QUERY = gql`
    query RequestIndustries {
        requestIndustries {
            errors {
                message
            }
            statusCode
            data {
                name
                awardCount
                eventCount
                id

            }
        }
    }
`;
export const REQUEST_INDUSTRIES_FORM_QUERY = gql`
    query RequestIndustries {
        requestIndustries {
            errors {
                message
            }
            statusCode
            data {
                name
                id

            }
        }
    }
`;

export const REQUEST_INDUSTRY_QUERY = gql`
    query RequestIndustry($parameters: RequestIndustryInput) {
        requestIndustry(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name

            }
        }
    }
`;

export const UPDATE_INDUSTRY_MUTATION = gql`
    mutation UpdateIndustry($parameters: UpdateIndustryInput) {
        updateIndustry(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                name
                id

            }
        }
    }
`;
