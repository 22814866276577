import React, { useContext, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { ClientForm } from "../../forms";
import { REQUEST_CLIENT_QUERY, REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, UPDATE_CLIENT_MUTATION } from "../../../graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { clientFormValidations } from "./helpers";
import Toast from "../../alerts";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { createEditorState, textEditorFormat } from "../../../helpers/formatters";
import { AppContext } from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import { useManagedForm } from "../../../helpers/hooks/useManageForm";

export const EditClient = () => {
    const { t } = useTranslation();
    const [clientData, setClientData] = useState({})
    const { clientId } = useParams();
    const { data, refetch } = useQuery(REQUEST_CLIENT_QUERY, {
        variables: {
            parameters: {
                client: {
                    id: clientId,
                },
            },
        }
    });
    const { user } = useContext(AppContext);

    useEffect(() => {
        if (data?.requestClient?.data) {
            let obj = { ...data?.requestClient?.data }
            const newObj = {
                ...obj,
                keywords: createEditorState(obj.keywords),

            }
            setClientData(newObj);
        }
    }, [data]);
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(clientFormValidations(t)),
        values: clientData
    });
    const { trigger, reset, formState: { isValid }, getValues } = methods;

    const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION,
        {
            refetchQueries: [
                {
                    query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                    variables: {
                        parameters: {
                            subscriber: {
                                id: user?.subscriber?.id
                            },
                            showAllItems: true,
                            showOnlyDisabledItems: false,
                        }
                    }
                },
                {
                    query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                    variables: {
                        parameters: {
                            subscriber: {
                                id: user?.subscriber?.id
                            },
                            showAllItems: false,
                            showOnlyDisabledItems: false,
                        }
                    }
                }
            ],
            awaitRefetchQueries: true
        });
    const cancelChanges = () => {
        reset(clientData)
    }
    const handleSubmit = () => {
        let data = getValues()
        data.keywords = textEditorFormat(data?.keywords)
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(clientData);
        if (!isDataChanged) return;

        updateClient({
            variables: {
                parameters: {
                    client: data,
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.clientUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => { trigger() }}
                    >
                        <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                            cancelFn={cancelChanges}
                        >
                            <ClientForm isUpdate={true} handleSubmit={handleSubmit} />
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};