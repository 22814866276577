import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {IconButton, Link, Typography} from "@mui/material";
import {Check} from "@mui/icons-material";
import get from "lodash/get";
import * as React from "react";
import {t} from "i18next";

function calculateDeadLineDays(date) {
    let today = new Date();
    let expire = new Date(date)
    let one_day = 1000 * 60 * 60 * 24
    return Math.ceil((expire.getTime() - today.getTime()) / (one_day));
}

const renderDeadlineCell = (value) => {
    const deadline = value ? value : '';
    const deadlineDays = calculateDeadLineDays(deadline); // Replace this with your actual logic to calculate deadline days

    const getDeadlineColor = () => {
        if (deadlineDays < 3) {
            return '#ff0000';
        } else if (deadlineDays <= 10) {
            return '#ff9800';
        } else if (deadlineDays >= 21) {
            return '#3366ff';
        }
        return '';
    };

    const deadlineColor = getDeadlineColor();

    if (isNaN(deadlineDays)) {
        return <></>; // Return empty JSX element if deadline is NaN
    }

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <FiberManualRecordIcon style={{color: deadlineColor, marginRight: 2}} fontSize="12px"/>
            <Typography style={{textAlign: 'center', lineHeight: '1rem'}}>{deadline}</Typography>
        </div>
    );
};


const CheckIcon = ({value}) => {
    return (
        <IconButton>
            {value ? <Check color="black"/> : null}
        </IconButton>
    );
};
const EmailCell = ({value}) => {
    return (
        <Link href={`mailto:${value}`} target="_blank" rel="noopener noreferrer">
            {value}
        </Link>
    );
};
const formatPhoneNumber = (phoneNumber) => {
    // Regex pattern to match phone number format (123) 234-3232
    const pattern = /^\(\d{3}\) \d{3}-\d{4}$/;

    // Check if the phone number matches the pattern
    if (pattern.test(phoneNumber)) {
        return phoneNumber; // Return as is if it matches
    } else if (phoneNumber) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    } else {
        return ''
    }
};

function getChipStatusCell(showActive = false) {
    return (cellValues) => {
        const row = get(cellValues, 'row');
        let chipColor = 'default';
        let chipLabel = 'Disabled';
        if(row.isDisabled){
            chipColor = '#FF6441';
            chipLabel = 'Disabled'; 
        }
        else if (!row.isDisabled && showActive) {
            chipColor = '#64B769';
            chipLabel = 'Active';
        }
        else if (row.status === 'Approved') {
            chipColor = '#64B769';
            chipLabel = 'Published';
        } else if (row.status === 'Pending') {
            chipColor = '#ED6C02';
            chipLabel = 'Pending';
        }

        return (
            <Typography variant="subtitle2" sx={{color: chipColor}}>
                {chipLabel}
            </Typography>
        );
    };
}
const STATUS_LABELS = {
    'New': 'submission.states.new',
    'Ready for Draft': 'submission.states.readyForDraft',
    'Internal Approval Required': 'submission.states.internalApprovalRequired',
    'Client Approval Required': 'submission.states.clientApprovalRequired',
    'Approved': 'submission.states.approved',
    'Submitted': 'submission.states.submitted',
    'Accepted': 'submission.states.accepted',
    'Archived': 'submission.states.archived'
};

const getSubmissionStatusChip = (status) => {
    const chipLabelKey = STATUS_LABELS[status] || status;
    return t(chipLabelKey);
};


function calculateLocation(row) {
    const venue = get(row, 'venue', '');
    const country = get(row, 'country', '');
    const city = get(row, 'city', '');
    const state = get(row, 'state', '');
    const zipCode = get(row, 'zipCode', '');
    let location = "";

    // Check if the venue is provided
    if (venue) {
        location += venue + ", ";
    }
    if (country) {
        location += country + ", ";
    }
    // Check if the address components are provided
    if (city) {
        location += city + ", ";
    }
    if (state) {
        location += state + ", ";
    }
    if (zipCode) {
        location += zipCode + ", ";
    }
    // Remove trailing comma and space
    location = location.trim().replace(/,$/, "");

    return location;
}

// Custom comparator for date range sorting
const dateRangeComparator = (start, end) => {
    const getStartDate = (value) => {
        const [start] = value.split(' - ');
        return new Date(start);
    };
    const date1 = getStartDate(start);
    const date2 = getStartDate(end);
    if (date1 < date2) {
        return -1;
    }
    if (date1 > date2) {
        return 1;
    }
    return 0;
};
export {
    renderDeadlineCell,
    getChipStatusCell,
    getSubmissionStatusChip,
    EmailCell,
    formatPhoneNumber,
    calculateLocation,
    CheckIcon,
    dateRangeComparator
}