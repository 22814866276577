import React, {useContext, useEffect} from "react";
import Grid from "@mui/material/Grid";
import {InputController, CheckboxController, DocumentInputController, TextEditorController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import {AppContext} from "../../App";
import ManageTasksComponent from "../manageTasks";
import {useParams} from "react-router-dom";
import EventDetailFormContainer from "./detailViewOfEvent";
import ClientDetailComponent from "./detailViewOfClients";
import {Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
// Lazy load components
const DynamicFormContainer = React.lazy(() => import('../dynamicSections/formContainer'));
const FormSection = React.lazy(() => import('../dynamicSections/formSection'));

const SubmissionForm = (
    {
        event = false,
        submissionData,
        handleSubmit,
        readOnly,
        fields=[],
        remove,
        update,
        refetch
    }) => {
    const {t} = useTranslation();
    const {setIsEditing} = useContext(AppContext);
    const {control, formState: {errors}} = useFormContext();
    useEffect(() => {
        setIsEditing(readOnly);
    }, [readOnly]);
    const {submissionId} = useParams();
    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={readOnly}
                message={`Submission profile is being modified by ${submissionData?.updatingBy?.fullName}. Please wait to make your edits.`}
            />
            <FormContainer title={t('section.tasks')} to={'tasks'} formContainerSX={{mt: 1}} boxSX={{p: 2}}>
                <ManageTasksComponent
                    inForm={true}
                    submissionId={submissionId}
                    type={event ? 'event' : 'award'}
                    readOnly={readOnly}
                    refetchSubmission={refetch}
                />
            </FormContainer>

            <FormContainer title={t('section.submissionDetails')} to={'details'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.title}
                            name='title'
                            required
                            fullWidth
                            label={t("enter.title")}
                            autoComplete="off"
                            disabled={readOnly}
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <CheckboxController
                            control={control}
                            disabled={readOnly}
                            name='clientApprovalRequired'
                            label={t("enter.clientApprovalRequired")}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px', mb: 1.5}}>
                        <DocumentInputController
                            control={control}
                            name='document'
                            disabled={readOnly}
                            label={event ? t("enter.submissionFormEvent.label") : t("enter.submissionFormAward.label")}
                            modalSubtitle={t("enter.submissionFormAward.modal.subtitle")}
                            modalTitle={t("enter.submissionFormAward.modal.title")}
                            subtitle={t("enter.submissionFormAward.subtitle")}/>
                    </Grid>

                </Grid>
            </FormContainer>
            <FormContainer
                title={t(event ? 'section.eventDetails' : 'section.awardDetails')}
                to={event ? 'eventDetails' : 'awardDetails'}
                boxSX={{minHeight: '160px', paddingBottom: '0px'}}
            >
                <EventDetailFormContainer
                    id={event ? submissionData?.interestedClient?.recognition?.event?.id : submissionData?.interestedClient?.recognition?.award?.id}
                    event={event}
                />
            </FormContainer>
            <FormContainer
                title={t('section.clientDetails')}
                to={'clientDetails'}
                boxSX={{minHeight: '160px'}}
            >
                <ClientDetailComponent
                    id={submissionData?.interestedClient?.client?.id}
                />
                <Grid xs={12} mt={1} px={'4px'}>
                    <Typography
                        key={'notes_typo'}
                        variant="subtitle1"
                    >
                        <strong>Notes:</strong>
                    </Typography>
                    <TextEditorController
                        control={control}
                        name='notes'
                        label={'Notes'}
                        disabled={readOnly}
                    />
                </Grid>
            </FormContainer>
            <React.Suspense fallback={<div>Loading...</div>}>
                {fields?.map((field, index) => (
                    <DynamicFormContainer
                        key={field?.id} // Added a key for better performance
                        title={"field?.name"}
                        to={field?.id}
                        field={field}
                        index={index}
                        remove={remove}
                        update={update}
                        disabled={readOnly}
                        handleSubmit={handleSubmit}
                    >
                        <FormSection control={control} field={field} index={index} disabled={readOnly}/>
                    </DynamicFormContainer>
                ))}
            </React.Suspense>
        </>
    )
        ;
};
export default SubmissionForm;