export const getDefaultPathByRole = (role) => {
    switch (role) {
        case "Administrator":
            return "/administrator/events"
        case "Subscriber":
        case "Subscriber Administrator":
            return "/agency/events/browse"
        default:
            return "/login"
    }
}