import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageContactsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {REQUEST_CONTACTS_OF_CLIENT_QUERY} from "../../graphql/contact";
import {useParams} from "react-router-dom";

export default function ManageContactsComponent({inForm = true}) {
    const [contactItems, setContactItems] = useState([]);
    const {t} = useTranslation();
    const {clientId} = useParams();
    const {data, loading} = useQuery(REQUEST_CONTACTS_OF_CLIENT_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    }
                }
            }
        }
    );

    useEffect(() => {
        if (data?.requestContactsOfClient?.data) {
            setContactItems(data?.requestContactsOfClient?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.manageUsers.subTitle")}/>}
            <ManageContactsGrid
                list={contactItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
