import * as yup from "yup";

export const loginFormValidations = (t) => {
    return yup.object().shape({
        emailAddress: yup.string().required(t('errorMessage.emailAddressRequired')),
        password: yup.string().required(t('errorMessage.passwordRequired'))


    });
}

export const onRememberMe = (fields) => {
    if (fields["rememberMe"]) {
        localStorage.emailAddress = fields["emailAddress"];
        localStorage.rememberMe = fields["rememberMe"];
    } else {
        localStorage.removeItem('emailAddress', '');
        localStorage.removeItem('rememberMe', '');
    }
}
