import React, { useContext, useEffect, useState, useMemo } from 'react';
import Box from "@mui/material/Box";
import { ViewTasksGrid } from "./component";
import { PageHeader } from "../pageHeader";
import { REQUEST_TASKS_OF_CLIENT_QUERY, REQUEST_TASKS_OF_SUBSCRIBER_QUERY } from "../../graphql/task";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../App";
import { t } from "i18next";

export default function ViewTasksComponent({ inForm = false }) {
    const [taskItems, setTaskItems] = useState([]);

    const { user } = useContext(AppContext);
    const subscriberId = user?.subscriber?.id;
    const clientId = user?.client?.id;
    const isClientRole = useMemo(() => user?.userRole?.name === 'Client' || user?.userRole?.name === 'Client Administrator', [user]);

    const { data: taskSubscriberData } = useQuery(REQUEST_TASKS_OF_SUBSCRIBER_QUERY, {
        skip: isClientRole,  // Skip this query if the user is a client
        variables: { parameters: { task: {}, subscriber: { id: subscriberId } } }
    });

    const { data, loading } = useQuery(REQUEST_TASKS_OF_CLIENT_QUERY, {
        skip: !isClientRole,  // Skip this query if the user is not a client
        variables: { parameters: { task: {}, client: { id: clientId } } }
    });

    useEffect(() => {
        const tasksData = isClientRole ? data?.requestTasksOfClient?.data : taskSubscriberData?.requestTasksOfSubscriber?.data;
        if (!loading && tasksData) {
            const filteredTasks = tasksData.filter(task => task?.interestedClient?.recognition?.event?.id);
            setTaskItems(filteredTasks);
        }
    }, [data, taskSubscriberData, loading, isClientRole]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left', px: 2 }}>
            {!inForm && <PageHeader title={t("headers.manageEventTasksOfSubscriber.subTitle")} />}
            <ViewTasksGrid list={taskItems} inForm={inForm} loading={loading} />
        </Box>
    );
}
