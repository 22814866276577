import React, {useState, useEffect} from 'react';
import GlobalDataGridPro from "../manage";
import IndustryColumns from './helpers/columns';
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DELETE_INDUSTRY_MUTATION, REQUEST_INDUSTRIES_QUERY} from "../../graphql/industry";
import {useMutation} from "@apollo/client";
import ImportIndustry from "./Import";
import DeleteIndustry from "./Delete";
import {EditIndustry} from "./Edit";
import {AddIndustry} from "./Add";
import searchByString from "../../helpers/searchByString";

export const ManageIndustriesGrid = ({list, inForm = false, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(16);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [deleteIndustry] = useMutation(DELETE_INDUSTRY_MUTATION,
        {
            refetchQueries: [{query: REQUEST_INDUSTRIES_QUERY}],
            awaitRefetchQueries: true
        });
    const handleDelete = DeleteIndustry(deleteIndustry);
    const handleDeleteIndustry = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    const handleAdd = () => {
        setAddOpen(!addOpen)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const handleEdit = (e, params) => {
        e.preventDefault();
        if (isEditOpen) {
            const currentSearchParams = new URLSearchParams(window.location.search);
            currentSearchParams.delete('industryId');
            currentSearchParams.delete('name');
            const currentUrl = new URL(window.location.href);
            currentUrl.search = currentSearchParams.toString();
            navigate(currentUrl.search);
        } else {
            const currentSearchParams = new URLSearchParams(window.location.search);
            currentSearchParams.append('industryId', params.id);
            const currentUrl = new URL(window.location.href);
            currentUrl.search = currentSearchParams.toString();
            navigate(currentUrl.search);
        }
        setIsEditOpen(!isEditOpen)
    }
    const CustomColumns = React.useMemo(
        () => [
            ...IndustryColumns(handleEdit),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-Industry"
                        icon={<EditIcon/>}
                        onClick={(e) => {
                            handleEdit(e, params);
                        }}
                        showInMenu={true}/>,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-Industry"
                        icon={<DeleteForeverIcon/>}
                        onClick={() => {
                            handleDeleteIndustry(params.id)
                        }}
                        showInMenu={true}/>,

                ]
            }
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Industries",
        title: inForm ? "" : "Industries",
        searchLabel: "Search Industry",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleAdd: handleAdd,
        handleImport: toogleImport,
        handleSearch: handleSearch,

        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps}/>
            <ImportIndustry isOpen={isOpen} handleClose={toogleImport}/>
            <EditIndustry isOpen={isEditOpen} handleClose={handleEdit}/>
            <AddIndustry isOpen={addOpen} handleClose={handleAdd}/>
        </>
    );
};