import {InterestedClientModal} from "../../modal/interestedClientModal";
import {FormProvider, useForm} from "react-hook-form";
import {InterestedClientForm} from "../../forms";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Toast from "../../alerts";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {createEditorState, textEditorFormat} from "../../../helpers/formatters";
import {useMutation, useQuery} from "@apollo/client";
import {
    REQUEST_INTERESTED_CLIENTS_QUERY,
    UPDATE_INTERESTED_CLIENT_QUERY
} from "../../../graphql/interestedClient";
import {AppContext} from "../../../App";
import Typography from "@mui/material/Typography";
import {REQUEST_EVENT_SUBMISSIONS_OF_SUBSCRIBER_QUERY} from "../../../graphql/submission";


export const EventFormOfClient = ({isOpen, handleClose,refetchData}) => {
    const {t} = useTranslation();
    // State to hold the selected item
    const [selectedItem, setSelectedItem] = useState(null);
    const currentSearchParams = new URLSearchParams(window.location.search);
    const {user} = useContext(AppContext);
    const [items, setItems] = useState([]);
    const clientName = currentSearchParams.get('clientName');
    const clientId = currentSearchParams.get('clientId');
    const [list, setList] = useState([]);
    const methods = useForm({
        mode: 'onTouched',
    });
    const {
        data: interestedClientData,
    } = useQuery(REQUEST_INTERESTED_CLIENTS_QUERY, {
        variables: {
            parameters: {
                interestedClient: {
                    recognition: {
                        event: {}
                    },
                    subscriber: {
                        id: user?.subscriber ? user?.subscriber.id : null
                    }
                }
            }
        }
    });
    useEffect(() => {
        if (interestedClientData?.requestInterestedClients?.data.length > 0) {
            setList(interestedClientData?.requestInterestedClients?.data.filter(item => item?.client?.id === clientId));
        }
    }, [interestedClientData,clientId]);
    const {trigger, formState: {isValid, isDirty}, getValues, reset} = methods;
    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset, isOpen]);
    useEffect(() => {
        setItems(list);
    }, [list]);
    useEffect(() => {
        if (selectedItem) {
            methods.reset({
                ...selectedItem,
                notes: selectedItem?.notes ? createEditorState(selectedItem.notes) : "",
            });
        }
    }, [selectedItem]);
    useEffect(() => {
        if (isOpen && list && list.length > 0) {
            setSelectedItem(list[0]);
        }
    }, [isOpen, list]);
    const [updateInterestedClient] = useMutation(UPDATE_INTERESTED_CLIENT_QUERY,{
        refetchQueries: [
            {
                query: REQUEST_EVENT_SUBMISSIONS_OF_SUBSCRIBER_QUERY,
                fetchPolicy: "network-only",
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        }
                    }
                }
            },
        ],
        awaitRefetchQueries: true
    });
    const handleSubmit = () => {
        const data = getValues();
        data.notes = textEditorFormat(data?.notes);
        updateInterestedClient({
            variables: {
                parameters: {
                    interestedClient: {
                        ...data,
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        recognition: {
                            willSpeak: data.recognition?.willSpeak,
                            event: {
                                ...selectedItem?.recognition?.event
                            }
                        }
                    },
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName
                    }
                },
            },
        })
            .then((res) => {
                Toast({
                    type: 'success',
                    message: t('successMessage.interestedClientUpdated')
                });
                setItems(items.map(item => {
                    if (item.id === res?.data?.updateInterestedClient?.data?.id) {
                        return res?.data?.updateInterestedClient?.data
                    }
                    return item;
                }));
                refetchData();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    };
    return (
            <InterestedClientModal isOpen={isOpen}
                                   handleClose={handleClose}
                                   title={clientName}
                                   subTitle={t('headers.eventsOfClient.subTitle')}
                                   onSave={handleSubmit}
                                   saveDivider={false}
                                   hasFooter={false}
                                   titleSX={{
                                       px: 3,
                                       py:2
                                   }}
                                   modalSX={{
                                       bgcolor: 'background.paper',
                                       width: '90%',
                                       p:0,
                                       maxHeight:'none'
                                   }}
            >
                <Box sx={{display: 'flex', width: '100%'}}>
                    {/* Items list on the left */}
                    <Paper elevation={2}
                           style={{width: '20%', overflowY: 'auto', flexGrow: 1}}>
                        {items.map(item => (
                            <MenuItem
                                key={item.id}
                                onClick={() => setSelectedItem(item)}
                                style={{
                                    backgroundColor: selectedItem && selectedItem.id === item.id ? '#EE814D' : 'transparent',
                                    color: selectedItem && selectedItem.id === item.id ? 'white' : 'inherit',
                                    height: '48px',
                                    width: '92%',
                                    margin: '10px auto',
                                    borderRadius: '12px',
                                }}
                            >
                                <Typography
                                    title={item?.recognition?.event?.name}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '98%',
                                    }}
                                >
                                    {item?.recognition?.event?.name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Paper>
                    {/* Form on the right */}
                    <Box sx={{width: '80%', px: 3, py: 2}}>
                        <FormProvider {...methods}>
                            <form style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%'
                            }}>  {/* <-- Changed to flex */}
                                {/* Pass the selectedItem to the form */}
                                <InterestedClientForm trigger={trigger} selectedItem={selectedItem}/>
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >

                                    <Button
                                        variant="outlined"
                                        sx={{borderRadius: '12px'}}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        disabled={!isDirty || !isValid}
                                        sx={{color: '#FFFFFF', borderRadius: '12px', ml: 1}}
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider>
                    </Box>
                </Box>
            </InterestedClientModal>
    )
}