import * as React from "react";
import {t} from "i18next";
import { Link } from '@mui/material';
import {calculateLocation, getSubmissionStatusChip, renderDeadlineCell} from "../../manage/utilis/columnHelpers";

const SubmissionColumns = [
    {
        field: 'status',
        headerName: t('columns.manageSubmissions.status'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => getSubmissionStatusChip(params.row.status.name),
        valueGetter: (params) => getSubmissionStatusChip(params.row.status.name),
    },
    {
        field: 'title',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.title'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/awards/submissions/${params?.id}?update_submission`}>{params.value}</Link>),
    },
    {
        field: 'client',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.client'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => params.row.interestedClient?.client?.name || '',
        renderCell: (params) => (
            <Link href={`clients/${params.row.interestedClient?.client?.id}?update_client`}>{params.row.interestedClient?.client?.name}</Link>),
    },
    {
        field: 'interestedClient.recognition.award.name',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.award'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`browse/${params?.row?.interestedClient?.recognition?.award?.id}?update_award`}>{params?.row?.interestedClient?.recognition?.award?.name}</Link>),
    },
    {
        field: 'location',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.location'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => calculateLocation(params.row.interestedClient.recognition.award)
    },
    {
        field: 'industries',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.industries'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => params.row.interestedClient.recognition.award.industries?.map(industry => industry.name).join(', ')
    },
    {
        field: 'applicationDeadline',
        headerName: t('columns.manageAwardSubmissionsOfSubscriber.deadline'),
        flex: 100,
        minWidth: 150,
        maxWidth: 200,
        align: "center",
        headerAlign: 'center',
        renderCell: (params) => renderDeadlineCell(params.row.interestedClient?.recognition?.award?.applicationDeadline)
    },

]
export default SubmissionColumns;