import React, { useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewTeamMemberUsersOfClientGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_TEAM_MEMBER_USERS_OF_CLIENT_QUERY} from "../../graphql/user";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {t} from "i18next";
export default function ViewTeamMemberUsersOfClientComponent({inForm = false, client = {}}) {
    const [userItems, setUserItems] = useState([]);
    //This will Replace the data with the data from the API
    const {clientId} = useParams();
    const {data, loading} = useQuery(REQUEST_TEAM_MEMBER_USERS_OF_CLIENT_QUERY,
        {
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    }
                }
            }
        });

    useEffect(() => {
        if (data?.requestTeamMemberUsersOfClient?.data) {
            setUserItems(data?.requestTeamMemberUsersOfClient?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: inForm ? 0 : 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewTeamMembersOfClient.subTitle")}/>}
            <ViewTeamMemberUsersOfClientGrid
                list={userItems} inForm={inForm} loading={loading} client={client}
            />
        </Box>
    );
}
