import popupConfirmation from "../../popUpAlerts/confirmation";
import { useTranslation } from "react-i18next";

const DeleteInterestedClient = (deletedInterestedClient) => {
    const { t } = useTranslation();

    const handleDelete = (id) => {
        let popUpParameters = {
            subParameters: {
                successTitle: t('successMessage.interestedClientDeleted.subTitle'),
                successText: t('successMessage.interestedClientDeleted.text'),
                icon: 'success'
            }
        };
        popupConfirmation(popUpParameters, onDelete, id);
    };

    const onDelete = (id) => {
        return deletedInterestedClient({
            variables: {
                parameters: {
                    interestedClient: { id: id },
                },
            },
        })
    };

    return handleDelete;
}
export default DeleteInterestedClient;
