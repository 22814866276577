import * as yup from "yup";
import regex from "../../../../helpers/regex";
export const awardFormValidations = (t) => {
    return yup.object().shape({
name: yup.string().required(t('errorMessage.nameRequired')),
website: yup.string().required(t('errorMessage.websiteRequired')),
applicationDeadline: yup.string().required(t('errorMessage.applicationDeadlineRequired')),
programContactPhoneNumber: yup
.string()
.nullable()
.transform((value, originalValue) => (originalValue === '' ? null : value))
.matches(regex.phoneNumber, t('errorMessage.invalidPhoneNumberFormat')),
programContactEmailAddress: yup
.string()
.nullable()
.transform((value, originalValue) => (originalValue === '' ? null : value))
.matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat')),
})};
