import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {formatPhoneNumber} from "../../manage/utilis/columnHelpers";

const SubscriberColumns = [
    {
        field: 'name',
        headerName: t('columns.manageSubscribers.name'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/administrator/agencies/${params?.id}?update_agency`}>{params.value}</Link>),
    },
    {
        field: "address",
        headerName: t('columns.manageSubscribers.address'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => {
            const {address, city, state, zipCode} = params.row;
            return `${address}, ${city}, ${state}, ${zipCode}`;
        }
    },
    {
        field: 'phoneNumber',
        headerName: t('columns.manageSubscribers.phoneNumber'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => formatPhoneNumber(params.value),
    },
]
export default SubscriberColumns;