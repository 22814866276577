// React and Hooks
import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { useTranslation } from "react-i18next"; // i18n hook
import { useNavigate } from "react-router-dom"; // Router hook

// Apollo Client
import { useMutation } from "@apollo/client";

// MUI Icons
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// MUI X Data Grid Pro
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

// GraphQL Queries and Mutations
import { DELETE_AWARD_MUTATION, REQUEST_AWARDS_QUERY, UPDATE_AWARD_MUTATION } from "../../graphql/award";

// Contexts
import { AppContext } from "../../App";

// Helper Functions
import searchByString from "../../helpers/searchByString";
import { filterBySelection } from "../../helpers/filterBySelection";

// Custom Components and Hooks
import DeleteAward from "./Delete";
import { AwardsFiltersOptions } from "./Filter";
const ImportAward = lazy(() => import("./Import"));
import getAwardColumns from "../manage/globalColumns/awards";
import { useTableFilter } from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, { GlobalFilters } from "../filters/containers/globalFilters";
import Toast from '../alerts';
import ShowDisabledItems from '../manage/utilis/quickFilters';
import GlobalDataGridPro from "../manage";


const tableID = 'fb257544-8fc3-11ee-b9d1-0242ac120002-awards';

export const ManageAwardsGrid = (
    {
        list,
        inForm = false,
        loading,
        handleShowAllItems,
        showAllItems
    }
) => {
    const { t } = useTranslation();
    const { user } = useContext(AppContext);
    const navigate = useNavigate()
    const { permanentFilter } = useTableFilter(tableID);
    const { applicationDeadline = [null, null], industries = [] } = permanentFilter;

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteAward] = useMutation(DELETE_AWARD_MUTATION,
        {
            refetchQueries: [{query: REQUEST_AWARDS_QUERY, variables: {
                    parameters: {
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }},
                {query: REQUEST_AWARDS_QUERY, variables: {
                    parameters: {
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }}
        ],
            awaitRefetchQueries: true
        });

    const [updateAward] = useMutation(UPDATE_AWARD_MUTATION,
        {
            refetchQueries: [{query: REQUEST_AWARDS_QUERY, variables: {
                parameters: {
                    showAllItems: false,
                    showOnlyDisabledItems: false
                }
            }},
            {query: REQUEST_AWARDS_QUERY, variables: {
                parameters: {
                    showAllItems: true,
                    showOnlyDisabledItems: false
                }
            }}],
            awaitRefetchQueries: true
        }
    );
    const handleDelete = DeleteAward(deleteAward);
    const handleDeleteAward = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    };
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            applicationDeadline: {
                type: 'dateRange',
                value: applicationDeadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
        }
        results = filterBySelection(results, filteredParams)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])
    const handleAdd = () => {
        navigate(`/administrator/awards/new_award`)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const handleDisable = (params) => {
        const toastId = 'handleDisableAward'
        Toast({
            autoClose: 7000,
            type: 'info',
            message: 'Updating...',
            toastId: toastId
        });
        updateAward({
            variables: {
                parameters: {
                    award: { ...params, isDisabled: !params.isDisabled },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.awardUpdated'),
                    toastId: toastId
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0],
                    toastId: toastId
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...getAwardColumns({
                baseURL: '/administrator/awards',
                role: user?.userRole?.name
            }),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-Award"
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(`/administrator/awards/${params?.id}?update_award`)
                        }}
                        showInMenu={true} />,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-Award"
                        icon={<DeleteForeverIcon />}
                        onClick={() => {
                            handleDeleteAward(params.id)
                        }}
                        showInMenu={true} />,
                    <GridActionsCellItem
                        key={params.id}
                        label={params?.row?.isDisabled ? t('button.activateItem') : t('button.disableItem')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Disabled-Award"
                        icon={params?.row?.isDisabled ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        onClick={() => {
                            handleDisable(params.row)
                        }}
                        showInMenu={true} />,

                ]
            }
        ], []);

    const dataGridProps = {
        id: tableID,
        title: inForm ? "" : "Awards",
        searchLabel: "Search Award",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        autoHeight: false,
        handleAdd: handleAdd,
        handleImport: toogleImport,
        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        customSubToolbar: <ShowDisabledItems toggleShowAll={handleShowAllItems} showAll={showAllItems} label={'button.showDisabledAwards'} />,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: isOpen ? 'simple-popper' : undefined,
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: AwardsFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters',
        },
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps} />
            {isOpen &&
                <Suspense fallback={<div>Loading...</div>}>
                    <ImportAward isOpen={isOpen} handleClose={toogleImport} />
                </Suspense>
            }
        </>

    );
};