import {Box, ButtonBase, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";

const NavHeader = () => {
    const {isMinimized, toggleNav, user} = useContext(AppContext);
    const {t} = useTranslation();
    return <Box
        sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: '3.5rem'
        }}
    >
        <MenuIcon
            sx={{
                cursor: 'pointer',
                ml: !isMinimized ? '6px' : 0,
                mr: !isMinimized ? '14px' : 0,
                fontSize: '24px'
            }}
            onClick={() => toggleNav(!isMinimized)}
        />
        {!isMinimized && (
            <ButtonBase
                href={user?.userRole?.name === "Administrator" ? '/administrator/events' : '/agency/events/browse'}>
                <Typography variant="navHeader">{t('appName')}</Typography>
            </ButtonBase>

        )}
    </Box>
        ;
}
export default NavHeader