import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {EmailCell, formatPhoneNumber} from "../../manage/utilis/columnHelpers";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const ContactColumns = [
    {
        field: 'name',
        headerName: t('columns.manageContact.name'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/clients/contacts/${params?.id}?update_contact`}>{params.value}</Link>),
    },
    {
        field: 'emailAddress',
        headerName: t('columns.manageContact.emailAddress'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (<EmailCell value={params.value}/>)
    },
    {
        field: 'phoneNumber',
        headerName: t('columns.manageContact.phoneNumber'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => formatPhoneNumber(params.value)
    },
    {
        field: 'isPrimaryContact',
        headerName: t('columns.manageContact.isPrimaryContact'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "center",
        headerAlign: 'center',
        renderCell: (params) => {
            return params.row?.isPrimaryContact ? <DoneIcon sx={{cursor: 'default'}}/> : <CloseIcon sx={{cursor: 'default'}}/>
        },
        valueGetter: (params) => params.row?.isPrimaryContact ? "Primary Contact" : ""
    },

]
export default ContactColumns;