import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import {InputController, AvatarUploadController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import {AppContext} from "../../App";
import ViewClients from "../viewClients";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CheckboxController from "../formController/CheckboxController";
import {useParams} from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const UserForm = ({isUpdate = false, handleResetPassword, isViewOnly = false, handleSubmit}) => {
    const {t} = useTranslation();
    const {control, watch, getValues, setValue,formState: {errors}} = useFormContext();
    const {user} = useContext(AppContext);
    const {userId} = useParams();
    const isAdmin = user?.userRole?.name === 'Subscriber Administrator';
    const isDisabled = watch('isDisabled');
    const userInfo = getValues()

    const handleToggleDisableClient = () => {
        setValue('isDisabled', !isDisabled);
        handleSubmit()
    }

    const ToggleDisableClient = () => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" pr={2}>{`Status: ${isDisabled ? "Disabled" : "Active"}`}</Typography>
                <Button
                disabled={userId === user?.id}
                    variant={"outlined"}
                    color={isDisabled ? 'blue' : 'warning'}
                    startIcon={isDisabled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                    sx={{ borderRadius: '12px' }}
                    onClick={handleToggleDisableClient}
                    type='button'
                >
                    {isDisabled ? t('button.activateItem') : t('button.disableItem')}
                </Button>
            </Box>
        )
    }


    return (
        <>
            <FormContainer title={t('section.details')} to={'details'} handleResetPassword={handleResetPassword} custonRightButton={isUpdate && isAdmin ? ToggleDisableClient : null}>
                <Grid container spacing={3}>
                    {!isViewOnly && <Grid item xs={12} md={2}>
                        <AvatarUploadController label={t("enter.user.avatar")} control={control} name="headshot"/>
                    </Grid>
                    }
                    <Grid item xs={12} md={isViewOnly ? 12 : 10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.firstName}
                                    name='firstName'
                                    inputMode="letter"
                                    disabled={isViewOnly}
                                    required
                                    fullWidth
                                    label={t("enter.firstName")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.lastName}
                                    name='lastName'
                                    disabled={isViewOnly}
                                    required
                                    fullWidth
                                    label={t("enter.lastName")}
                                    autoComplete="off"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.emailAddress}
                                    name='emailAddress'
                                    required
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.emailAddress")}
                                    autoComplete="email"
                                    readOnly={isUpdate}
                                    type="email"

                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.phoneNumber}
                                    name='phoneNumber'
                                    required
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.phoneNumber")}
                                    autoComplete="tel"
                                    type="phone"

                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.address}
                                    name='address'
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.address")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.city}
                                    name='city'
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.city")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.state}
                                    name='state'
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.state")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.zipCode}
                                    name='zipCode'
                                    fullWidth
                                    disabled={isViewOnly}
                                    label={t("enter.zipCode")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormContainer>
            {user?.userRole?.name !== 'Administrator' && !isViewOnly &&
                <FormContainer title={t('section.security')} to={'security'}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{height: '95px'}}>
                            <CheckboxController control={control} name='isAdmin' label={t('enter.isAdmin')}
                                                disabled={userId === user?.id}
                                                error={errors?.zipCode}/>
                        </Grid>
                    </Grid>
                </FormContainer>
            }
            {user?.userRole?.name !== 'Administrator' && (user?.userRole?.name !== 'Client Administrator' && user?.userRole?.name !== 'Client') && isUpdate ? (
                <FormContainer title={t('section.clients')} to={'clients'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ViewClients inForm={true} userInfo={userInfo} />
                        </Grid>
                    </Grid>
                </FormContainer>
            ) : null}
        </>
    );
};
export default UserForm;
