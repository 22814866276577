import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {EventForm} from "../../forms";
import {
    CREATE_EVENT_MUTATION,
    REGISTER_EVENT_MUTATION,
    REQUEST_APPROVED_EVENTS_QUERY,
    REQUEST_EVENTS_QUERY
} from "../../../graphql/event";
import {useMutation} from "@apollo/client";
import {eventFormValidations} from "../Edit/helpers";
import {eventRegistryFormValidations} from "../Edit/helpers/registry";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {textEditorFormat} from "../../../helpers/formatters";

export const AddEvent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentSearchParams = new URLSearchParams(window.location.search);
    const isRegistration = currentSearchParams.get('register') === 'true';
    const sessionTypes = [{name: "Speaker"},
        {name: "Forum"},
        {name: "Panel"},
        {name: "Abstract"}]
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: isRegistration ? yupResolver(eventRegistryFormValidations(t)) : yupResolver(eventFormValidations(t)),
    });
    const {trigger, formState: {isValid}, getValues} = methods;
    const {user} = useContext(AppContext);
    const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
        refetchQueries: [{
            query: REQUEST_EVENTS_QUERY, variables: {
                parameters: {
                    showAllItems: true,
                    showOnlyDisabledItems: false
                }
            }
        },{
            query: REQUEST_EVENTS_QUERY, variables: {
                parameters: {
                    showAllItems: false,
                    showOnlyDisabledItems: false
                }
            }
        }],
        awaitRefetchQueries: true,
    });
    const [registerEvent] = useMutation(REGISTER_EVENT_MUTATION, {
        refetchQueries: [{
            query: REQUEST_APPROVED_EVENTS_QUERY,
        }],
        awaitRefetchQueries: true,
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = getValues()
        if (!isRegistration) {
            data.expertTips = textEditorFormat(data?.expertTips)
            data.agencyNotes = textEditorFormat(data?.agencyNotes)
            data.speakerEventGuidelines = textEditorFormat(data?.speakerEventGuidelines)
            data.description = textEditorFormat(data?.description)
            data.sessionTopics = textEditorFormat(data?.sessionTopics)
        }
        if (user?.userRole?.name === 'Subscriber' || user?.userRole?.name === 'Subscriber Administrator') {
            registerEvent({
                variables: {
                    parameters: {
                        event: {
                            ...data,
                            status: 'Pending',
                            startDate: new Date().toISOString('MM/DD/YYYY'),
                            endDate: new Date().toISOString('MM/DD/YYYY'),
                        },
                    },
                },
            })
                .then(() => {
                    Toast({
                        type: 'success',
                        message: t('successMessage.eventCreated')
                    });
                    navigate(`/agency/events/browse?register=true`, { replace: true });
                })
                .catch((error) => {
                    const errorMessage = error.graphQLErrors.map((x) => x.message);
                    Toast({
                        type: 'error',
                        message: errorMessage[0]
                    });
                });
        } else {

            createEvent({
                variables: {
                    parameters: {
                        event: {
                            ...data,
                            status: user?.userRole?.name === 'Administrator' ? 'Approved' : 'Pending',
                        },
                    },
                },
            })
                .then((res) => {
                    let paramId = res?.data?.createEvent?.data?.id
                    Toast({
                        type: 'success',
                        message: t('successMessage.eventCreated')
                    });
                    navigate(`/administrator/events/${paramId}?update_event`)
                })
                .catch((error) => {
                    const errorMessage = error.graphQLErrors.map((x) => x.message);
                    Toast({
                        type: 'error',
                        message: errorMessage[0]
                    });
                });
        }
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <FormProvider {...methods} >
                <form
                    onBlur={() => {
                        trigger()
                    }}
                >
                    <FormWrapperComponent
                        title={t('headers.manageEvents.edit.subTitle')}
                        variant="add"
                        backTo={user?.userRole?.name.includes('Subscriber') ? `/agency/events/browse` : '/administrator/events'}
                        submitFn={isValid ? handleSubmit : null}
                        disableBarSave={user?.userRole?.name.includes('Subscriber')}
                        stepper={user?.userRole?.name.includes('Subscriber')}
                        stepperSubtitle={t('enter.event.stepper.addSubtitle')}
                        stepperSubmit={handleSubmit}
                    >
                        <EventForm sessionTypes={sessionTypes}/>
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>

    );
};