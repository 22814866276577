import React, { useState, useEffect, useContext } from 'react';
import GlobalDataGridPro from "../manage";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ClientColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import { DISABLE_CLIENT_MUTATION, REQUEST_AWARD_ANALYTICS_OF_CLIENTS_QUERY, REQUEST_CLIENTS_OF_BROWSE_QUERY, REQUEST_EVENT_ANALYTICS_OF_CLIENTS_QUERY} from 'src/graphql/client';
import { AppContext } from 'src/App';
import Toast from '../alerts';
import { useMutation } from '@apollo/client';
import ShowDisabledItems from '../manage/utilis/quickFilters';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';


export const ViewClientsOfSubscriberGrid = (
    { 
        list,
         inForm = false, 
         loading,
         handleShowAll,
         showAll,
         toggleShowMyClients,
         myClients
         }) => {
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [selectedOption, setSelectedOption] = useState('');
    const isAdmin = user?.userRole?.name === 'Subscriber Administrator';

    const [updateClient] = useMutation(DISABLE_CLIENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_CLIENTS_OF_BROWSE_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        user: {
                            id: myClients ? user?.id : null
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            },{
                query: REQUEST_CLIENTS_OF_BROWSE_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        user: {
                            id: myClients ? user?.id : null
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            },
            {
                query: REQUEST_EVENT_ANALYTICS_OF_CLIENTS_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        user: {
                            id: null
                        },
                       
                    }
                }
            },
            {
                query: REQUEST_AWARD_ANALYTICS_OF_CLIENTS_QUERY, variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        user: {
                            id: null
                        },
                       
                    }
                }
            },
        ],
            awaitRefetchQueries: true
        });

    useEffect(() => {
        setSearchResults(list)
    }, [list])
    useEffect(() => {
        if (myClients && selectedOption === '') {
            setSelectedOption('myClients')
        } else if (!myClients && selectedOption === '') {
            setSelectedOption('allClients')
        }
    }, [myClients]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    const handleRadioChange = (event) => {
        if (event.target.value === 'myClients') {
            setSelectedOption('myClients');
            toggleShowMyClients(true);
        } else {
            setSelectedOption('allClients');
            toggleShowMyClients(false);
        }
    };
    const handleDisable = (params) => {
        const toastId = 'handleDisableClient'
        Toast({
            autoClose: 7000,
            type: 'info',
            message: 'updating...',
            toastId: toastId
        });
        updateClient({
            variables: {
                parameters: {
                    client: {id: params?.id, isDisabled: !params.isDisabled },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.clientUpdated'),
                    toastId: toastId
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0],
                    toastId: toastId
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...ClientColumns(isAdmin),
            isAdmin ?
                {field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={params?.row?.isDisabled ? t('button.activateItem') : t('button.disableItem')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Disabled-client"
                        icon={params?.row?.isDisabled ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        onClick={() => {
                            handleDisable(params.row)
                        }}
                        showInMenu={true}
                    />,
                ]}
                : null
            
        ], []);
        const filterList = () => {
            return (
                <div>
                    <RadioGroup value={selectedOption} onChange={handleRadioChange} sx={{color: "white"}} row>
                        <FormControlLabel
                            value="myClients"
                            control={<Radio color="primary"/>}
                            label={t("button.myClients")}
                        />
                        <FormControlLabel
                            value="allClients"
                            control={<Radio color="primary"/>}
                            label={t("button.allClients")}
                        />
                    </RadioGroup>
                </div>
            )
        }
    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Clients",
        title: inForm ? "" : "Clients",
        searchLabel: "Search Clients",
        rows: searchResults,
        columns: CustomColumns,
        customSubToolbar: isAdmin ? <ShowDisabledItems toggleShowAll={handleShowAll} showAll={showAll} label={'button.showDisabledClients'} /> : null,
        filterCircle: filterList,
        pagination: false,
        autoHeight: false,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <GlobalDataGridPro {...dataGridProps} inForm={inForm} />
    );
};