import React, {useEffect, useState, useMemo, memo} from 'react';
import {CircularProgress} from '@mui/material';
import {useQuery} from "@apollo/client";
import {processTextBlocks} from "../../helpers/formatters";
import readOnlyDetailsForm from "./readOnlyDetailsForm";
import {REQUEST_CLIENT_DETAILS_QUERY} from "../../graphql/client";

const ClientDetailComponent = ({id}) => {
    const [recognitionData, setRecognitionData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);

    const {data, loading} = useQuery(REQUEST_CLIENT_DETAILS_QUERY, {
        variables: {
            parameters: {
                client: {
                    id: id
                },
            },
        },
        skip: !id,
    });


    useEffect(() => {
        if (!loading && data?.requestClient?.data) {
            const obj = {...data?.requestClient.data};
            const keywords = processTextBlocks(obj?.keywords, 'keywords');
            setRecognitionData({...obj, keywords});
            setIsDataReady(true);
        }
    }, [loading, data]);

    const commonFields = useMemo(() => [
        {
            xs: 12, md: 12, maxHeight: '120px',
            id: '5422ea66-9e27-11ee-8c90-0242ac120002',
            fields: [
                {
                    id: '585cbb0c-9e27-11ee-8c90-0242ac120002',
                    name: 'name',
                    label: "Name:",
                    link: `/agency/clients/${recognitionData?.id}?update_client`,
                    enableLink: true
                },
                {id: "75b388c0-9e27-11ee-8c90-0242ac120002", name: 'keywords', label: "Keywords:", labelTop: true}
            ]
        },
        // {
        //     xs: 12, md: 6, height: '95px',
        //     id: '8076f436-9e27-11ee-8c90-0242ac120002',
        //     fields: [{id: "75b388c0-9e27-11ee-8c90-0242ac120002", name: 'keywords', label: "Keywords:", labelTop: true}]
        // }
    ], [recognitionData]);

    if (loading || !isDataReady || !recognitionData?.id) {
        return <CircularProgress/>;
    }

    return readOnlyDetailsForm(commonFields, recognitionData, id);
};

export default memo(ClientDetailComponent);


