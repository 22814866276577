import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/Check';

const steps = [
    'Request Addition',
    'In Review',
    'Addition Status'
];

function StepIconComponent(props) {
    const { active, completed, icon } = props;
    return (
        <div style={{
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            backgroundColor: completed ? '#EE814D' : active ? '#EE814D' : 'grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {completed ?
                <CheckIcon style={{ color: 'white' }} /> :
                <span style={{ color: active ? 'white' : 'black' }}>{icon}</span>
            }
        </div>
    );
}


export default function CustomStepper({ status, stepper, subtitle, submitFn, variant }) {
    let activeStep = 0;
    let completedSteps = {};

    if (!status || status === "undefined") {
        activeStep = 0;
    } else if (status === 'Pending') {
        activeStep = 1;
        completedSteps = { 0: true }; // Marking the first step as completed
    } else if (status === 'Approved') {
        activeStep = 2;
        completedSteps = { 0: true, 1: true }; // Marking the first two steps as completed
    }
    return (
        <>
            {stepper ?
                <Box sx={{ width: '100%', height: '95px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9.4}>
                            <Stepper activeStep={activeStep} sx={{ width: "100%" }}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label} completed={completedSteps[index]}>
                                            <StepLabel StepIconComponent={StepIconComponent}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {/* Subtitle */}
                            <Typography variant="subtitle2" sx={{ marginLeft: 5, marginTop: 1 }}>{subtitle}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={(e) => {
                                submitFn(e, true)
                            }}>
                                {variant === "add" ? 'Submit' : 'Add to system'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                : null}
        </>
    );
}
