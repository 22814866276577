import React, {useEffect, useState, useMemo, memo} from 'react';
import {CircularProgress} from '@mui/material';
import {useQuery} from "@apollo/client";
import {REQUEST_EVENT_DETAILS_QUERY} from "../../graphql/event";
import {REQUEST_AWARD_DETAILS_QUERY} from "../../graphql/award";
import readOnlyDetailsForm from "./readOnlyDetailsForm";

const EventDetailComponent = ({event, id}) => {
    const [recognitionData, setRecognitionData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);

    const query = useMemo(() => event ? REQUEST_EVENT_DETAILS_QUERY : REQUEST_AWARD_DETAILS_QUERY, [event]);
    const queryField = useMemo(() => event ? 'requestEvent' : 'requestAward', [event]);

    const {data, loading} = useQuery(query, {
        variables: {parameters: {[event ? 'event' : 'award']: {id}}},
        skip: !id,
    });

    useEffect(() => {
        if (!loading && data?.[queryField]?.data) {
            setRecognitionData({...data[queryField].data});
            setIsDataReady(true);
        }
    }, [loading, data, queryField]);
    const commonFields = useMemo(() => [
        {
            xs: 12, md: 6,
            id: '23e4ea7e-9e28-11ee-8c90-0242ac120002',
            fields: [
                {
                    id: '26d47a38-9e28-11ee-8c90-0242ac120002',
                    name: 'name',
                    label: "Title:",
                    link: `/agency/${event ? 'events' : 'awards'}/browse/${recognitionData?.id}?update_${event ? 'event' : 'award'}`,
                    enableLink: true
                },
                {
                    id: '298b8ac8-9e28-11ee-8c90-0242ac120002',
                    name: 'eventDate',
                    label: "Date:",
                    type: 'fullDate',
                    skip: !event
                },
                {id: '2c1e7fb6-9e28-11ee-8c90-0242ac120002', name: event ? 'deadline' : 'applicationDeadline', label:  event ? 'Submission Deadline:' : 'Final Submission Deadline:'},
                {
                    id: '26d47a38-9e28-11ee-8c90-0242ac120002',
                    name: 'website',
                    label: "Website:",
                    link: `${recognitionData?.website}`,
                    enableLink: true
                }
            ]
        },
    ], [recognitionData, event]);

    if (loading || !isDataReady || !recognitionData?.id) {
        return <CircularProgress/>;
    }

    return readOnlyDetailsForm(commonFields, recognitionData, id);
};

export default memo(EventDetailComponent);


