import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {renderDeadlineCell} from "../../manage/utilis/columnHelpers";

const AnalyticsColumns = ()=>{
    return [
        {
            field: 'name',
            headerName: t('columns.viewAwardAnalytics.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/agency/awards/analytics/${params?.id}?update_award`}>{params.value}</Link>
            ),
        },
        {
            field: 'clients',
            headerName: t('columns.viewAwardAnalytics.clients'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.clients
            }
        },
        {
            field: 'submissionsTracking',
            headerName: t('columns.viewAwardAnalytics.submissionsTracking'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.submissionsTracking
            }
        },
        {
            field: 'pending',
            headerName: t('columns.viewAwardAnalytics.pending'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.pending
            }
        },
        {
            field: 'completed',
            headerName: t('columns.viewAwardAnalytics.completed'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.completed
            }
        },
        {
            field: 'applicationDeadline',
            headerName: t('columns.viewAwardAnalytics.deadline'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.applicationDeadline
            },
            renderCell: (params) => {
                return renderDeadlineCell(params?.row?.applicationDeadline)
            }
        },
    ]
}
export default AnalyticsColumns;