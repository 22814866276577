import React from 'react';
import Box from "@mui/material/Box";
import {ManageInterestedClientsOfAwardGrid} from "./component";

export default function ManageInterestedClientsOfAwardComponent(props) {
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            <ManageInterestedClientsOfAwardGrid
                {...props}
            />
        </Box>
    );
}
