import React, {useState, useEffect, createContext, useContext} from 'react';
import GlobalDataGridPro from "../manage";
import SubmissionColumns from './helpers/columns';
import SubmissionFiltersContainer, {SubmissionFilters} from "./Filter";

import searchByString from "../../helpers/searchByString";
import dayjs from "dayjs";
import {filterBySelection, filterDataByDateRange} from "../../helpers/filterBySelection";
import {AppContext} from "../../App";

export const SubmissionContext = createContext();
export const ViewEventSubmissionsOfClientGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);

    const [filterByDeadline, setFilterByDeadline] = useState(null)
    const [filterBySubmissionDate, setFilterBySubmissionDate] = useState([null, null])
    const [filterByIndustries, setFilterByIndustries] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [roleName, setRoleName] = useState('');
    const {user} = useContext(AppContext);
    useEffect(() => {
        if (user?.userRole?.name === "Administrator") {
            setRoleName("administrator");
        } else if (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") {
            setRoleName("agency");
        } else if (user?.userRole?.name === "Client" || user?.userRole?.name === "Client Administrator") {
            setRoleName("client");
        }
    }, [user]);
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const formatDate = (date) => {
        return dayjs(date).format('MM/DD/YYYY');
    };
    const removeAllFilters = () => {

        setFilterByDeadline(null)
        setFilterBySubmissionDate([null, null])
        setFilterByIndustries([])

    }
    const hasFilters = filterByDeadline !== null || (filterBySubmissionDate[0] !== null || filterBySubmissionDate[1] !== null) || filterByIndustries.length !== 0
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            deadline: filterByDeadline ? formatDate(filterByDeadline) : '',
            industries: filterByIndustries.map((industry) => {
                return industry.name
            }),

        }
        results = filterBySelection(results, filteredParams)
        results = filterDataByDateRange(results, filterBySubmissionDate)
        setSearchResults(results)
    }, [filterByDeadline, filterBySubmissionDate, filterByIndustries, searchTerm])

    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...SubmissionColumns(roleName)
        ], [roleName]);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Submissions",
        title: inForm ? "" : "Submissions",
        searchLabel: "Search Submission",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,


        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: SubmissionFiltersContainer,
            filterComponentToolbar: SubmissionFilters,
            anchorEl: anchorEl,
            title: 'Filters',
            removeAllFilters: removeAllFilters,
            hasFilters: hasFilters
        },
        searchTerm: searchTerm,
        inForm: inForm,
        loading: loading
    }
    const context = {
        filterByDeadline, filterBySubmissionDate, filterByIndustries,
        setFilterByDeadline, setFilterBySubmissionDate, setFilterByIndustries,
        removeAllFilters
    }
    return (

        <SubmissionContext.Provider value={context}>
            <GlobalDataGridPro {...dataGridProps}/>

        </SubmissionContext.Provider>

    );
};