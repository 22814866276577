import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {contactFormValidations} from "../Edit/helpers";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {CREATE_CONTACT_MUTATION, REQUEST_CONTACTS_OF_CLIENT_QUERY} from "../../../graphql/contact";
import ContactForm from "../../forms/contacts";

export const AddContact = () => {
    const {t} = useTranslation();
    const currentSearchParams = new URLSearchParams(window.location.search);
    const clientId = currentSearchParams.get('clientId');
    const navigate = useNavigate();
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(contactFormValidations(t)),
    });
    const {trigger, formState: {isValid}, getValues} = methods;
    const {user} = useContext(AppContext);
    const [createContact] = useMutation(CREATE_CONTACT_MUTATION, {
        refetchQueries: [{
            query: REQUEST_CONTACTS_OF_CLIENT_QUERY,
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    }
                }
            }
        }],
        awaitRefetchQueries: true,
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = getValues()
        createContact({
            variables: {
                parameters: {
                    contact: {
                        ...data,
                        subscriber: {
                            id: user?.subscriber?.id,
                        },
                        client: {
                            id: clientId
                        }
                    },
                },
            },
        })
            .then((res) => {
                let contactId = res?.data?.createContact?.data?.id
                Toast({
                    type: 'success',
                    message: t('successMessage.contactCreated')
                });
                navigate(`/agency/clients/contacts/${contactId}?update_contact`, { replace: true })
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <FormProvider {...methods} >
                <form
                    onBlur={() => {
                        trigger()
                    }}
                >
                    <FormWrapperComponent
                        submitFn={isValid ? handleSubmit : null}
                        title={'New Contact'}
                        variant="add"
                    >
                        <ContactForm/>
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>

    );
};