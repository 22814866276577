import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageAwardsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_AWARDS_QUERY} from "../../graphql/award";
import {useQuery} from "@apollo/client";
import useUpdatedItems from "../../hooks/useUpdateIndustries";

export default function ManageAwardsComponent({inForm = false}) {
    const [awardItems, setAwardItems] = useState([]);
    const [showAllItems, setShowAllItems] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const updatedItems = useUpdatedItems(awardItems);

    const {data, loading} = useQuery(REQUEST_AWARDS_QUERY, {
        variables: {
            parameters: {
                showAllItems: showAllItems,
                showOnlyDisabledItems: false
            }
        }
    });

    const handleShowAllItems = (e) => {
        const value = e.target.checked;
        setShowAllItems(value);
    };
    
    useEffect(() => {
        if (!loading && data?.requestAwards?.data) {
            setAwardItems(data?.requestAwards?.data);
            setIsLoading(false);
        }else if(!loading && !data?.requestAwards?.data){
            setIsLoading(false)
        }
    }, [data, loading]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader/>}
            <ManageAwardsGrid
                list={updatedItems}
                inForm={inForm}
                loading={isLoading}
                handleShowAllItems={handleShowAllItems}
                showAllItems={showAllItems}
            />
        </Box>
    );
}
