// React and Hooks
import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { useTranslation } from "react-i18next"; // i18n hook
import { useNavigate } from "react-router-dom"; // Router hook

// Apollo Client
import { useMutation } from "@apollo/client";

// GraphQL Queries and Mutations
import { DELETE_EVENT_MUTATION, REQUEST_EVENTS_QUERY, UPDATE_EVENT_MUTATION } from "../../graphql/event";

// MUI Components and Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

// Contexts
import { AppContext } from "../../App";

// Helper Functions
import searchByString from "../../helpers/searchByString";
import { filterBySelection, filterDataByDateRange } from "../../helpers/filterBySelection";

// Custom Components and Hooks
import DeleteEvent from "./Delete";
const ImportEvent = lazy(() => import("./Import"));
import getEventColumns from "../manage/globalColumns/events";
import GlobalFiltersContainer, { GlobalFilters } from "../filters/containers/globalFilters";
import { useTableFilter } from "../filters/containers/hooks/filterProvider";
import { EventFiltersOptions } from "./Filter";
import ShowDisabledItems from "../manage/utilis/quickFilters";
import Toast from "../alerts/index";

// Custom Manage Component
import GlobalDataGridPro from "../manage";

const tableID = '1473ed38-8d61-11ee-b9d1-0242ac120002-events';

export const ManageEventsGrid = (
    {
        list,
        inForm = false,
        loading,
        handleShowAllItems,
        showAllItems
    }) => {
    const { permanentFilter } = useTableFilter(tableID);

    const {
        eventDate = [null, null],
        deadline = [null, null],
        industries = [],
        rollingDeadline = false,
        directContact = false
    } = permanentFilter;
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useContext(AppContext);
    const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            }, {
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            }],
            awaitRefetchQueries: true
        });
    const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: true,
                        showOnlyDisabledItems: false
                    }
                }
            }, {
                query: REQUEST_EVENTS_QUERY, variables: {
                    parameters: {
                        showAllItems: false,
                        showOnlyDisabledItems: false
                    }
                }
            }],
            awaitRefetchQueries: true
        });
    const handleDelete = DeleteEvent(deleteEvent);
    const handleDeleteEvent = (id) => {
        handleDelete(id);
    }
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    };
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            deadline: {
                type: 'dateRange',
                value: deadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
            coldPitch: {
                type: 'objSearch',
                value: directContact
            },
            rollingDeadline: {
                type: 'objSearch',
                value: rollingDeadline
            },
        }
        results = filterBySelection(results, filteredParams)
        results = filterDataByDateRange(results, eventDate)
        setSearchResults(results)
    }, [searchTerm, permanentFilter]) 
    const handleAdd = () => {
        navigate(`/administrator/events/new_event`)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const handleDisable = (params) => {
        const toastId = 'handleDisableEvent'
        Toast({
            autoClose: 7000,
            type: 'info',
            message: 'updating...',
            toastId: toastId
        });
        updateEvent({
            variables: {
                parameters: {
                    event: { ...params, isDisabled: !params.isDisabled },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.eventUpdated'),
                    toastId: toastId
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0],
                    toastId: toastId
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...getEventColumns(
                {
                    baseURL: '/administrator/events',
                    role: user?.userRole?.name
                }),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-Event"
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(`/administrator/events/${params?.id}?update_event`)
                        }}
                        showInMenu={true} />,

                    <GridActionsCellItem
                        key={params.id}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-Event"
                        icon={<DeleteForeverIcon />}
                        onClick={() => {
                            handleDeleteEvent(params.id)
                        }}
                        showInMenu={true} />,
                    <GridActionsCellItem
                        key={params.id}
                        label={params?.row?.isDisabled ? t('button.activateItem') : t('button.disableItem')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Disabled-Event"
                        icon={params?.row?.isDisabled ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        onClick={() => {
                            handleDisable(params.row)
                        }}
                        showInMenu={true} />,

                ]
            }
        ], []);

    const dataGridProps = {
        id: tableID,
        title: inForm ? "" : "Events",
        searchLabel: "Search Event",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        autoHeight: false,
        handleAdd: handleAdd,
        handleImport: toogleImport,
        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        customSubToolbar: <ShowDisabledItems toggleShowAll={handleShowAllItems} showAll={showAllItems} label={'button.showDisabledEvents'} />,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: isOpen ? 'simple-popper' : undefined,
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: EventFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters',
        },
        searchTerm: searchTerm,
        loading: loading
    }
    return (

        <>
            <GlobalDataGridPro {...dataGridProps} />
            {isOpen &&
                <Suspense fallback={<div>Loading...</div>}>
                    <ImportEvent isOpen={isOpen} handleClose={toogleImport} />
                </Suspense>
            }
        </>

    );
};