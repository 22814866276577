import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import { getChipStatusCell } from "src/components/manage/utilis/columnHelpers";

const ClientColumns = (isAdmin) => {

const columns =[
    {
        field: 'name',
        headerName: t('columns.viewClientsOfSubscriber.name'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`/agency/clients/${params?.id}?update_client`}>{params.value}</Link>),
    },
    {
        field: 'agencyContact',
        headerName: t('columns.viewClientsOfSubscriber.agencyContact'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.agencyContact?.fullName || ''
        },
        renderCell: (params) => (
            <Link href={`/agency/team_members/${params?.row?.agencyContact?.id}?update_user`}>{params.value}</Link>),
    },
    {
        field: 'clientContact',
        headerName: t('columns.viewClientsOfSubscriber.clientContact'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => {
            return params?.row?.clientContact?.name || ''
        },
        renderCell: (params) => (
            <Link href={`/agency/clients/contacts/${params?.row?.clientContact?.id}?update_contact`}>{params.value}</Link>),
    },
    {
        field: 'openEventSubmissions',
        headerName: t('columns.viewClientsOfSubscriber.openEventSubmissions'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left'
    },
    {
        field: 'openAwardSubmissions',
        headerName: t('columns.viewClientsOfSubscriber.openAwardSubmissions'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left'
    }
]
if (isAdmin) {
    columns.push( 
        {
            field: 'status',
            headerName: t('columns.manageEvents.status'),
            width: 200,
            align: "center",
            resizable: false,
            headerAlign: 'center',
            renderCell: getChipStatusCell(true),
            valueGetter: (params) => {
                return params?.row?.isDisabled ? 'Disabled' : 'Active'
            },
        }
    );
}
return columns;
}
export default ClientColumns;