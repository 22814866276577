import {ContentState, convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';

export const getNameInitials = (name) => {
    if(name) {

        // Split the name into an array of words
        const words = name?.split(' ');
        // Get the first character of the first word
        const firstInitial = words[0] ? words[0].charAt(0).toUpperCase() : '';

        // Get the first character of the last word
        const lastInitial = words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : '';

        // Return the two initials
        return firstInitial + lastInitial;
    }
};

export const toTitleCase = (str) => {
    if (!str) return '';
    return str
        ?.split(/[\s_]+/) // Split by spaces or underscores
        ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
        ?.join(' '); // Join with spaces
}
export function createEditorState(data) {
    let newData;
    try {
        newData = JSON.parse(data);
    } catch (error) {
        newData = `<p>${data}</p>`;
    }
    if (!newData) {
        // If data is undefined or falsy, return an empty editor state
        return EditorState.createEmpty();
    }
    let contentState;
    if (typeof newData === 'string') {
        // Check if data is a string
        try {
            const blocksFromHTML = stateFromHTML(newData);
            return EditorState.createWithContent(blocksFromHTML);
        } catch (error) {
            console.error('Error converting string to HTML:', error.message);
            // If there is an error, return an empty editor state
            return EditorState.createEmpty();
        }
    } else if (typeof newData === 'object' && newData.blocks && newData.entityMap) {
        // Check if data is in `convertFromRaw` format
        contentState = convertFromRaw(newData);
    } else if (typeof newData === 'object' && newData instanceof ContentState) {
        // Check if data is already a `ContentState` object
        contentState = newData;
    } else {
        try {
            // Try parsing data as JSON
            const parsedData = JSON.parse(newData);
            contentState = convertFromRaw(parsedData);
        } catch (error) {
            // If parsing as JSON fails or the data is not in a recognized format, return an empty editor state
            return EditorState.createEmpty();
        }
    }

    return EditorState.createWithContent(contentState);
}

// Function to process text blocks
export const processTextBlocks = (text, key) => {
    // Check if text is a non-empty string
    if (typeof text === 'string' && text.trim() !== '') {
        if (text.includes("blocks")) {
            try {
                const data = JSON.parse(text);

                // Validate that data is an object and has a 'blocks' property which is an array
                if (data && typeof data === 'object' && Array.isArray(data.blocks)) {
                    return data.blocks.reduce((acc, block) => {
                        // Ensure each block is an object and has a 'text' property which is a string
                        if (block && typeof block === 'object' && typeof block.text === 'string') {
                            return acc + block.text;
                        }
                        return acc;
                    }, '');
                }
            } catch (error) {
                console.log(`Error parsing ${key}:`, error);
            }
        } else {
            // Return the original text if it doesn't include "blocks"
            return text;
        }
    }
    // Return an empty string as a fallback
    return '';
};
export const textEditorFormat = (value) => {
    if (!value) {
        return null;
    }

    const rawContent = convertToRaw(value?.getCurrentContent());
    return JSON.stringify(rawContent, null, 4);
}
export function formatLabel(label) {
    // Split the label at each capital letter to create an array of words
    const words = label.match(/[A-Z][a-z]+|[a-z]+/g);

    // Capitalize the first letter of each word and join them with spaces
    return words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

