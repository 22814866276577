import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageUsersGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_USERS_QUERY} from "../../graphql/user";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

export default function ManageUsersComponent({inForm = false}) {
    const [userItems, setUserItems] = useState([]);
    //This will Replace the data with the data from the API
    const {t} = useTranslation();
    const {data, loading} = useQuery(REQUEST_USERS_QUERY);

    useEffect(() => {
        if (data?.requestUsers?.data) {
            setUserItems(data?.requestUsers?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.manageUsers.subTitle")}/>}
            <ManageUsersGrid
                list={userItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
