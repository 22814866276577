import {useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {REQUEST_INDUSTRIES_FORM_QUERY} from "../graphql/industry";

// Custom hook to deeply update items with industry names, considering the provided data structure
function useUpdatedItems(items) {
    const { data: industriesData } = useQuery(REQUEST_INDUSTRIES_FORM_QUERY);
    const [updatedItems, setUpdatedItems] = useState(items);

    useEffect(() => {
        // Create a lookup map for quick industry name lookup
        const industryLookup = new Map(industriesData?.requestIndustries?.data.map(ind => [ind.id, ind.name]));

        const updateIndustryNames = (obj) => {
            if (!obj || typeof obj !== 'object') return obj;
            if (Array.isArray(obj)) {
                return obj.map(updateIndustryNames);
            }
            // Specifically for industries array
            if (obj.industries) {
                return {
                    ...obj,
                    industries: obj.industries.map(industryItem => ({
                        ...industryItem,
                        name: industryLookup.get(industryItem.id) || industryItem.name,
                    }))
                };
            }
            // Recurse for other objects
            const updatedObj = {};
            for (const key of Object.keys(obj)) {
                updatedObj[key] = updateIndustryNames(obj[key]);
            }
            return updatedObj;
        };

        if (items.length > 0 && industryLookup.size > 0) {
            const newItems = items.map(updateIndustryNames);
            setUpdatedItems(newItems);
        } else {
            setUpdatedItems([]);
        }
    }, [items, industriesData]);

    return updatedItems;
}

export default useUpdatedItems;