import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewClientsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_CLIENTS_OF_USER_QUERY} from "../../graphql/client";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {useParams} from "react-router-dom";
import {t} from "i18next";
export default function ViewClientsComponent({inForm = false, userInfo}) {
    const [clientItems, setClientItems] = useState([]);
    //This will Replace the data with the data from the API
    const {userId} = useParams();
    const {user} = useContext(AppContext);
    const {data,loading} = useQuery(REQUEST_CLIENTS_OF_USER_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    },
                    user: {
                        id: userId
                    }
                }
            }
        });

    useEffect(() => {
        if (!loading && data?.requestClientsOfUser?.data) {
            setClientItems(data?.requestClientsOfUser?.data);
        }
    }, [data,loading]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewClients.subTitle")}/>}
            <ViewClientsGrid
                list={clientItems} inForm={inForm} loading={loading} userInfo={userInfo}
            />
        </Box>
    );
}
