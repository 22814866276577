import {useContext, useEffect} from "react";
import {useMutation} from "@apollo/client";
import {AUTH_KEY} from "../index";
import {AppContext} from "../App";
import {LOGOUT_MUTATION, REQUEST_LOGGED_USER_QUERY} from "../graphql/user";
import {useLocation, useNavigate} from "react-router-dom";
import popupAutoCLoseConfirmation from "../components/popUpAlerts/autoClose";


const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const AppLogout = () => {
    let timer;
    const navigate = useNavigate();
    const {user, setUser} = useContext(AppContext);
    const [logout] = useMutation(LOGOUT_MUTATION,{
        refetchQueries: [{query: REQUEST_LOGGED_USER_QUERY}],
            awaitRefetchQueries: true
    });
    const {pathname} = useLocation();
    const params = pathname === '/logout' ? '' : '?next=' + pathname;
    const handleLogOut = () => {
        logout().then(() => {
            setUser(null);
            localStorage.setItem(AUTH_KEY, null);
            navigate(`/login${params}`);
        });
    }
    useEffect(() => {
            Object.values(events).forEach((item) => {
                window.addEventListener(item, () => {
                    resetTimer();
                    handleTimer();
                });
            });
    }, []);

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    const handleTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, 18000000); // 5 hours in milliseconds
    };


    const logoutAction = () => {
        if (user && user?.userRole) {
            let parameters = {
                title: "Are you still there?",
                html: "You will be logged out in <b></b> seconds!",
                confirmButtonText: "I'm here!",
                timer: 15000
            }
            popupAutoCLoseConfirmation(parameters, handleLogOut)
        }
    };
    return <></>
};

export default AppLogout;