import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import GlobalDataGridPro from "../manage";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DELETE_USER_MUTATION, REQUEST_TEAM_MEMBER_USERS_QUERY, DISABLE_USER_MUTATION } from "../../graphql/user";
import { useMutation } from "@apollo/client";
import DeleteUser from "./Delete";
import { AppContext } from "../../App";
import dayjs from "dayjs";
import searchByString from "../../helpers/searchByString";
import getTeamMemberColumns from "../manage/globalColumns/teamMembers";
import { CircularProgress } from "@mui/material";
import Toast from '../alerts';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ShowDisabledItems from '../manage/utilis/quickFilters';

const AssignClients = lazy(() => import('../forms/assignClients'));
const ImportUser = lazy(() => import('./Import'));

export const ManageTeamMemberUsersGrid = (
    {
        list,
        inForm = false,
        loading,
        handleShowAll,
        showAll
    }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useContext(AppContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAssign, setIsOpenAssing] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState({});
    const [deleteUser] = useMutation(DELETE_USER_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_TEAM_MEMBER_USERS_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false,
                    }
                }
            }, {
                query: REQUEST_TEAM_MEMBER_USERS_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false,
                    }
                }
            }],
            awaitRefetchQueries: true,
        });
    const [disableTeamMember] = useMutation(DISABLE_USER_MUTATION,
        {
            refetchQueries: [{
                query: REQUEST_TEAM_MEMBER_USERS_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false,
                    }
                }
            }, {
                query: REQUEST_TEAM_MEMBER_USERS_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false,
                    }
                }
            }],
            awaitRefetchQueries: true,
        });

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleDelete = DeleteUser(deleteUser);
    const handleDeleteUser = (id) => {
        handleDelete(id);
    }

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const handleAdd = () => {
        navigate(`/agency/user/new_user_profile`)
    }
    const toogleImport = () => {
        setIsOpen(!isOpen)
    }
    const handleOpenAssing = (params) => {
        setSelectedTeamMember(params?.row)
        setIsOpenAssing(!isOpenAssign)
    }
    const handleDisable = (params) => {
        const toastId = 'handleDisableClient'
        Toast({
            autoClose: 7000,
            type: 'info',
            message: 'updating...',
            toastId: toastId
        });
        disableTeamMember({
            variables: {
                parameters: {
                    user: { id: params?.id, isDisabled: !params.isDisabled },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.userUpdated'),
                    toastId: toastId
                });
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0],
                    toastId: toastId
                });
            });
    }
    const CustomColumns = React.useMemo(
        () => [
            ...getTeamMemberColumns({ role: user?.userRole?.name, handleButtonClick: handleOpenAssing }),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={dayjs().valueOf()}
                        label={t('button.edit')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Edit-User"
                        icon={<EditIcon />}
                        onClick={() => {
                            navigate(`/agency/user/${params?.id}?update_user`)
                        }}
                        showInMenu={true} />,

                    <GridActionsCellItem
                        key={dayjs().valueOf()}
                        label={t('button.delete')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Delete-User"
                        icon={<DeleteForeverIcon />}
                        disabled={params.row?.id === user?.id}
                        onClick={() => {
                            handleDeleteUser(params.id)
                        }}
                        showInMenu={true} />,
                        <GridActionsCellItem
                        disabled={params?.id === user?.id}
                        key={params.id}
                        label={params?.row?.isDisabled ? t('button.activateItem') : t('button.disableItem')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-Disabled-client"
                        icon={params?.row?.isDisabled ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        onClick={() => {
                            handleDisable(params.row)
                        }}
                        showInMenu={true}
                    />,

                ]
            }
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Users",
        title: inForm ? "" : "Users",
        searchLabel: "Search User",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        autoHeight: false,
        handleAdd: handleAdd,
        customSubToolbar: <ShowDisabledItems toggleShowAll={handleShowAll} showAll={showAll} label={'button.showDisabledTeamMembers'} />,
        handleImport: toogleImport,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm} />
            {isOpen && (
                <Suspense fallback={<CircularProgress />}>
                    <ImportUser isOpen={isOpen} handleClose={toogleImport} />
                </Suspense>
            )}
            {isOpenAssign && (
                <Suspense fallback={<CircularProgress />}>
                    <AssignClients isOpen={isOpenAssign} handleClose={handleOpenAssing} teamMember={selectedTeamMember} />
                </Suspense>
            )}
        </>

    );
};