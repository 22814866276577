
export const EventFiltersOptions = [
    {
        title: "manage.manageEvents.filters.eventRange",
        fieldName: "eventDate",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEvents.filters.deadline",
        fieldName: "deadline",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEvents.filters.industries",
        fieldName: "industries",
        isGlobal: true,
        type: 'multiSelect',
        defaultValue: []
    },
    {
        title: "manage.manageEvents.filters.rollingDeadline",
        fieldName: "rollingDeadline",
        type: 'checkbox',
        defaultValue: false
    },
    {
        title: "manage.manageEvents.filters.directContact",
        fieldName: "directContact",
        type: 'checkbox',
        defaultValue: false
    }

]