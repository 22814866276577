import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {industryFormValidations} from "./helpers";
import {useMutation} from "@apollo/client";
import {IMPORT_INDUSTRIES_MUTATION, REQUEST_INDUSTRIES_QUERY} from "../../../graphql/industry";
import Toast from "../../alerts";
import {AppContext} from "../../../App";
import {useContext, useEffect} from "react";
import {toast} from "react-toastify";
import ImportComponent from "../../import";
import getSpreadsheetId from 'src/helpers/getSpreadsheetId';

const ImportIndustry = ({isOpen, handleClose}) => {
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const {control, reset, clearErrors, handleSubmit, formState: {errors, isValid}} = useForm({
        mode: 'onTouched',
        resolver: yupResolver(industryFormValidations(t)),
    });

    useEffect(() => {
        reset({'documentId': ''});
        clearErrors()
        // eslint-disable-next-line
    }, [isOpen])
    const [importIndustries] = useMutation(IMPORT_INDUSTRIES_MUTATION,
        {
            refetchQueries: [{query: REQUEST_INDUSTRIES_QUERY}],
            awaitRefetchQueries: true
        });
    const onSubmit = (data) => {
        const newData = {...data}
        newData.documentId = getSpreadsheetId(data.documentId);
        Toast({
            type: 'success',
            message: t('successMessage.importProcessStarted'),
            autoClose: 10000
        });
        importIndustries({
            variables: {
                parameters: {
                    industry: newData,
                    user: {emailAddress: user?.emailAddress}
                },
            },
        })
            .then(() => {
                toast.dismiss(t('successMessage.importProcessStarted'));
                Toast({
                    type: 'success',
                    message: t('successMessage.importProcessCompleted'),
                });
                handleClose()
                reset()
            })
            .catch((error) => {
                toast.dismiss(t('successMessage.importProcessStarted'));
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                let message = errorMessage[0]
                if (message && message.includes('Google API error - [404]')) {
                    message = "Invalid google document id"
                }
                Toast({
                    type: 'error',
                    message: message
                });
            });
    };
    const importTemplateURL = process.env.REACT_APP_IMPORT_INDUSTRIES || ""
    return (<ImportComponent isOpen={isOpen} handleClose={handleClose} handleSubmit={handleSubmit}
                             onSubmit={onSubmit} control={control} errors={errors} isValid={isValid}
                             title={"headers.manageIndustries.import"}
                             subtitle={"headers.manageIndustries.importSubtitle"}
                             importTemplateURL={importTemplateURL}
        />
    );
}
export default ImportIndustry
