import React, {useState, useEffect} from 'react';
import GlobalDataGridPro from "../manage";
import UserColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";

export const ViewTeamMemberUsersGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...UserColumns
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Users",
        title: inForm ? "" : "Users",
        searchLabel: "Search User",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,


        handleSearch: handleSearch,

        searchTerm: searchTerm,
        loading: loading
    }

    return (
        <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
    );
};