import { useQuery } from '@apollo/client';
import {useState, useEffect} from 'react';
import { REQUEST_SUBMISSION_QUERY } from 'src/graphql/submission';
import { createEditorState } from 'src/helpers/formatters';

const useRequestSubmissionQuery = (parameters) => {
    const [submissionData, setSubmissionData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {data, loading,error, refetch} = useQuery(REQUEST_SUBMISSION_QUERY, {
        variables: {
            parameters: {...parameters},
        }
    });

    useEffect(() => {
        if (!loading && data?.requestSubmission?.data) {
            let obj = {...data?.requestSubmission?.data}
            const newObj = {
                ...obj,
                notes: createEditorState(obj?.notes),
                customSections: obj?.customSections?.map((section) => {
                    return {
                        ...section,
                        value: createEditorState(section?.value)
                    }
                })
            }
            setSubmissionData(newObj);
            setIsLoading(false);
        }else if(!loading && !data?.requestSubmission?.data){
            setIsLoading(false);
        }
    }, [data, loading]);

    return {data: submissionData, loading: isLoading, error, refetch};
}
export default useRequestSubmissionQuery;