import React from 'react';
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";



const ModalHeader = (parameters) => {
    const { title, subtitle,children,isValid,handleClose,onSubmit,submitButtonName } = parameters;
    const {t} = useTranslation();
    return (
        <>
            <Typography align="left" variant="formHeader" id="df5d5a6b-0f21-45d6-aab6-c17e5211ae92-modal-header" color="white">
                {t(title)}
            </Typography>
            <Typography align="left" variant="subtitle2" color="white">
                {t(subtitle)}
            </Typography>
            <Divider sx={{width: '100%',mt:1}}/>
            {children}
            <Divider sx={{width: '100%',mb:2}}/>
            <Box
                sx={{display: 'flex', justifyContent: 'end', width:'100%'}}
            >
                <Button
                    variant="outlined"
                    sx={{ borderRadius: '12px'}}
                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-cancel"
                    onClick={handleClose}
                >
                    {t('button.cancelCAP')}
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                    sx={{ color: '#FFFFFF', borderRadius: '12px', ml:1 }}
                    disabled={!isValid}
                    id="8d28b6df-5ec7-4a9a-a333-c91aca02f483-import"
                >
                    {submitButtonName ? submitButtonName : t('button.importCAP')}
                </Button>
            </Box>
        </>
    )
}
export default ModalHeader;