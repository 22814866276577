import {gql} from "@apollo/client";


export const CREATE_TEAM_MEMBER_USER_MUTATION = gql`
    mutation CreateTeamMemberUser($parameters: CreateTeamMemberUserInput) {
        createTeamMemberUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                isAdmin

                zipCode

                lastName

                phoneNumber

                createdAt

                password

                username

                updatedAt

                deleted

                subscriber {
                    id
                }

                state

                fullName

                headshot

                city
                emailAddress

                id

                avatar

                isActive
                userRole {
                    name
                    documentId
                    id
                    label
                }
                address
                firstName
            }
        }
    }
`;

export const CREATE_USER_MUTATION = gql`
    mutation CreateUser($parameters: CreateUserInput) {
        createUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                isAdmin

                zipCode

                lastName

                phoneNumber

                createdAt

                password

                username

                updatedAt

                deleted

                subscriber {
                    id
                }
                isLocked

                state

                fullName

                headshot

                city

                emailAddress

                id

                avatar

                isActive

                userRole {
                    name
                    documentId
                    id
                    label
                }
                address

                firstName

            }
        }
    }
`;

export const DELETE_USER_MUTATION = gql`
    mutation DeleteUser($parameters: DeleteUserInput) {
        deleteUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;
export const DISABLE_USER_MUTATION = gql`
    mutation disableUser($parameters: UpdateUserInput) {
        disableUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const IMPORT_CLIENT_USERS_MUTATION = gql`
    mutation ImportClientUsers($parameters: ImportClientUsersInput) {
        importClientUsers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                fullName
                state
                city
                phoneNumber
                address
                id
                emailAddress
                zipCode
                lastName
                firstName

            }
        }
    }
`;

export const IMPORT_TEAM_MEMBER_USERS_MUTATION = gql`
    mutation ImportTeamMemberUsers($parameters: ImportTeamMemberUsersInput) {
        importTeamMemberUsers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                zipCode
                address
                lastName
                city
                phoneNumber
                emailAddress
                state
                id
                firstName
                fullName
            }
        }
    }
`;

export const IMPORT_USERS_MUTATION = gql`
    mutation ImportUsers($parameters: ImportUsersInput) {
        importUsers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                firstName
                zipCode
                fullName
                lastName
                id
                city
                phoneNumber
                emailAddress
                state
                address

            }
        }
    }
`;

export const REQUEST_CLIENT_USERS_QUERY = gql`
    query RequestClientUsers($parameters: RequestClientUsersInput) {
        requestClientUsers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                emailAddress
                subscriber {
                    deleted
                    id
                    documentId
                    city
                    updatedAt
                    name
                    phoneNumber
                    address
                    createdAt
                    eventSummaries
                    zipCode
                    logo
                    state
                }
                userRole {
                    name
                }
                zipCode
                isAdmin
                client {
                    phoneNumber
                    emailAddress
                    deleted
                    state
                    createdAt
                    avatar
                    keywords
                    name
                    subscriberId
                    id
                    headshot
                    zipCode
                    documentId
                    updatedAt
                    address
                    city
                }
                id
                state
                firstName
                phoneNumber
                fullName
                city
                lastName
                address

            }
        }
    }
`;

export const REQUEST_LOGGED_USER_QUERY = gql`
    query RequestLoggedUser($parameters: RequestLoggedUserInput) {
        requestLoggedUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                subscriber {
                    id
                    name
                }
                isAdmin
                lastName
                client {
                    name
                    id
                }
                userRole {
                    name
                    documentId
                    id
                    label
                }
                firstName
                id
                fullName
                emailAddress
            }
        }
    }
`;

export const REQUEST_TEAM_MEMBER_USERS_QUERY = gql`
    query RequestTeamMemberUsers($parameters: RequestTeamMemberUsersInput) {
        requestTeamMemberUsers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                subscriber {
                    id
                }
                isAdmin
                assignedClients
                phoneNumber
                emailAddress
                id
                firstName
                fullName
                lastName
                submissionInProcess
                accepted,
                isDisabled
            }
        }
    }
`;

export const REQUEST_TEAM_MEMBER_USERS_OF_CLIENT_QUERY = gql`
    query RequestTeamMemberUsersOfClient($parameters: RequestTeamMemberUsersOfClientInput) {
        requestTeamMemberUsersOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                subscriber {
                    id
                }
                client {
                    id
                }
                phoneNumber
                emailAddress
                id
                fullName
                isPrimaryContact
            }
        }
    }
`;

export const REQUEST_USER_QUERY = gql`
    query RequestUser($parameters: RequestUserInput) {
        requestUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isDisabled
                headshot
                subscriber {
                    id
                    name
                }
                isAdmin
                address
                client {
                    name
                    id
                }
                city
                phoneNumber
                state
                zipCode
                id
                fullName
                firstName
                emailAddress
                userRole {
                    name
                    documentId
                    id
                    label
                }
                lastName
            }
        }
    }
`;

export const REQUEST_USERS_QUERY = gql`
    query RequestUsers {
        requestUsers {
            errors {
                message
            }
            statusCode
            data {
                subscriber {
                    id
                }
                isAdmin
                firstName
                client {
                    id
                }
                lastName
                id
                phoneNumber
                fullName
                emailAddress
            }
        }
    }
`;

export const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($parameters: UpdateUserInput) {
        updateUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                phoneNumber
                state
                zipCode
                city
                emailAddress
                address
                fullName
                id
                firstName
                lastName

            }
        }
    }
`;
export const FORGET_PASSWORD_MUTATION = gql`
    mutation ForgetPassword($parameters: ForgetPasswordOfUserInput) {
        forgetPassword(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                headshot
                isAdmin

                zipCode

                lastName

                phoneNumber

                createdAt

                password

                username

                updatedAt

                deleted

                subscriber {
                    id
                    name
                }
                isLocked

                state

                fullName

                headshot

                client {
                    name
                    id
                }
                city

                emailAddress

                id

                avatar

                isActive

                userRole {
                    name
                    documentId
                    id
                    label
                }
                address

                firstName

            }
        }
    }
`;
export const CHANGE_PASSWORD_OF_USER_MUTATION = gql`
    mutation ChangePasswordOfUser($parameters: ChangePasswordOfUserInput) {
        changePasswordOfUser(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                isAdmin

                zipCode

                lastName

                phoneNumber

                createdAt

                password

                username

                deleted

                isLocked

                state

                fullName

                city

                emailAddress

                id

                userRole {
                    name
                    documentId
                    id
                    label
                }
                address

                firstName
            }
        }
    }
`;
export const LOGOUT_MUTATION = gql`
    mutation Logout {
        logout
    }
`;