import {useMutation} from "@apollo/client";
import {RECORD_LOCKING_SUBMISSION_MUTATION} from "../graphql/submission";
import {useEffect} from "react";
import Toast from "../components/alerts";

const useSubmissionRecordLocking = (submissionId, user, setIsEditing) => {
    const [recordLocking] = useMutation(RECORD_LOCKING_SUBMISSION_MUTATION);

    const lockRecord = (updating) => {
        recordLocking({
            variables: {
                parameters: {
                    submission: {id: submissionId},
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        fullName: user?.fullName,
                    },
                    updating: updating,
                },
            },
        })
            .then((res) => {
                const resData = res?.data?.updateRecordLockingStatusOfSubmission?.data;
                if (resData?.updating && resData?.updatingBy.id !== user?.id) {
                    setIsEditing(resData.updating);
                }
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                if(errorMessage[0] === "Record is locked by another user"){
                    setIsEditing(true)
                }
            });
    };

    // Effect for handling component mount and unmount
    useEffect(() => {
        lockRecord(true);
        return () => {
            lockRecord(false);
        };
    }, []);
    useEffect(() => {
        window.addEventListener('beforeunload', ()=>lockRecord(false));
        return () => {
            window.removeEventListener('beforeunload', ()=>lockRecord(false));
        };
    }, []);
};
export default useSubmissionRecordLocking;
