import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewAwardsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_APPROVED_AWARDS_QUERY} from "../../graphql/award";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {REQUEST_INTERESTED_CLIENTS_QUERY} from "../../graphql/interestedClient";
import {t} from "i18next";
import useUpdatedItems from "../../hooks/useUpdateIndustries";
export default function ViewAwardsComponent({inForm = false}) {
    const [awardItems, setAwardItems] = useState([]);
    //This will Replace the data with the data from the API
    const [interestedClients, setInterestedClients] = useState([]);
    const {user} = useContext(AppContext);
    const {data, loading: loadingAwards} = useQuery(REQUEST_APPROVED_AWARDS_QUERY, {
        variables: {
            parameters: {
                showAllItems: false,
                showOnlyDisabledItems: false
            }
        }
    });
    useEffect(() => {
        if (data?.requestApprovedAwards?.data) {
            setAwardItems(data?.requestApprovedAwards?.data);
        }
    }, [data]);
    const {data: interestedClientData, loading, refetch} = useQuery(REQUEST_INTERESTED_CLIENTS_QUERY, {
        variables: {
            parameters: {
                interestedClient: {
                    recognition: {
                        award: {}
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        }
    });
    useEffect(() => {
        if (interestedClientData?.requestInterestedClients?.data.length > 0) {
            setInterestedClients(interestedClientData?.requestInterestedClients?.data)
        }
    }, [interestedClientData, loading])
    const updatedItems = useUpdatedItems(awardItems);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewAwards.subTitle")}/>}
            <ViewAwardsGrid
                list={updatedItems} inForm={inForm} interestedClients={interestedClients}
                refetch={refetch} loading={loadingAwards}
            />
        </Box>
    );
}
