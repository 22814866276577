import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useQuery} from "@apollo/client";

import {AppContext} from "../../../App";
import {REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY} from "../../../graphql/client";
import {REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY} from "../../../graphql/interestedClient";
import ManageUnassignedInterestedClientsOfAwardComponent from '../../manageUnassignedInterestedClientsOfAward';
import ManageInterestedClientsOfAwardComponent from '../../manageInterestedClientsOfAward';
import AssignModal from "../../assignModal";

const AssignInterestedClients = ({isOpen, handleClose, award}) => {
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const awardId = award?.id || '';
    const awardName = award?.name|| '';

    const [clientItems, setClientItems] = useState([]);
    const [clientInterestedItems, setClientInterestedItems] = useState([]);
    const [showAll, setShowAll] = useState(true);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    }
    const {
        data: dataUnassignedClients,
        loading: loadingUnassignedClients
    } = useQuery(REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                parameters: {
                    award: {
                        id: awardId
                    },
                    teamMember: {
                        id: !showAll ? user?.id : null
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });
    const {data, loading} = useQuery(REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY,
        {
            variables: {
                parameters: {
                    award: {
                        id: awardId
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });

    useEffect(() => {
        if (!loadingUnassignedClients && dataUnassignedClients?.requestUnassignedClientsOfAward?.data) {
            setClientItems(dataUnassignedClients?.requestUnassignedClientsOfAward?.data);
        }
    }, [dataUnassignedClients, loadingUnassignedClients]);
    useEffect(() => {
        if (!loading && data?.requestInterestedClientsOfAward?.data) {
            setClientInterestedItems(data?.requestInterestedClientsOfAward?.data);
        }
    }, [data, loading]);
    useEffect(() => {
        if (user?.userRole && user?.userRole?.name !== "Subscriber Administrator") {
            setShowAll(false)
        }
    }, [user]);
    return (
        <AssignModal
            isOpen={isOpen}
            handleClose={handleClose}
            title={t('headers.viewAwards.assignInterestedClients') + ' ' + awardName}
            subtitle={t('headers.viewAwards.subTitleInterested')}
        >

            <Grid item xs={6}>
                <ManageUnassignedInterestedClientsOfAwardComponent
                    list={clientItems}
                    showAll={showAll}
                    toggleShowAll={toggleShowAll}
                    award={award}
                    loading={loadingUnassignedClients}
                />
            </Grid>

            <Grid item xs={6}>
                <ManageInterestedClientsOfAwardComponent
                    list={clientInterestedItems}
                    showAll={showAll}
                    award={award}
                    loading={loading}
                />
            </Grid>
        </AssignModal>
    );
};

export default AssignInterestedClients;
