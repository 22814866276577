import FormContainer from "../../formContainer";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { REQUEST_INDUSTRIES_FORM_QUERY } from "../../../graphql/industry";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import searchByString from "../../../helpers/searchByString";
import EllipsisTooltip from "./utilis";

const IndustriesListComponent = ({ disabled }) => {
    const [list, setList] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    //This will Replace the data with the data from the API
    const { data } = useQuery(REQUEST_INDUSTRIES_FORM_QUERY);
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext();
    const checkedIndustries = watch('industries') || [];
    useEffect(() => {
        if (!disabled && data?.requestIndustries?.data) {
            setList(data?.requestIndustries?.data);
            setSearchResults(data?.requestIndustries?.data);
        }
    }, [data]);
    useEffect(() => {
        if (disabled) {
            // Create a map for quick lookup
            const industryMap = new Map(data?.requestIndustries?.data?.map(item => [item.id, item]));

            // Update checkedIndustries with names from the database
            const updatedIndustries = checkedIndustries.map(industry => {
                const dbIndustry = industryMap.get(industry.id);
                return dbIndustry ? { ...industry, name: dbIndustry.name } : industry;
            });

            // Functional state updates for list and searchResults
            setList(prevList => updatedIndustries);
            setSearchResults(prevResults => updatedIndustries);
        }
    }, [disabled, checkedIndustries, data?.requestIndustries?.data]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const searchParams = {
        handleSearch: handleSearch,
        searchLabel: 'Search Industry'
    }
    const handleCheckboxChange = (selectedIndustries) => {
        setValue('industries', selectedIndustries, { shouldDirty: true });
    };
    return (
        <FormContainer title={t('section.industries')} to={'industries'} hasSearch={true} searchParams={searchParams}>

            <Grid container spacing={2} sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                <Grid item xs={12}>
                    <Controller
                        name="industries"
                        control={control}
                        render={({ field }) => (
                            searchResults.length ?
                                searchResults?.map((item) => {
                                    return (
                                        <FormControlLabel
                                            disabled={disabled}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    sx={{
                                                        color: disabled && '#EE814D !important'
                                                    }}
                                                    value={item?.name}
                                                    checked={checkedIndustries?.some(obj => obj?.id === item?.id)}
                                                    onChange={(e) => {
                                                        const selectedIndustries = e?.target?.checked
                                                            ? [...checkedIndustries, item]
                                                            : checkedIndustries?.filter((value) => value?.id !== item.id);
                                                        handleCheckboxChange(selectedIndustries);
                                                    }}
                                                />
                                            }
                                            label={ <EllipsisTooltip text={item?.name} disabled={disabled} />}
                                            key={item?.id}
                                            sx={{
                                                m: 1,
                                            }}
                                        />
                                    )
                                })
                                :
                                <Typography
                                    sx={{
                                        mx: 1,
                                    }}
                                    noWrap
                                    component="div"
                                >
                                    No selected industries
                                </Typography>
                        )}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    )
}
export default IndustriesListComponent