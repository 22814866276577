import {gql} from "@apollo/client";


export const CREATE_SUBSCRIBER_MUTATION = gql`
    mutation CreateSubscriber($parameters: CreateSubscriberInput) {
        createSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                website
                emailAddress
                phoneNumber
                id
                logo
                city
                updatedAt
                name
                ownerUser {
                    lastName
                    fullName
                    emailAddress
                    id
                    firstName
                }
                phoneNumber

                address

                createdAt

                zipCode
                
                state

            }
        }
    }
`;

export const DELETE_SUBSCRIBER_MUTATION = gql`
    mutation DeleteSubscriber($parameters: DeleteSubscriberInput) {
        deleteSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id

            }
        }
    }
`;

export const IMPORT_SUBSCRIBERS_MUTATION = gql`
    mutation ImportSubscribers($parameters: ImportSubscribersInput) {
        importSubscribers(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                gracePeriod
                website
                emailAddress
                logo
                phoneNumber
                city
                state
                zipCode
                address
                name
                ownerUser {
                    isAdmin
                    zipCode
                    lastName
                    documentId
                    phoneNumber
                    createdAt
                    password
                    username
                    updatedAt
                    deleted
                    isLocked
                    state
                    fullName
                    headshot
                    city
                    emailAddress
                    id
                    avatar
                    isActive
                    address
                    firstName
                }
                id

            }
        }
    }
`;

export const REQUEST_SUBSCRIBER_QUERY = gql`
    query RequestSubscriber($parameters: RequestSubscriberInput) {
        requestSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                gracePeriod
                website
                emailAddress
                logo
                state
                city
                phoneNumber
                name
                ownerUser {
                    lastName
                    fullName
                    id
                    firstName
                }
                zipCode
                id
                address

            }
        }
    }
`;

export const REQUEST_SUBSCRIBERS_QUERY = gql`
    query RequestSubscribers {
        requestSubscribers {
            errors {
                message
            }
            statusCode
            data {
                id
                city
                name
                phoneNumber
                address
                state
                zipCode

            }
        }
    }
`;

export const update_agency_MUTATION = gql`
    mutation UpdateSubscriber($parameters: UpdateSubscriberInput) {
        updateSubscriber(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                gracePeriod
                website
                emailAddress
                logo
                name
                address
                state
                id
                city
                zipCode
                phoneNumber
                ownerUser {
                    lastName
                    fullName
                    id
                    firstName
                }

            }
        }
    }
`;
