import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import EventIcon from '@mui/icons-material/Event';
import { IconButton } from "@mui/material";

const DatePickerController = ({
                                  control,
                                  error,
                                  name,
                                  openTo = 'year',
                                  label = 'Deadline',
                                  views = ["year", "month", "day"],
                                  componentSx,
                                  ...rest
                              }) => {
    const formatDate = (date) => {
        return dayjs(date).format('MM/DD/YYYY');
    };

    // Maintain the open state of the DatePicker
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, ...fieldProps } }) => (
                <DatePicker
                    views={views}
                    label={label}
                    openTo={openTo}
                    closeOnSelect={false}
                    disabled={rest?.disabled}
                    value={value ? dayjs(value) : null}
                    onChange={(date) => {
                        const formattedDate = date ? formatDate(date) : null;
                        onChange(formattedDate);
                    }}
                    open={isOpen}  // Control the open state
                    onClose={() => setIsOpen(false)}  // Close the picker
                    {...fieldProps}
                    sx={{
                        ...componentSx
                    }}
                    slotProps={{
                        textField: {
                            ...rest,
                            error: !!error,
                            fullWidth: true,
                            helperText: error?.message || ' ',
                            readOnly: rest?.disabled,
                            InputProps: {
                                endAdornment: (
                                    <IconButton
                                        disabled={rest?.disabled}
                                        onClick={() => setIsOpen(true)}  // Open the picker on click
                                    >
                                        <EventIcon />
                                    </IconButton>
                                ),
                            },
                            ...fieldProps
                        }
                    }}
                />
            )}
        />
    );
};

export default DatePickerController;
