import LoginComponent from "../../components/login";
import ChangePasswordOfUser from "../../components/changePasswordOfUser";
import React, {useEffect} from "react";

const Login = ({isChangePassword=false}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    useEffect(() => {
        if (isChangePassword) {
            setIsOpen(true)
        }
    }, [isChangePassword])
    return <>
        {isChangePassword && <ChangePasswordOfUser isOpen={isOpen} handleClose={toggle}/>}
        <LoginComponent/>
    </>
}
export default Login;