import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {FormProvider, useFieldArray} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {useLocation, useParams} from 'react-router-dom';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';

import {SubmissionForm} from '../../forms';
import Toast from '../../alerts';
import FormWrapperComponent from '../../pageHeader/PageHeaderForm';
import {ActionModal, SubmissionHeader} from '../../pageHeader/SubmissionPageHeader';
import {useManagedForm} from '../../../helpers/hooks/useManageForm';
import {createEditorState, textEditorFormat} from '../../../helpers/formatters';

import {
    REQUEST_TASKS_ORDER_SUBMISSION_QUERY, UPDATE_SUBMISSION_MUTATION
} from '../../../graphql/submission';
import {REQUEST_TASKS_OF_SUBMISSION_QUERY} from '../../../graphql/task';
import {submissionFormValidations} from './helpers';

import {AppContext} from '../../../App';
import useSubmissionRecordLocking from "../../../helpers/recordLocking";
import {REQUEST_HISTORIES_QUERY} from "../../../graphql/history";
import useRequestSubmissionQuery from 'src/hooks/useRequestSubmissionQuery';
// Lazy load the modal
const ReadyToMoveModal = lazy(() => import('../../modal/moveToNextStatus/moveToNextState'));
const HistoryForm = lazy(() => import('../../forms/history'));

export const EditSubmission = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const {submissionId} = useParams();
    const {
        user,
        setNavItems,
        setIsAddingSection,
        setIsNavLoading,
        viewHistory,
        setTriggerFunction
    } = useContext(AppContext);

    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isOpenWizard, setIsOpenWizard] = useState(false);
    const [wizardType, setWizardType] = useState('');
    const [selectedStatus, setSelectedStatus] = useState("");
    const [approved, setApproved] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [unArchived, setUnArchived] = useState(false);

    // Queries
    // Queries
    const {data: submissionData, refetch} = useRequestSubmissionQuery({
        submission: {
            id: submissionId,
        },
        user: {
            id: user?.id,
            fullName: user?.fullName,
            firstName: user?.firstName,
            lastName: user?.lastName,
        }
    });
    useEffect(() => {
        // Perform the refetch or other actions when the location changes
        refetch();
    }, [location, viewHistory]); // Only re-run the effect if the location changes

    useSubmissionRecordLocking(submissionId, user, setIsEditing);
    // Effects

    useEffect(() => {
        if (submissionData?.customSections?.length) {
            const customSections = submissionData?.customSections;

            // Adding a 'type' key to each item and updating state
            const newNavItems = customSections?.map(item => ({
                title: item.name,
                description: item.name,
                type: 'scroll',
                href: item.id,
            }));
            setNavItems(newNavItems);
            setIsNavLoading(false);
        } else {
            setNavItems([]); // Set to empty array if no custom sections
            setIsNavLoading(false);
        }
    }, [submissionData]); // Re-run effect when submissionData changes

    useEffect(() => {
        if (submissionData?.readyToMove) {
            setSelectedStatus(submissionData?.status?.name);
            setWizardType('')
            setIsOpenWizard(true);
        }
    }, [submissionData?.readyToMove]);

    // Form management
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(submissionFormValidations(t)),
        values: submissionData
    });
    const {control, trigger, reset, formState: {isValid}, getValues} = methods;

    const {fields, append, remove, update} = useFieldArray({
        control,
        name: "customSections",
        keyName: "newId"
    });

    useEffect(() => {
        const addCustomSection = () => {
            append({name: '', value: createEditorState('')})
            trigger('customSections');
        };
        setTriggerFunction(() => addCustomSection);
        return () => setTriggerFunction(() => {
        });
    }, [setTriggerFunction]);
    const [updateSubmission] = useMutation(UPDATE_SUBMISSION_MUTATION, {
        refetchQueries: [{
            query: REQUEST_TASKS_OF_SUBMISSION_QUERY,
            variables: {
                parameters: {
                    submission: {
                        id: submissionId
                    }
                }
            }
        },
            {
                query: REQUEST_HISTORIES_QUERY,
                variables: {
                    parameters: {
                        history: {
                            recordId: submissionId
                        }
                    }
                }
            },
            {
                query: REQUEST_TASKS_ORDER_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id: submissionId
                        }
                    }
                }
            }],
        awaitRefetchQueries: true,
    });

    // Handlers
    const cancelChanges = () => {
        reset(submissionData)
        setIsAddingSection(false);
    }
    const handleSubmit = ({updateStatus = false, newReason, newApproval, lastStatus, lastApproval}) => {
        if (!isValid) {
            return;
        }
        let data = getValues();
        data.notes = textEditorFormat(data?.notes)
        data.customSections = data?.customSections?.map((section) => {
            return {
                ...section,
                value: textEditorFormat(section?.value)
            }
        })
        let params = {
            submission: data,
            user: {
                firstName: user?.firstName,
                fullName: user?.fullName,
                id: user?.id,
                lastName: user?.lastName,
            },
        }

        if (updateStatus) {
            params.submission.status.reasons = newReason;
            params.submission.approval = newApproval;
        }
        if (lastStatus && lastApproval) {
            params.submission.lastStatus = lastStatus;
            params.submission.lastApproval = lastApproval;
        }
        setIsAddingSection(false);
        updateSubmission({
            variables: {
                parameters: {
                    ...params
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.submissionUpdated')
                });

                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    const handleClickOpen = ({status, approved, rejected, unArchived}) => {
        if (unArchived) {
            setWizardType('unarchived');
            setIsOpenWizard(true);
        } else {
            setSelectedStatus(status);
            setApproved(approved);
            setRejected(rejected);
            setUnArchived(unArchived);
            setIsOpen(true);
        }
    };
    const handleClose = () => {
        setSelectedStatus("");
        setApproved(false);
        setRejected(false);
        setUnArchived(false);
        setIsOpen(false);
    };
    const handleCloseWizard = () => {
        setSelectedStatus("");
        setWizardType('')
        setIsOpenWizard(false);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form onBlur={() => {
                        trigger()
                    }}>
                        <FormWrapperComponent
                            submitFn={isValid ? handleSubmit : null}
                            title={submissionData?.name || 'Submission'}
                            cancelFn={cancelChanges}
                            hasCustomHeader
                            customHeader={SubmissionHeader({
                                data: submissionData,
                                openActionModal: handleClickOpen,
                                user,
                                disabled: isEditing,
                            })}
                            disableBarSave={viewHistory}
                        >
                            {viewHistory ? <>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <HistoryForm submissionId={submissionData?.id}/>
                                    </Suspense>
                                </> :
                                <SubmissionForm
                                    event={true}
                                    submissionData={submissionData}
                                    handleSubmit={handleSubmit}
                                    readOnly={isEditing}
                                    fields={fields}
                                    remove={remove}
                                    update={update}
                                    refetch={refetch}
                                />
                            }
                            <ActionModal
                                isOpen={isOpen}
                                handleClose={handleClose}
                                statusName={selectedStatus}
                                handleSubmit={handleSubmit}
                                submissionData={submissionData}
                                approved={approved}
                                rejected={rejected}
                                unArchived={unArchived}
                            />
                            {isOpenWizard && !isEditing && (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ReadyToMoveModal
                                        isOpen={isOpenWizard}
                                        handleClose={handleCloseWizard}
                                        statusName={selectedStatus}
                                        handleSubmit={handleSubmit}
                                        currentStatus={submissionData?.status?.name}
                                        type={wizardType}
                                        lastStatus={submissionData?.lastStatus?.name}
                                    />
                                </Suspense>
                            )}
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};