import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageEventsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_EVENTS_QUERY} from "../../graphql/event";
import {useQuery} from "@apollo/client";
import useUpdatedItems from "../../hooks/useUpdateIndustries";

export default function ManageEventsComponent({inForm = false}) {
    const [eventItems, setEventItems] = useState([]);
    const [showAllItems, setShowAllItems] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {data, loading} = useQuery(REQUEST_EVENTS_QUERY, {
        variables: {
            parameters: {
                showAllItems: showAllItems,
                showOnlyDisabledItems: false
            }
        }
    });
    const handleShowAllItems = (e) => {
        const value = e.target.checked;
        setShowAllItems(value);
    };

    useEffect(() => {
        if (!loading && data?.requestEvents?.data) {
            setEventItems(data?.requestEvents?.data);
           setIsLoading(false); 
        }else if(!loading && !data?.requestEvents?.data){
            setIsLoading(false) 
        }
    }, [data,loading]);
    const updatedItems = useUpdatedItems(eventItems);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader/>}
            <ManageEventsGrid
                list={updatedItems}
                inForm={inForm}
                loading={isLoading}
                handleShowAllItems={handleShowAllItems}
                showAllItems={showAllItems}
            />
        </Box>
    );
}
