import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function GlobalDataGridProComponent(
    {
        id,
        rows,
        columns = [],
        getDetailPanelHeight = null,
        getDetailPanelContent = null,
        loading = true,
        autoHeight = false,
        checkboxSelection = false,
        rowThreshold = 0,
        initialState = {},
        pageSize = 6,
        onPageSizeChange = null,
        rowsPerPageOptions = [],
        pagination = false,
        density = 'standard',
        componentsProps = {},
        disableSelectionOnClick = true,
        disableCellSelection = true,
        rowHeight = 45,
        showCellRightBorder = true,
        rowLength = 16,
        inForm,
        rowReordering = false,
        onRowOrderChange,
        setSelectionModel = () => {
        },
        selectionModel,
        filterModel = {  items: []},
        onFilterModelChange
    }
) {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if(!loading) {
            setTimeout(() => {
                setIsLoading(loading);
            }, 500);
        }else{
            setIsLoading(loading);
        }
    }, [loading]);
    return (
        <Box sx={{
            height: inForm ? '472px' : {
                xs: 'calc(100% - 20px)'
            }, width: '100%',
            scrollBehavior: 'smooth',
        }}>
            <DataGridPro
                sx={{
                    border: 0,
                    ".MuiTablePagination-displayedRows": {margin: 0},
                    ".MuiTablePagination-selectLabel": {margin: 0},
                    '& .MuiDataGrid-cell:focus': {outline: 'none'}
                }}
                rowLength={rowLength}
                id={id ? `${id}-grid` : ''}
                autoHeight={autoHeight}
                disableSelectionOnClick={disableSelectionOnClick}
                disableCellSelection={disableCellSelection}
                checkboxSelection={checkboxSelection}
                rows={rows}
                columns={columns}
                rowThreshold={rowThreshold}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                loading={isLoading}
                initialState={initialState}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                rowsPerPageOptions={rowsPerPageOptions}
                pagination={pagination}
                density={density}
                componentsProps={componentsProps}
                rowHeight={rowHeight}
                showCellRightBorder={showCellRightBorder}
                rowReordering={rowReordering}
                onRowOrderChange={onRowOrderChange}
                onSelectionModelChange={(newRowSelectionModel) => {
                    setSelectionModel(newRowSelectionModel[0]);
                }}
                selectionModel={selectionModel}
                filterModel={filterModel}
                onFilterModelChange={onFilterModelChange}
            />
        </Box>
    );
}
