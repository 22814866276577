import * as yup from "yup";
import regex from "../../../../helpers/regex";
export const eventFormValidations = (t) => {
    return yup.object().shape({
name: yup.string().required(t('errorMessage.nameRequired')),
website: yup.string().required(t('errorMessage.websiteRequired')),
startDate: yup.string().required(t('errorMessage.startDateRequired')),
endDate: yup.string().required(t('errorMessage.endDateRequired')),
programContactPhoneNumber: yup
.string()
.nullable()
.transform((value, originalValue) => (originalValue === '' ? null : value))
.matches(regex.internationalPhoneNumber, t('errorMessage.invalidPhoneNumberFormat')),
programContactEmailAddress: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .matches(regex.emailAddress, t('errorMessage.invalidEmailAddressFormat'))
})};
