import React, {useState} from 'react';
import {Button, Typography, Link, Tooltip, IconButton, Menu, MenuItem, Grid, Box, TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import DocumentImg from '../icons/document.png';
import {useTranslation} from "react-i18next";
import ModalHeader from "../pageHeader/ModaHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "../modal";
import { useMediaQuery, useTheme } from '@mui/material';
const LinkInput = ({onChange, documentLink, error, field, title, subtitle, openModal, handleModalClose, onSubmit}) => {
    const {t} = useTranslation();

    return (
        <Modal isOpen={openModal} handleClose={handleModalClose}>
            <form>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left'
                    }}
                >
                    <ModalHeader title={title} subtitle={subtitle}
                                 isValid={true}
                                 handleClose={handleModalClose} onSubmit={onSubmit}
                                 submitButtonName={t('button.saveCAP')}
                    >
                        <Box
                            sx={{mt: 1, width: '100%', height: '200px', display: 'flex', alignItems: 'center'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Document Link"
                                        variant="outlined"
                                        value={documentLink}
                                        error={!!error}
                                        helperText={error?.message || ' '}
                                        fullWidth
                                        onChange={(e) => {
                                            onChange(e);
                                            field.onChange(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </ModalHeader>
                </Box>
            </form>
        </Modal>
    );
}

const DocumentInputController = ({control, name, label, subtitle,modalTitle,modalSubtitle, disabled}) => {
    const [openModal, setOpenModal] = useState(false);
    const [documentLink, setDocumentLink] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const buttonWidth = isXSmallScreen ? '100%' : '225px';
    const buttonHeight = isXSmallScreen ? '40px' : '100%';
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleModalClose = () => {
        handleClose();
        setOpenModal(!openModal);
    };
    const onChange = (e) => {
        setDocumentLink(e.target.value);
    }
    const onSubmit = () => {
        setOpenModal(!openModal);
    }
    const handleDelete = (field) => {
        setDocumentLink(null);
        field.onChange("");
        handleClose();
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({field}) => (
                <Box
                    sx={{
                        borderRadius: '12px',
                        border: '1px solid #656E7C',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px',
                        fontSize: "1rem"
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <img
                            src={DocumentImg}
                            alt="Document"
                            style={{
                                height: '75%',
                                width: '7%',
                                marginRight: '16px',
                                fontSize: "1rem"
                            }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={10} md={6} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                <Typography variant="subtitle1" sx={{fontSize: "14px", flexGrow: 1}}>
                                    {label}
                                </Typography>
                                {!field?.value ?
                                    <Typography variant="subtitle2" sx={{flexGrow: 1, whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '100%'}}>
                                        {subtitle}
                                    </Typography> :
                                    <Tooltip title={field?.value ? field?.value : documentLink}>
                                        <Link
                                            href={field?.value ? field?.value : documentLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'block',
                                                maxWidth: '100%',
                                                fontFamily: " Roboto, serif"
                                            }}
                                        >
                                            {field?.value ? field?.value : documentLink || subtitle}
                                        </Link>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={2} md={5} sx={{display: 'flex', justifyContent: 'flex-end', flexShrink: 1}}>
                                {!field?.value ? (
                                    <Button
                                        variant="outlined"
                                        sx={{ width: buttonWidth, height: buttonHeight }}
                                        onClick={handleModalClose}
                                        disabled={disabled}
                                    >
                                        Add Document
                                    </Button>
                                ) : <>
                                    <IconButton onClick={handleClick} sx={{flexShrink: 1}} disabled={disabled}>
                                        <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                    >
                                        <MenuItem onClick={handleModalClose}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={()=>{
                                            handleDelete(field)
                                        }}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <LinkInput onChange={onChange} documentLink={documentLink} field={field} label={label} title={modalTitle} subtitle={modalSubtitle}
                               openModal={openModal} handleModalClose={handleModalClose} onSubmit={onSubmit}/>
                </Box>
            )}
        />
    );
};

export default DocumentInputController;
