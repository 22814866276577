import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {calculateLocation, getSubmissionStatusChip, renderDeadlineCell} from "../../manage/utilis/columnHelpers";

const SubmissionColumns = (userRoleName)=> {
    return [
        {
            field: 'status',
            headerName: t('columns.viewEventSubmissionsOfClient.status'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => getSubmissionStatusChip(params.row.status.name)
        },
        {
            field: 'title',
            headerName: t('columns.viewEventSubmissionsOfClient.title'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/${userRoleName}/events/submissions/${params?.id}?update_submission`}>{params.value}</Link>),
        },
        {
            field: 'location',
            headerName: t('columns.viewEventSubmissionsOfClient.location'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => calculateLocation(params.row.interestedClient.recognition.event)
        },
        {
            field: 'interestedClient.recognition.event.industries',
            headerName: t('columns.viewEventSubmissionsOfClient.industries'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => params.row.interestedClient.recognition.event.industries?.map(industry => industry.name).join(', ')
        },
        {
            field: 'interestedClient.recognition.event.deadline',
            headerName: t('columns.viewEventSubmissionsOfClient.deadline'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => renderDeadlineCell(params.row.interestedClient?.recognition?.event?.deadline)
        }
    ]
}
export default SubmissionColumns;