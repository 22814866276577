import * as React from "react";
import {Controller} from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxController = (
    {
        control,
        name,
        readOnly ,
        ...rest
    }
) => {
    return <Controller
        name={name}
        control={control}
        rules={{required: false}}
        render={({field}) => (
            <FormControlLabel
                control={<Checkbox {...field} checked={!!(field?.value || field?.value === 'true')} readOnly={readOnly}/>}
                {...rest}
            />
        )
        }
    />;
}
export default CheckboxController