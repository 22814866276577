import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {GridActionsCellItem} from "@mui/x-data-grid-pro";

import GlobalDataGridPro from "../manage";
import DeleteTask from "./Delete";
import {AppContext} from "../../App";
import searchByString from "../../helpers/searchByString";
import {filterBySelection} from "../../helpers/filterBySelection";
import getTaskColumns from "../manage/globalColumns/tasks";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";
import {taskFiltersOptions} from "../viewEventTasks/Filter";

import {
    DELETE_TASK_MUTATION,
    REQUEST_TASKS_OF_SUBMISSION_QUERY,
    REQUEST_TASKS_OF_SUBSCRIBER_QUERY,
} from "../../graphql/task";
import {
    REQUEST_SUBMISSION_QUERY,
    REQUEST_TASKS_ORDER_SUBMISSION_QUERY,
    UPDATE_TASKS_SUBMISSION_ORDER_MUTATION
} from "../../graphql/submission";
import {updateSubmissionParams} from "./Helpers";
import Toast from "../alerts";
import updateRowPosition from "./Helpers/updateRowPosition";
import TaskQuickFilters from "./Helpers/quickFilters";
import {reorderInOriginalArray} from "./Helpers/reorderArray";
import {useUpdateTask} from "./Complete";
import {REQUEST_HISTORIES_QUERY} from "../../graphql/history";

export const TaskContext = React.createContext();

const ManageTasksGrid = (
    {
        list,
        inForm = false,
        submissionId,
        loading,
        type,
        readOnly,
        refetch,
        refetchSubmissionQuery,
    }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useContext(AppContext);
    // Conditional settings based on type
    const isAward = type === 'award';
    const navigationPath = isAward ? '/agency/awards/tasks' : '/agency/events/tasks';
    const navigationPathAdd = isAward ? `${navigationPath}/new_task` : `${navigationPath}/new_task`;
    const role = isAward ? 'award' : 'event';
    const tableID = isAward ? '1473ed38-8d61-11ee-b9d1-0242ac120002-task-awards' : '1473ed38-8d61-11ee-b9d1-0242ac120002-submission-task-events';

    const {permanentFilter, hasSelectedFilters} = useTableFilter(tableID);
    const {deadline = [null, null]} = permanentFilter;
    const isFiltersApply = hasSelectedFilters(tableID);
    // State hooks
    const [searchResults, setSearchResults] = useState(list);
    const [searchTerm, setSearchTerm] = useState('');
    const [submissionData, setSubmissionData] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openTasks, setOpenTasks] = useState(JSON.parse(localStorage.getItem('openTasks')) || false);
    const [currentStatus, setCurrentStatus] = useState(JSON.parse(localStorage.getItem('currentStatus')) || false);
    const rowReorder = !isFiltersApply && !readOnly && !openTasks && searchTerm === '';
    const submissionStatus = submissionData?.status?.name;

    // GraphQL mutation and query
    const [deleteTask] = useMutation(DELETE_TASK_MUTATION, {
        refetchQueries: [
            {query: REQUEST_TASKS_OF_SUBMISSION_QUERY, variables: {parameters: {submission: {id: submissionId}}}},
            {
                query: REQUEST_TASKS_OF_SUBSCRIBER_QUERY,
                variables: {parameters: {subscriber: {id: user?.subscriber?.id}}}
            },
            {
                query: REQUEST_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id: submissionId
                        }
                    },
                }
            },
            {
                query: REQUEST_HISTORIES_QUERY,
                variables: {
                    parameters: {
                        history: {
                            recordId: submissionId
                        }
                    }
                }
            }
        ],
        awaitRefetchQueries: true
    });
    const [updateSubmission] = useMutation(UPDATE_TASKS_SUBMISSION_ORDER_MUTATION);

    const {
        data: submissionRes,
        loading: submissionLoading,
        refetch: refetchSubmission
    } = useQuery(REQUEST_TASKS_ORDER_SUBMISSION_QUERY, {
        variables: {parameters: {submission: {id: submissionId}}}
    });
    useEffect(() => {
        setIsLoading(loading)
    }, [loading]);
    // Effect for setting submission status
    useEffect(() => {
        if (!submissionLoading && submissionRes?.requestSubmission?.data) {
            setSubmissionData(submissionRes?.requestSubmission?.data);
        }
    }, [submissionRes, submissionLoading]);

    // Search and filter handling
    useEffect(() => {
        let results = searchByString(list, searchTerm);
        const filteredParams = {
            dueDate: {type: 'dateRange', value: deadline},
            isDone: openTasks ? 'false' : null,
            submissionStatus: currentStatus ? submissionData?.status?.name : null
        };
        results = filterBySelection(results, filteredParams);
        setSearchResults(results);
    }, [permanentFilter, searchTerm, list, openTasks, currentStatus, submissionStatus]);
    // Event handlers
    const handleDelete = DeleteTask(deleteTask, refetchSubmissionQuery);
    const handleDeleteTask = (id) => {
        handleDelete(id);
    }
    const handleCompleteTask = useUpdateTask(submissionId, user, t, refetchSubmissionQuery);

    const handleSearch = (event) => setSearchTerm(event.target.value);
    const handleAdd = () => updateSubmissionParams(submissionId, navigationPathAdd, navigate);
    const toogleFilters = (event) => setAnchorEl(anchorEl ? null : event?.currentTarget);
    const handleRowOrderChange = (params) => {
        setIsLoading(true);
        let newRows ;
        if (currentStatus) {
            const allItems = submissionData?.tasks;
            const filteredItems = searchResults;
            const oldIndex = params.oldIndex;
            const newIndex = params.targetIndex;

            // Reorder the original array based on changes in the filtered view.
            newRows = reorderInOriginalArray(allItems, filteredItems, oldIndex, newIndex);
        } else {
            newRows = updateRowPosition(
                params.oldIndex,
                params.targetIndex,
                submissionData?.tasks,
            );
        }
        updateSubmission({
            variables: {
                parameters: {
                    submission: {
                        id: submissionData?.id,
                        tasks: newRows
                    },
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        fullName: user?.fullName,
                    },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.taskOrderUpdated')
                });
                refetchSubmission();
                refetch();
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
                setIsLoading(false);
            });
    };
    // Custom column definitions
    const CustomColumns = React.useMemo(() => {
        // Define the columns based on role and submission status
        const taskColumns = getTaskColumns({
            role: role, // 'award' or 'event'
            isUpdate: true,
            status: submissionData?.status?.name
        });

        // Additional column for actions
        const actionsColumn = {
            field: 'actions',
            type: 'actions',
            resizable: false,
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    key={`${params.id}-edit`}
                    label={t('button.edit')}
                    id={`${params.id}-edit-task-action`}
                    icon={<EditIcon/>}
                    disabled={submissionData?.status?.name !== params?.row?.submissionStatus}
                    onClick={() => {
                        // Navigate based on the type (award or event) and the task ID
                        navigate(`${navigationPath}/${params?.id}?update_task`);
                    }}
                    showInMenu={true}
                />,
                <GridActionsCellItem
                    key={`${params.id}-close-task`}
                    label={t('button.completeTask')}
                    id={`${params.id}-close-task-action`}
                    icon={<CheckCircleOutlineIcon/>}
                    disabled={params?.row?.isDone || submissionData?.status?.name !== params?.row?.submissionStatus}
                    onClick={() => {
                      handleCompleteTask(params?.row, params?.row?.isDone);
                    }}
                    showInMenu={true}
                />,
                <GridActionsCellItem
                    key={`${params.id}-delete`}
                    label={t('button.delete')}
                    id={`${params.id}-delete-task-action`}
                    icon={<DeleteForeverIcon/>}
                    onClick={() => handleDeleteTask(params.id)}
                    showInMenu={true}
                />,
            ]
        };

        // Combine the task columns with the actions column
        return [...taskColumns, actionsColumn];
    }, [submissionData, submissionId]);

    // DataGrid properties
    const dataGridProps = {
        id: tableID, // ID based on the type ('award' or 'event')
        title: inForm ? "" : "Tasks", // Title of the data grid
        searchLabel: "Search Task", // Label for the search input
        rows: searchResults, // Data rows to display in the grid
        columns: CustomColumns, // Custom column definitions based on role and status
        pageSize: pageSize || 10, // Number of rows per page
        onPageSizeChange: setPageSize, // Handler for changing the page size
        rowsPerPageOptions: [10, 20, 30], // Options for page size
        pagination: false, // Enable or disable pagination
        componentsProps: {pageSize}, // Props for the components used in the grid
        autoHeight: false, // Auto height setting for the grid
        handleAdd, // Handler for the add action
        handleSearch, // Handler for the search action
        handleFilter: toogleFilters, // Handler for toggling filters
        filterProps: {
            isOpen: Boolean(anchorEl), // Boolean indicating if the filter is open
            id: 'simple-popper', // ID for the filter component
            filterComponent: GlobalFiltersContainer, // Filter component to use
            filterComponentToolbar: GlobalFilters, // Toolbar for the filter component
            filterOptions: taskFiltersOptions, // Options for the filters
            anchorEl: anchorEl, // Element to anchor the filter popover
            title: 'Filters', // Title for the filter section
        },
        customSubToolbar: <TaskQuickFilters/>,
        searchTerm, // Current search term
        loading: isLoading, // Loading state for asynchronous data
        rowReordering: rowReorder, // rearrange rows by dragging the special reordering cell
        onRowOrderChange: handleRowOrderChange, // Handler for row reordering
    };

    const context = {
        setOpenTasks,
        openTasks,
        currentStatus,
        setCurrentStatus,
    }
    return (
        <TaskContext.Provider value={context}>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
        </TaskContext.Provider>
    )
};

export default ManageTasksGrid;
