import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGridSubToolbar } from "./utilis/dataGridSubHeader";
import { DataGridFilterToolbar } from "./utilis/dataGridFilterToolbar";
import GlobalDataListComponent from "./component";

const GlobalDataListPro = (parameters) => {
    return (
        <Box>
            <DataGridSubToolbar {...parameters}/>
            {parameters?.filterProps?.hasFilters &&
                <DataGridFilterToolbar {...parameters}/>
            }
            <Paper
                elevation={parameters?.inForm ? 0 : 2}
                sx={{
                    borderRadius: '12px',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        border: 0.1,
                        borderRadius: '8px',
                        p: 1,
                        height: '450px', // This sets the height of the container
                        overflow: 'hidden' // Only one overflow property is necessary
                    }}
                    id={parameters?.id + '-container'}
                >
                    <GlobalDataListComponent {...parameters} containerHeight='450px'/>
                </Box>
            </Paper>
        </Box>
    );
}
export default GlobalDataListPro;
