import React from 'react';
import {Icon, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const NavButton = (props) => {
    const {
        icon = '',
        title = '',
        isMinimized,
        onClickFn,
        id,
        isActive,
        disabled= false,
    } = props;

    return (
        <>
            <ListItem
                key={id}
                disablePadding
                sx={{height: '48px'}}
            >
                <ListItemButton
                    disabled={disabled}
                    sx={{
                        p: 0,
                        height: '48px',
                        cursor: 'pointer',
                        backgroundColor: isActive ? '#EE814D' : false,
                        borderRadius: '12px',
                        '&:hover': {
                            backgroundColor: isActive && '#c65a25',
                        },
                    }}
                    onClick={isActive ? () => {
                    } : onClickFn}
                >
                    <ListItemIcon sx={{justifyContent: 'center', width: isMinimized && '100%'}}>
                        <Icon
                            as={icon}
                            sx={{
                                ml: !isMinimized ? '6px' : 0,
                                mr: !isMinimized ? '14px' : 0,
                                fontSize: '24px',
                                color: isActive ? '#082032' : '#fff'
                            }}
                        />
                    </ListItemIcon>
                    {!isMinimized &&
                        <ListItemText
                            primaryTypographyProps={
                                {
                                    sx: {
                                        color: isActive ? '#082032' : '#fff'
                                    },
                                    variant: "navItem"
                                }
                            }
                            primary={title}
                        />
                    }
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default NavButton
