import React, {useState, useEffect, useContext} from 'react';
import GlobalDataGridPro from "../manage";
import {taskFiltersOptions} from "./Filter";

import searchByString from "../../helpers/searchByString";
import {AppContext} from "../../App";
import {filterBySelection} from "../../helpers/filterBySelection";
import Grid from "@mui/material/Grid";
import {Avatar, Chip} from "@mui/material";
import getTaskColumns from "../manage/globalColumns/tasks";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";

const tableID = '1473ed38-8d61-11ee-b9d1-0242ac120002-task-events';
export const ViewTasksGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);
    const {permanentFilter} = useTableFilter(tableID);
    const {deadline = [null, null]} = permanentFilter;
    const [filterByOverdue, setFilterByOverdue] = useState(false);
    const [filterByNewAdded, setFilterByNewAdded] = useState(false);
    const [newAdded, setNewAdded] = useState(0);
    const [overdue, setOverdue] = useState(0);
    const [myTasks, setMyTasks] = useState(0);
    const [filterByMyTasks, setFilterByMyTasks] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {user} = useContext(AppContext);

    useEffect(() => {
        if (list.length > 0) {
            let newAddeds = list.filter((task) => {
                return task.status === "New"
            });
            let overdues = list.filter((task) => {
                return new Date(task.dueDate) < new Date()
            });
            let myTasks = list.filter((task) => {
                return task.user.id === user?.id
            });
            setMyTasks(myTasks.length);
            setOverdue(overdues.length);
            setNewAdded(newAddeds.length);
        }
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            dueDate: {
                type: 'dateRange',
                value: deadline
            }
        }
        results = filterBySelection(results, filteredParams)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...getTaskColumns({role: 'event', location: user?.userRole?.name}),
        ], []);
    const handleOverdue = () => {
        if (!filterByOverdue) {
            let overdueData = searchResults.filter((task) => {
                return new Date(task.dueDate) < new Date()
            });
            setSearchResults(overdueData)
            setFilterByOverdue(true)
        } else {
            setSearchResults(list)
            setFilterByOverdue(false)
        }
    }
    const handleNewAdded = () => {
        if (!filterByNewAdded) {
            let newAddedData = searchResults.filter((task) => {
                return task.status === "New"
            });
            setSearchResults(newAddedData)
            setFilterByNewAdded(true)
        } else {
            setSearchResults(list)
            setFilterByNewAdded(false)
        }
    }
    const handleMyTasks = () => {
        if (!filterByMyTasks) {
            let myTasksData = searchResults.filter((task) => {
                return task.user.id === user?.id
            });
            setSearchResults(myTasksData)
            setFilterByMyTasks(true)
        } else {
            setSearchResults(list)
            setFilterByMyTasks(false)
        }
    }
    const getChipStyle = (isActive, isError) => ({
        width: '150px',
        mr: 1,
        backgroundColor: isActive ? isError ? (theme) => theme.palette.error.main : '#EE814D' : 'transparent', // light orange background when active
        border: isActive ? 'none' : '1px solid', // remove border when active
        color: isActive ? 'white' : '#EE814D', // white text when active, else primary color
    });
    const quickFilter = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Chip
                        variant="outlined"
                        sx={getChipStyle(filterByNewAdded)}
                        label="Newly Added"
                        onClick={handleNewAdded}
                        avatar={
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    bgcolor: '#EE814D',
                                }}
                            >
                                {newAdded}
                            </Avatar>
                        }
                    />
                    <Chip
                        variant="outlined"
                        sx={getChipStyle(filterByOverdue, true)}
                        label="Overdue"
                        onClick={handleOverdue}
                        avatar={
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => theme.palette.error.main,
                                    width: 32,
                                    height: 30,
                                }}
                            >
                                {overdue}
                            </Avatar>
                        }
                    />
                    {(user?.userRole?.name !== "Client" && user?.userRole?.name !== "Client Administrator") ?
                        <Chip
                            variant="outlined"
                            sx={getChipStyle(filterByMyTasks)}
                            label="My Tasks"
                            onClick={handleMyTasks}
                            avatar={
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        bgcolor: '#EE814D',
                                    }}
                                >
                                    {myTasks}
                                </Avatar>
                            }
                        /> : null
                    }
                </Grid>
            </Grid>
        )
    }

    const dataGridProps = {
        id: tableID,
        title: inForm ? "" : "Tasks",
        searchLabel: "Search Task",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,


        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        handleQuickFilter: {
            component: quickFilter,
        },
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: taskFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters',
        },
        searchTerm: searchTerm,
        loading: loading
    }

    return (
        <GlobalDataGridPro {...dataGridProps}/>
    );
};