import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {
    Box,
    Drawer,
    List,
    Divider, CircularProgress,
} from '@mui/material';
import {AppContext} from "../../App";
import HistoryIcon from '@mui/icons-material/History';
import {GetNavigationOptions} from "./helpers";
import {NavItem, NavHeader, NavUserProfile} from "./index";
import NavButton from "./NavButton";
import SubScrollToItem from "./SubScrollToItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const SIDE_NAV_WIDTH = '285px';
export const SIDE_NAV_MIN_WIDTH = '80px';

const paperProps = {
    elevation: 4,
    sx: {
        borderRadius: '12px',
        height: '100vh',
    }
};

const SideNav = () => {
    const {
        isMinimized,
        user,
        navItems: customItems = [],
        isAddingSection,
        setIsAddingSection,
        triggerFunction,
        setCurrentSideNavWidth,
        isNavLoading,
        isEditing,
        setViewHistory,
        viewHistory
    } = useContext(AppContext);
    const location = useLocation();
    const userRole = user?.userRole?.name || '';
    const currentPath = location?.pathname || '';
    const searchPath = location?.search || '';
    const isView = !!location?.hash;
    const navItems = GetNavigationOptions({userRole, currentPath, searchPath, isView})
    const lastItem = customItems[customItems.length - 1];
    const scrollToBottom = () => {
        // For scrolling the entire window
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    const contentRef = useRef(null);
    const [contentWidth, setContentWidth] = useState(SIDE_NAV_MIN_WIDTH);
    const updateDrawerWidth = () => {
        if (contentRef.current && !isMinimized) {
            setContentWidth(`${contentRef.current.offsetWidth}px`);
            setCurrentSideNavWidth(`${contentRef.current.offsetWidth + 16}`);
        } else {
            setContentWidth(SIDE_NAV_MIN_WIDTH);
            setCurrentSideNavWidth('80');
        }
    };

    // Observe changes to the referenced element
    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateDrawerWidth);

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current);
            }
        };
    }, [contentRef, isMinimized]);

    const [isLoading, setIsLoading] = useState(true);
    const isUpdateSubmission = searchPath.includes('update_submission');
    const renderLoadingIndicator = isUpdateSubmission && isLoading;
    const renderCustomItems = isUpdateSubmission && !isLoading && customItems.length;

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(isNavLoading);
        }, 500);
    }, [isNavLoading]);

    const handleNavButtonClick = useCallback(() => {
        setViewHistory(false);
        setIsAddingSection(true);
        triggerFunction();
        setTimeout(scrollToBottom, 500);
    }, [setIsAddingSection, triggerFunction, scrollToBottom]);
    const handleHistoryClick = () => {
        setViewHistory(true);
    }
    return (
        <Drawer
            variant="permanent"
            PaperProps={paperProps}
            position="left"
            sx={{
                transition: 'width .1s ease-in-out',
                borderRadius: '12px',
                width: contentWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    minWidth: isMinimized ? SIDE_NAV_MIN_WIDTH : "240px",
                    maxWidth: SIDE_NAV_WIDTH,
                    boxSizing: 'border-box'
                },
            }}
        >
            <Box
                ref={contentRef}
                display="flex" flexDirection="column" justifyContent="space-between" height='100vh'>
                <Box sx={{px: '0.75rem'}}>
                    {NavHeader()}
                    <Divider sx={{my: "0.5rem"}}/>
                </Box>
                <Box sx={{flex: 1, overflowY: "auto"}}>
                    <List sx={{px: !isMinimized && '0.75rem'}} key={"8840b7a4-9a28-11ee-b9d1-0242ac120002"}>
                        {navItems?.map((item) => (
                            <NavItem isMinimized={isMinimized} {...item} key={item.id || item.title}
                                     lastItem={renderCustomItems ? lastItem : {}}/>
                        ))}
                        {renderLoadingIndicator && (
                            <Box sx={{display: 'flex', justifyContent: 'center'}}
                                 key={'8814a652-9ab2-11ee-b9d1-0242ac120002'}>
                                <CircularProgress size={20}/>
                            </Box>
                        )}
                        {renderCustomItems ? customItems.map((item, index) => (
                            <SubScrollToItem item={item} index={index} lastItem={lastItem} isCustom={true}
                                             key={`6bbec732-9a29-11ee-b9d1-0242ac120002-${index}`}/>
                        ))
                        :
                        null}
                        {isUpdateSubmission &&
                            <NavButton
                                title={'Add Section'}
                                key='dd9743ba-9633-11ee-b9d1-0242ac120002-button'
                                id='dd9743ba-9633-11ee-b9d1-0242ac120002-button-id'
                                icon={AddCircleOutlineIcon}
                                isMinimized={isMinimized}
                                onClickFn={handleNavButtonClick}
                                disabled={isEditing}
                                isActive={isAddingSection}
                            />
                        }
                    </List>
                </Box>
                <Box sx={{px: '0.75rem'}}>
                    {isUpdateSubmission &&
                        <NavButton
                            title={'History'}
                            icon={HistoryIcon}
                            description={'History'}
                            key={'dd9743ba-9633-11ee-b9d1-0242ac120002'}
                            id={'b4e75050-9a29-11ee-b9d1-0242ac120002'}
                            isMinimized={isMinimized}
                            onClickFn={handleHistoryClick}
                            isActive={viewHistory}
                        />
                    }
                    <Divider/>
                    {NavUserProfile(user)}
                </Box>
            </Box>
        </Drawer>
    )
        ;
}

export default React.memo(SideNav);
