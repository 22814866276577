import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import {Avatar, Chip} from "@mui/material";

const QuickFilterSubmission = (parameters) => {
    const {
        searchResults,
        setSearchResults,
        list,
    } = parameters;
    const [filterByNewAdded, setFilterByNewAdded] = useState(false);
    const [newAdded, setNewAdded] = useState(0);
    const [filterByInProcess, setFilterByInProcess] = useState(false);
    const [inProcess, setInProcess] = useState(0);
    useEffect(() => {
        let newAddedData = list.filter((submission) => {
            return submission.status.name === "New"
        });
        setNewAdded(newAddedData.length)
        let inProcessData = list.filter((submission) => {
            return submission.status.name !== "New"
        });
        setInProcess(inProcessData.length)
    }, [list])
    const handleNewAdded = () => {
        if (!filterByNewAdded) {
            let newAddedData = searchResults.filter((submission) => {
                return submission.status.name === "New"
            });
            setSearchResults(newAddedData)
            setFilterByNewAdded(true)
        } else {
            setSearchResults(list)
            setFilterByNewAdded(false)
        }
    }
    const handleInProcess = () => {
        if (!filterByInProcess) {
            let newAddedData = searchResults.filter((submission) => {
                return submission.status.name !== "New"
            });
            setSearchResults(newAddedData)
            setFilterByInProcess(true)
        } else {
            setSearchResults(list)
            setFilterByInProcess(false)
        }
    }
    const getChipStyle = (isActive) => ({
        width: '150px',
        mr: 1,
        bgcolor: isActive ? '#EE814D' : 'transparent', // light orange background when active
        border: isActive ? 'none' : '1px solid', // remove border when active
        color: isActive ? 'white' : '#EE814D', // white text when active, else primary color
    });
    return (
        <Grid container>
            <Grid item xs={12}>
                <Chip
                    variant="outlined"
                    sx={getChipStyle(filterByNewAdded)}
                    label="Newly Added"
                    onClick={handleNewAdded}
                    avatar={
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                bgcolor: '#EE814D',
                            }}
                        >
                            {newAdded}
                        </Avatar>
                    }
                />
                <Chip
                    variant="outlined"
                    sx={getChipStyle(filterByInProcess)}
                    label="In Progress"
                    onClick={handleInProcess}
                    avatar={
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                bgcolor: '#EE814D',
                            }}
                        >
                            {inProcess}
                        </Avatar>
                    }
                />
            </Grid>
        </Grid>
    );
}

export default QuickFilterSubmission;
