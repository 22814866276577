import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { t } from "i18next";

const CheckboxComponent = ({ value, setValue, field, label }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox 
                    checked={value}
                    onChange={(e)=>{
                        setValue(field, e.target.checked)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
            label={t(label)}
        />
    )
}
export default CheckboxComponent