import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import { Editor } from 'react-draft-wysiwyg';
import './helpers/controllers.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styleMap = {
    'FONT_ROBOTO': {
        'fontFamily': 'Roboto, sans-serif',
    },
};

const TextEditorController = ({control, name, label, ...rest}) => {
    const {setValue} = useFormContext();
    return <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
            const handleEditorStateChange = (editorState) => {
                onChange(editorState); // Update the value
               setValue(name, editorState, { shouldDirty: true, shouldTouch: true }); // Manually set dirty and touched
            };
            return (
                <Editor
                    customStyleMap={styleMap}
                    editorState={value}
                    onEditorStateChange={handleEditorStateChange}
                    wrapperClassName="wrapperTextEditor"
                    editorClassName="editor-container"
                    toolbarClassName="toolbar-container"
                    readOnly={rest?.disabled}
                    toolbarHidden={rest?.disabled}
                    toolbar={
                        {
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                            inline: {
                                className: "wysiwyg-icon",
                            },
                            textAlign: {
                                className: "wysiwyg-icon",
                            },
                            list: {
                                className: "wysiwyg-icon",
                            },
                            remove: {
                                className: "wysiwyg-icon",
                            },
                            blockType: {
                                inDropdown: true,
                            },
                            history: {inDropdown: false, className: "wysiwyg-icon"},
                        }
                    }
                    placeholder={label + "..."}
                />
            )
        }}
    />
}
export default TextEditorController