import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {ThemeProvider} from "@mui/material";
import {theme} from './theme';
export const AUTH_KEY = `Technica PRM-auth`;
export const API_URL = process.env.REACT_APP_API_URL ||
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":3000";

const client = new ApolloClient({
    uri: API_URL + "/graphql",
    credentials: "include",
    cache: new InMemoryCache({
        addTypename: false
    })
});
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV !== 'production') {
    import('@axe-core/react').then(axe => {
        axe.default(React, ReactDOM, 1000);
        root.render(
            <React.StrictMode>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={theme}>
                        <App/>
                    </ThemeProvider></ApolloProvider>
            </React.StrictMode>
        )
    });
} else {
    root.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider></ApolloProvider>
        </React.StrictMode>
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
