import React, { useState, useRef, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';

const EllipsisTooltip = ({ text, disabled }) => {
    const textRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [text]);

    return (
        <Tooltip title={showTooltip ? text : ''}>
            <Typography
                ref={textRef}
                noWrap
                component="div"
                sx={{
                    width: '250px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: disabled && 'white !important'
                }}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};

export default EllipsisTooltip;
