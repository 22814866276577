import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageAwardSubmissionsOfSubscriberGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_AWARD_SUBMISSIONS_OF_SUBSCRIBER_QUERY} from "../../graphql/submission";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {t} from "i18next";
import useUpdatedItems from "../../hooks/useUpdateIndustries";

export default function ManageAwardSubmissionsOfSubscriberComponent({inForm = false}) {
    const [submissionItems, setSubmissionItems] = useState([]);
    //This will Replace the data with the data from the API

    const {user} = useContext(AppContext);
    const {data, loading} = useQuery(REQUEST_AWARD_SUBMISSIONS_OF_SUBSCRIBER_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });

    useEffect(() => {
        if (data?.requestAwardSubmissionsOfSubscriber?.data) {
            setSubmissionItems(data?.requestAwardSubmissionsOfSubscriber?.data);
        }
    }, [data]);
    const updatedItems = useUpdatedItems(submissionItems);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewAwardSubmissions.subTitle")}/>}
            <ManageAwardSubmissionsOfSubscriberGrid
                list={updatedItems}
                inForm={inForm}
                loading={loading}
            />
        </Box>
    );
}
