import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GridViewIcon from '@mui/icons-material/GridView';
import StarsIcon from '@mui/icons-material/Stars';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';

export const navAdministratorItems = () => [{
    title: 'Dashboard',
    icon: SpaceDashboardIcon,
    description: 'Administrator Dashboard',
    href: '/administrator/events',
    parentHref: 'administrator',
    hasSubNav: true,
    subNavs: [
        {
            title: 'Events',
            icon: CalendarTodayIcon,
            description: 'Events',
            href: '/administrator/events',
        },
        {
            title: 'Awards',
            icon: StarsIcon,
            description: 'Awards',
            href: '/administrator/awards',
        },
        {
            title: 'Agencies',
            icon: GridViewIcon,
            description: 'Agencies',
            href: '/administrator/agencies',
        },
        {
            title: 'Administrators',
            icon: GroupOutlinedIcon,
            description: 'Administrators',
            href: '/administrator/administrators',
        },
        {
            title: 'Industries',
            icon: PrecisionManufacturingOutlinedIcon,
            description: 'Industries',
            href: '/administrator/industries',
        },]
},
];
export const navSubscriberItems = (data) => [{
    title: 'Events',
    icon: SpaceDashboardIcon,
    description: 'Events Dashboard',
    href: '/agency/events/browse',
    parentHref: '/agency/events',
    hasSubNav: true,
    subNavs: [
        {
            title: 'Browse',
            icon: SearchOutlinedIcon,
            description: 'Events Browse',
            href: '/agency/events/browse',
        },
        {
            title: 'Analytics',
            icon: TrendingUpOutlinedIcon,
            description: 'Events Analytics',
            href: '/agency/events/analytics',
        },
        {
            title: 'Submissions',
            icon: RuleFolderOutlinedIcon,
            description: 'Event Submissions',
            href: '/agency/events/submissions',
        },
        {
            title: 'Tasks',
            icon: AssignmentOutlinedIcon,
            description: 'Event Tasks',
            href: '/agency/events/tasks',
        },]
},

    {
        title: 'Awards',
        icon: SpaceDashboardIcon,
        description: 'Award Dashboard',
        href: '/agency/awards/browse',
        hasSubNav: true,
        subNavs: [
            {
                title: 'Browse',
                icon: SearchOutlinedIcon,
                description: 'Awards Browse',
                href: '/agency/awards/browse',
            },
            {
                title: 'Analytics',
                icon: TrendingUpOutlinedIcon,
                description: 'Award Analytics',
                href: '/agency/awards/analytics',
            },
            {
                title: 'Submissions',
                icon: RuleFolderOutlinedIcon,
                description: 'Award Submissions',
                href: '/agency/awards/submissions',
            },
            {
                title: 'Tasks',
                icon: AssignmentOutlinedIcon,
                description: 'Award Tasks',
                href: '/agency/awards/tasks',
            },]
    },

    {
        title: 'Clients',
        icon: PeopleAltOutlinedIcon,
        description: 'Clients',
        href: '/agency/clients/event_analytics',
        hasSubNav: true,
        subNavs: [
            {
                title: 'Browse',
                icon: SearchOutlinedIcon,
                description: 'Clients Browse',
                href: '/agency/clients/browse',
            },
            {
                title: 'Event Analytics',
                icon: TrendingUpOutlinedIcon,
                description: 'Client Event Analytics',
                href: '/agency/clients/event_analytics',
            },
            {
                title: 'Award Analytics',
                icon: TrendingUpOutlinedIcon,
                description: 'Client Award Analytics',
                href: '/agency/clients/award_analytics',
            }
        ]

    },

    {
        title: 'Agency Profile',
        icon: PersonPinRoundedIcon,
        description: 'Agency Profile',
        href: `/agency/agency/${data?.subscriber?.id}?update_agency`,
        hasSubNav: false,
    },
    {
        title: 'Team Members',
        icon: GroupsIcon,
        description: 'Team Members',
        href: `/agency/team_members`,
        hasSubNav: false
    },

];
export const navClientItems = (data) => [{
    title: 'Event',
    icon: SpaceDashboardIcon,
    description: 'Event Dashboard',
    href: '/client/event/browse',
    parentHref: '/client/event',
    hasSubNav: true,
    subNavs: [
        {
            title: 'Browse',
            icon: SearchOutlinedIcon,
            description: 'Events',
            href: '/client/event/browse',
        },
        {
            title: 'Submissions',
            icon: RuleFolderOutlinedIcon,
            description: 'Submissions',
            href: '/client/event/submissions',
        },
        {
            title: 'Tasks',
            icon: AssignmentOutlinedIcon,
            description: 'Tasks',
            href: '/client/event/tasks',
        },]
},

    {
        title: 'Award',
        icon: SpaceDashboardIcon,
        description: 'Award Dashboard',
        href: '/client/award/browse',
        hasSubNav: true,
        subNavs: [
            {
                title: 'Browse',
                icon: SearchOutlinedIcon,
                description: 'Awards',
                href: '/client/award/browse',
            },
            {
                title: 'Submissions',
                icon: RuleFolderOutlinedIcon,
                description: 'Submissions',
                href: '/client/award/submissions',
            },
            {
                title: 'Tasks',
                icon: AssignmentOutlinedIcon,
                description: 'Tasks',
                href: '/client/award/tasks',
            },]
    },

    {
        title: 'Contacts',
        icon: PeopleAltOutlinedIcon,
        description: 'Contacts',
        href: '/client/contact/contacts',
        hasSubNav: false,

    },

    {
        title: 'Profile',
        icon: AccountCircleOutlinedIcon,
        description: 'Client Profile',
        href: `/client/client/${data?.client?.id}?update_client`,
        hasSubNav: false,
    },

];

export const navNewAwardItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Awards',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Awards',
            href: `/${roleName.includes("Subscriber") ? "agency/award/browse" : roleName.toLowerCase() + '/awards'}`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Award Profile' : 'New Award',
            icon: StarsIcon,
            description: isUpdate ? 'Award' : 'New Award',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_award`,
            hasSubNav: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
                {
                    title: 'Description',
                    description: 'Description',
                    type: 'scroll',
                    href: 'description',
                },
                {
                    title: 'Award Guidelines',
                    description: 'Award Guidelines',
                    type: 'scroll',
                    href: 'awardGuidelines',
                },
                {
                    title: 'Award Categories',
                    description: 'Award Categories',
                    type: 'scroll',
                    href: 'awardCategories',
                },
                ...(roleName.includes("Subscriber") ? [{
                    title: 'Agency Notes',
                    description: 'Agency Notes',
                    type: 'scroll',
                    href: 'agencyNotes',
                }] : []),
            ]
        },
    ];
};
export const navReadAwardItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Awards',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Awards',
            href: `/${roleName.includes("Subscriber") ? "agency/award/browse" : roleName.toLowerCase() + '/awards'}`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Award' : 'New Award',
            icon: StarsIcon,
            description: isUpdate ? 'Award' : 'New Award',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_award`,
            hasSubNav: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
                {
                    title: 'Description',
                    description: 'Description',
                    type: 'scroll',
                    href: 'description',
                },
                {
                    title: 'Award Guidelines',
                    description: 'Award Guidelines',
                    type: 'scroll',
                    href: 'awardGuidelines',
                },
                {
                    title: 'Award Categories',
                    description: 'Award Categories',
                    type: 'scroll',
                    href: 'awardCategories',
                },
                {
                    title: 'Agency Notes',
                    description: 'Agency Notes',
                    type: 'scroll',
                    href: 'agencyNotes',
                },
                {
                    title: 'Clients',
                    description: 'Clients',
                    type: 'scroll',
                    href: 'clients',
                }
            ]
        },
    ];
};
export const navRegistryAwardItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Awards',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Awards',
            href: `/${roleName.includes("Subscriber") ? "agency/award" : roleName.toLowerCase()}/browse`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Award' : 'New Award',
            icon: StarsIcon,
            description: isUpdate ? 'Award' : 'New Award',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_award`,
            hasSubNav: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            }]
        },
    ];
};
export const navNewClientSubscriberItems = (isUpdate, currentPath, roleName, user) => {
    let baseItems = [
        {
            title: 'Back to Clients',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Clients',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agency/${user?.subscriber?.id}?update_agency`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Client' : 'New Client',
            icon: StarsIcon,
            description: isUpdate ? 'Client' : 'New Client',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/clients/new_client`,
            hasSubNav: true,
            subNavs: []
        },
    ];
    if (currentPath.includes("new")) {
        baseItems[1].subNavs.push(
            {
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
       );
    }
    if (!currentPath.includes("new")) {
        baseItems[1].subNavs.push(
            {
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
            {
                title: 'Events',
                description: 'Events',
                type: 'scroll',
                href: 'events',
            },
            {
                title: 'Event Submissions',
                description: 'Event Submissions',
                type: 'scroll',
                href: 'eventSubmissions',
            },
            {
                title: 'Awards',
                description: 'Awards',
                type: 'scroll',
                href: 'awards',
            },
            {
                title: 'Award Submissions',
                description: 'Award Submissions',
                type: 'scroll',
                href: 'awardSubmissions',
            },
            {
                title: 'Keywords',
                description: 'Keywords',
                type: 'scroll',
                href: 'keywords',
            },
            {
                title: 'Industries',
                description: 'Industries',
                type: 'scroll',
                href: 'industries',
            },
            {
                title: 'Contacts',
                description: 'Contacts',
                type: 'scroll',
                href: 'contacts',
            },
            {
                title: 'Team Members',
                description: 'Team Members',
                type: 'scroll',
                href: 'teamMembers',
            }
        );
    }
    return baseItems;
};
export const navNewClientItems = (isUpdate, currentPath, roleName, user) => {
    let baseItems = [
        {
            title: 'Back to Clients',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Clients',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agency/${user?.subscriber?.id}?update_agency`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Client' : 'New Client',
            icon: StarsIcon,
            description: isUpdate ? 'Client' : 'New Client',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/clients/new_client`,
            hasSubNav: true,
            subNavs: []
        },
    ];
    if (currentPath.includes("new")) {
        baseItems[1].subNavs.push(
            {
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            });
    }
    if (!currentPath.includes("new")) {
        baseItems[1].subNavs.push(
            {
                title: 'Events',
                description: 'Events',
                type: 'scroll',
                href: 'events',
            },
            {
                title: 'Event Submissions',
                description: 'Event Submissions',
                type: 'scroll',
                href: 'eventSubmissions',
            },
            {
                title: 'Awards',
                description: 'Awards',
                type: 'scroll',
                href: 'awards',
            },
            {
                title: 'Award Submissions',
                description: 'Award Submissions',
                type: 'scroll',
                href: 'awardSubmissions',
            },
            {
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            }, {
                title: 'Keywords',
                description: 'Keywords',
                type: 'scroll',
                href: 'keywords',
            },
            {
                title: 'Industries',
                description: 'Industries',
                type: 'scroll',
                href: 'industries',
            },
            {
                title: 'Client Users',
                description: 'ClientsUsers',
                type: 'scroll',
                href: 'clientsUsers',
            },
            {
                title: 'Contacts',
                description: 'Contacts',
                type: 'scroll',
                href: 'contacts',
            });
    }
    return baseItems;
};
export const navNewEventItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Events',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Events',
            href: `/${roleName.includes("Subscriber") ? "agency/event/browse" : roleName.toLowerCase() + '/events'}`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Event Profile' : 'New Event',
            icon: StarsIcon,
            description: isUpdate ? 'Event' : 'New Event',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_event`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
                {
                    title: 'Organizer',
                    description: 'Organizer',
                    type: 'scroll',
                    href: 'organizer',
                },
                {
                    title: 'Description',
                    description: 'Description',
                    type: 'scroll',
                    href: 'description',
                },
                {
                    title: 'Speaker Event Guidelines',
                    description: 'Speaker Event Guidelines',
                    type: 'scroll',
                    href: 'speakerEventGuidelines',
                },
                {
                    title: 'Industries',
                    description: 'Industries',
                    type: 'scroll',
                    href: 'industries',
                },
                {
                    title: 'Session Topics',
                    description: 'Session Topics',
                    type: 'scroll',
                    href: 'sessionTopics',
                },
                {
                    title: 'Expert Tips',
                    description: 'Expert Tips',
                    type: 'scroll',
                    href: 'expertTips',
                },
                ...(roleName.includes("Subscriber") ? [{
                    title: 'Agency Notes',
                    description: 'Agency Notes',
                    type: 'scroll',
                    href: 'agencyNotes',
                }] : []),
                {
                    title: 'Additional Details',
                    description: 'Additional Details',
                    type: 'scroll',
                    href: 'additionalDetails',
                }
            ]
        },
    ];
};
export const navReadOnlyEventItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Events',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Events',
            href: `/${roleName.includes("Subscriber") ? "agency/event/browse" : roleName.toLowerCase() + '/events'}`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Event' : 'New Event',
            icon: StarsIcon,
            description: isUpdate ? 'Event' : 'New Event',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_event`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
                {
                    title: 'Organizer',
                    description: 'Organizer',
                    type: 'scroll',
                    href: 'organizer',
                },
                {
                    title: 'Description',
                    description: 'Description',
                    type: 'scroll',
                    href: 'description',
                },
                {
                    title: 'Speaker Event Guidelines',
                    description: 'Speaker Event Guidelines',
                    type: 'scroll',
                    href: 'speakerEventGuidelines',
                },
                {
                    title: 'Industries',
                    description: 'Industries',
                    type: 'scroll',
                    href: 'industries',
                },
                {
                    title: 'Session Topics',
                    description: 'Session Topics',
                    type: 'scroll',
                    href: 'sessionTopics',
                },
                {
                    title: 'Expert Tips',
                    description: 'Expert Tips',
                    type: 'scroll',
                    href: 'expertTips',
                },
                {
                    title: 'Agency Notes',
                    description: 'Agency Notes',
                    type: 'scroll',
                    href: 'agencyNotes',
                },
                {
                    title: 'Additional Details',
                    description: 'Additional Details',
                    type: 'scroll',
                    href: 'additionalDetails',
                },
                {
                    title: 'Clients',
                    description: 'Clients',
                    type: 'scroll',
                    href: 'clients',
                },
            ]
        },
    ];
};
export const navRegistryEventItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Events',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Events',
            href: `/${roleName.includes("Subscriber") ? "agency/event" : roleName.toLowerCase()}/browse`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Event' : 'New Event',
            icon: StarsIcon,
            description: isUpdate ? 'Event' : 'New Event',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/browse/new_event`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            }]
        },
    ];
};
export const navNewIndustryItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Industries',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Industries',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/industries`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Industry Profile' : 'New Industry',
            icon: StarsIcon,
            description: isUpdate ? 'Industry' : 'New Industry',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/industries/new_industry`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },
            ]
        },
    ];
};
export const NavNewSubmissionItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Submissions',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Submissions',
            href: `/${roleName.includes("Subscriber") ? `agency/${currentPath.includes('event') ? 'event' : "award"}` : roleName.toLowerCase()}/submissions`,
            hasSubNav: false,
            type: 'button',
            id: 'db14e2da-9a27-11ee-b9d1-0242ac120002'
        },
        {
            title: isUpdate ? 'Submission' : 'New Submission',
            icon: StarsIcon,
            id: 'e61450bc-9a27-11ee-b9d1-0242ac120002',
            description: isUpdate ? 'Submission' : 'New Submission',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/submissions/new_submission`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Tasks',
                    description: 'Tasks',
                    type: 'scroll',
                    href: 'tasks',
                    id: 'fc77b628-9a27-11ee-b9d1-0242ac120002'
                }, {
                    title: 'Submission Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                    id: '00522936-9a28-11ee-b9d1-0242ac120002'
                },
                {
                    title: currentPath.includes("award") || currentPath.includes("Award") ? 'Award Details' : 'Event Details',
                    description: 'Event Details',
                    type: 'scroll',
                    href: currentPath.includes("award") || currentPath.includes("Award") ? 'awardDetails' : 'eventDetails',
                    id: '02d2026c-9a28-11ee-b9d1-0242ac120002'
                },
                {
                    title: 'Client Details',
                    description: 'Client Details',
                    type: 'scroll',
                    href: 'clientDetails',
                    id: '05dae4e2-9a28-11ee-b9d1-0242ac120002'
                }
            ]
        }
    ];
};
export const navNewSubscriberItems = (isUpdate, currentPath, roleName) => {
    const baseItems = [
        {
            title: 'Back to Agencies',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Agencies',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Agency' : 'New Agency',
            icon: StarsIcon,
            description: isUpdate ? 'Agency' : 'New Agency',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies/new_agency`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            },]
        },
    ];
    if (roleName !== 'administrator') {
        baseItems[1].subNavs.push(
            {
                title: 'Team Members',
                description: 'Team Members',
                type: 'scroll',
                href: 'teamMembers',
            },
            {
                title: 'Clients',
                description: 'Clients',
                type: 'scroll',
                href: 'clients',
            },
            {
                title: 'System Configuration',
                description: 'System Configuration',
                type: 'scroll',
                href: 'systemConfiguration',
            }
        );
    }
    return baseItems;
};
export const navNewAgencyItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Agencies',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Agencies',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Agency' : 'New Agency',
            icon: StarsIcon,
            description: isUpdate ? 'Agency' : 'New Agency',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies/new_agency`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            }, {
                title: 'Admin',
                description: 'admin',
                type: 'scroll',
                href: 'admin',
            },
                {
                    title: 'System Configuration',
                    description: 'System Configuration',
                    type: 'scroll',
                    href: 'systemConfiguration',
                }]
        },
    ];
};
export const navNewAgencyItemsAdmin = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Agencies',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Agencies',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Agency Profile' : 'New Agency',
            icon: StarsIcon,
            description: isUpdate ? 'Agency' : 'New Agency',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agencies/new_agency`,
            hasSubNav: true,
            noButton: true,
            subNavs: [{
                title: 'Details',
                description: 'Details',
                type: 'scroll',
                href: 'details',
            }]
        },
    ];
};
export const navNewTaskItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Tasks',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Tasks',
            href: currentPath.includes('event') || currentPath.includes('award') ? `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/${currentPath.includes("award") || currentPath.includes("Award") ? "award" : "event"}/tasks` : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Task' : 'New Task',
            icon: StarsIcon,
            description: isUpdate ? 'Task' : 'New Task',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/tasks/new_task`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                },
                {
                    title: 'Description',
                    description: 'Description',
                    type: 'scroll',
                    href: 'description',
                },
                {
                    title: currentPath.includes("award") || currentPath.includes("Award") ? 'Award Details' : 'Event Details',
                    description: 'Event Details',
                    type: 'scroll',
                    href: currentPath.includes("award") || currentPath.includes("Award") ? 'awardDetails' : 'eventDetails',
                },
                {
                    title: 'Client Details',
                    description: 'Client Details',
                    type: 'scroll',
                    href: 'clientDetails',
                },

            ]
        },
    ];
};
export const navClientNewTaskItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Tasks',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Tasks',
            href: currentPath.includes('event') || currentPath.includes('award') ? `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/${currentPath.includes("award") || currentPath.includes("Award") ? "award" : "event"}/tasks` : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Task' : 'New Task',
            icon: StarsIcon,
            description: isUpdate ? 'Task' : 'New Task',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/tasks/new_task`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                },
                {
                    title: currentPath.includes("award") || currentPath.includes("Award") ? 'Award Details' : 'Event Details',
                    description: 'Event Details',
                    type: 'scroll',
                    href: currentPath.includes("award") || currentPath.includes("Award") ? 'awardDetails' : 'eventDetails',
                },
                {
                    title: 'Client Details',
                    description: 'Client Details',
                    type: 'scroll',
                    href: 'clientDetails',
                },

            ]
        },
    ];
};


export const navNewAdminUserItems = (isUpdate, currentPath, roleName) => {
    return [
        {
            title: 'Back to Administrators',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Users',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/administrators`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'User Profile' : 'User Profile',
            icon: StarsIcon,
            description: isUpdate ? 'User Profile' : 'User Profile',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/administrators/new_user_profile`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                }
            ],
        },
    ];
};
export const navNewTeamMemberUserItems = (isUpdate, currentPath, roleName, user) => {
    const baseItems = [
        {
            title: 'Back to Team Members',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Users',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/agency/${user?.subscriber?.id}?update_agency`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Team Member' : 'New Team Member',
            icon: StarsIcon,
            description: isUpdate ? 'Team Member' : 'New Team Member',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/administrators/new_user_profile`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                }
            ],
        },
    ];

    if (roleName !== 'administrator') {
        baseItems[1].subNavs.push(
            {
                title: 'Security',
                description: 'Security',
                type: 'scroll',
                href: 'security',
            },
            {
                title: 'Clients',
                description: 'Clients',
                type: 'scroll',
                href: 'clients',
            }
        );
    }

    return baseItems;
};

export const navNewClientUserItems = (isUpdate, currentPath, roleName, user, searchPath, isView) => {
    const baseItems = [
        {
            title: 'Back to Client Users',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Users',
            href: `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/client/${user?.client?.id}?update_client`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'User' : 'New User',
            icon: StarsIcon,
            description: isUpdate ? 'User' : 'New User',
            href: isUpdate ? currentPath : `/${roleName.includes("Subscriber") ? "agency" : roleName.toLowerCase()}/administrators/new_user_profile`,
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                }
            ],
        },
    ];

    if (roleName !== 'administrator' && !isView) {
        baseItems[1].subNavs.push(
            {
                title: 'Security',
                description: 'Security',
                type: 'scroll',
                href: 'security',
            }
        );
    }

    return baseItems;
};


export const navContactItems = (isUpdate) => {
    return [
        {
            title: 'Back to Client',
            icon: ArrowCircleLeftOutlinedIcon,
            description: 'Back to Client',
            href: `/agency/clients`,
            hasSubNav: false,
            type: 'button',
        },
        {
            title: isUpdate ? 'Contact Profile' : 'New Contact',
            icon: StarsIcon,
            description: isUpdate ? 'Contact Profile' : 'New Contact',
            hasSubNav: true,
            noButton: true,
            subNavs: [
                {
                    title: 'Details',
                    description: 'Details',
                    type: 'scroll',
                    href: 'details',
                }
            ],
        },
    ];
};