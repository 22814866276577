import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { TaskContext } from '../component';

// TaskQuickFilters component for filtering tasks based on their status.
const TaskQuickFilters = () => {
    // Hook for internationalization.
    const { t } = useTranslation();

    // Destructuring necessary functions and state variables from context.
    const {
        setOpenTasks,
        openTasks,
        currentStatus,
        setCurrentStatus
    } = useContext(TaskContext);

    // Handles changes for the current status checkbox.
    const handleStatusChange = (event, type) => {
        const { checked } = event.target;

        // Update state based on type (currentStatus or openTasks).
        type === 'currentStatus' ? setCurrentStatus(checked) : setOpenTasks(checked);

        // Persist state to localStorage.
        localStorage.setItem(type, JSON.stringify(checked));
    }

    return (
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={currentStatus}
                        onChange={(e) => handleStatusChange(e, 'currentStatus')}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={t('manage.manageEventTasksOfSubscriber.filters.currentStatus')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={openTasks}
                        onChange={(e) => handleStatusChange(e, 'openTasks')}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={t('manage.manageEventTasksOfSubscriber.filters.openTask')}
            />
        </Box>
    );
};

export default TaskQuickFilters;
