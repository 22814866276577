import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

toast.configure();

const DEFAULT_AUTO_CLOSE = 4000;
const DEFAULT_POSITION = toast.POSITION.TOP_RIGHT;
const DEFAULT_HIDE_PROGRESS_BAR = false;
const DEFAULT_PAUSE_ON_FOCUS_LOSS = false;
const DEFAULT_CLOSE_ON_CLICK = true;
const DEFAULT_THEME = "dark";

const Toast = ({
    type,
    message,
    position = DEFAULT_POSITION,
    autoClose = DEFAULT_AUTO_CLOSE,
    hideProgressBar = DEFAULT_HIDE_PROGRESS_BAR,
    pauseOnFocusLoss = DEFAULT_PAUSE_ON_FOCUS_LOSS,
    closeOnClick = DEFAULT_CLOSE_ON_CLICK,
    toastId
}) => {
    const messageText = typeof message === "object" && message.message ? message.message : message;

    const toastOptions = {
        position,
        autoClose,
        hideProgressBar,
        pauseOnFocusLoss,
        theme: DEFAULT_THEME,
        closeOnClick,
        toastId
    };

    if (toastId && toast.isActive(toastId)) {
        // Update existing toast
        toast.update(toastId, {
            ...toastOptions,
            render: messageText,
            type
        });
    } else {
        // Show new toast
        toast(messageText, {
            ...toastOptions,
            type
        });
    }
};

export default Toast;