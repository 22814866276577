import React, {useState, useEffect, useContext, Suspense} from 'react';
import GlobalDataGridPro from "../manage";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import searchByString from "../../helpers/searchByString";
import {AppContext} from "../../App";
import {filterBySelection} from "../../helpers/filterBySelection";
import AssignInterestedClients from "../viewAwards/assign";
import getAwardColumns from "../manage/globalColumns/awards";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import GlobalFiltersContainer, {GlobalFilters} from "../filters/containers/globalFilters";
import {awardFiltersOptions} from "./Filter";
import {CircularProgress} from "@mui/material";

const tableID = '1473ed38-8d61-11ee-b9d1-0242ac120002-awards';
export const ViewAwardsGrid = ({list, interestedClients, loading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const {user} = useContext(AppContext);
    const {permanentFilter} = useTableFilter(tableID);
    const {deadline = [null, null], industries = []} = permanentFilter;
    const [searchTerm, setSearchTerm] = useState('');

    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAward, setSelectedAward] = useState({});

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };

    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {
            applicationDeadline: {
                type: 'dateRange',
                value: deadline
            },
            industries: industries?.map((industry) => {
                return industry?.name
            }),
        }
        results = filterBySelection(results, filteredParams)
        setSearchResults(results)
    }, [permanentFilter, searchTerm])
    const handleAdd = () => {
        navigate(`/agency/awards/browse/new_award?register=true`)
    }
    const handleButtonClick = (params) => {
        setSelectedAward(params?.row)
        setIsOpen(!isOpen)
    }
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...getAwardColumns({
                baseURL: pathname,
                role: user?.userRole?.name,
                handleButtonClick,
                interestedClients
            }),
        ], [interestedClients, isOpen]);
    const dataGridProps = {
        id: tableID,
        title: t("view.viewAwards.title"),
        searchLabel: t("view.viewAwards.search"),
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleAdd: handleAdd,

        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: isOpen ? 'simple-popper' : undefined,
            filterComponent: GlobalFiltersContainer,
            filterComponentToolbar: GlobalFilters,
            filterOptions: awardFiltersOptions,
            anchorEl: anchorEl,
            title: 'Filters'
        },
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps}/>
            {isOpen && (
                <Suspense fallback={ <CircularProgress />}>
                    <AssignInterestedClients isOpen={isOpen} handleClose={handleButtonClick} award={selectedAward}/>
                </Suspense>
            )}
        </>
    );
};