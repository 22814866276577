import {Icon, ListItem, ListItemButton, ListItemIcon, ListItemText, List} from "@mui/material";
import React, {useContext} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../App";

const SubNavToItem = ({item}) => {
    const {isMinimized} = useContext(AppContext);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const isActiveSubItem = pathname === item?.href;

    const handleItemClick = (e) => {
        // Only prevent default and navigate for left-clicks
        if (e.button === 0) {
            e.preventDefault();
            navigate(item?.href);
        }
    };

    return (
        <>
            <ListItem key={item.id || item?.description} disablePadding sx={{height: '52px'}}>

                <a href={item?.href} onClick={handleItemClick}
                   style={{textDecoration: 'none', color: 'inherit', width: "100%"}}>
                    <ListItemButton
                        sx={{
                            p: 0,
                            height: '48px',
                            ml: !isMinimized && 2,
                            backgroundColor: isActiveSubItem ? '#EE814D' : false,
                            borderRadius: '12px',
                            '&:hover': {
                                backgroundColor: isActiveSubItem && '#c65a25',
                            },
                        }}
                    >
                        <ListItemIcon sx={{justifyContent: 'center', width: isMinimized && '100%'}}>
                            <Icon
                                as={item?.icon}
                                sx={{
                                    ml: 0,
                                    mr: 0,
                                    fontSize: '24px',
                                    color: isActiveSubItem && item?.hasSubNav ? '#EE814D' : isActiveSubItem ? '#082032' : '#fff',
                                }}
                            />
                        </ListItemIcon>
                        {!isMinimized && (
                            <ListItemText
                                primaryTypographyProps={{
                                    sx: {
                                        maxWidth: '130px',
                                        color: isActiveSubItem && item?.hasSubNav ? '#EE814D' : isActiveSubItem ? '#082032' : '#fff',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                    variant: 'navItem',
                                }}
                                primary={item?.title}
                            />
                        )}
                    </ListItemButton>
                </a>
            </ListItem>
            {item?.subNavs && item?.subNavs?.length > 0 && (
                <List disablePadding sx={{paddingLeft: '32px'}}>
                    {item.subNavs.map((subItem, index) => (
                        <SubNavToItem key={index} item={subItem}/>
                    ))}
                </List>
            )}
        </>
    );
};

export default SubNavToItem;