import * as React from "react";
import {t} from "i18next";
import {Link} from '@mui/material';
import {calculateLocation, getSubmissionStatusChip, renderDeadlineCell} from "../../manage/utilis/columnHelpers";

const SubmissionColumns = (pathname) => [
    {
        field: 'status',
        headerName: t('columns.manageSubmissions.status'),
        flex: 100,
        minWidth: 100,
        maxWidth: 150,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => getSubmissionStatusChip(params.row.status.name),
        valueGetter: (params) => getSubmissionStatusChip(params.row.status.name),
    },
    {
        field: 'title',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.title'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`${pathname}/${params?.id}?update_submission`}>{params.value}</Link>),
    },
    {
        field: 'interestedClient.client.name',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.client'),
        flex: 1,
        minWidth: 250,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (params) => params.row.interestedClient?.client?.name || '',
        renderCell: (params) => (
            <Link href={`clients/${params.row.interestedClient?.client?.id}?update_client`}>{params.row.interestedClient?.client?.name}</Link>),
    },
    {
        field: 'interestedClient.recognition.event.name',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.event'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        renderCell: (params) => (
            <Link href={`browse/${params?.row?.interestedClient?.recognition?.event?.id}?update_event`}>{params?.row?.interestedClient?.recognition?.event?.name}</Link>),
    },
    {
        field: 'location',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.location'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => calculateLocation(params.row.interestedClient.recognition.event)
    },
    {
        field: 'interestedClient.recognition.event.industries',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.industries'),
        flex: 100,
        minWidth: 250,
        align: "left",
        headerAlign: 'left',
        valueGetter: (params) => params.row?.interestedClient?.recognition?.event?.industries?.map(industry => industry.name).join(', ')
    },
    {
        field: 'interestedClient.recognition.event.deadline',
        headerName: t('columns.manageEventSubmissionsOfSubscriber.deadline'),
        flex: 100,
        minWidth: 150,
        maxWidth: 200,
        align: "center",
        headerAlign: 'center',
        valueGetter: (params) => {
            return params?.row?.interestedClient?.recognition?.event?.deadline
        },
        renderCell: (params) => {
            return renderDeadlineCell(params.row.interestedClient?.recognition?.event?.deadline)
        }
    },

]
export default SubmissionColumns;