import React, {createContext, useContext, useState} from 'react';

const FilterContext = createContext();

const defaultFilterState = {
    deadline: [null, null],
    eventDate: [null, null],
    applicationDeadline: [null, null],
    submissionDate: [null, null],
    industries: [],
    rollingDeadline: false,
    directContact: false
};

export const FilterProvider = ({children}) => {
    const [filters, setFilters] = useState(defaultFilterState); // Permanent filters for each table
    const [tempFilters, setTempFilters] = useState({...defaultFilterState}); // Temporary filters for each table

    const updateTempFilter = (tableId, key, value) => {
        setTempFilters(prev => ({
            ...prev,
            [tableId]: {
                ...prev[tableId],
                [key]: value
            }
        }));
    };

    const applyFilters = (tableId) => {
        setFilters(prev => ({
            ...prev,
            [tableId]: {...tempFilters[tableId]}
        }));
    };

    const removeAllFilters = (tableId) => {
        const resetState = {...defaultFilterState};
        setFilters(prev => ({...prev, [tableId]: resetState}));
        setTempFilters(prev => ({...prev, [tableId]: resetState}));
    };

    const cancelFilters = (tableId) => {
        setTempFilters(prev => ({
            ...prev,
            [tableId]: {...defaultFilterState}
        }));
    };
    const closeFilters = (tableId) => {
        setTempFilters(prev => ({
            ...prev,
            [tableId]: {...filters[tableId]}
        }));
    };
    const isTempFilterDifferent = (tableId) => {
        const tempFilter = tempFilters[tableId] || {};
        const permanentFilter = filters[tableId] || {};

        return Object.keys({...tempFilter, ...permanentFilter}).some(key => {
            return tempFilter[key] !== permanentFilter[key];
        });
    };

    const isFilterActive = (tableId, key, isPermanentFilters = false) => {
        const value = isPermanentFilters ?
            filters[tableId] ? filters[tableId][key] : undefined
            : tempFilters[tableId] ? tempFilters[tableId][key] : undefined;
        if (value === undefined || value === null || value === '' || value === false) {
            return false;
        }
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return value.some(item => item !== null && item !== undefined && item !== '');
            } else {
                return Object.keys(value).some(key => value[key] !== null && value[key] !== undefined && value[key] !== '');
            }
        }
        return true;
    };

    const hasTempSelectedFilters = (tableId) => {
        return tempFilters[tableId] && Object.keys(tempFilters[tableId]).some(key => isFilterActive(tableId, key));
    };
    const hasSelectedFilters = (tableId) => {
        return filters[tableId] && Object.keys(filters[tableId]).some(key => isFilterActive(tableId, key, true));
    };

    return (
        <FilterContext.Provider value={{
            filters,
            tempFilters,
            updateTempFilter,
            applyFilters,
            cancelFilters,
            removeAllFilters,
            isFilterActive,
            hasSelectedFilters,
            hasTempSelectedFilters,
            isTempFilterDifferent,
            closeFilters
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useTableFilter = (tableId) => {
    const {
        filters,
        tempFilters,
        updateTempFilter,
        applyFilters,
        cancelFilters,
        removeAllFilters,
        isFilterActive,
        hasSelectedFilters,
        hasTempSelectedFilters,
        isTempFilterDifferent,
        closeFilters
    } = useContext(FilterContext);

    const tableFilter = tempFilters[tableId] || defaultFilterState;
    const permanentFilter = filters[tableId] || defaultFilterState;

    const setTableFilter = (key, value) => {
        updateTempFilter(tableId, key, value);
    };

    // Other functionalities can be added here, using the tableId

    return {
        tableFilter,
        permanentFilter,
        setTableFilter,
        applyFilters,
        cancelFilters,
        removeAllFilters,
        isFilterActive,
        hasSelectedFilters,
        hasTempSelectedFilters,
        isTempFilterDifferent,
        closeFilters
    };
};