import {createEditorState, textEditorFormat} from "./formatters";
import {EditorState} from "draft-js";

export const getAgencyNote = (agencyId, agenciesNotes)  => {
    // Check if the agencyId exists in the agenciesNotes array
    if (!Array.isArray(agenciesNotes)) {
        agenciesNotes = [];
    }
    let agencyNote = agenciesNotes.find(agency => agency?.agencyId === agencyId);

    // If the agencyId exists, return the found object
    if (agencyNote) {
        return createEditorState(agencyNote?.notes);
    }

    // Return the new object
    return EditorState.createEmpty();
}

export const updateAgencyNotes = (agencyId, agenciesNotes, newNotes)  => {
    // Check if the agencyId exists in the agenciesNotes array
    if (!agenciesNotes) {
        agenciesNotes = [];
    }else {
        // Ensure we're working with a mutable copy of the array
        agenciesNotes = [...agenciesNotes];
    }
    // Find the index of the agency object by agencyId
    const index = agenciesNotes.findIndex(note => note.agencyId === agencyId);


    if (index !== -1) {
        // Create a new object with updated notes
        agenciesNotes[index] = {
            ...agenciesNotes[index],
            notes: textEditorFormat(newNotes)
        };
    } else {
        // Agency does not exist, create a new object with the agencyId and newNotes
        const newAgencyNote = { agencyId, notes: textEditorFormat(newNotes)};
        agenciesNotes.push(newAgencyNote);
    }
    // Return the updated agenciesNotes array
    return agenciesNotes;
}