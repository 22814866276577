import Swal from 'sweetalert2';

export default function popupAutoCLoseConfirmation(parameters, thenFuctionName) {
    let timerInterval
    Swal.fire({
        title: parameters.title ? parameters.title : 'Are you sure?',
        html: parameters.html ? parameters.html : "You won't be able to revert this!",
        icon: parameters.icon ? parameters.icon : 'warning',
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonColor: parameters.cancelButtonColor ? parameters.cancelButtonColor : '#393BA5',
        cancelButtonText: parameters.confirmButtonText ? parameters.confirmButtonText : 'Yes, delete it!',
        timer: parameters.timer ? parameters.timer : 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
                b.textContent = ((Swal.getTimerLeft() % 60000) / 1000).toFixed(0)
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
            thenFuctionName()
        }
    })
}