import { lazy, Suspense } from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
const HeaderBreadcrumbs = lazy(() => import('../breadcrumbs')); // Lazy-loaded

const PageHeaderComponent = ({ title }) => {
    const titleExists = Boolean(title);

    return (
        <Paper
            elevation={4}
            sx={{
                mb: 2,
                borderRadius: '12px',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    px: 3,
                    py: 2, // Increase padding if title does not exist
                }}
            >
                <Suspense fallback={<div>Loading...</div>}>
                    <HeaderBreadcrumbs />
                </Suspense>
                {/* Render title or a placeholder if title is not present */}
                {titleExists ? (
                    <Typography variant="subtitle2">
                        {title}
                    </Typography>
                ) : (
                    <Box sx={{ height: 22 }}> {/* Placeholder with the height of the title */}</Box>
                )}
            </Box>
        </Paper>
    );
};

export default PageHeaderComponent;
