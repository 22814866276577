import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';
import {
    getChipStatusCell, renderDeadlineCell,
} from "../utilis/columnHelpers";

const getAwardColumns = (
    {
        baseURL = '/administrator/awards',
        role = '',
        handleButtonClick,
        interestedClients
    }
) => {
    const columns = [
        {
            field: 'name',
            headerName: t('columns.manageAwards.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`${baseURL}/${params?.id}?update_award`}>{params.value}</Link>),
        },
        {
            field: 'organizerCompany',
            headerName: t('columns.manageAwards.organizerCompany'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
        },
        {
            field: 'industries',
            headerName: t('columns.manageEvents.industries'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            valueGetter: (params) => params.row.industries?.map(industry => industry.name).join(', ')
        },
        {
            field: 'applicationDeadline',
            headerName: t('columns.manageAwards.applicationDeadline'),
            flex: 100,
            minWidth: 150,
            maxWidth: 200,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.applicationDeadline
            },
            renderCell: (params) => {
                return renderDeadlineCell(params?.row?.applicationDeadline)
            }
        }
    ]
    if (role === 'Administrator') {
        columns.push({
            field: 'status',
            headerName: t('columns.manageAwards.status'),
            width: 200,
            align: "center",
            resizable: false,
            headerAlign: 'center',
            renderCell: getChipStatusCell(),
            valueGetter: (params) => {
                return params?.row?.isDisabled ? 'Disabled' : params?.row?.status === 'Approved' ? 'Published' : 'Pending'
            },
        });
    }
    if (role.includes("Subscriber")) {
        columns.push({
            field: 'clients',
            headerName: t('columns.viewEvents.clients'),
            width: 180,
            align: "center",
            resizable: false,
            headerAlign: 'center',
            valueGetter: (params) => {
                return interestedClients?.filter((client) => client?.recognition?.award?.id === params?.id)?.length
            },
            renderCell: (params) => {
                let numberOfInterestedClients = interestedClients?.filter((client) => client?.recognition?.award?.id === params?.id).length
                return <Avatar
                    onClick={() => {
                        handleButtonClick(params);
                    }}
                    sx={{
                        backgroundColor: "#717485",
                        color: "#fff",
                        fontSize: "1rem",
                        width: 30,
                        height: 30,
                    }}
                >
                    {numberOfInterestedClients}
                </Avatar>
            }
        });
    }

    return columns
}
export default getAwardColumns;