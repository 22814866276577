import React, {useState, useEffect, createContext} from 'react';
import GlobalDataGridPro from "../manage";
import AwardFiltersContainer, {AwardFilters} from "./Filter";

import searchByString from "../../helpers/searchByString";
import dayjs from "dayjs";
import {filterBySelection} from "../../helpers/filterBySelection";
import getAwardColumns from "../manage/globalColumns/awards";

export const AwardContext = createContext();
export const ViewAwardsOfClientGrid = ({list, inForm = false, loading}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);

    const [filterByApplicationDeadline, setFilterByApplicationDeadline] = useState(null)

    const [filterByIndustries, setFilterByIndustries] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const formatDate = (date) => {
        return dayjs(date).format('MM/DD/YYYY');
    };
    const removeAllFilters = () => {

        setFilterByApplicationDeadline(null)

        setFilterByIndustries([])

    }
    const hasFilters = filterByApplicationDeadline !== null || filterByIndustries.length !== 0
    useEffect(() => {
        // Perform the search
        let results
        results = searchByString(list, searchTerm);
        const filteredParams = {


            applicationDeadline: filterByApplicationDeadline ? formatDate(filterByApplicationDeadline) : '',


            industries: filterByIndustries.map((industry) => {
                return industry.name
            }),

        }
        results = filterBySelection(results, filteredParams)
        setSearchResults(results)
    }, [filterByApplicationDeadline, filterByIndustries, searchTerm])
    const toogleFilters = (event) => {
        setAnchorEl(anchorEl ? null : event?.currentTarget);
    };
    const CustomColumns = React.useMemo(
        () => [
            ...getAwardColumns({
                baseURL: '/agency/awards/browse'
            }),
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Awards",
        title: inForm ? "" : "Awards",
        searchLabel: "Search Award",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleSearch: handleSearch,
        handleFilter: toogleFilters,
        filterProps: {
            isOpen: Boolean(anchorEl),
            id: 'simple-popper',
            filterComponent: AwardFiltersContainer,
            filterComponentToolbar: AwardFilters,
            anchorEl: anchorEl,
            title: 'Filters',
            removeAllFilters: removeAllFilters,
            hasFilters: hasFilters
        },
        searchTerm: searchTerm,
        inForm: inForm,
        loading: loading
    }
    const context = {
        filterByApplicationDeadline, filterByIndustries,
        setFilterByApplicationDeadline, setFilterByIndustries,
        removeAllFilters
    }
    return (

        <AwardContext.Provider value={context}>
            <GlobalDataGridPro {...dataGridProps}/>

        </AwardContext.Provider>

    );
};