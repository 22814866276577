import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";

import {
    RECORD_LOCKING_TASK_MUTATION,
    REQUEST_TASK_QUERY,
    REQUEST_TASKS_OF_SUBMISSION_QUERY,
    UPDATE_TASK_MUTATION
} from "../../../graphql/task";
import {
    REQUEST_CURRENT_SUBMISSION_STATUS_QUERY,
    REQUEST_SUBMISSION_QUERY
} from "../../../graphql/submission";
import {REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../../graphql/user";

import {TaskForm} from "../../forms";
import {taskFormValidations} from "../Helpers/validation";
import Toast from "../../alerts";
import {createEditorState, textEditorFormat} from "../../../helpers/formatters";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";

const EditTask = ({type}) => {
    const {t} = useTranslation();
    const {taskId} = useParams();
    const {user} = useContext(AppContext);

    const [taskData, setTaskData] = useState({})
    const [isEditing, setIsEditing] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [userList, setUserList] = useState([]);

    // mutation for record locking task
    const [recordLocking] = useMutation(RECORD_LOCKING_TASK_MUTATION);

    // Use a custom hook to manage form states and validations
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(taskFormValidations(t)),
        values: {
            ...taskData,
            user: {
                id: taskData?.user?.id,
                name: taskData?.user?.firstName + " " + taskData?.user?.lastName,
                firstName: taskData?.user?.firstName,
                lastName: taskData?.user?.lastName,
            }
        }
    });
    const {trigger, reset, formState: {isValid}, getValues} = methods;

    // Query for task, submission, and team member data
    const {data, refetch} = useQuery(REQUEST_TASK_QUERY, {
        variables: {
            parameters: {
                task: {
                    id: taskId,
                },
            },
        }
    });
    const {
        data: submissionData,
        loading: submissionLoading
    } = useQuery(REQUEST_CURRENT_SUBMISSION_STATUS_QUERY, {
        variables: {
            parameters: {
                submission: {
                    id: taskData?.submission?.id,
                }
            },
        }
    });
    const {data: teamMembersData} = useQuery(REQUEST_TEAM_MEMBER_USERS_QUERY, {
        variables: {
            parameters: {
                subscriber: {
                    id: user?.subscriber?.id,
                }
            }
        }
    });

    // Mutation for updating task
    const [updateTask] = useMutation(UPDATE_TASK_MUTATION, {
        refetchQueries: [{
            query: REQUEST_TASKS_OF_SUBMISSION_QUERY, variables: {
                parameters: {
                    submission: {
                        id: taskData?.submission?.id
                    }
                }
            }
        },
            {
                query: REQUEST_SUBMISSION_QUERY,
                variables: {
                    parameters: {
                        submission: {
                            id: taskData?.submission?.id,
                        }
                    },
                }
            }],
        awaitRefetchQueries: true
    });
    // Handlers for form submission and cancellation
    const cancelChanges = () => {
        reset(taskData)
    }
    const handleSubmit = () => {
        let data = getValues()
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(taskData);
        if (!isDataChanged) return;
        data.description = textEditorFormat(data?.description)

        updateTask({
            variables: {
                parameters: {
                    task: {
                        ...data,
                        user: {
                            id: data?.user?.id,
                            firstName: data?.user?.firstName,
                            lastName: data?.user?.lastName,
                        },
                        submissionStatus: data?.submissionStatus?.name,
                    },
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        fullName: user?.fullName,
                    }
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.taskUpdated')
                });
                refetch();
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    // Effect hooks for updating state based on query data
    useEffect(() => {
        recordLocking({
            variables: {
                parameters: {
                    task: {
                        id: taskId,
                    },
                    user: {
                        id: user?.id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        fullName: user?.fullName,
                    },
                    updating: true,
                }
            }
        })
            .then((res) => {
                const resData = res?.data?.updateRecordLockingStatusOfTask?.data;
                if (resData?.updating && resData?.updatingBy.id !== user?.id) {
                    setIsEditing(data.updating);
                }
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                if(errorMessage[0] === "Record is locked by another user"){
                    setIsEditing(true)
                }
            });
    }, []);
    useEffect(() => {
        const handleBeforeUnload = () => {
            recordLocking({
                variables: {
                    parameters: {
                        task: {
                            id: taskId,
                        },
                        user: {
                            id: user?.id,
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            fullName: user?.fullName,
                        },
                        updating: false,
                    }
                }
            });

        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (data?.requestTask?.data) {
            const updatedTaskData = {
                ...data.requestTask.data,
                description: createEditorState(data.requestTask.data.description),
                submissionStatus: {
                    id: data.requestTask.data.submissionStatus,
                    name: data.requestTask.data.submissionStatus,
                },
            };
            setTaskData(updatedTaskData);
        }
    }, [data]);

    useEffect(() => {
        if (!submissionLoading && submissionData?.requestSubmission?.data) {
            setSubmissionStatus(submissionData.requestSubmission.data.status.name);
        }
    }, [submissionData, submissionLoading]);

    useEffect(() => {
        if (teamMembersData?.requestTeamMemberUsers?.data) {
            const updatedUserList = teamMembersData.requestTeamMemberUsers.data.map((item) => ({
                id: item.id,
                name: `${item.firstName} ${item.lastName}`,
                firstName: item.firstName,
                lastName: item.lastName,
            }));
            setUserList(updatedUserList);
        }
    }, [teamMembersData]);

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods} >
                    <form
                        onBlur={() => {
                            trigger()
                        }}
                    >

                        <FormWrapperComponent
                            submitFn={isValid ? handleSubmit : null}
                            cancelFn={cancelChanges}
                        >
                            <TaskForm
                                event={type === 'event'}
                                usersOptions={userList}
                                customData={taskData}
                                isUpdate={true}
                                readOnly={submissionStatus !== taskData?.submissionStatus?.name || isEditing}
                                isEditing={isEditing}
                                currentStatus={submissionStatus}
                            />
                        </FormWrapperComponent>
                    </form>
                </FormProvider>
            </LocalizationProvider>
        </Box>

    );
};
export default EditTask;