import React, {useContext, useEffect} from "react";
import {LoginForm} from './component';
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import Toast from "../alerts";
import {AppContext} from "../../App";
import {useLocation, useNavigate} from "react-router-dom";
import {getDefaultPathByRole} from "../../helpers/roleBase";
import {REQUEST_LOGGED_USER_QUERY} from "../../graphql/user";
import {LOGIN_MUTATION} from "../../graphql/credential";
import {loginFormValidations, onRememberMe} from "./helpers";

export default function LoginComponent() {
    const {setUser} = useContext(AppContext);
    const navigate = useNavigate()
    const location = useLocation();
    const {t} = useTranslation();
    const methods = useForm({
        mode: 'onTouched',
        resolver: yupResolver(loginFormValidations(t)),
    });
    const {setValue, handleSubmit} = methods
    const [onLoginUser] = useMutation(LOGIN_MUTATION, {
        refetchQueries: [{query: REQUEST_LOGGED_USER_QUERY, fetchPolicy: 'network-only' }],
        awaitRefetchQueries: true
    });
    //check local storage for email and remember me values
    useEffect(() => {
        let rememberMe = localStorage.getItem('rememberMe');
        let emailAddress = localStorage.getItem('emailAddress');
        let isRemember = !!(rememberMe || rememberMe === 'true')
        if (isRemember && emailAddress) {
            setValue('emailAddress', emailAddress);
            setValue('rememberMe', isRemember);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        onLoginUser({
            variables: {
                parameters: {
                    credential: {username: data.emailAddress, password: data.password},
                },
            },
        })
            .then((res) => {
                const response = res?.data?.login?.data;
                if (response) {
                    onRememberMe(data)
                    setUser(response)
                    const path =
                        location.search.split('?next=')?.[1] || getDefaultPathByRole(response?.userRole?.name)
                    navigate(path)
                }
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast(
                    {
                        type: 'error',
                        message: errorMessage[0]
                    }
                );
            });
    }
    return (
        <FormProvider {...methods} >
            <form>
                <LoginForm onSubmit={handleSubmit(onSubmit)}/>
            </form>
        </FormProvider>
    );
}
