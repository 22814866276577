
export const awardFiltersOptions = [
    {
        title: "manage.viewAwards.filters.deadline",
        fieldName: "deadline",
        isGlobal: true,
        type: 'dateRange',
        defaultValue: [null, null]
    },
    {
        title: "manage.manageEvents.filters.industries",
        fieldName: "industries",
        isGlobal: true,
        type: 'multiSelect',
        defaultValue: []
    }

]
