import React, { useContext, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { ClientForm } from "../../forms";
import { CREATE_CLIENT_MUTATION, REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY } from "../../../graphql/client";
import { useMutation } from "@apollo/client";
import { clientFormValidations } from "../Edit/helpers";
import Toast from "../../alerts";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import { textEditorFormat } from "../../../helpers/formatters";
import { useManagedForm } from "../../../helpers/hooks/useManageForm";

export const AddClient = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(clientFormValidations(t)),
    });
    const { trigger, formState: { isValid }, getValues } = methods;
    const [roleName, setRoleName] = useState('');
    const { user } = useContext(AppContext);
    const [createClient] = useMutation(CREATE_CLIENT_MUTATION, {
        refetchQueries: [
            {
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: true,
                        showOnlyDisabledItems: false,
                    }
                }
            },
            {
                query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY,
                variables: {
                    parameters: {
                        subscriber: {
                            id: user?.subscriber?.id
                        },
                        showAllItems: false,
                        showOnlyDisabledItems: false,
                    }
                }
            }
        ],
        awaitRefetchQueries: true,
    });
    useEffect(() => {
        if (user?.userRole?.name === "Administrator") {
            setRoleName("administrator");
        } else if (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") {
            setRoleName("agency");
        } else if (user?.userRole?.name === "Client" || user?.userRole?.name === "Client Administrator") {
            setRoleName("client");
        }
    }, [user]);
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = getValues();
        data.keywords = textEditorFormat(data?.keywords)
        createClient({
            variables: {
                parameters: {
                    client: {
                        ...data, subscriber: {
                            id: user?.subscriber?.id
                        }
                    },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.clientCreated')
                });
                navigate(`/${roleName}/agency/${user?.subscriber?.id}?update_agency`, { replace: true })
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <FormProvider {...methods} >
                <form
                    onBlur={() => {
                        trigger()
                    }}
                >
                    <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                        title={'New Client'}
                        variant="add"
                        backTo={`/${roleName}/agency/${user?.subscriber?.id}?update_agency`}
                    >
                        <ClientForm />
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>

    );
};