import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import {InputController, AvatarUploadController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import {AppContext} from "../../App";
import ManageTeamMemberUsers from "../manageTeamMemberUsers";
import ManageClientsOfSubscriber from "../manageClientsOfSubscriber";
import ViewClientsOfSubscriber from "../viewClientsOfSubscriber";
import ViewTeamMemberUsersComponent from "../viewTeamMemberUsers";

const SubscriberForm = ({isUpdate}) => {
    const {t} = useTranslation();
    const {control, formState: {errors}} = useFormContext();
    const {user} = useContext(AppContext);

    return (
        <>
            <FormContainer title={t('section.details')} to={'details'}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                        <AvatarUploadController label={t("enter.user.avatar")} control={control} name="logo"/>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.name}
                                    name='name'
                                    required
                                    fullWidth
                                    label={t("enter.name")}
                                    autoComplete="off"
                                    disabled={isUpdate}
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.phoneNumber}
                                    name='phoneNumber'
                                    required
                                    fullWidth
                                    label={t("enter.phoneNumber")}
                                    autoComplete="tel"
                                    type="phone"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.emailAddress}
                                    name='emailAddress'
                                    required
                                    fullWidth
                                    label={t("enter.emailAddress")}
                                    autoComplete="email"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController control={control}
                                                 error={errors?.website}
                                                 name='website'
                                                 fullWidth
                                                 label={t("enter.website")}
                                                 autoComplete="off"
                                                 type="link"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.address}
                                    name='address'
                                    fullWidth
                                    label={t("enter.address")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.city}
                                    name='city'
                                    fullWidth
                                    label={t("enter.city")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.state}
                                    name='state'
                                    fullWidth
                                    label={t("enter.state")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            <Grid item xs={2} md={2} sx={{height: '95px'}}>
                                <InputController
                                    control={control}
                                    error={errors?.zipCode}
                                    name='zipCode'
                                    fullWidth
                                    label={t("enter.zipCode")}
                                    autoComplete="off"
                                    type="text"

                                />
                            </Grid>
                            {!isUpdate ? <></> :
                                <Grid item xs={12} md={6} sx={{height: '95px'}}>
                                    <InputController
                                        control={control}
                                        error={errors?.ownerUser?.lastName}
                                        name='ownerUser.fullName'
                                        required
                                        fullWidth
                                        label={t("section.admin")}
                                        disabled={isUpdate}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </FormContainer>
            {isUpdate ? <></> :
                <FormContainer title={t('section.admin')} to={'admin'}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{height: '95px'}}>
                            <InputController
                                control={control}
                                error={errors?.ownerUser?.lastName}
                                name='ownerUser.lastName'
                                required
                                fullWidth
                                label={t("enter.ownerUser.lastName")}
                                autoComplete="off"
                                type="text"

                            />
                        </Grid> <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.ownerUser?.firstName}
                            name='ownerUser.firstName'
                            required
                            fullWidth
                            label={t("enter.ownerUser.firstName")}
                            autoComplete="off"
                            type="text"
                        />
                    </Grid> <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.ownerUser?.emailAddress}
                            name='ownerUser.emailAddress'
                            required
                            fullWidth
                            label={t("enter.ownerUser.emailAddress")}
                            autoComplete="email"
                            type="email"

                        />
                    </Grid> <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.ownerUser?.phoneNumber}
                            name='ownerUser.phoneNumber'
                            required
                            fullWidth
                            label={t("enter.ownerUser.phoneNumber")}
                            autoComplete="tel"
                            type="phone"

                        />
                    </Grid>
                    </Grid>
                </FormContainer>
            }
            {user?.userRole?.name === 'Subscriber Administrator' || user?.userRole?.name === 'Subscriber' ? <>
                    <FormContainer title={t('section.teamMembers')} to={'teamMembers'}>
                        {user?.userRole?.name === 'Subscriber Administrator' ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ManageTeamMemberUsers inForm={true}/>
                                </Grid>
                            </Grid>
                            : <></>}
                        {user?.userRole?.name === 'Subscriber' ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ViewTeamMemberUsersComponent inForm={true}/>
                                </Grid>
                            </Grid>
                            : <></>}
                    </FormContainer>
                    <FormContainer title={t('section.clients')} to={'clients'}>
                        {user?.userRole?.name === 'Subscriber Administrator' ?
                            <Grid container spacing={2} title={t('section.clients')} to={'clients'}>
                                <Grid item xs={12}>
                                    <ManageClientsOfSubscriber inForm={true}/>
                                </Grid>
                            </Grid>
                            : <></>}
                        {user?.userRole?.name === 'Subscriber' ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ViewClientsOfSubscriber inForm={true}/>
                                </Grid>
                            </Grid>
                            : <></>}
                    </FormContainer> </>
                : <></>}
            {user?.userRole?.name === 'Subscriber Administrator' &&
                <FormContainer title={t('section.systemConfiguration')} to={'systemConfiguration'}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{height: '95px'}}>
                            <InputController
                                control={control}
                                error={errors?.gracePeriod}
                                name='gracePeriod'
                                fullWidth
                                label={t("enter.gracePeriod")}
                                autoComplete="off"
                                type="text"
                                inputMode={'number'}
                                helperText="Set delay period in hours"
                            />
                        </Grid>
                    </Grid>
                </FormContainer>
            }
        </>
    );
};
export default SubscriberForm;