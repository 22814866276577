import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {IconButton, InputAdornment, Popper} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useTableFilter} from "../../filters/containers/hooks/filterProvider";

export const DataGridSubToolbar = (parameters) => {
    const {t} = useTranslation();
    const {closeFilters} = useTableFilter(parameters?.id);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: parameters?.hasSubTitle ? 'space-between':'end',
            pb: 1,
            px: 1
        }}>
            {parameters?.hasSubTitle &&
                <Typography variant="formHeader" id={parameters?.id + '-label'}>
                    {parameters?.title}
                </Typography>}
            <Box sx={{display: 'flex', alignItems: {md: 'end', lg:'center'}, flexDirection:{md: 'column', lg:'row'}}}>
                {parameters?.customSubToolbar &&
                    <Box sx={{mr: 2}}>
                        {parameters?.customSubToolbar}
                    </Box>
                }
                {parameters?.hrefExport &&
                    <Button
                        sx={{borderRadius: '12px', mr: {md: 0, lg:2}, mb: {md: 1, lg:0}, ...parameters?.exportSX}}
                        variant="outlined"
                        color='blue'
                        startIcon={<FileDownloadIcon/>}
                        href={parameters?.hrefExport}
                        target="_blank"
                        id={parameters?.id + '-export'}
                    >
                        {t('button.exportHistory')}
                    </Button>}
                {parameters?.handleFilter &&
                    <Box>
                        <Button
                            sx={{borderRadius: '12px', mr: 2, ...parameters?.filterSX}}
                            variant="outlined"
                            color='blue'
                            startIcon={<FilterListIcon/>}
                            onClick={parameters.handleFilter}
                            id={parameters?.id + '-filters'}
                        >
                            {t('button.filtersCAP')}
                        </Button>
                        <Popper id={parameters?.filterProps?.id}
                                open={parameters?.filterProps?.isOpen}
                                anchorEl={parameters?.filterProps?.anchorEl}
                                placement={parameters?.filterProps?.placement || 'bottom-end'}
                                sx={{pt: 1, zIndex: 1300}}
                        >
                            <Paper elevation={12} sx={{width: '100%', maxWidth: '500px'}}>
                                <Box
                                    sx={{
                                        backgroundColor: "#1A2D3F",
                                        height: '48px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                    <Typography variant="formSubHeader" ml={2}>
                                        {parameters?.filterProps?.title}
                                    </Typography>
                                    <Box>
                                        <IconButton aria-label="close" size="small" sx={{mr: 2, ml: 1}}
                                                    onClick={() => {
                                                        closeFilters(parameters?.id)
                                                        parameters.handleFilter()
                                                    }}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                {parameters?.filterProps?.filterComponent(
                                    parameters?.id,
                                    parameters?.filterProps?.filterOptions,
                                    parameters.handleFilter
                                )}
                            </Paper>
                        </Popper>
                    </Box>
                }
                {parameters?.filterCircle && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginRight: 'auto'
                    }}>
                        {parameters?.filterCircle()}
                    </Box>
                )}
                <TextField
                    variant="outlined"
                    size="small"
                    sx={parameters?.searchSX}
                    label={parameters?.searchLabel}
                    onChange={parameters?.handleSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};