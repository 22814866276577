import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewTeamMemberUsersGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../graphql/user";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";

export default function ViewTeamMemberUsersComponent({inForm = false}) {
    const [userItems, setUserItems] = useState([]);
    //This will Replace the data with the data from the API

    const {user} = useContext(AppContext);
    const {data, loading} = useQuery(REQUEST_TEAM_MEMBER_USERS_QUERY, {
        variables: {
            parameters: {
                subscriber: {
                    id: user?.subscriber?.id
                }
            }
        }
    });
    useEffect(() => {
        if (data?.requestTeamMemberUsers?.data) {
            setUserItems(data?.requestTeamMemberUsers?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                pt: 2,
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={'Users Dashboard'}/>}
            <ViewTeamMemberUsersGrid
                list={userItems} inForm={inForm} loading={loading}
            />
        </Box>
    );
}
