import * as yup from "yup";
import regex from "../../../helpers/regex";
export const changePasswordFormValidations = (t) => {
    return yup.object().shape({
        password: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.password, t('errorMessage.invalidPasswordFormat'))
            .required(t('errorMessage.passwordRequired')),
        confirmPassword: yup
            .string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .matches(regex.password, t('errorMessage.invalidPasswordFormat'))
            .required(t('errorMessage.confirmPasswordRequired'))
            .oneOf([yup.ref('password'), null], t('errorMessage.passwordsMustMatch')),
    });
};
