import * as React from "react";
import {Controller} from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {FormLabel} from "@mui/material";
import FormControl from "@mui/material/FormControl";

const CircularRadioController = ({control, name, row = false, label, ...rest}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="false" // Set the default value to "false"
            render={({field}) => {
                // Check if the value is null, if yes, set it to "false"
                const value = field.value === null ? "false" : field.value.toString();

                return (
                    <FormControl>
                        <FormLabel id="row-radio-buttons-group-label">{label}</FormLabel>
                        <RadioGroup
                            aria-label={name}
                            row={row}
                            value={value} // Use the updated value
                            onChange={(e) => field.onChange(e.target.value)}
                            style={{display: "inline-flex"}}
                        >
                            <FormControlLabel
                                {...rest}
                                value="true"
                                control={<Radio/>}
                                label="Yes"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                {...rest}
                                value="false"
                                control={<Radio/>}
                                label="No"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                )
                    ;
            }}
        />
    );
};

export default CircularRadioController;
