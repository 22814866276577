import {useLocation} from "react-router-dom";
import {toTitleCase} from "../../../helpers/formatters";

function isUUID(str) {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return regex.test(str);
}
function processSearch(search) {
    let processed = search;

    // Remove the leading '?'
    if (processed.startsWith('?')) {
        processed = processed.substring(1);
    }

    // Remove 'new'
    processed = processed.replace(/new_/g, '');
    if (processed.includes('update')) {
        processed = processed.replace(/update_/g, '') + '_Profile';
    }


    return processed;
}

export const PathArray = () => {
    const location = useLocation();
    const pathSegments = location?.pathname?.split('/')?.filter(Boolean); // filter to remove empty strings

    return pathSegments?.map((segment, index) => {
        const isSegmentUUID = isUUID(segment);
        const searchValue = processSearch(location.search,location.pathname)
        const value = isSegmentUUID ? toTitleCase(searchValue) : (index === 0 ? toTitleCase(segment) + ' Dashboard' : toTitleCase(segment));
        return {
            value,
            url: '/' + pathSegments?.slice(0, index + 1).join('/')
        };
    });
};