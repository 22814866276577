import React, {useContext, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Icon, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {SubNavToItem} from "./index";
import SubScrollToItem from "./SubScrollToItem";
import {AppContext} from "../../App";
import DataProtectionPopUp from "../popUpAlerts/dataProtection";
import {useTranslation} from "react-i18next";

const NavItem = (props) => {
    const location = useLocation();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isFormDirty, setFormDirty} = useContext(AppContext);
    const {
        icon = '',
        title = '',
        subNavs = [],
        isMinimized,
        href = '',
        type,
        hasSubNav = false,
        parentHref = '',
        noButton = false,
        lastItem= {} ,
        id
    } = props;
    const isActive = useMemo(() => location?.pathname?.includes(parentHref ? parentHref : href) && type !== 'button', [location?.pathname])

    const discardChanges = () => {
        setFormDirty(false)
        navigate(-1); // Navigate only if user confirms
    };
    const handleNavigation = () => {
        if (isFormDirty) {
            DataProtectionPopUp.show(isFormDirty, discardChanges, t);
        } else {
            navigate(-1); // Navigate if the form isn't dirty
        }
    }

    return (
        <>
            <ListItem
                key={id || title}
                disablePadding
                sx={{height: '48px'}}
            >
                <ListItemButton
                    sx={{
                        p: 0,
                        height: '48px',
                        '&:hover': noButton ? {
                            backgroundColor: 'inherit'  // No change in background color on hover
                        } : {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'  // Your existing hover effect
                        },
                        '&:focus': noButton ? {
                            outline: 'none',
                            backgroundColor: 'inherit'
                        } : {},
                        '&:active': noButton ? {
                            backgroundColor: 'inherit'
                        } : {},
                        cursor: noButton ? 'default' : 'pointer'
                    }}
                    onClick={() => {
                        if (!noButton) {
                            if (type === 'button') {
                                handleNavigation()
                            } else {
                                navigate(href)
                            }
                        }
                    }}
                >
                    <ListItemIcon sx={{justifyContent: 'center', width: isMinimized && '100%'}}>
                        <Icon
                            as={icon}
                            sx={{
                                ml: 0,
                                mr: 0,
                                fontSize: '24px',
                                color: (isActive && hasSubNav) || (isActive && !hasSubNav) ? '#EE814D' : isActive ? '#082032' : '#fff'
                            }}
                        />
                    </ListItemIcon>
                    {!isMinimized &&
                        <ListItemText
                            primaryTypographyProps={
                                {
                                    sx: {
                                        color: (isActive && hasSubNav) || (isActive && !hasSubNav) ? '#EE814D' : isActive ? '#FFFFFF' : '#fff'
                                    },
                                    variant: "navItem"
                                }
                            }
                            primary={type === 'button' ? 'Back' : title}
                        />
                    }
                </ListItemButton>
            </ListItem>
            {hasSubNav && subNavs?.length &&
                subNavs.map((item, index) => {
                    const lastI = Object.keys(lastItem).length ? lastItem : subNavs[subNavs.length - 1]
                    return (
                        item?.type === 'scroll' ?
                            SubScrollToItem({item, index, lastItem: lastI})
                            :
                            SubNavToItem({item})
                    )
                })
            }
        </>
    );
};

export default NavItem;
