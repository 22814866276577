import {DatePicker} from "@mui/x-date-pickers-pro";
import React from "react";

const DatePickerComponent = ({value, setValue, componentSx, textFieldSx,fullWidth = true}) => {
    return <DatePicker
        sx={{...componentSx}}
        value={value}
        onChange={setValue}
        slotProps={{
            textField: {
                size: 'small',
                fullWidth: fullWidth,
                sx: {minWidth: '150px', ...textFieldSx}
            },
        }}
        label='Deadline'/>;
}
export default DatePickerComponent