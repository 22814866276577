import React from "react";
import {Grid} from "@mui/material";
import {ReadOnlyFieldController} from "../formController";

const readOnlyDetailsForm = (commonFields, recognitionData, id) => {
    return (
        <Grid container spacing={2} key={id}>
            {commonFields.map(({xs, md, height,id,  fields}) => (
                <Grid item xs={xs} md={md} sx={{height}} key={xs + md + id}>
                    {fields.map(field => (
                        <ReadOnlyFieldController
                            key={field.name}
                            customData={recognitionData}
                            {...field}
                        />
                    ))}
                </Grid>
            ))}
        </Grid>
    );
}
export default readOnlyDetailsForm;