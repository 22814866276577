import React,{useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {UserForm} from "../../forms";
import {CREATE_TEAM_MEMBER_USER_MUTATION, REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../../graphql/user";
import {useMutation} from "@apollo/client";
import {userFormValidations} from "../Edit/helpers";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
export const AddUser = () => {
    const {t} = useTranslation();
        const navigate = useNavigate();
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: yupResolver(userFormValidations(t)),
    });
    const {trigger, formState: {isValid}, getValues} = methods;
    const [roleName, setRoleName] = useState('');
    const {user} = useContext(AppContext);
    const [createTeamMemberUser] = useMutation(CREATE_TEAM_MEMBER_USER_MUTATION,{
        refetchQueries: [{
            query:REQUEST_TEAM_MEMBER_USERS_QUERY,
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        }],
        awaitRefetchQueries: true,
    });
     useEffect(() => {
        if(user?.userRole?.name === "Administrator") {
            setRoleName("administrator");
        }else if (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") {
            setRoleName("agency");
        }else if(user?.userRole?.name === "Client" || user?.userRole?.name === "Client Administrator") {
            setRoleName("client");
        }
    }, [user]);
const handleSubmit = (e) => {
    e.preventDefault();
        let data = getValues()

    createTeamMemberUser({
            variables: {
                parameters: {
                    user: {
                        ...data,
                        userRole: {
                            name:"Subscriber"
                        },
                        subscriber: {
                            id: user?.subscriber?.id
                        }
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    },
                },
            },
        })
            .then(() => {
                Toast({
                    type: 'success',
                    message: t('successMessage.userCreated')
                });
                navigate(`/${roleName}/agency/${user?.subscriber?.id}?update_agency`,{ replace: true })
            })
            .catch((error) => {
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <FormProvider {...methods} >
                <form
                    onBlur={()=>{trigger()}}
                  >
                    <FormWrapperComponent submitFn={isValid ? handleSubmit : null}
                        title={'New User'}
                        variant="add"
                        backTo={`/${roleName}/agency/${user?.subscriber?.id}?update_agency`}
                    >
                        <UserForm/>
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>

    );
};