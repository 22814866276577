import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';
import {CheckIcon, EmailCell, formatPhoneNumber, getChipStatusCell} from "../utilis/columnHelpers";

const getTeamMemberColumns = (
    {
        role = '',
        handleButtonClick
    }
) => {
    const columns = [
        {
            field: 'fullName',
            headerName: t('columns.manageTeamMemberUsers.fullName'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (<Link href={`/agency/team_members/${params?.id}?update_user`}>{params.value}</Link>),
        },
        {
            field: 'emailAddress',
            headerName: t('columns.manageTeamMemberUsers.emailAddress'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (<EmailCell value={params.value}/>)
        },
        {
            field: 'phoneNumber',
            headerName: t('columns.manageTeamMemberUsers.phoneNumber'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => formatPhoneNumber(params.value)
        },
        {
            field: 'isAdmin',
            headerName: t('columns.manageTeamMemberUsers.isAdmin'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (<CheckIcon value={params.value}/>)
        },
        {
            field: 'submissionInProcess',
            headerName: t('columns.manageTeamMemberUsers.submissionInProcess'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "left",
            headerAlign: 'left'
        },
        {
            field: 'accepted',
            headerName: t('columns.manageTeamMemberUsers.accepted'),
            flex: 100,
            minWidth: 100,
            maxWidth: 150,
            align: "left",
            headerAlign: 'left',
        },
    ]

    if (role.includes("Subscriber Administrator")) {
        columns.push(
            {
                field: "assignedClients",
                headerName: t('columns.manageTeamMemberUsers.assignedClients'),
                width: 200,
                align: "center",
                resizable: false,
                headerAlign: 'center',
                renderCell: (params) => {
                    let numberOfInterestedClients = params?.value || 0;
                    return <Avatar
                        onClick={() => {
                            handleButtonClick(params);
                        }}
                        sx={{
                            backgroundColor: "#717485",
                            color: "#fff",
                            fontSize: "1rem",
                            width: 30,
                            height: 30,
                        }}
                    >
                        {numberOfInterestedClients}
                    </Avatar>
                }
            },
            {
                field: 'status',
                headerName: t('columns.manageEvents.status'),
                width: 200,
                align: "center",
                resizable: false,
                headerAlign: 'center',
                renderCell: getChipStatusCell(true),
                valueGetter: (params) => {
                    return params?.row?.isDisabled ? 'Disabled' : 'Active'
                },
            }
        );
    }

    return columns
}
export default getTeamMemberColumns;