import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ManageTeamMemberUsersGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../graphql/user";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";

export default function ManageTeamMemberUsersComponent({inForm = false}) {
    const [userItems, setUserItems] = useState([]);
    const {user} = useContext(AppContext);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {data, loading} = useQuery(REQUEST_TEAM_MEMBER_USERS_QUERY, {
        variables: {
            parameters: {
                subscriber: {
                    id: user?.subscriber?.id
                },
                showAllItems: showAll,
                showOnlyDisabledItems: false,
            }
        }
    });

    const handleShowAll = (e) => {
        const value = e.target.checked;
        setShowAll(value);
      };
      useEffect(() => {
        if (!loading && data?.requestTeamMemberUsers?.data) {
            setUserItems(data?.requestTeamMemberUsers?.data);
          setIsLoading(false);
        } else if (!loading && !data?.requestTeamMemberUsers?.data) {
          setIsLoading(false);
        }
      }, [data, loading]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 1.5,
            }}
        >
            {inForm ? <></> : <PageHeader/>}
            <ManageTeamMemberUsersGrid
                list={userItems} 
                inForm={inForm} 
                loading={isLoading}
                handleShowAll={handleShowAll}
                showAll={showAll}
            />
        </Box>
    );
}
