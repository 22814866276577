import React, { useContext, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { ViewClientsOfSubscriberGrid } from "./component";
import { PageHeader } from "../pageHeader";
import { REQUEST_CLIENTS_OF_BROWSE_QUERY } from "../../graphql/client";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../App";

export default function ViewClientsOfSubscriberComponent({ inForm = false }) {
    const { user } = useContext(AppContext);
    const isAdmin = user?.userRole?.name === 'Subscriber Administrator';
    const [clientItems, setClientItems] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [myClients, setMyClients] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { data, loading } = useQuery(REQUEST_CLIENTS_OF_BROWSE_QUERY,
        {
            variables: {
                parameters: {
                    subscriber: {
                        id: user?.subscriber?.id
                    },
                    user: {
                        id: myClients ? user?.id : null
                    },
                    showAllItems: showAll,
                    showOnlyDisabledItems: false
                }
            }
        });
    const handleShowAll = (e) => {
        const value = e.target.checked;
        setShowAll(value);
    };

    const toggleShowMyClients = (e) => {
        setMyClients(e);

    }
    useEffect(() => {
        if (!loading && data?.requestClientsBrowseOfSubscriber?.data) {
            setClientItems(data?.requestClientsBrowseOfSubscriber?.data);
            setIsLoading(false)
        } else if (!loading && !data?.requestClientsBrowseOfSubscriber?.data) {
            setIsLoading(false)
        }
    }, [data, loading]);
    useEffect(() => {
        if(isAdmin) {
            setMyClients(false);
        }
    }, [user]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader />}
            <ViewClientsOfSubscriberGrid
                list={clientItems}
                inForm={inForm}
                loading={isLoading}
                handleShowAll={handleShowAll}
                toggleShowMyClients={toggleShowMyClients}
                showAll={showAll}
                myClients={myClients}
            />
        </Box>
    );
}
