import React from "react";
import Grid from "@mui/material/Grid";
import {InputController, TextEditorController} from "../formController";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import FormContainer from "../formContainer";
import Typography from "@mui/material/Typography";

const ContactForm = ({isUpdate = false}) => {
    const {t} = useTranslation();
    const {control, formState: {errors}} = useFormContext();
    return (
        <>
            <FormContainer title={t('section.details')} to={'details'}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.name}
                            name='name'
                            required
                            fullWidth
                            label={t("enter.name")}
                            autoComplete="off"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.emailAddress}
                            name='emailAddress'
                            required
                            fullWidth
                            label={t("enter.emailAddress")}
                            autoComplete="email"
                            readOnly={isUpdate}
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '95px'}}>
                        <InputController
                            control={control}
                            error={errors?.phoneNumber}
                            name='phoneNumber'
                            required
                            fullWidth
                            label={t("enter.phoneNumber")}
                            autoComplete="tel"
                            type="phone"
                        />
                    </Grid>
                    {isUpdate &&
                        <Grid item xs={12}>
                            <Typography sx={{mb:1}}>{t('section.notes')}</Typography>
                            <TextEditorController
                                control={control}
                                name='notes'
                                label={t('section.notes')}
                            />
                        </Grid>
                    }
                </Grid>
            </FormContainer>
        </>
    );
};
export default ContactForm;