import React, {useState, useEffect, Suspense} from 'react';
import GlobalDataGridPro from "../manage";
import ClientColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";
import {CircularProgress} from "@mui/material";
const AssignClients = React.lazy(() => import('../forms/assignClients'));

export const ViewClientsGrid = ({list, inForm = false, loading, userInfo}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const toggleClose = () => {
        setIsOpen(!isOpen)
    }

    const CustomColumns = React.useMemo(
        () => [
            ...ClientColumns
        ], []);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Clients",
        title: inForm ? "" : "Clients",
        searchLabel: "Search Client",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        autoHeight: false,
        handleAdd: toggleClose,
        addLabel: "button.assignClient",
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading:loading
    }
    return (
       <>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
            {isOpen && (
                <Suspense fallback={<CircularProgress />}>
                    <AssignClients isOpen={isOpen} handleClose={toggleClose} teamMember={userInfo} />
                </Suspense>

            )}
        </>

    );
};