import React from 'react';
import Box from "@mui/material/Box";
import {ManageUnassignedTeamMembersComponent} from "./component";

export default function ManageUnassignedTeamMembers(props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
            }}
        >
            <ManageUnassignedTeamMembersComponent {...props}/>
        </Box>
    );
}
