import dayjs from "dayjs";

const FilterValues = (arr, key, value) => {
  if (arr && arr.length) {
    return arr.filter((eachObj) => {
      switch (Object.prototype.toString.call(eachObj[key])) {
        case "[object Object]":
          return Object.entries(value).every(
            ([extFilterProperty, extFilterValue]) => {
              return (
                new Map(Object.entries(eachObj[key])).get(extFilterProperty) ===
                extFilterValue
              );
            }
          );
        case "[object Array]":
          return eachObj[key].some((productValue) => {
            for (let v of value) {
              if (Object.values(productValue).includes(v)) {
                return true;
              }
            }
            return false;
          });
        default:
          return value.includes(
            eachObj[key] || eachObj[key] === 0 || eachObj[key] === false
              ? eachObj[key]
              : undefined
          );
      }
    });
  }
};
const formatDate = (date) => {
  return dayjs(date).format("MM/DD/YYYY");
};
export const filterDataByDateRange = (data, dateRange = [null, null]) => {
  const start = dateRange[0] ? new Date(formatDate(dateRange[0])) : null;
  const end = dateRange[1] ? new Date(formatDate(dateRange[1])) : null;

  return data?.filter((item) => {
    const itemStart = new Date(item?.startDate);
    const itemEnd = new Date(item?.endDate);
    // If neither date is provided, return all data
    if (!start && !end) {
      return data;
    }
    // If only startDate is provided
    if (start && !end) {
      return itemStart >= start;
    }
    // If only endDate is provided
    if (!start && end) {
      return itemEnd <= end;
    }
    // Check if the date ranges overlap
    return itemStart <= end && itemEnd >= start;
  });
};
export const filterByDateRange = (data, dateRange, field) => {
  if (!dateRange || !field) {
    return data;
  }
  const start = dateRange[0] ? new Date(formatDate(dateRange[0])) : null;
  const end = dateRange[1] ? new Date(formatDate(dateRange[1])) : null;
  return data.filter((item) => {
    const itemDate = new Date(formatDate(item[field]));
    // If neither date is provided, return all data
    if (!start && !end) {
      return data;
    }
    // Check if the date ranges overlap
    return itemDate >= start && itemDate <= end;
  });
};

function findInObject(obj, key, value) {
    // Check if the object is null or undefined to avoid errors during recursion
    if (obj === null || obj === undefined) {
        return false;
    }

    // Use Object.prototype.hasOwnProperty to check if the current object has the key
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return obj[key] === value;
    }

    // Iterate through each property in the object
    for (let prop in obj) {
        // Continue only if the property is an object and not null
        if (obj[prop] !== null && typeof obj[prop] === 'object') {
            // Recursively search this property (which is an object)
            let found = findInObject(obj[prop], key, value);
            // If the key-value pair is found in the nested object, return true
            if (found) return true;
        }
    }

    // Return false if the key-value pair was not found in the current object
    return false;
}

function filterByKeyValue(data, searchKey, searchValue) {
    // Filter the data array, using the findInObject function to check each item
    return data.filter(item => findInObject(item, searchKey, searchValue));
}

export const filterBySelection = (data, filters) => {
  let result;
  for (const [key, value] of Object.entries(filters)) {
    if (value?.type === "dateRange") {
      if (result && result?.length) {
        result = filterByDateRange(result, value?.value, key);
      } else if (!result) {
        result = filterByDateRange(data, value?.value, key);
      }
    } else if (value?.type === "objSearch") {
      if (result && result?.length &&  value.value) {
        result = filterByKeyValue(result, key, value?.value);
      } else if (!result) {
        result = filterByKeyValue(data, key, value?.value);
      }
    } else if (filters[key]?.length > 0 && !value?.type) {
      const filtered = Array.isArray(filters[key])
        ? filters[key].filter(function (el) {
            return el != null;
          })
        : value;
      if (
        result &&
        result.length > 0 &&
        filters[key].length > 0 &&
        filtered.length > 0
      ) {
        result = FilterValues(result, key, filtered);
      } else if (!result && filters[key].length > 0 && filtered.length > 0) {
        result = FilterValues(data, key, filtered);
      }
    }
  }
  if (result) {
    return result;
  } else {
    return data;
  }
};
