import {gql} from "@apollo/client";


export const CREATE_CONTACT_MUTATION = gql`
    mutation CreateContact ($parameters: CreateContactInput) {
        createContact (parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                emailAddress
                phoneNumber
                notes
                client {
                    name
                    id
                }
            }
        }
    }
`;

export const DELETE_CONTACT_MUTATION = gql`
    mutation DeleteContact($parameters: DeleteContactInput) {
        deleteContact(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;

export const REQUEST_CONTACT_QUERY = gql`
    query RequestContact($parameters: RequestContactInput) {
        requestContact(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                emailAddress
                phoneNumber
                notes
                client {
                    name
                    id
                }
            }
        }
    }
`;

export const REQUEST_CONTACTS_OF_CLIENT_QUERY = gql`
    query RequestContactsOfClient($parameters: RequestContactsOfClientInput) {
        requestContactsOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                emailAddress
                phoneNumber
                isPrimaryContact
            }
        }
    }
`;

export const UPDATE_CONTACT_MUTATION = gql`
    mutation UpdateContact($parameters: UpdateContactInput) {
        updateContact(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
                name
                emailAddress
                phoneNumber
                notes
                client {
                    name
                    id
                }

            }
        }
    }
`;
export const SET_PRIMARY_CONTACT_MUTATION = gql`
    mutation setContactAsPrimaryContactOfClient($parameters: setContactAsPrimaryContactOfClientInput) {
        setContactAsPrimaryContactOfClient(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;
export const SET_PRIMARY_TEAM_MEMBER_MUTATION = gql`
    mutation setPrimaryTeamMemberContact($parameters: setPrimaryTeamMemberContactInput) {
        setPrimaryTeamMemberContact(parameters: $parameters) {
            errors {
                message
            }
            statusCode
            data {
                id
            }
        }
    }
`;