import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {getDefaultPathByRole} from "../roleBase";

export const Home = ({user}) => {
    const navigate = useNavigate();
    const {pathname, search} = useLocation();
    const params = pathname === '/login' ? '' : '?next=' + pathname + search;
    useEffect(() => {
        if (!user?.userRole) {
            navigate(`/login${params}`);
        } else {
            navigate(getDefaultPathByRole(user?.userRole?.name))
        }
    }, [navigate, user]);
};
export const ForwardToLogin = (user) => {
    const navigate = useNavigate();
    const {pathname, search} = useLocation();
    const params = pathname === '/logout' ? '' : '?next=' + pathname + search;
    useEffect(() => {
        if (!user?.userRole) {
            navigate(`/login${params}`);
        }
        // eslint-disable-next-line
    }, [navigate]);
};
export const ForwardToDashboard = ({path}) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`${path}`);
        // eslint-disable-next-line
    }, [navigate]);
};
