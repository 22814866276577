import React, {useState, useEffect, createContext} from 'react';
import GlobalDataGridPro from "../manage";
import AwardColumns from './helpers/columns';
import {useTranslation} from "react-i18next";
import searchByString from "../../helpers/searchByString";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {AwardFormOfClient} from "./viewAwards";

export const AwardContext = createContext();
export const ViewAwardAnalyticsOfClientsGrid = (parameters) => {
    const {
        list, inForm = false, showMyClients,
        showAllClients,
        myClients,
        allClients,
        refetchData,
        loading
    } = parameters;

    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [pageSize, setPageSize] = React.useState(10);

    const [filterByApplicationDeadline, setFilterByApplicationDeadline] = useState(null)
    const [filterByAwardDate, setFilterByAwardDate] = useState([null, null])
    const [filterByIndustries, setFilterByIndustries] = useState([])

    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    useEffect(() => {
        if (myClients && selectedOption === '') {
            setSelectedOption('myClients')
        } else if (allClients && selectedOption === '') {
            setSelectedOption('allClients')
        }
    }, [myClients, allClients]);

    useEffect(() => {
        setSearchResults(list)
    }, [list]);
    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const removeAllFilters = () => {

        setFilterByApplicationDeadline(null)
        setFilterByAwardDate([null, null])
        setFilterByIndustries([])

    }
    const handleRadioChange = (event) => {
        if (event.target.value === 'myClients') {
            setSelectedOption('myClients');
            showMyClients();
        } else {
            setSelectedOption('allClients');
            showAllClients();
        }
    };
    const handleButtonClick = (params) => {
        // Ensure params is defined
        if (!params) return;

        const nextIsOpen = !isOpen;
        setIsOpen(nextIsOpen);

        const {id} = params;
        const currentSearchParams = new URLSearchParams(window.location.search);

        if (nextIsOpen) {
            // Set the parameters to ensure no duplication
            currentSearchParams.set('clientId', id);
            currentSearchParams.set('clientName', params?.row?.name);
            // Construct the new URL with the updated search parameters
            const currentUrl = new URL(window.location.href);
            currentUrl.search = currentSearchParams.toString();
            // Navigate to the new URL
            // If using react-router's navigate function, replace this with your navigate method
            window.history.pushState({}, '', currentUrl.toString());
        } else {
            // Remove the parameters when closing the modal
            currentSearchParams.delete('clientId');
            currentSearchParams.delete('clientName');
            // Construct the new URL with the removed search parameters
            const currentUrl = new URL(window.location.href);
            currentUrl.search = currentSearchParams.toString();
        }
    };
    const filterList = () => {
        return (
            <div>
                <RadioGroup value={selectedOption} onChange={handleRadioChange} sx={{color: "white"}} row>
                    <FormControlLabel
                        value="myClients"
                        control={<Radio color="primary"/>}
                        label={t("button.myClients")}
                    />
                    <FormControlLabel
                        value="allClients"
                        control={<Radio color="primary"/>}
                        label={t("button.allClients")}
                    />
                </RadioGroup>
            </div>
        )
    }
    const CustomColumns = React.useMemo(
        () => [
            ...AwardColumns(handleButtonClick),
        ], [isOpen]);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Awards",
        title: inForm ? "" : "Awards",
        searchLabel: "Search Award",
        rows: searchResults,
        columns: CustomColumns,
        pageSize: pageSize || 10,
        onPageSizeChange: (newPageSize) => {
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 20, 30],
        pagination: false,
        componentsProps: {pageSize: pageSize},
        autoHeight: false,
        handleSearch: handleSearch,
        filterCircle: filterList,
        searchTerm: searchTerm,
        loading: loading
    }
    const context = {
        filterByApplicationDeadline, filterByAwardDate, filterByIndustries,
        setFilterByApplicationDeadline, setFilterByAwardDate, setFilterByIndustries,
        removeAllFilters
    }
    return (

        <AwardContext.Provider value={context}>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
            <AwardFormOfClient isOpen={isOpen} handleClose={handleButtonClick}
                               refetchData={refetchData}/>
        </AwardContext.Provider>

    );
};