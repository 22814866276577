import React, {useState, useEffect, useContext} from 'react';
import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {AppContext} from "../../../App";
import Toast from "../../alerts";
import GlobalDataListPro from "../../list";
import searchByString from "../../../helpers/searchByString";
import {
    ASSIGN_TEAM_MEMBER_MUTATION,
    REQUEST_ASSIGNED_TEAM_MEMBER_QUERY,
    REQUEST_UNASSIGNED_TEAM_MEMBER_QUERY
} from "../../../graphql/teamMember";
import {REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY} from "../../../graphql/client";
import {REQUEST_TEAM_MEMBER_USERS_OF_CLIENT_QUERY, REQUEST_TEAM_MEMBER_USERS_QUERY} from "../../../graphql/user";

export const ManageUnassignedTeamMembersComponent = (parameters) => {
    const {
        list,
        loading,
        clientId
    } = parameters;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const [addClient, setAddClient] = useState(false);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const [unAssignTeamMember] = useMutation(ASSIGN_TEAM_MEMBER_MUTATION,
        {
            refetchQueries: [
                {
                    query: REQUEST_UNASSIGNED_TEAM_MEMBER_QUERY, variables: {
                        parameters: {
                            client: {
                                id: clientId
                            },
                            subscriber: {
                                id: user?.subscriber?.id
                            }
                        }
                    }
                },
                {
                    query: REQUEST_ASSIGNED_TEAM_MEMBER_QUERY, variables: {
                        parameters: {
                            client: {
                                id: clientId
                            },
                            subscriber: {
                                id: user?.subscriber?.id
                            }
                        }
                    }
                },
                {
                    query: REQUEST_CLIENTS_OF_SUBSCRIBER_QUERY, variables: {
                        parameters: {
                            subscriber: {
                                id: user?.subscriber?.id
                            }
                        }
                    }
                },
                {
                    query: REQUEST_TEAM_MEMBER_USERS_QUERY, variables: {
                        parameters: {
                            subscriber: {
                                id: user?.subscriber?.id
                            }
                        }
                    }
                },
                {
                    query: REQUEST_TEAM_MEMBER_USERS_OF_CLIENT_QUERY, variables: {
                        parameters: {
                            client: {
                                id: clientId
                            }
                        }
                    }
                }
            ],
            awaitRefetchQueries: true
        });
    const handleSubmit = (params) => {
        setAddClient(true)
        const {id} = params;

        unAssignTeamMember({
            variables: {
                parameters: {
                    user: {
                        id: id
                    },
                    client: {
                        id: clientId
                    }
                },
            },
        })
            .then(() => {
                setAddClient(false)
                Toast({
                    type: 'success',
                    message: t('successMessage.teamMemberAssigned')
                });
            })
            .catch((error) => {
                setAddClient(false)
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }

    const listActions = ({params}) => {
        return (
            <>
                <Button variant="outlined" style={{color: '#1976D2', borderColor: '#1976D2'}} size='small'
                        disabled={addClient}
                        onClick={() => {
                            handleSubmit(params)
                        }} startIcon={< AddOutlinedIcon/>}>
                    ADD
                </Button>
            </>
        )
    }

    const dataListProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-team-members-unassigned",
        title: t("manage.manageUnassignedTeamMembers.title"),
        subtitle: t("manage.manageUnassignedTeamMembers.subtitle"),
        searchLabel: t("manage.manageUnassignedTeamMembers.search"),
        items: searchResults,
        maxItems: 5,
        hasActions: true,
        Actions: listActions,
        autoHeight: false,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading,
        keyName: ['fullName','emailAddress']
    }
    return (
        <>
            <GlobalDataListPro {...dataListProps}/>
        </>

    );
};