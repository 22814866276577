import {DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import React from "react";
import {t} from "i18next";
const DateRangePickerComponent = ({value = [null, null], setValue, componentSx, isGlobal, field}) => {
    return <DateRangePicker
        sx={{...componentSx}}
        value={value}
        fullWidth
        onChange={isGlobal ? (e)=>{ setValue(field, e)}: setValue}
        slotProps={{
            textField: {
                size: 'small',
                fullWidth:true,
                sx: {minWidth: '120px'}
            },
        }}
    />
}
export default DateRangePickerComponent


export const DateRangePickerSingleComponent = ({value = [null, null], setValue, componentSx, isGlobal, field, label}) => {
    return <DateRangePicker
        sx={{...componentSx}}
        value={value}
        onChange={isGlobal ? (e)=>{ setValue(field, e)}: setValue}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
            textField: {
                size: 'small',
                label: t(label),
                placeholder: t(label),
                sx:{minWidth: '200px', input: { fontSize: '14px' }}
            },
        }}
    />
}