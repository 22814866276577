import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {AwardForm} from "../../forms";
import {CREATE_AWARD_MUTATION, REGISTER_AWARD_MUTATION, REQUEST_AWARDS_QUERY} from "../../../graphql/award";
import {useMutation} from "@apollo/client";
import {awardFormValidations} from "../Edit/helpers";
import Toast from "../../alerts";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import FormWrapperComponent from "../../pageHeader/PageHeaderForm";
import {awardRegistryFormValidations} from "../Edit/helpers/registry";
import {useManagedForm} from "../../../helpers/hooks/useManageForm";
import {textEditorFormat} from "../../../helpers/formatters";

export const AddAward = () => {
    const {t} = useTranslation();
    const currentSearchParams = new URLSearchParams(window.location.search);
    const isRegistration = currentSearchParams.get('register') === 'true';
    const navigate = useNavigate();
    const methods = useManagedForm({
        mode: 'onTouched',
        resolver: isRegistration ? yupResolver(awardRegistryFormValidations(t)) : yupResolver(awardFormValidations(t)),
    });
    const {trigger, formState: {isValid}, getValues} = methods;
    const {user} = useContext(AppContext);
    const [createAward] = useMutation(CREATE_AWARD_MUTATION, {
        refetchQueries: [  {query: REQUEST_AWARDS_QUERY, variables: {
            parameters: {
                showAllItems: true,
                showOnlyDisabledItems: false
            }
        }},
        {query: REQUEST_AWARDS_QUERY, variables: {
            parameters: {
                showAllItems: false,
                showOnlyDisabledItems: false
            }
        }},],
        awaitRefetchQueries: true,
    });
    const [registerAward] = useMutation(REGISTER_AWARD_MUTATION);

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = getValues()
        if (!isRegistration) {
            data.description = textEditorFormat(data?.description)
            data.awardGuidelines = textEditorFormat(data?.awardGuidelines)
            data.agencyNotes = textEditorFormat(data?.agencyNotes)
            data.awardCategories = textEditorFormat(data?.awardCategories)
        }
        if (user?.userRole?.name === "Subscriber" || user?.userRole?.name === "Subscriber Administrator") {
            registerAward(
                {
                    variables: {
                        parameters: {
                            award: {
                                ...data,
                                status: 'Pending',
                                applicationDeadline: new Date().toISOString('MM/DD/YYYY'),
                            },
                        },
                    },
                })
                .then(() => {
                    Toast({
                        type: 'success',
                        message: t('successMessage.awardCreated')
                    });
                    navigate(`/agency/awards/browse`)
                })
                .catch((error) => {
                    const errorMessage = error.graphQLErrors.map((x) => x.message);
                    Toast({
                        type: 'error',
                        message: errorMessage[0]
                    });
                });
        } else {
            createAward({
                variables: {
                    parameters: {
                        award: {
                            ...data,
                            status: user?.userRole?.name === 'Administrator' ? 'Approved' : 'Pending',
                        },
                    },
                },
            })
                .then((res) => {
                    let awardId = res?.data?.createAward?.data?.id
                    Toast({
                        type: 'success',
                        message: t('successMessage.awardCreated')
                    });
                    navigate(`/administrator/awards/${awardId}?update_award`, { replace: true })
                })
                .catch((error) => {
                    const errorMessage = error.graphQLErrors.map((x) => x.message);
                    Toast({
                        type: 'error',
                        message: errorMessage[0]
                    });
                });
        }
    }
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            <FormProvider {...methods} >
                <form
                    onBlur={() => {
                        trigger()
                    }}
                >
                    <FormWrapperComponent
                        variant="add"
                        backTo={user?.userRole?.name.includes('Subscriber') ? `/agency/awards/browse` : '/administrator/awards'}
                        submitFn={isValid ? handleSubmit : null}
                        disableBarSave={user?.userRole?.name.includes('Subscriber')}
                        stepper={user?.userRole?.name.includes('Subscriber')}
                        stepperSubtitle={t('enter.award.stepper.addSubtitle')}
                        stepperSubmit={handleSubmit}
                    >
                        <AwardForm/>
                    </FormWrapperComponent>
                </form>
            </FormProvider>
        </Box>

    );
};