import {
    navAdministratorItems,
    navClientItems, navClientNewTaskItems, navContactItems,
    navNewAdminUserItems,
    navNewAgencyItems,
    navNewAgencyItemsAdmin,
    navNewAwardItems,
    navNewClientItems, navNewClientSubscriberItems,
    navNewClientUserItems,
    navNewEventItems,
    navNewIndustryItems,
    NavNewSubmissionItems,
    navNewSubscriberItems,
    navNewTaskItems,
    navNewTeamMemberUserItems, navReadAwardItems, navReadOnlyEventItems,
    navRegistryAwardItems, navRegistryEventItems, navSubscriberItems
} from "./navItems";
import {useContext} from "react";
import {AppContext} from "../../../App";

export const GetNavigationOptions = ({userRole, currentPath, searchPath,isView}) => {
    const includesUpdate = searchPath.includes('update');
    const includesNew = currentPath.includes('/new');
    const {user} = useContext(AppContext);
    const rolePaths = {
        "Administrator": {
            'new_award': navNewAwardItems,
            'update_award': navNewAwardItems,
            'new_industry': navNewIndustryItems,
            'update_industry': navNewIndustryItems,
            'new_event': navNewEventItems,
            'update_event': navNewEventItems,
            'new_user_profile': navNewAdminUserItems,
            'update_user': navNewAdminUserItems,
            'new_agency': navNewAgencyItems,
            'update_agency': navNewAgencyItemsAdmin,
            'new_client': navNewClientItems,
            'update_client': navNewClientItems,
        },
        "Subscriber": {
            'new_award': navRegistryAwardItems,
            'update_award': navReadAwardItems,
            'new_event': navRegistryEventItems,
            'update_event': navReadOnlyEventItems,
            'new_user_profile': navNewTeamMemberUserItems,
            'update_user': navNewTeamMemberUserItems,
            'update_client_user': navNewClientUserItems,
            'new_agency': navNewSubscriberItems,
            'update_agency': navNewSubscriberItems,
            'new_client': navNewClientItems,
            'update_client': navNewClientSubscriberItems,
            'new_task': navNewTaskItems,
            'update_task': navNewTaskItems,
            'new_contact': navContactItems,
            'update_contact': navContactItems,
        },
        "Client": {
            'new_award': navNewAwardItems,
            'update_award': navNewAwardItems,
            'update_event': navNewEventItems,
            'new_event': navNewEventItems,
            'new_user_profile': navNewClientUserItems,
            'update_user': navNewClientUserItems,
            'new_client': navNewClientItems,
            'update_client': navNewClientItems,
            'update_task': navClientNewTaskItems,
        }
    };

    if (!includesUpdate && !includesNew) {
        switch (userRole) {
            case "Administrator":
                return navAdministratorItems(user);
            case "Subscriber":
            case "Subscriber Administrator":
                return navSubscriberItems(user);
            case "Client":
            case "Client Administrator":
                return navClientItems(user);
            default:
                return [];
        }
    } else {
        let userRoleName = userRole;
        if (userRole === "Subscriber" || userRole === "Subscriber Administrator") {
            userRoleName = "Subscriber";
        } else if (userRole === "Client" || userRole === "Client Administrator") {
            userRoleName = "Client";
        }
        for (let path in rolePaths[userRoleName]) {
            if (currentPath.includes(path) || searchPath.includes(path)) {
                return rolePaths[userRoleName][path](includesUpdate, currentPath, userRoleName, user,searchPath,isView);
            }
        }
        if (currentPath.includes('new_submission') || searchPath.includes('update_submission')) {
            return NavNewSubmissionItems(includesUpdate, currentPath, userRoleName, user,searchPath,isView);
        }
    }
    return [];
};
export const getInitials = (title) => {
    // Split the title by spaces to find words
    const words = title.split(' ');

    // Check if the title is a single word
    if (words.length === 1) {
        // Return the first and last letter of the single word
        return words[0].length > 1 ? `${words[0][0]}${words[0][words[0].length - 1]}` : words[0][0];
    }

    // If more than one word, return the initial of each of the first two words
    return words.slice(0, 2).map(word => word[0]).join('');
};