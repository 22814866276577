import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ModalComponent({ isOpen, handleClose, children, sxBox}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 700,
        maxWidth: '90%',
        maxHeight: '90vh', // Use vh unit instead of a fixed value
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:'12px',
        px: 3,
        py:2
    };
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            backdropComponent={Backdrop}
        >
            <Box sx={{...style, ...sxBox}}>
                {/* Close button inside the modal */}
                <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 10,
                        color:"white"
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {children}
            </Box>
        </Modal>
    );
}
