import React, {useState, useEffect, useContext, useMemo, Suspense} from 'react';
import GlobalDataGridPro from "../manage";
import UserColumns from './helpers/columns';
import searchByString from "../../helpers/searchByString";
import {AppContext} from "../../App";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Toast from "../alerts";
import {useMutation} from "@apollo/client";
import {
    SET_PRIMARY_TEAM_MEMBER_MUTATION
} from "../../graphql/contact";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import { REQUEST_CLIENT_QUERY } from 'src/graphql/client';
import {CircularProgress} from "@mui/material";
import AssignTeamMembers from "../forms/assignTeamMembers";

export const ViewTeamMemberUsersOfClientGrid = ({list, inForm = false, loading, client}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {clientId} = useParams();
    const {user} = useContext(AppContext);
    const {getValues} = useFormContext();
    const assignedUsers = getValues("assignedUsers") || [];
    const primaryContact = useMemo(() => assignedUsers.find(user => user?.isPrimaryContact === true), [assignedUsers]);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);

    const [setPrimaryContact] = useMutation(SET_PRIMARY_TEAM_MEMBER_MUTATION,{
        refetchQueries: [{
            query: REQUEST_CLIENT_QUERY,
            variables: {
                parameters: {
                    client: {
                        id: clientId
                    }
                }
            }
        }],
        awaitRefetchQueries: true
    });

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const handleSetPrimaryContact = (id) => {
        setPrimaryContact({
            variables: {
                parameters: {
                    user: {
                        id: id
                    },
                    client: {
                        id: clientId
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        }).then(() => {
            Toast({
                type: 'success',
                message: t('successMessage.primaryTeamMemberUpdated')
            });

        }).catch((error) => {
            const errorMessage = error.graphQLErrors.map((x) => x.message);
            Toast({
                type: 'error',
                message: errorMessage[0]
            });
        });
    }
    const toggleClose = () => {
        setIsOpen(!isOpen)
    }
    const CustomColumns = React.useMemo(
        () => [
            ...UserColumns(primaryContact),
            {
                field: 'actions', type: 'actions', resizable: false, width: 100, getActions: (params) => [
                    <GridActionsCellItem
                        key={params.id}
                        disabled={params.id === primaryContact?.id}
                        label={t('button.primaryContact')}
                        id="22e2d8d6-71b5-408a-82a7-78597cf6dbf9-set-primary-contact"
                        icon={<VerifiedUserIcon/>}
                        onClick={() => {
                            handleSetPrimaryContact(params.id)
                        }}
                        showInMenu={true}/>,
                ]
            }
        ], [primaryContact]);

    const dataGridProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Users",
        title: inForm ? "" : "Contacts",
        searchLabel: "Search User",
        rows: searchResults,
        columns: CustomColumns,
        pagination: false,
        autoHeight: false,
        handleSearch: handleSearch,
        handleAdd: toggleClose,
        addLabel: "button.assignTeamMembers",
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataGridPro {...dataGridProps} inForm={inForm}/>
            {isOpen && (
                <Suspense fallback={<CircularProgress />}>
                    <AssignTeamMembers isOpen={isOpen} handleClose={toggleClose} client={client} />
                </Suspense>
            )}
        </>
    );
};