import React, {useState, useEffect, useContext} from 'react';
import searchByString from "../../helpers/searchByString";
import {AppContext} from "../../App";
import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import GlobalDataListPro from "../list";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {
    CREATE_INTERESTED_CLIENT_QUERY,
    REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY,
    REQUEST_INTERESTED_CLIENTS_QUERY,
} from "../../graphql/interestedClient";
import Toast from "../alerts";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
    REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY
} from "../../graphql/client";

export const ManageUnassignedInterestedClientsOfAwardGrid = (
    {
        list,
        showAll,
        toggleShowAll,
        award,
        loading
    }) => {
    const {user} = useContext(AppContext);
    const {t} = useTranslation();
    const awardId = award?.id || '';
    const awardName = award?.name || '';
    const subscriberId = user?.subscriber?.id

    const [addClient, setAddClient] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        if (!showAll && selectedOption === '') {
            setSelectedOption('myClients')
        } else if (showAll && selectedOption === '') {
            setSelectedOption('allClients')
        }
    }, [showAll]);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        // Perform the search
        const results = searchByString(list, searchTerm);
        setSearchResults(results);
    };
    const [createInterestedClient] = useMutation(CREATE_INTERESTED_CLIENT_QUERY,
        {
            refetchQueries: [
                {
                    query: REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY, variables: {
                        parameters: {
                            award: {
                                id: awardId
                            },
                            subscriber: {
                                id: subscriberId
                            }
                        }
                    }
                },
                {
                    query: REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY, variables: {
                        parameters: {
                            award: {
                                id: awardId
                            },
                            teamMember: {
                                id: !showAll ? user?.id : null
                            },
                            subscriber: {
                                id: subscriberId
                            }
                        }
                    }
                },
                {
                    query: REQUEST_INTERESTED_CLIENTS_QUERY, variables: {
                        parameters: {
                            interestedClient: {
                                recognition: {
                                    award: {}
                                },
                                subscriber: {
                                    id: subscriberId
                                }
                            }
                        }
                    }
                }
            ],
            awaitRefetchQueries: true
        });
    const handleSubmit = (params) => {
        setAddClient(true)
        const {id} = params;
        let interestedClient = {
            interestedClient: {
                client: {
                    id: id
                },
                recognition: {
                    award: {
                        id: awardId,
                        name: awardName
                    },
                    willSpeak: false,
                }
            },
        }
        createInterestedClient({
            variables: {
                parameters: {
                    ...interestedClient,
                    subscriber: {
                        id: subscriberId
                    }
                },
            },
        })
            .then(() => {
                setAddClient(false)
                Toast({
                    type: 'success',
                    message: t('successMessage.interestedClientCreated')
                });
            })
            .catch((error) => {
                setAddClient(false)
                const errorMessage = error.graphQLErrors.map((x) => x.message);
                Toast({
                    type: 'error',
                    message: errorMessage[0]
                });
            });
    }
    const handleRadioChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        toggleShowAll();
    };

    const listActions = ({params}) => {
        return (
            <Button variant="outlined" style={{color: '#1976D2', borderColor: '#1976D2'}} disabled={addClient}
                    onClick={() => {
                        handleSubmit(params)
                    }} startIcon={< AddOutlinedIcon/>}>
                ADD
            </Button>
        )
    }
    const filterList = () => {
        return (
            <div>
                <RadioGroup value={selectedOption} onChange={handleRadioChange} sx={{color: "white"}} row>
                    <FormControlLabel
                        value="myClients"
                        control={<Radio color="primary"/>}
                        label={t("button.myClients")}
                    />
                    <FormControlLabel
                        value="allClients"
                        control={<Radio color="primary"/>}
                        label={t("button.allClients")}
                    />
                </RadioGroup>
            </div>
        )
    }
    const dataListProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Clients",
        title: t("manage.manageUnassignedInterestedClientsOfAward.title"),
        subtitle: t("manage.manageUnassignedInterestedClientsOfAward.subtitle"),
        searchLabel: t("manage.manageUnassignedInterestedClientsOfAward.search"),
        items: searchResults,
        maxItems: 5,
        hasActions: true,
        Actions: listActions,
        filterCircle: filterList,
        autoHeight: false,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading: loading
    }
    return (
        <>
            <GlobalDataListPro {...dataListProps}/>
        </>

    );
};