import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useTranslation} from "react-i18next";

export const DataGridToolbar = (parameters) => {
    const {t} = useTranslation();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 1,
            px: 1
        }}>
            <Typography variant="formHeader" id={parameters?.id + '-label'}>
                {parameters?.hasTitle && parameters?.title}
            </Typography>
            <Box>
                {parameters?.handleQuickFilter && <>
                    {parameters?.handleQuickFilter?.component()}
                </>
                }
                {parameters?.handleImport &&
                    <Button
                        sx={{borderRadius: '12px'}}
                        variant="contained"
                        size="small"
                        startIcon={<FileUploadIcon/>}
                        onClick={parameters.handleImport}
                        id={parameters?.id + '-import'}
                    >
                        {t('button.importCAP')}
                    </Button>
                }
                {parameters?.handleAdd &&
                    <Button
                        sx={{ml: 2, borderRadius: '12px'}}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={parameters.handleAdd}
                        id={parameters?.id + '-add'}
                    >
                        {parameters?.addLabel ? t(parameters?.addLabel) : t('button.addCAP')}
                    </Button>
                }
            </Box>
        </Box>
    );
};