import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ViewClientsGrid} from "./component";
import {PageHeader} from "../pageHeader";
import {useQuery} from "@apollo/client";
import {AppContext} from "../../App";
import {useParams} from "react-router-dom";
import {t} from "i18next";
import {REQUEST_CLIENTS_OF_AWARD_QUERY} from "../../graphql/interestedClient";
export default function ViewClientsOfAwardComponent({inForm = false, award}) {
    const [clientItems, setClientItems] = useState([]);
    //This will Replace the data with the data from the API
    const {awardId} = useParams();
    const {user} = useContext(AppContext);
    const {data, loading} = useQuery(REQUEST_CLIENTS_OF_AWARD_QUERY,
        {
            variables: {
                parameters: {
                    award: {
                        id: awardId
                    },
                    subscriber: {
                        id: user?.subscriber?.id
                    }
                }
            }
        });
    useEffect(() => {
        if (data?.requestInterestedClientsOfAward?.data) {
            setClientItems(data?.requestInterestedClientsOfAward?.data);
        }
    }, [data]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                px: 2,
            }}
        >
            {inForm ? <></> : <PageHeader title={t("headers.viewClients.subTitle")}/>}
            <ViewClientsGrid
                list={clientItems} 
                inForm={inForm}
                loading={loading}
                award={award}
            />
        </Box>
    );
}
