import * as React from "react";
import {t} from "i18next";
import {Avatar, Link} from '@mui/material';

const ClientColumns = (handleButtonClick) => {
    return [
        {
            field: 'name',
            headerName: t('columns.viewEventAnalyticsOfClients.name'),
            flex: 100,
            minWidth: 250,
            align: "left",
            headerAlign: 'left',
            renderCell: (params) => (
                <Link href={`/agency/clients/${params?.id}?update_client`}>{params.value}</Link>),
        },
        {
            field: 'events',
            headerName: t('columns.viewEventAnalyticsOfClients.events'),
            width: 180,
            align: "center",
            headerAlign: 'center',
            resizable: false,
            sortable: false,
            renderCell: (params) => {
                return <Avatar
                    onClick={() => {
                        if(params?.row?.analytics?.events === 0) return;
                        handleButtonClick(params);
                    }}
                    sx={{
                        backgroundColor: "#717485",
                        color: "#fff",
                        fontSize: "1rem",
                        width: 30,
                        height: 30,
                    }}
                >
                    {params?.row?.analytics?.events}
                </Avatar>
            },
            valueGetter: (params) => {
                return params?.row?.analytics?.events
            }
        },
        {
            field: 'analytics.speaking',
            headerName: t('columns.viewEventAnalyticsOfClients.speaking'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.speaking
            }
        },
        {
            field: 'attending',
            headerName: t('columns.viewEventAnalyticsOfClients.attending'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.attending
            }
        },
        {
            field: 'sponsor',
            headerName: t('columns.viewEventAnalyticsOfClients.sponsor'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.sponsor
            }
        },
        {
            field: 'other',
            headerName: t('columns.viewEventAnalyticsOfClients.other'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.other
            }
        },
        {
            field: 'deadlineNet30',
            headerName: t('columns.viewEventAnalyticsOfClients.deadline'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.deadlineNet30
            }
        },
        {
            field: 'submissionsTracking',
            headerName: t('columns.viewEventAnalyticsOfClients.submissionTracking'),
            flex: 100,
            minWidth: 250,
            align: "center",
            headerAlign: 'center',
            valueGetter: (params) => {
                return params?.row?.analytics?.submissionsTracking
            }
        },

    ]
}
export default ClientColumns;