import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {MarketingIcon, PublicRIcon, SocialMediaIcon} from "../components/icons";
import {useTranslation} from "react-i18next";

export const leftOptions = [
    {
        icon: <MarketingIcon sx={{fontSize: 40}}/>,
        label: 'login.marketing'
    },
    {
        icon: <SocialMediaIcon sx={{fontSize: 40}}/>,
        label: 'login.socialMedia'
    },
    {
        icon: <PublicRIcon sx={{fontSize: 40}}/>,
        label: 'login.publicRelation'
    }
];
export default function SplitView({children}) {
    const {t} = useTranslation();

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <CssBaseline/>
            <Grid
                item
                xs={false}
                md={6}
                sx={{
                    backgroundImage: 'linear-gradient(0deg, 000000, 000000),\n' +
                        '            linear-gradient(0deg, #2C394B, #2C394B)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <img
                    src={window.location.origin + '/PRMLogo.svg'}
                    srcSet={`${window.location.origin}/PRMLogo.svg 300w, ${window.location.origin}/PRMLogo.svg 768w, ${window.location.origin}/PRMLogo.svg 1280w`}
                    alt='Technica PRM Logo'
                    width={'80%'}
                    loading="lazy"
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '80%',
                    mt: 8
                }}>
                    {leftOptions.map((item) => {
                        return (
                            <Box
                                key={item.label}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    my: 2
                                }}>
                                {item.icon}
                                <Typography sx={{ml: 2}} variant='subtitle1'>
                                    {t(item.label)}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>

            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                component={Paper}
                elevation={6}
                square
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
}