// Function to reorder the items based on the filtered view.
export function reorderInOriginalArray(allItems, filteredItems, oldIndex, newIndex) {
    // Create a shallow copy of allItems to avoid mutating the original array.
    const itemsCopy = [...allItems];

    // Find the actual item from the filteredItems using the oldIndex.
    const itemToMove = filteredItems[oldIndex];

    // Find the corresponding index of the item in the original array.
    const originalIndex = itemsCopy.findIndex(item => item.id === itemToMove.id);

    // Remove the item from the copied array.
    const [reorderedItem] = itemsCopy.splice(originalIndex, 1);

    if (newIndex === filteredItems.length - 1) {
        // Find the last item in the allItems array that has the same status as the itemToMove.
        const lastSameStatusIndex = findLastIndexWithStatus(itemsCopy, itemToMove.submissionStatus);
        // Place the item after the last item with the same status.
        itemsCopy.splice(lastSameStatusIndex + 1, 0, reorderedItem);
    } else {
        // Calculate the new index in the original array.
        const referenceItemId = filteredItems[newIndex]?.id;
        const referenceOriginalIndex = referenceItemId ? itemsCopy.findIndex(item => item.id === referenceItemId) : -1;

        // Calculate the target index for reinsertion.
        let targetIndex = referenceOriginalIndex >= 0 ? referenceOriginalIndex : itemsCopy.length;

        // Adjust the targetIndex when moving down in the list.
        if (newIndex > oldIndex && referenceOriginalIndex >= 0) {
            targetIndex++;
        }

        // Insert the item back into the copied array at the new index.
        itemsCopy.splice(targetIndex, 0, reorderedItem);
    }

    return itemsCopy;
}

// Helper function to find the last index of an item with the same status.
function findLastIndexWithStatus(items, status) {
    for (let i = items.length - 1; i >= 0; i--) {
        if (items[i].submissionStatus === status) {
            return i;
        }
    }
    return -1;
}
