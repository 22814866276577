import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {DataGridToolbar} from "./utilis/dataGridHeader";
import GlobalDataGridProComponent from "./component";
import {DataGridSubToolbar} from "./utilis/dataGridSubHeader";
import {DataGridFilterToolbar} from "./utilis/dataGridFilterToolbar";
import "../manage/utilis/dataGrid.css";
import {useTableFilter} from "../filters/containers/hooks/filterProvider";
import CellWithTooltip from './utilis/cellWithTooltip';

// Custom sorting comparator with trimming, case-insensitive comparison, and special character check
const emptyValuesLastComparator = (v1, v2) => {
    // Function to check if the string starts with a special character
    const startsWithSpecialChar = (str) => /^[^a-zA-Z0-9]/.test(str);

    // Trim and convert to lowercase if values are strings
    if (typeof v1 === 'string') {
        v1 = v1.trim().toLowerCase();
    }
    if (typeof v2 === 'string') {
        v2 = v2.trim().toLowerCase();
    }

    // Check for special character start
    const v1Special = typeof v1 === 'string' && startsWithSpecialChar(v1);
    const v2Special = typeof v2 === 'string' && startsWithSpecialChar(v2);

    // Sort strings starting with a special character to the end
    if (v1Special && !v2Special) return 1;
    if (!v1Special && v2Special) return -1;

    // Sort non-empty values before empty values
    if (v1 !== null && v1 !== undefined && v1 !== '' && (v2 === null || v2 === undefined || v2 === '')) {
        return -1;
    }
    if ((v1 === null || v1 === undefined || v1 === '') && v2 !== null && v2 !== undefined && v2 !== '') {
        return 1;
    }

    // If neither value is empty or starts with a special character, fall back to the default sorting
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};


// Utility function to add ellipsis and tooltip to columns
const enhanceColumnsWithEllipsisAndTooltip = (columns) => {
    return columns.map((column) => {
        if (column.field === 'actions' || column.noTooltip) {
            return column;
        }

        const renderCell = (params) => {
            const {title, ...rest} = params;
            const value = title ?? params.value;
            const cellContent = column.renderCell ? column.renderCell(rest) : value;

            return (
                <CellWithTooltip value={value} currentWidth={params?.colDef?.width}>
                    {cellContent}
                </CellWithTooltip>
            )
        };

        // Add the sorting comparator to the column
        const sortComparator = column.sortComparator ? column.sortComparator : emptyValuesLastComparator;

        return {
            ...column,
            renderCell,
            cellClassName: 'ellipsis-cell',
            sortComparator // Add this line
        };
    });
};

const GlobalDataGridPro = (parameters) => {
    const {columns, inForm, id, hasSubTitle} = parameters;
    const {hasSelectedFilters} = useTableFilter(id);
    const paperStyle = {
        elevation: inForm ? 0 : 2,
        height: inForm ? '100%' : hasSubTitle ?{xs: 'calc(100vh - 178px)'} : {xs: 'calc(100vh - 112px)'},
        borderRadius: '12px',
        backgroundColor: inForm ? 'transparent' : '#2C394B',
        overflow: 'hidden'
    };

    const boxStyle = {
        p: inForm ? 0 : 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    };

    // Memoize the enhanced columns to prevent them from being recalculated on every render
    const enhancedColumns = React.useMemo(() => {
        return columns ? enhanceColumnsWithEllipsisAndTooltip(columns) : [];
    }, [columns]);

    return (
        <Paper elevation={paperStyle.elevation} sx={paperStyle}>
            <Box sx={{...boxStyle}} id={id + '-container'}>
                <Box sx={{marginBottom: '8px'}}>
                    <DataGridToolbar {...parameters} />
                    <DataGridSubToolbar {...parameters} />
                    {hasSelectedFilters(id) && <DataGridFilterToolbar {...parameters} />}
                </Box>
                <Box sx={{flexGrow: 1, overflow: 'hidden'}}>
                    <GlobalDataGridProComponent {...parameters} columns={enhancedColumns}/>
                </Box>
            </Box>
        </Paper>
    );
}

export default React.memo(GlobalDataGridPro);
