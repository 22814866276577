import * as React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import Link from "@mui/material/Link";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import CustomStepper from "./Stepper";
import {PathArray} from "../breadcrumbs/helpers";
import DataProtectionPopUp from "../popUpAlerts/dataProtection";
import {useContext} from "react";
import {AppContext} from "../../App";

const defaultHeader = ({title, status, stepper, stepperSubtitle, stepperSubmit, variant}) => {
    const paths = PathArray()
    const titleExists = false; //remove this line if you want to show the title
    return (
        <Paper
            elevation={2}
            sx={{
                mb: 2,
                borderRadius: '12px',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    px: 3,
                    py: 1.5,
                }}
            >
                <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: "24px"}}>
                    {paths.map((path, index) => {
                        let formattedPath = path?.value
                        const pathValue = path?.url
                        const isLast = index === paths.length - 1;

                        if (isLast) {
                            return (
                                <Typography
                                    key={pathValue}
                                    sx={{
                                        cursor: 'default',
                                        fontWeight: '500',
                                        fontSize: "24px",
                                        color: "rgb(238,129,77)"
                                    }}
                                >
                                    {formattedPath}
                                </Typography>
                            );
                        }

                        return (
                            <Link
                                underline="hover"
                                color="inherit"
                                sx={{cursor: 'pointer'}}
                                key={pathValue}
                                href={pathValue}
                            >
                                {formattedPath}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
                {titleExists ? (
                    <Typography variant="subtitle2">
                        {title}
                    </Typography>
                ) : (
                    <Box sx={{height: 24}}> {/* Placeholder with the height of the title */}</Box>
                )}
            </Box>
            <CustomStepper status={status} stepper={stepper} submitFn={stepperSubmit} subtitle={stepperSubtitle}
                           variant={variant}/>
        </Paper>
    )
}
const FormWrapperComponent = (parameters) => {
    const {
        title,
        variant,
        children,
        backTo,
        cancelFn,
        disableBarSave = false,
        hasCustomHeader = false,
        customHeader,
        submitFn,
        status,
        stepper,
        stepperSubtitle,
        stepperSubmit
    } = parameters;
    const location = useLocation();
    const paths = location?.pathname?.split('/').filter((path) => path);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {formState: {isValid, isDirty, touchedFields}} = useFormContext();
    const {isFormDirty, setFormDirty, currentSideNavWidth, isMinimized} = useContext(AppContext);
    const hasChanges = isDirty && Object.keys(touchedFields).length > 0
    const discardChanges = () => {
        setFormDirty(false)
        navigate(backTo); // Navigate only if user confirms
    };
    const searchPath = location?.search;
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box>
                    {hasCustomHeader === false ? defaultHeader({
                        paths, title, navigate, searchPath, status, stepper, stepperSubtitle, stepperSubmit, variant
                    }) : customHeader}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', paddingBottom: !disableBarSave && '64px'}}>
                    {children}
                </Box>
                {!disableBarSave &&
                    <Paper
                        elevation={8}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            left: isMinimized ? 96 : `${currentSideNavWidth}px`,
                            right: 16,
                            p: 1.7,
                            justifyContent: 'flex-end',
                            borderRadius: '12px',
                            position: 'fixed',
                            bottom: 0,
                            zIndex: 1000
                        }}>
                        <Button
                            variant="outlined"
                            color="blue"
                            disabled={variant === 'add' ? false : !hasChanges}
                            sx={{mr: 2}}
                            onClick={() => {
                                if (variant === 'add') {
                                    if (isFormDirty) {
                                        DataProtectionPopUp.show(isFormDirty, discardChanges, t);
                                    } else {
                                        navigate(backTo)
                                    }
                                } else {
                                    cancelFn()
                                }
                            }}
                        >{t('button.cancelCAP')}</Button>
                        <Button
                            variant="contained"
                            color="blue"
                            sx={{color: '#fff'}}
                            onClick={submitFn}
                            disabled={(!isValid && !hasChanges) || (variant !== 'add' && !hasChanges)}
                            type='button'
                        >
                            {variant === 'add' ? t('button.createCAP') : t('button.saveCAP')}
                        </Button>
                    </Paper>
                }
            </Box>
        </Box>
    );
};
export default FormWrapperComponent;
