import React from "react";
import {Route, Routes} from "react-router-dom";
import Login from "../../pages/Login/PresentCredentialOfUser";
import ManageEventsAdministrator from "../../pages/Administrator/ManageEvents";
import {AddEvent as AddEventEvent, AddEvent as AddEventAdministrator} from "../../components/manageEvents/Add";
import {EditEvent as EditEventEvent, EditEvent as EditEventAdministrator} from "../../components/manageEvents/Edit";
import ManageAwardsAdministrator from "../../pages/Administrator/ManageAwards";
import {AddAward as AddAwardAward, AddAward as AddAwardAdministrator} from "../../components/manageAwards/Add";
import {EditAward as EditAwardAward, EditAward as EditAwardAdministrator} from "../../components/manageAwards/Edit";
import ManageSubscribersAdministrator from "../../pages/Administrator/ManageSubscribers";
import {AddSubscriber as AddSubscriberAdministrator} from "../../components/manageSubscribers/Add";
import {EditSubscriber as EditSubscriberAdministrator} from "../../components/manageSubscribers/Edit";
import ManageUsersAdministrator from "../../pages/Administrator/ManageUsers";
import {AddUser as AddUserAdministrator} from "../../components/manageUsers/Add";
import {EditUser as EditUserAdministrator} from "../../components/manageUsers/Edit";
import ManageIndustriesAdministrator from "../../pages/Administrator/ManageIndustries";
import {AddIndustry as AddIndustryAdministrator} from "../../components/manageIndustries/Add";
import {EditIndustry as EditIndustryAdministrator} from "../../components/manageIndustries/Edit";
import ViewEventsEvent from "../../pages/Event/ViewEvents";
import ViewAnalyticsOfEvents from "../../pages/Event/ViewAnalyticsOfEvents";
import ViewEventSubmissionsOfSubscriberPage from "../../pages/Event/ViewEventSubmissionsOfSubscriber";
import {EditSubmission as EditSubmissionEvent} from "../../components/manageEventSubmissionsOfSubscriber/Edit";
import ViewTasksOfSubmissionEvent from "../../pages/Event/ViewTasksOfSubmission";
import {EditClient as EditClientClient} from "../../components/manageClientsOfSubscriber/Edit";
import ViewAwardsAward from "../../pages/Award/ViewAwards";
import ViewAwardAnalyticsPage from "../../pages/Award/ViewAwardAnalytics";
import ViewAwardSubmissionsOfSubscriberPage from "../../pages/Award/ViewAwardSubmissionsOfSubscriber";
import {EditSubmission as EditSubmissionAward} from "../../components/manageAwardSubmissionsOfSubscriber/Edit";
import ViewTasksOfSubmissionAward from "../../pages/Award/ViewTasksOfSubmission";
import AddTask from "../../components/manageTasks/Add";
import EditTask from "../../components/manageTasks/Edit";
import ViewClientsOfSubscriberClient from "../../pages/Client/ViewClientsOfSubscriber";
import {AddClient as AddClientClient} from "../../components/manageClientsOfSubscriber/Add";
import {AddContact} from "../../components/manageContacts/Add";
import {EditContact} from "../../components/manageContacts/Edit";
import ViewEventAnalyticsOfClientsPage from "../../pages/Event/ViewEventAnalyticsOfClients";
import ViewAwardAnalyticsOfClientPage from "../../pages/Award/ViewAwardAnalyticsOfClient";
import {AddUser as AddTeamMemberUser} from "../../components/manageTeamMemberUsers/Add";
import {EditUser as EditTeamMemberUser} from "../../components/manageTeamMemberUsers/Edit";
import EditSubscriberPage from "../../pages/SubscriberProfile/EditSubscriber";
import ManageTeamMemberUsersComponent from "../../components/manageTeamMemberUsers";
import {Home, ForwardToLogin, ForwardToDashboard} from "./redirects";

export const generateRoutes = ({user}) => {
    //use React state and auth token to confirm
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    const isChangePassword = !!token;
    if (isChangePassword) {
        return (
            <Routes>
                <Route path="/changepassword" element={<Login isChangePassword={true}/>}/>
            </Routes>
        );
    } else if (!user && !isChangePassword) {
        return (
            <Routes>
                <Route path="/" element={<Home user={user}/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="*" element={<ForwardToLogin/>}/>
            </Routes>
        );
    } else if (user && user?.userRole?.name === "Administrator") {
        return (
            <Routes>
                <Route path="/" element={<Home user={user}/>}/>
                <Route path={"/administrator"}>
                    <Route index element={<ForwardToDashboard path={'/administrator/events'}/>}/>
                    <Route path="events/*">
                        <Route index element={<ManageEventsAdministrator/>}/>
                        <Route path="new_event" element={<AddEventAdministrator/>}/>
                        <Route path=":paramId?" element={<EditEventAdministrator/>}/>
                    </Route>
                    <Route path="awards/*">
                        <Route index element={<ManageAwardsAdministrator/>}/>
                        <Route path="new_award" element={<AddAwardAdministrator/>}/>
                        <Route path=":awardId?" element={<EditAwardAdministrator/>}/>
                    </Route>
                    <Route path="agencies/*">
                        <Route index element={<ManageSubscribersAdministrator/>}/>
                        <Route path="new_agency" element={<AddSubscriberAdministrator/>}/>
                        <Route path=":subscriberId?" element={<EditSubscriberAdministrator/>}/>
                    </Route>
                    <Route path="administrators/*">
                        <Route index element={<ManageUsersAdministrator/>}/>
                        <Route path="new_user_profile" element={<AddUserAdministrator/>}/>
                        <Route path=":userId?" element={<EditUserAdministrator/>}/>
                    </Route>
                    <Route path="industries/*">
                        <Route index element={<ManageIndustriesAdministrator/>}/>
                        <Route path="new_industry" element={<AddIndustryAdministrator/>}/>
                        <Route path=":industryId?" element={<EditIndustryAdministrator/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<Home user={user}/>}/>
            </Routes>
        )
    } else if (user && user?.userRole?.name.includes('Subscriber')) {
        return (
            <Routes>
                <Route path="/" element={<Home user={user}/>}/>
                <Route path="/agency">
                    <Route index element={<ForwardToDashboard path={'/agency/events/browse'}/>}/>
                    <Route path="events">
                        <Route index element={<ForwardToDashboard path={'/agency/events/browse'}/>}/>
                        <Route path="browse">
                            <Route index element={<ViewEventsEvent/>}/>
                            <Route path="new_event" element={<AddEventEvent/>}/>
                            <Route path=":paramId?" element={<EditEventEvent/>}/>
                        </Route>
                        <Route path="analytics">
                            <Route index element={<ViewAnalyticsOfEvents/>}/>
                            <Route path=":paramId?" element={<EditEventEvent/>}/>
                        </Route>
                        <Route path="submissions">
                            <Route index element={<ViewEventSubmissionsOfSubscriberPage/>}/>
                            <Route path=":submissionId?" element={<EditSubmissionEvent/>}/>
                        </Route>
                        <Route path="tasks">
                            <Route index element={<ViewTasksOfSubmissionEvent/>}/>
                            <Route path="new_task" element={<AddTask type='event'/>}/>
                            <Route path=":taskId?" element={<EditTask type='event'/>}/>
                        </Route>
                        <Route path="clients/*">
                            <Route path=":clientId?" element={<EditClientClient/>}/>
                        </Route>
                    </Route>
                    <Route path="awards">
                        <Route index element={<ForwardToDashboard path={'/agency/awards/browse'}/>}/>
                        <Route path="browse">
                            <Route index element={<ViewAwardsAward/>}/>
                            <Route path="new_award" element={<AddAwardAward/>}/>
                            <Route path=":awardId?" element={<EditAwardAward/>}/>
                        </Route>
                        <Route path="analytics">
                            <Route index element={<ViewAwardAnalyticsPage/>}/>
                            <Route path=":awardId?" element={<EditAwardAward/>}/>
                        </Route>
                        <Route path="submissions">
                            <Route index element={<ViewAwardSubmissionsOfSubscriberPage/>}/>
                            <Route path=":submissionId?" element={<EditSubmissionAward/>}/>
                        </Route>
                        <Route path="tasks">
                            <Route index element={<ViewTasksOfSubmissionAward/>}/>
                            <Route path="new_task" element={<AddTask type='award'/>}/>
                            <Route path=":taskId?" element={<EditTask type='award'/>}/>
                        </Route>
                        <Route path="clients/*">
                            <Route path=":clientId?" element={<EditClientClient/>}/>
                        </Route>
                    </Route>
                    <Route path="clients">
                        <Route index element={<ForwardToDashboard path={'/agency/clients/browse'}/>}/>
                        <Route path="browse">
                            <Route index element={<ViewClientsOfSubscriberClient/>}/>
                        </Route>
                        <Route path="new_client" element={<AddClientClient/>}/>
                        <Route path=":clientId?" element={<EditClientClient/>}/>
                        <Route path="contacts">
                            <Route index element={<ForwardToDashboard path={'/agency/clients/event_analytics'}/>}/>
                            <Route path="new_contact" element={<AddContact/>}/>
                            <Route path=":contactId?" element={<EditContact/>}/>
                        </Route>
                        <Route path="event_analytics">
                            <Route index element={<ViewEventAnalyticsOfClientsPage/>}/>
                            <Route path=":clientId?" element={<EditClientClient/>}/>
                        </Route>
                        <Route path="award_analytics/*">
                            <Route index element={<ViewAwardAnalyticsOfClientPage/>}/>
                            <Route path=":clientId?" element={<EditClientClient/>}/>
                        </Route>
                    </Route>
                    <Route path="user">
                        <Route index element={<ForwardToDashboard path={'/agency/events/browse'}/>}/>
                        <Route path="new_user_profile" element={<AddTeamMemberUser/>}/>
                        <Route path=":userId?" element={<EditTeamMemberUser/>}/>
                    </Route>
                    <Route path="agency">
                        <Route index element={<ForwardToDashboard path={'/agency/events/browse'}/>}/>
                        <Route path=":subscriberId?" element={<EditSubscriberPage/>}/>
                    </Route>
                    <Route path="team_members">
                        <Route path="new_user_profile" element={<AddTeamMemberUser/>}/>
                        <Route path=":userId?" element={<EditTeamMemberUser/>}/>
                        <Route index element={<ManageTeamMemberUsersComponent/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<Home user={user}/>}/>
            </Routes>)
    } else {
        // Auth routes
        return (
            <Routes>
                <Route path="/" element={<Home user={user}/>}/>
                <Route path="*" element={<Home user={user}/>}/>
            </Routes>
        );
    }
};