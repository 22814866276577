import React, {useContext, useEffect, useState} from 'react';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import DeleteInterestedClient from "./Delete";
import searchByString from "../../helpers/searchByString";
import { AppContext } from "../../App";
import {
    DELETE_INTERESTED_CLIENT_MUTATION, REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY, REQUEST_INTERESTED_CLIENTS_QUERY
} from "../../graphql/interestedClient";
import GlobalDataListPro from "../list";
import Button from "@mui/material/Button";
import {REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY} from "../../graphql/client";

export const ManageInterestedClientsOfAwardGrid = ({ list, loading, award, showAll }) => {
    const { t } = useTranslation();
    const { user } = useContext(AppContext);
    const awardId = award?.id || '';
    const subscriberId = user?.subscriber?.id

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(list);

    useEffect(() => {
        setSearchResults(list)
    }, [list])

    const [deleteInterestedClient] = useMutation(DELETE_INTERESTED_CLIENT_MUTATION, {
        refetchQueries: [
            {
                query: REQUEST_INTERESTED_CLIENTS_OF_AWARD_QUERY, variables: {
                    parameters: {
                        award: {
                            id: awardId
                        },
                        subscriber: {
                            id: subscriberId
                        }
                    }
                }
            },
            {
                query: REQUEST_UNASSIGNED_CLIENTS_OF_AWARD_QUERY, variables: {
                    parameters: {
                        award: {
                            id: awardId
                        },
                        teamMember: {
                            id: !showAll ? user?.id : null
                        },
                        subscriber: {
                            id: subscriberId
                        }
                    }
                }
            },
            {
                query: REQUEST_INTERESTED_CLIENTS_QUERY, variables: {
                    parameters: {
                        interestedClient: {
                            recognition: {
                                award: {}
                            },
                            subscriber: {
                                id: subscriberId
                            }
                        }
                    }
                }
            }
        ],
        awaitRefetchQueries: true
    });

    const handleDelete = DeleteInterestedClient(deleteInterestedClient);
    const handleDeleteInterestedClient = (id) => {
        handleDelete(id);
    }
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        // Perform the search
        const results = searchByString(list, value);
        setSearchResults(results);
    };

    const ListActions = ({ params }) => (
        <Button
            variant="outlined" style={{ color: '#1976D2', borderColor: '#1976D2' }}
            onClick={() => {
                handleDeleteInterestedClient(params.id);
            }}
            startIcon={<DeleteForeverIcon/>}
        >
            DELETE
        </Button>
    );

    const dataListProps = {
        id: "c301bd08-a46c-4210-b472-d2d63e2c1227-Interested-Clients",
        title: t("manage.manageAssignedInterestedClientsOfAward.title"),
        subtitle: t("manage.manageAssignedInterestedClientsOfAward.subtitle"),
        searchLabel: t("manage.manageAssignedInterestedClientsOfAward.search"),
        items: searchResults,
        keyName: "client.name",
        hasActions: list.length > 0,
        Actions: ListActions,
        autoHeight: false,
        handleSearch: handleSearch,
        searchTerm: searchTerm,
        loading
    };

    return <GlobalDataListPro {...dataListProps}/>;
};
